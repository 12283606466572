import "./css.css"
import React from 'react';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Skeleton } from 'primereact/skeleton';

function TableStackedBar({ initData, loadingAll, loading }) {
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (initData) {
            const {
                labels,
                result
            } = initData;

            setChartData({
                labels,
                datasets: result
            })
        }
    }, [initData])

    // console.log('chartData', chartData);

    const renderTable = () => {
        let header = []
        let body = []

        if (chartData && chartData.datasets && _.isArray(chartData.datasets) && chartData.labels && _.isArray(chartData.labels)) {
            chartData.datasets.map((items, idx) => {
                const { label } = items;
                header.push(
                    <th key={idx} style={{ verticalAlign: `middle` }}>{label}</th>
                )
            })

            chartData.labels.map((item, index) => {
                body.push(
                    <tr key={index}>
                        <td>{item}</td>
                        {chartData.datasets.map((item, idx) => {
                            return (
                                <td key={`${index}-${idx}`} style={{ verticalAlign: `middle` }}>{chartData.datasets[idx] && chartData.datasets[idx].data && chartData.datasets[idx].data[index]}</td>
                            )
                        })}
                    </tr>
                )
            })


            return (
                <>
                    {header.length > 0 && body.length > 0 && (
                        <table className='table table-bordered chart-table'>
                            <thead style={{backgroundColor: '#e74c3c'}}>
                                <tr>
                                    <th style={{ verticalAlign: `middle` }}>Kênh/Suất</th>
                                    {header}
                                </tr>
                            </thead>
                            <tbody style={{backgroundColor: '#ffc6ba'}}>
                                {body}
                            </tbody>
                        </table>
                    )}
                </>
            )
        }

        return null;
    }

    // console.log('chartData', chartData);

    return (
        <>
            {(loadingAll || loading) && (
                <Skeleton width="100%" height='195px' borderRadius="16px"></Skeleton>
            )}
            {!loadingAll && !loading && Object.keys(chartData).length > 0 &&(
                <div className="p-card" style={{ padding: 10, }}>
                    {renderTable()}
                </div>
            )}
        </>
    );
}

TableStackedBar.propTypes = {
    initData: PropTypes.any,
    loadingAll: PropTypes.bool,
    loading: PropTypes.bool,
};

TableStackedBar.defaultProps = {
    initData: [],
    loadingAll: false,
    loading: false,
}

export default TableStackedBar;