// import * as types from './actionTypes';
// import * as api  from './serviceEndpoint'
// const axios = require('axios').default;

// axios.interceptors.response.use((response) => {
//     console.log(response)
//     return response
// }, (err) => {

//     console.log(err)
//     // Return any error which is not due to authentication bac
//     if (err.response.status === 403) {
//         localStorage.removeItem(types.ACCESSTOKEN_STORAGE);
//         localStorage.removeItem(types.REFRESHTOKEN_STORAGE);
//         localStorage.clear();
//         window.location = '/';
//     }
//     // console.log(store.getState().auth.token)
//     if (err.response.status === 401) {
//         return getNewToken()
//                 .then((res) => {
//                     let config = err.config;
//                     const { accesstoken, refreshtoken } = res;
//                     //  console.log(accesstoken)
//                     config.headers['Authorization'] = accesstoken;
//                     localStorage.setItem(types.ACCESSTOKEN_STORAGE,accesstoken);
//                     localStorage.setItem(types.REFRESHTOKEN_STORAGE,refreshtoken);
//                     return new Promise((resolve, reject) => {
//                         axios.request(config)
//                             .then(res => resolve(res))
//                             .catch(e => reject(e))
//                     })
//                 })
//     }

//     // return new (Promise as any).reject(err) 
// })

// const getNewToken = () => {
//     let token =  localStorage.getItem(types.REFRESHTOKEN_STORAGE);
//     //  console.log(token)
//     let req = {
//         url: api.REFRESHTOKEN,
//         method: 'POST',
//         headers: {
//             "Content-Type": "application/json"
//         },
//         data: JSON.stringify({
//             refreshToken: token
//         })
//     };
//     return new Promise((resolve, reject) => {
//         axios(req)
//             .then((res) => {
//                 return resolve(res.data)
//             })
//             .catch(e => {
//                 //  console.log(e);
//                 return reject(e)
//             })
//     })
// }








import axios from 'axios';
import * as enums from '../../common/enum';
import * as types from "../constants/actionTypes"
import { REFRESHTOKEN } from "../constants/serviceEndpoint"

let axiosInstance = axios.create({
    baseURL: ''
});

const getLocalRefreshToken = () => {
    return localStorage.getItem(types.REFRESHTOKEN_STORAGE);
}

const refreshToken = async () => {
    let request = {
        url: REFRESHTOKEN,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify({
            refreshToken: getLocalRefreshToken()
        })
    }
    // console.log(JSON.stringify(request));
    return new Promise((resolve, reject) => {
        axios(request)
            .then(async (response) => {
                console.log(`error > `, response);
                if (response.data && response.data.status === enums.RESPONSE_FORBIDDEN) {
                    await localStorage.removeItem(types.ACCESSTOKEN_STORAGE);
                    await localStorage.removeItem(types.REFRESHTOKEN_STORAGE);
                    window.location.href = '/login';
                    return;
                }

                // if(response.data && response.data.status === enums.RESPONSE_FORBIDDEN){
                //     window.location.href = '/login';
                //     return;
                // }
                return resolve(response.data)
            })
            .catch(e => {
                //  console.log(e);
                return reject(e)
            })
    })
}
// axiosInstance.defaults.baseURL = '';
// let isRefreshing = false;

axiosInstance.interceptors.response.use(
    async response => {
        // console.log(`data -->`, response);
        // console.log(response.config, 'Token expired')
        if (response.data && response.data.Status === enums.RESPONSE_UNAUTHORIZED) {
            console.log(enums.RESPONSE_UNAUTHORIZED)
            // if(!isRefreshing){

            return refreshToken()
                .then(async (res) => {
                    // console.log(res.data);
                    if (res.data && res.data.status === enums.RESPONSE_FORBIDDEN) {
                        await localStorage.removeItem(types.ACCESSTOKEN_STORAGE);
                        await localStorage.removeItem(types.REFRESHTOKEN_STORAGE);
                        window.location.href = '/login';
                        return;
                    }
                    let config = response.config;
                    const { accesstoken, refreshtoken } = res;
                    //  console.log(accesstoken)
                    config.headers['Content-Type'] = 'application/json';
                    config.headers['Authorization'] = "Bearer " + accesstoken;

                    // console.log(JSON.stringify(config.headers));

                    await localStorage.setItem(types.ACCESSTOKEN_STORAGE, accesstoken);
                    await localStorage.setItem(types.REFRESHTOKEN_STORAGE, refreshtoken);

                    // isRefreshing = true;
                    return new Promise((resolve, reject) => {
                        axios.request(config)
                            .then(res => resolve(res))
                            .catch(e => reject(e))
                    })
                })
            // }
        }

        if (response.data && response.data.status === enums.RESPONSE_FORBIDDEN) {
            console.log('Redirect to login page')
            await localStorage.removeItem(types.ACCESSTOKEN_STORAGE);
            await localStorage.removeItem(types.REFRESHTOKEN_STORAGE);
            window.location.href = '/login';
            return;
        }
        // Do something with response data
        return response;
    },
    error => {
        // console.log(`data error -->`, error);
        return Promise.reject(error);
    });

export async function axiosWithHeadersNoToken(verb, url, data) {
    // axiosInstance.defaults.headers.common['Content-Type'] = '';

    let request = {
        url: url,
        method: verb,
        headers: {
            "Content-Type": "application/json"
        },
        data: JSON.stringify(data)
    };

    return await axiosInstance(request);
};

export async function axiosWithHeaders(verb, url, data) {

    let token = localStorage.getItem(types.ACCESSTOKEN_STORAGE);
    // console.log(token);

    let request = {
        url: url,
        method: verb,
        headers: {
            "Content-Type": "application/json",
            'Authorization': "Bearer " + token
        },

    };

    if (verb.toLowerCase() === "post") {
        if (data) {
            request.data = JSON.stringify(data)
        }
    }

    if (verb.toLowerCase() === "get") {
        if (data)
            request.params = data
    }
    // console.log(request);
    return await axiosInstance(request);
};


export async function axiosWithHeadersFormData(verb, url, body) {
    let token =  localStorage.getItem(types.ACCESSTOKEN_STORAGE);
    let request = {
        url: url,
        method: verb,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': "Bearer " + token
        },
        data: body
    };
    return await axiosInstance(request);
};

export async function axiosWithHeadersDownload(verb, url, body) {
    let token =  localStorage.getItem(types.ACCESSTOKEN_STORAGE);
    let request = {
        url: url,
        method: verb,
        headers: {
            "Content-Type": "application/json",
            'Authorization': "Bearer " + token
        },
        data: body,
        responseType: 'blob'
    };
    return await axiosInstance(request);
};





