import * as types from '../constants/actionTypes';
import { USER_CHANGE_PASSWORD, USER_GET_INFO_ADMIN } from '../constants/serviceEndpoint';
import { deCryptUserId, enCryptServer } from '../../crypto/Crypto';

export const account_ini = () => {
    return {
        type: types.ACCOUNT_INI
    };
};

export const account_set = (user) => {
    return {
        type: types.ACCOUNT_SET,
        user
    }
}

export const account_set_change_password = () => {
    return {
        type: types.ACCOUNT_SET_CHANGE_PASSWORD
    }
}

export const account_message = (message) => {
    return {
        type: types.ACCOUNT_MESSAGE,
        message
    }
}

export function getUserInfo() {
    return dispatch => {
        let id = deCryptUserId();
        var deCode = enCryptServer([id]);
        fetch(`${USER_GET_INFO_ADMIN}`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(deCode)
        }).then((response) => response.json())
            .then((data) => {
                if (data.status && data.status === 200) {
                    dispatch(account_set(data.result));
                }
            }).catch(err => {
                console.log(err);
            });
    }
}

export function changePassword(oldPassword, newPassword) {
    return dispatch => {
        let id = deCryptUserId();
        let request = { userId: id, oldPassword: oldPassword, newPassword: newPassword };
        fetch(USER_CHANGE_PASSWORD, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(request)
        }).then((response) => response.json())
            .then((data) => {
                if (data.status && data.status === 200) {
                    localStorage.removeItem(types.CHANGE_PASSWORD_STORAGE);
                    dispatch(account_set_change_password());
                }
            }).catch(err => console.log(err));
    }
}