import React from 'react';
import {
    Chart,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Skeleton } from 'primereact/skeleton';

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: [10, 30, 46, 23, 38, 40, 33],
            borderColor: '#FF3333',
            backgroundColor: '#FF3333',
            // datalabels: {
            //     align: 'start',
            //     anchor: 'start'
            // }
        },
        {
            label: 'Dataset 2',
            data: [27, 4, 16, 36, 22, 32, 44],
            borderColor: '#FFC433',
            backgroundColor: '#FFC433',
            // datalabels: {
            //     align: 'end',
            //     anchor: 'end'
            // }
        },

    ],
};

function LineChart({
    initData,
    loadingAll,
    loading,
}) {
    const [chartData, setChartData] = useState(data);

    useEffect(() => {
        let list = _.cloneDeep(initData);
        // console.log('initData', initData);s
        if (list) {
            const {
                labels,
                result
            } = list;



            let dataSet = [];

            let objDataSet = {
                labels,
            };

            if (_.isArray(result)) {
                result.forEach((item) => {
                    dataSet.push(
                        {
                            label: item.label,
                            data: item.data,
                            borderColor: item.borderColor,
                            backgroundColor: item.backgroundColor
                        }
                    )
                });
            }

            objDataSet.datasets = dataSet;

            console.log(dataSet)

            setChartData(objDataSet);
            // console.log('objDataSet', objDataSet);
        }
    }, [initData])

    // const getOrCreateLegendList = (chart, id) => {
    //     const legendContainer = document.getElementById(id);
    //     let listContainer = null;
    //     if (legendContainer) {
    //         listContainer = legendContainer.querySelector('ul');

    //         if (!listContainer) {
    //             listContainer = document.createElement('ul');
    //             listContainer.style.display = 'flex';
    //             listContainer.style.flexDirection = 'row';
    //             listContainer.style.margin = 0;
    //             listContainer.style.padding = 0;

    //             legendContainer.appendChild(listContainer);
    //         }
    //     }
    //     return listContainer;
    // };

    const options = {
        responsive: true,

        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: 'Ngày',
                    font: {
                        size: 14,
                        weight: 'bold',
                        lineHeight: 1.2,
                    },
                    padding: { top: 0, left: 0, right: 0, bottom: 0 }
                }
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: 'Số lượng cửa hàng',
                    font: {
                        size: 14,
                        weight: 'bold',
                        lineHeight: 1.2,
                    },
                    padding: { top: 0, left: 0, right: 0, bottom: 0 },
                },
                beginAtZero: true,
                suggestedMax: 69
            },
        },

        ///////////////////////////////////////////Option 1////////////////////////////////////////////
        // maintainAspectRatio: false,
        // plugins: {

        //     legend: {
        //         display: true,
        //         position: 'bottom',
        //     },
        //     title: {
        //         display: false,
        //         padding: {
        //             bottom: 30
        //         },
        //         weight: "bold",
        //         color: "#00325c",
        //         font: {
        //             size: 13
        //         },
        //         align: "start"
        //     },
        //     datalabels: {
        //         backgroundColor: function (context) {
        //             return context.dataset.backgroundColor;
        //         },
        //         borderRadius: '100%',
        //         color: 'transparent',
        //         font: {
        //             weight: 'bold'
        //         },
        //         formatter: Math.round,
        //         padding: 2
        //     },
        //     // aspectRatio: 2 / 1,
        //     layout: {
        //         padding: {
        //             top: 32,
        //             right: 16,
        //             bottom: 16,
        //             left: 8
        //         }
        //     },
        //     elements: {
        //         line: {
        //             fill: false,
        //             tension: 0.4
        //         }
        //     },
        //     scales: {
        //         y: {
        //             stacked: true
        //         }
        //     } 
        // },

        ///////////////////////////////////////////Option 2////////////////////////////////////////////
        maintainAspectRatio: false,
        plugins: {
            htmlLegend: {
                // ID of the container to put the legend in
                containerID: 'legend-container',
            },
            legend: {
                display: false,
                // position: 'bottom',
            },
            datalabels: {
                backgroundColor: function (context) {
                    return context.dataset.backgroundColor;
                },
                borderRadius: '100%',
                color: 'transparent',
                font: {
                    weight: 'bold'
                },
                formatter: Math.round,
                padding: 2
            },
        }
    };


    const htmlLegendPlugin = {
        id: "htmlLegend",
        afterUpdate(chart, args, options) {
            // console.log('chart', chart);
            // console.log('args', args);
            // console.log('options', options, options.containerID);
            // const ul = getOrCreateLegendList(chart, options.containerID);
            // if (ul) {
            //     while (ul.firstChild) {
            //         ul.firstChild.remove();
            //     }
            // }


            // Reuse the built-in legendItems generator
            const items = chart.options.plugins.legend.labels.generateLabels(chart);
            const ul = document.querySelector(".item-legend");
            ul.innerHTML = '';
            ul.style.display = "flex";
            items.forEach(item => {
                const li = document.createElement('li');
                li.style.alignItems = 'center';
                li.style.cursor = 'pointer';
                li.style.display = 'flex';
                li.style.flexDirection = 'row';
                li.style.marginLeft = '10px';

                li.onclick = () => {
                    const { type } = chart.config;
                    if (type === 'pie' || type === 'doughnut') {
                        // Pie and doughnut charts only have a single dataset and visibility is per item
                        chart.toggleDataVisibility(item.index);
                    } else {
                        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                    }
                    chart.update();
                };

                // Color box
                const boxSpan = document.createElement('div');
                // boxSpan.style.background = item.fillStyle;
                // boxSpan.style.border = `1px solid ${item.fillStyle}`;
                // boxSpan.style.borderWidth = item.lineWidth + 'px';
                boxSpan.style.display = 'flex';
                boxSpan.style.alignItems = 'center';
                boxSpan.style.position = 'relative';
                boxSpan.style.height = '20px';
                boxSpan.style.marginRight = '10px';
                boxSpan.style.width = '30px';

                const circle = document.createElement('div');
                circle.style.background = item.fillStyle;
                circle.style.borderRadius = '50%';
                circle.style.height = '10px';
                circle.style.left = '50%';
                circle.style.position = 'absolute';
                circle.style.top = '50%';
                circle.style.transform = 'translate(-50%, -50%)';
                circle.style.width = '10px';
                boxSpan.appendChild(circle);

                const line = document.createElement('div');
                line.style.background = item.fillStyle;
                line.style.height = '3px';
                line.style.width = '100%';

                boxSpan.appendChild(line);

                // line.style.left = '50%';

                // Text
                const textContainer = document.createElement('p');
                textContainer.style.color = item.fontColor;
                textContainer.style.margin = 0;
                textContainer.style.padding = 0;
                textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);

                li.appendChild(boxSpan);
                li.appendChild(textContainer);

                ul.appendChild(li);
            });

            const jsLegend = document.getElementById("js-legend");


            // console.log('items', items);
            jsLegend.appendChild(ul);
            // console.log(chart.options.plugins.legend)
            // const items = chart.options.plugins.legend.labels.generateLabels(chart);
            // const ul = document.createElement("ul");
            // items.forEach(item => {
            //     const li = document.createElement("li");
            //     const boxSpan = document.createElement("span");
            //     boxSpan.style.background = item.fillStyle;
            //     li.appendChild(boxSpan);
            //     li.appendChild(document.createTextNode(item.text));
            //     ul.appendChild(li);
            // });
            // const jsLegend = document.getElementById("js-legend");
            // jsLegend.appendChild(ul);
        }
    };

    // console.log('chartData', JSON.stringify(data));
    // console.log('chartData', JSON.stringify(chartData));
    // console.log('chartData', chartData);

    return (
        <>
            {(loadingAll || loading) && (
                <Skeleton width="100%" height='514px' borderRadius="16px"></Skeleton>
            )}
            {!loadingAll && !loading && (
                <div className="p-card" style={{ padding: 10, }}>
                    <div style={{ padding: `5px 0px`, borderBottom: `1px solid #c4c4c4`, fontSize: 20 }}>
                        <b>BIỂU ĐỒ TIẾN ĐỘ</b>
                    </div>
                    <div style={{ height: 400 }}>
                        <Line id='chart-line' datasetIdKey="lineChart" data={chartData} options={options} plugins={[htmlLegendPlugin]} />
                    </div>
                    <div id='js-legend' className='d-flex justify-content-center' style={{ padding: `20px 30px 0 30px` }}>
                        <ul className='item-legend d-flex'></ul>
                    </div>
                </div>
            )}
        </>
    );
}

LineChart.propTypes = {
    initData: PropTypes.any,
    loadingAll: PropTypes.bool,
    loading: PropTypes.bool,
};

LineChart.defaultProps = {
    initData: {},
    loadingAll: false,
    loading: false,
}

export default LineChart;