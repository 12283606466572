import React, { useMemo, Fragment } from 'react';
import { Button } from 'primereact/button';
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import * as actionTypes from '../../store/constants/actionTypes';
import { withRouter } from "react-router-dom";

const AccountSetting = ({ account, changeEvent, onLogout, history }) => {
    console.log(account);

    const onLogoutClick = () => {
        changeEvent(null);
        onLogout();
    }

    const settingPage = () => {
        history.push("/event/setting");
    }

    const renderBody = useMemo(() => {
        if (account) {
            const {
                FullName,
                // userName,
                // phone,
            } = account.userInfo;
            return (
                <div className="p-col-12">
                    <Row style={{ paddingBottom: 5, borderBottom: '1px solid #d8d8d8' }}>
                        <Col xs={4} className="notification-thumbnail">
                            <div className="div-img-account">
                                <img src={`${process.env.PUBLIC_URL}/img/web-16.png`} alt={FullName} className="img-account" />
                            </div>
                        </Col>
                        <Col xs={8}>
                            <Row>
                                <Col xs={12}>
                                    <span style={{ fontWeight: "bold", textTransform: 'uppercase' }}>Thông tin tài khoản</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <span>Họ Tên : <b>{FullName}</b></span>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col xs={12}>
                                    <span>Di động : <b>{phone}</b></span>
                                </Col>
                            </Row> */}
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: 5 }}>
                        <Col xs={6}>
                            <Button
                                label="Cài đặt"
                                icon="pi pi-cog"
                                className="p-button-raised p-button-warning"
                                onClick={settingPage}
                            />
                        </Col>
                        <Col xs={6} className="text-right">
                            <Button
                                label="Đăng xuất"
                                icon="pi pi-power-off"
                                className="p-button-raised p-button-secondary"
                                onClick={onLogoutClick}
                            />
                        </Col>
                    </Row>
                </div>
            )
        }
        return null;
    }, [account])

    return (
        <Fragment>
            {renderBody}
        </Fragment>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout()),
        changeEvent: (eventId) => dispatch(actions.event_set(eventId)),
    }
}

export default withRouter(connect(null, mapDispatchToProps)(AccountSetting));
