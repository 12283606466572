import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Skeleton } from 'primereact/skeleton';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgb(255, 99, 132)',
        },
        {
            label: 'Dataset 2',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgb(75, 192, 192)',
        },
        {
            label: 'Dataset 3',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
            backgroundColor: 'rgb(53, 162, 235)',
        },
    ],
};


function StackedBar({ initData, loadingAll, loading }) {
    // console.log('data', data, initData);
    const [chartData, setChartData] = useState(data);

    const chunkArray = (arr, size) => {
        var groupedArray = [];
        for (var i = 0; i < arr.length; i += size) {
            groupedArray.push(
                arr.slice(i, i + size)
            );
        }
        return groupedArray;
    }

    const options = {
        maintainAspectRatio: false,
        plugins: {
            // title: {
            //     display: true,
            //     text: 'Chart.js Bar Chart - Stacked',
            // },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    // usePointStyle: true,
                    // pointStyle: 'circle',                                    
                    padding: 10,
                    width: 10
                }
            },
        },
        responsive: true,
        scales: {
            x: {
                display: true,
                stacked: true,
                title: {
                    display: true,
                    text: 'Kênh/Suất',
                    font: {
                        size: 14,
                        weight: 'bold',
                        lineHeight: 1.2,
                    },
                    padding: { top: 0, left: 0, right: 0, bottom: 0 }
                },
                ticks: {
                    crossAlign: 'far',
                    callback: function (value, index, labels) {
                        var title = chartData.labels[value].split(' ');

                        let chunked = chunkArray(title, 3);
                        let newTitle = [];

                        chunked.forEach((item) => {
                            newTitle.push(item.join(' '));
                        });

                        return newTitle;
                    }
                }
            },
            y: {
                display: true,
                stacked: true,
                title: {
                    display: true,
                    text: 'Số lượng',
                    font: {
                        size: 14,
                        weight: 'bold',
                        lineHeight: 1.2,
                    },
                    padding: { top: 0, left: 0, right: 0, bottom: 0 },
                    ticks: {
                        beginAtZero: true,
                    }
                }
            },
        },
    };


    useEffect(() => {
        if (initData) {
            const {
                labels,
                result
            } = initData;
            setChartData({
                labels,
                datasets: result
            })
        }
    }, [initData])

    return (
        <>
            {(loadingAll || loading) && (
                <Skeleton width="100%" height='514px' borderRadius="16px"></Skeleton>
            )}
            {!loadingAll && !loading && (
                <div className="p-card" style={{ padding: 10, }}>
                    <div style={{ padding: `5px 0px`, borderBottom: `1px solid #c4c4c4`, fontSize: 20 }}>
                        <b>BIỂU ĐỒ TRẠNG THÁI</b>
                    </div>
                    <div style={{ height: 453 }}>
                        <Bar data={chartData} options={options} />
                    </div>
                </div>
            )}
        </>
    );
}

StackedBar.propTypes = {
    initData: PropTypes.any,
    loadingAll: PropTypes.bool,
    loading: PropTypes.bool,
};

StackedBar.defaultProps = {
    initData: {},
    loadingAll: false,
    loading: false,
}

export default StackedBar;