import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import * as router from "./common/route";

// import Layout from './hoc/layout';
import Template from "./shared/template";
import TemplateLogin from "./shared/templateLogin";

import Login from "./components/pages/Login";
import Home from "./components/admin/event/ListEvent";
//Admin
import ListInfo from "./components/admin/info/listinfo";
// import ListInfo from './view/info/list';

import DetailInfo from "./components/admin/info/detailinfo";
import DetailLayer from "./components/admin/layer/detaillayer";
import DetailInfoInt from "./components/admin/info/detailInfoInt";

import ListEvent from "./components/admin/event/ListEvent";
import UpdateEvent from "./components/admin/event/UpdateEvent";
import ListStore from "./components/admin/store/liststore";
import DetailStore from "./components/admin/store/detailstore";
import ImportStore from "./components/admin/store/importstore";

import ListUser from "./components/admin/user/listuser";
import AddUser from "./components/admin/user/adduser";
import DetailUser from "./components/admin/user/detailuser";
import ImportUser from "./components/admin/user/importuser";

//Cài đặt
import Setting from "./components/admin/setting/setting";

//Nhà phân phối
import ListSupplier from "./components/admin/suppiler/listsupplier";
import DetailSupplier from "./components/admin/suppiler/detailsupplier";
//Danh sách sản phẩm
import ListProduct from "./components/admin/product/listproduct";
//Danh sách hình ảnh sản phẩm
import ListImageProduct from "./components/admin/imageproduct/listimageproduct";
//Danh sách layer
import ListLayer from "./components/admin/layer/listlayer";
import Import from "./components/admin/import/index";
//Dashboard
import Dashboard from "./components/admin/dashboard/dashboard";

const Loader = () => (
  <div style={{ textAlign: "center" }}>
    <img
      style={{ marginTop: "10%" }}
      src={`${process.env.PUBLIC_URL}/img/loading.gif`}
      alt="Waiting..."
    />
  </div>
);

const App = (props) => {
  let { isAuth, isChangePassword } = props;
  // console.log(isAuth, isChangePassword);
  let route = null;
  // let route = (
  //   <TemplateLogin>
  //     <Switch>
  //       <Route path={router.LOGIN} component={Login} />
  //       <Redirect to={router.LOGIN} />
  //     </Switch>
  //   </TemplateLogin>
  // );
  if (isAuth) {
    // if (!isChangePassword) {
    //   route = (
    //     <TemplateLogin>
    //       <Switch>
    //         <Route path={router.CHANGE_PASSWORD} component={Setting} />
    //         <Redirect to={router.CHANGE_PASSWORD} />
    //       </Switch>
    //     </TemplateLogin>
    //   );
    // } else {
    route = (
      <Switch>
        <Route path={router.LOGIN} component={Login} />
        <Route path={router.CHANGE_PASSWORD} component={Setting} />
        <Route>
          <Template>
            <Switch>
              <Route exact path={router.HOME} component={Home} />
              <Route path={router.CHANGE_PASSWORD} component={Setting} />
              <Route path={`${router.LIST_INFO}/:id`} component={ListInfo} />
              <Route
                exact
                path={`${router.DETAIL_INFO}/:id`}
                render={(props) => <DetailInfo {...props} />}
              />
              <Route
                exact
                path={`${router.DETAIL_LAYER}/:id`}
                render={(props) => <DetailLayer {...props} />}
              />
              <Route
                exact
                path={`${router.DETAIL_INFO_NEW}/:id`}
                render={(props) => <DetailInfoInt {...props} />}
              />
              <Route path={router.DASHBOARD} component={Dashboard} />
              <Route path={router.LIST_EVENT} component={ListEvent} />
              <Route path={router.LIST_STORE} component={ListStore} />
              <Route
                exact
                path={`${router.DETAIL_STORE}/:id`}
                render={(props) => <DetailStore {...props} />}
              />
              <Route path={router.IMPORT_STORE} component={ImportStore} />
              <Route path={router.LIST_USER} component={ListUser} />
              <Route path={router.ADD_USER} component={AddUser} />
              <Route
                exact
                path={`${router.DETAIL_USER}/:id`}
                render={(props) => <DetailUser {...props} />}
              />
              <Route path={router.IMPORT_USER} component={ImportUser} />
              <Route
                exact
                path={`${router.DETAIL_EVENT}/:id`}
                render={(props) => <UpdateEvent {...props} />}
              />
              <Route path={router.SETTING} component={Setting} />
              <Route path={router.LIST_SUPPLIER} component={ListSupplier} />
              <Route path={router.DETAIL_SUPPLIER} component={DetailSupplier} />
              {/* <Route path={router.LIST_PRODUCT} component={ListProduct} /> */}
              <Route
                path={router.LIST_IMAGE_PRODUCT}
                component={ListImageProduct}
              />
              <Route path={`${router.LIST_LAYER}/:id`} component={ListLayer} />
              <Route path={`${router.IMPORT}`} component={Import} />

              <Redirect to={router.HOME} />
            </Switch>
          </Template>
        </Route>
      </Switch>
    );
    // }
  }
  return <Suspense fallback={<Loader />}>{route}</Suspense>;
};
const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.accessToken !== "",
    // isChangePassword: state.account.isChangePassword
  };
};

export default withRouter(connect(mapStateToProps, null)(App));
