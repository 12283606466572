import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import * as actions from "../store/actions/index";
import * as actionTypes from "../store/constants/actionTypes";
import * as router from "../common/route";
import AccountSetting from "../view/account/accountsetting";

const NavMenu = ({ account, changeEvent, propEventId }) => {
  const accountDiv = useRef(null);
  const [eventId, setEventId] = useState(0);

  // useEffect(() => {
  //     if (account) {
  //         const {
  //             level
  //         } = account;
  //         console.log(level);
  //         setLevel(level);
  //     }
  // }, [account]);

  useEffect(() => {
    setEventId(propEventId);
  }, [propEventId]);

  const renderListInfo = () => {
    if (eventId !== undefined && eventId !== null && eventId !== "") {
      return (
        <Link to={router.LIST_INFO + "/" + eventId} className="nav-item-coca">
          <span>THÔNG TIN THEO CỬA HÀNG</span>
        </Link>
      );
    }
    return null;
  };

  const renderListLayer = () => {
    if (eventId !== undefined && eventId !== null && eventId !== "") {
      return (
        <Link to={router.LIST_LAYER + "/" + eventId} className="nav-item-coca">
          <span>BẢN NHÁP</span>
        </Link>
      );
    }
    return null;
  };

  const renderListInfoChannelPackage = () => {
    if (eventId !== undefined && eventId !== null && eventId !== "" &&  account.level !== "10") {
      return (
        <Link
          to={router.INFO_CHANNEL_PACKAGE + "/" + eventId}
          className="nav-item-coca"
        >
          <span>THÔNG TIN THEO SUẤT</span>
        </Link>
      );
    }
    return null;
  };

  // console.log(level);

  const renderListStore = () => {
    if (
      eventId !== undefined &&
      eventId !== null &&
      eventId !== "" &&
      account.level == "6"
    ) {
      return (
        <Link to={router.LIST_STORE} className="nav-item-coca">
          <span>
            {account && account.level === "6"
              ? "CỬA HÀNG"
              : "DANH SÁCH CỬA HÀNG"}
          </span>
        </Link>
      );
    }
    return null;
  };

  const resetEvent = () => {
    localStorage.removeItem(actionTypes.STORAGE_EVENTID);
    localStorage.removeItem(actionTypes.STORAGE_INFO);
    changeEvent(null);
  };

  const renderDashboard = () => {
    if (eventId !== undefined && eventId !== null && eventId !== "" && account && account.level != "10") {
      return (
        <Link to={router.DASHBOARD} className="nav-item-coca">
          <span>DASHBOARD</span>
        </Link>
      );
    }
    return null;
  };

  const renderListSupplier = () => {
    if (account && account.level === "6") {
      return (
        <Link to={router.LIST_SUPPLIER} className="nav-item-coca">
          <span>NHÀ PHÂN PHỐI</span>
        </Link>
      );
    }
    return null;
  };

  const renderListProduct = () => {
    // if (account && account.level === "6") {
    //   return (
    //     <Link to={router.LIST_PRODUCT} className="nav-item-coca">
    //       <span>SẢN PHẨM</span>
    //     </Link>
    //   );
    // }
    return null;
  };

  const renderImport = () => {
    if (eventId !== undefined && eventId !== null && eventId !== "" && account && account.level == "6") {
      return (
        <Link to={router.IMPORT} className="nav-item-coca">
          <span>IMPORT</span>
        </Link>
      );
    }
    return null;
  };

  return (
    <Fragment>
      <Navbar expand="lg" bg="dark" className="nav-cocacola">
        <Navbar.Brand>
          <Link to={router.HOME}>
            <img
              src={`${process.env.PUBLIC_URL}/img/web-18.png`}
              alt="logo"
              className="nav-menu-logo"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav button-logo-coca" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {renderDashboard()}
            <Link
              to={router.LIST_EVENT}
              className="nav-item-coca"
              onClick={resetEvent}
            >
              <span>HOẠT ĐỘNG</span>
            </Link>
            {renderListInfo()}
            {renderListLayer()}
            {account && account.level == "6" && renderListStore()}
            {account && ["1","2","3","6"].includes(`${account.level}`) && (
                <Link to={router.LIST_USER} className="nav-item-coca">
              <span>NHÂN VIÊN</span>
            </Link>
            )}
            
            {account && account.level == "6" && renderListSupplier()}
            {/* {renderListProduct()} */}
            {renderImport()}
          </Nav>
          <div style={{ display: "inline" }}>
            <Button
              icon="pi pi-user"
              onClick={(e) => accountDiv.current.toggle(e)}
              className="btn-account"
            />
            <OverlayPanel ref={accountDiv} className="p-setting-account">
              <div className="p-grid p-account-setting-body">
                <AccountSetting account={account} />
              </div>
            </OverlayPanel>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </Fragment>
  );
};

const mapPropsToState = (state) => {
  return {
    account: state.account,
    propEventId: state.events.eventId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeEvent: (eventId) => dispatch(actions.event_set(eventId)),
  };
};

export default connect(mapPropsToState, mapDispatchToProps)(NavMenu);
