import React, { Component, lazy, Suspense } from 'react';
import { deCryptUserId } from '../../../crypto/Crypto'
import { connect } from 'react-redux';
import {
    // GET_IMAGE_PRODUCT,
    ADD_IMAGE_PRODUCT,
    UPD_IMAGE_PRODUCT,
    DEL_IMAGE_PRODUCT,
    LIST_EVENT,
    GET_PRODUCT,
    GET_EVENT_LIST_IMAGE
}
    from '../../../store/constants/serviceEndpoint';
import * as actions from '../../../store/actions/index';
// import * as actionTypes from '../../../store/constants/actionTypes';
import * as enums from '../../../common/enum';
import { removeToken } from '../../../crypto/Crypto'
import { headerToken } from '../../../store/constants/header';
import * as router from '../../../common/route';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import LoadingView from '../../loadingview/LoadingView';
import * as actionTypes from '../../../store/constants/actionTypes';
import { axiosWithHeaders } from '../../../store/constants/axiosWrapper';
// const Grid = lazy(() => import('../../grid/grid'));
const axios = require("axios");
const moment = require("moment");
const Grid = lazy(() => import('../../grid/grid'));

class ListImageProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableId: 4,
            editMode: "inline",
            layoutTitle: "HÌNH ẢNH SẢN PHẨM",
            customParam: {},
            role: null,
            userId: null,
            titleDialog: '',
            listEvent: [],
            listImage: [],
            listProduct: [],
            eventId: ''
        }
    }

    componentDidMount() {
        this.GetListChannel();
        this.getListProduct();
        let eventId = localStorage.getItem(actionTypes.STORAGE_EVENTID);
        if ((eventId !== undefined && eventId !== null)) {
            this.setState({
                eventId: eventId
            })
            this.GetImageByEvent(eventId);
        }
    }

    deleteImageProduct = (e, id) => {
        const _this = this;
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa hình ảnh sản phẩm này không ?",
            type: 'warning',
            showCancelButton: true,
            //confirmButtonColor: '#3085d6',
            //cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý!',
            cancelButtonText: 'Trở về'
        }).then((result) => {
            if (result.value) {
                // axios({
                // method: 'post',
                // url: DEL_IMAGE_PRODUCT,
                // headers: headerToken(),
                // data: {
                //     Id: id
                // }
                // })
                axiosWithHeaders("post", DEL_IMAGE_PRODUCT, { Id: id })
                    .then(function (response) {
                        if (response.data.status === enums.RESPONSE_TOKEN_INVALID) {
                            removeToken();
                            _this.props.auth_ini();
                            _this.props.history.push(router.LOGIN);
                        }
                        if (response.data.status === enums.RESPONSE_OK) {
                            Swal.fire("Thông báo", "Xóa hình ảnh sản phẩm thành công !", "success")
                            _this.resetData(moment(new Date()));
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            }
        })
    }

    getListProduct = () => {
        const _this = this;
        // axios({
        //     method: 'post',
        //     url: GET_PRODUCT,
        //     headers: headerToken(),
        // })
        axiosWithHeaders("post", GET_PRODUCT, null)
            .then(function (response) {
                if (response.data.status === enums.RESPONSE_TOKEN_INVALID) {
                    removeToken();
                    _this.props.auth_ini();
                    _this.props.history.push(router.LOGIN);
                }
                if (response.data.status === enums.RESPONSE_OK) {
                    let data = response.data;
                    let { result } = data;
                    if (result !== undefined && result !== null && result.length > 0) {
                        let arr = [];
                        (result).map(item => {
                            arr.push({
                                "value": item.id,
                                "label": item.name
                            })

                        })
                        if (arr !== undefined && arr !== null && arr.length > 0) {
                            _this.setState({
                                listProduct: arr
                            })
                        }
                    } else {
                        Swal.fire("Lỗi", "Vui lòng thêm sản phẩm mới", "error");
                    }

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    GetImageByEvent = (eventId) => {
        const _this = this;
        // axios({
        //     method: 'get',
        //     url: GET_EVENT_LIST_IMAGE,
        //     headers: headerToken(),
        //     params: {
        //         id: eventId
        //     }
        // })
        axiosWithHeaders("get", GET_EVENT_LIST_IMAGE, { id: eventId })
            .then(function (response) {
                if (response.data.status === enums.RESPONSE_TOKEN_INVALID) {
                    removeToken();
                    _this.props.auth_ini();
                    _this.props.history.push(router.LOGIN);
                }
                if (response.data.status === enums.RESPONSE_OK) {
                    const data = response.data;
                    let arr = [];
                    (data.result).map(item => {
                        arr.push({
                            "value": item.id,
                            "label": item.name
                        })
                    })
                    _this.setState({
                        listImage: arr,
                        eventId: eventId
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    celltemplate = (row, column) => {
        const field = column.field;
        const userInfo = this.props.account.userInfo;
        if (userInfo !== undefined && userInfo !== null && Object.keys(userInfo).length > 0) {
            switch (field) {
                case 'STT':
                    return (column.rowIndex + 1)
                case 'range':
                    return null;
                case 'action':
                    if (userInfo.level === 6) {
                        return (
                            <div style={{ textAlign: "center" }}>
                                <Button
                                    icon="pi pi-pencil"
                                    className="p-button-success p-button-raised"
                                    style={{ marginRight: 7, width: 35 }}
                                    onClick={(e, uniqueId) => this.updateRow(e, row)}
                                />
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-danger p-button-raised"
                                    style={{ width: 35 }}
                                    onClick={(e, uniqueId) => this.deleteImageProduct(e, row.id)}
                                />
                            </div>
                        )
                    }
                    return null;
                default:
                    return null;


            }
        }
    }

    GetListChannel = () => {
        const _this = this;
        // axios({
        //     method: 'post',
        //     url: LIST_EVENT,
        //     headers: headerToken(),
        // })
        axiosWithHeaders("post", LIST_EVENT, null)
            .then(function (response) {
                if (response.data.status === enums.RESPONSE_TOKEN_INVALID) {
                    removeToken();
                    _this.props.auth_ini();
                    _this.props.history.push(router.LOGIN);
                }
                if (response.data.status === enums.RESPONSE_OK) {
                    const data = response.data;
                    let arr = [];
                    (data.result).map(item => {
                        arr.push({
                            "value": item.id,
                            "label": item.name
                        })
                    })

                    _this.setState({
                        listEvent: arr
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    onValueChange = (e, field) => {

        let obj = {}
        obj[field] = e.value;
        this.setState(obj)

        switch (field) {
            case 'eventId':
                this.GetImageByEvent(e.value);
                break;
            default:
                break;
        }
    }



    checkData = (obj) => {
        for (var field in obj) {
            if (obj[field] === undefined || obj[field] === null || obj[field] === "" || obj[field] === 0) {
                switch (field) {
                    case 'EventImageId':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn hình ảnh !' });
                        break;
                    case 'ProductId':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn sản phẩm !' });
                        break;
                    default:
                        break;
                }
                return false;
            }
        }
        return true;
    }

    addRow = (e) => {
        e.preventDefault();
        this.setState({
            visible: true,
            titleDialog: 'THÊM MỚI HÌNH ẢNH SẢN PHẨM',
            mode: 0,
            apiUpdate: ADD_IMAGE_PRODUCT,
            // eventId: null,
            eventImageId: null,
            productId: null,
        })
    }

    updateRow = (e, row) => {
        e.preventDefault();
        this.GetImageByEvent(row.eventId);
        this.setState({
            visible: true,
            titleDialog: 'CẬP NHẬT HÌNH ẢNH SẢN PHẨM',
            mode: 1,
            eventId: row.eventId,
            eventImageId: row.eventImageId,
            productId: row.productId,
            apiUpdate: UPD_IMAGE_PRODUCT,
            id: row.id
        })
    }

    updateData = (e) => {
        const _this = this;
        let objUpdate = {}
        objUpdate.EventImageId = this.state.eventImageId;
        objUpdate.ProductId = this.state.productId;
        const check = this.checkData(objUpdate);
        if (check) {
            if (this.state.mode === 0) {
                objUpdate.CreatedBy = deCryptUserId();
                // objUpdate.CreatedDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            } else {
                objUpdate.CreatedBy = this.state.createdBy;
                // objUpdate.CreatedDate = moment(this.state.createdDate).format("YYYY-MM-DD HH:mm:ss");
                objUpdate.Id = this.state.id
            }
            // axios({
            //     method: 'post',
            //     url: this.state.apiUpdate,
            //     headers: headerToken(),
            //     data: objUpdate
            //     })
            axiosWithHeaders("post", this.state.apiUpdate, objUpdate)
                .then(function (response) {
                    if (response.data.status == enums.RESPONSE_TOKEN_INVALID) {
                        removeToken();
                        _this.props.auth_ini();
                        _this.props.history.push(router.LOGIN);
                    }
                    if (response.data.status === enums.RESPONSE_OK) {
                        Swal.fire("Thông báo", "Cập nhật hình ảnh sản phẩm thành công !", "success")
                        _this.resetData(moment(new Date()));
                    }
                }).catch(function (error) {
                    console.log(error);
                })
        }
    }

    resetData(Id) {
        this.setState({
            visible: false,
            titleDialog: 'THÊM MỚI HÌNH ẢNH SẢN PHẨM',
            mode: 0,
            apiUpdate: ADD_IMAGE_PRODUCT,
            // eventId: null,
            eventImageId: null,
            productId: null,
            uniqueId: Id
        })
    }

    render() {
        const footer = (
            <div>
                <Button
                    label="Cập nhật"
                    icon="pi pi-check"
                    onClick={(e, Id) => this.updateData(e)}
                    className="p-button-raised p-button-info"
                />
                <Button
                    label="Hủy"
                    className="p-button-raised p-button-secondary"
                    icon="pi pi-times"
                    onClick={() => this.setState({
                        visible: false,
                        name: "",
                        description: "",
                        apiUpdate: "",
                        mode: 0
                    })}
                />
            </div>
        );
        const loading = (
            <div className="p-col-12">
                <LoadingView />
            </div>
        )
        return (
            <div className="p-grid">
                <Dialog header={this.state.titleDialog} footer={footer}
                    visible={this.state.visible} style={{ width: 500 }} modal={true} onHide={() => this.setState({ visible: false })}>
                    <Messages ref={(el) => this.messages = el}></Messages>
                    <div className="p-grid">
                        <div className="p-col-4" style={{ textAlign: "right", paddingTop: 13, paddingBottom: 0 }}>
                            <label style={{ fontSize: 14 }}>Hoạt động</label>
                        </div>
                        <div className="p-col-6" style={{ paddingBottom: 0 }}>
                            <Dropdown
                                value={this.state.eventId}
                                options={this.state.listEvent}
                                onChange={(e, field) => this.onValueChange(e, 'eventId')}
                                className="p-cust-width"
                                placeholder={"Chọn hoạt động"}
                                filter={true}
                                filterPlaceholder={"Tìm kiếm hoạt động"}
                                filterBy="label,value"
                                filterMatchMode="contains"
                                style={{ width: "100%" }}
                                tooltip={"Chọn hoạt động"}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="p-grid" style={{ marginTop: 10 }}>
                        <div className="p-col-4" style={{ textAlign: "right", paddingTop: 13, paddingBottom: 0 }}>
                            <label style={{ fontSize: 14 }}>Hình ảnh</label>
                        </div>
                        <div className="p-col-6" style={{ paddingBottom: 0 }}>
                            <Dropdown
                                value={this.state.eventImageId}
                                options={this.state.listImage}
                                onChange={(e, field) => this.onValueChange(e, 'eventImageId')}
                                className="p-cust-width"
                                placeholder={"Chọn hình ảnh"}
                                filter={true}
                                filterPlaceholder={"Tìm kiếm hình ảnh"}
                                filterBy="label,value"
                                filterMatchMode="contains"
                                style={{ width: "100%" }}
                                tooltip={"Chọn hoạt động để tải danh sách hình ảnh"}
                                disabled={(this.state.listImage.length > 0 && this.state.eventId !== undefined && this.state.eventId !== null) ? false : true}
                            />
                        </div>
                    </div>
                    <div className="p-grid" style={{ marginTop: 10 }}>
                        <div className="p-col-4" style={{ textAlign: "right", paddingTop: 13, paddingBottom: 0 }}>
                            <label style={{ fontSize: 14 }}>Sản phẩm</label>
                        </div>
                        <div className="p-col-6" style={{ paddingBottom: 0 }}>
                            <Dropdown
                                value={this.state.productId}
                                options={this.state.listProduct}
                                onChange={(e, field) => this.onValueChange(e, 'productId')}
                                className="p-cust-width"
                                placeholder={"Chọn sản phẩm"}
                                filter={true}
                                filterPlaceholder={"Tìm kiếm sản phẩm"}
                                filterBy="label,value"
                                filterMatchMode="contains"
                                style={{ width: "100%" }}
                                tooltip={"Chọn sản phẩm"}
                            />
                        </div>
                    </div>
                </Dialog>
                <div className="p-col-12">
                    <Suspense fallback={loading}>
                        <Grid
                            tableId={this.state.tableId}
                            filterTitle="Tìm kiếm nâng cao"
                            editMode={this.state.editMode}
                            layoutTitle={this.state.layoutTitle}
                            celltemplate={(row, column) => this.celltemplate(row, column)}
                            clickImport={(e) => this.clickImport(e)}
                            uniqueId={this.state.uniqueId}
                            addRow={(e) => this.addRow(e)}
                        />
                    </Suspense>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
        events: state.events,
        account: state.account
    }
}

const mapDispatchToProps = dispatch => {
    return {
        auth_ini: () => dispatch(actions.auth_ini()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListImageProduct);