import React, { Component } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
// import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Messages } from "primereact/messages";
// import { Message } from "primereact/message";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from "primereact/checkbox";
import { InputSwitch } from 'primereact/inputswitch';
import {
  GET_CHANNELS_PACKAGE,
  GET_AREA_EVENT,
  GET_CHANNELS,
  ADD_CHANNELS_PACKAGE,
  DEL_CHANNELS_PACKAGE,
  UPDATE_CHANNELS_PACKAGE,
} from "../../../store/constants/serviceEndpoint";
import { headerToken } from "../../../store/constants/header";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Swal from "sweetalert2/dist/sweetalert2.js";
// import "./event.css";
import { withRouter } from "react-router-dom";
import { axiosWithHeaders } from "../../../store/constants/axiosWrapper";
const axios = require("axios");
const _ = require("lodash");
class ConfigChannelPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataChannelPackage: [],
      dataChannel: [],
      dataArea: [],
      Name: "",
      Description: "",
      ChannelId: null,
      AreaId: [],
      apiUpdate: ADD_CHANNELS_PACKAGE,
      isDefault: false,
    };
    // this.UpdateData = this.UpdateData.bind(this);
    // this.onChangeArea = this.onChangeArea.bind(this);
  }

  componentDidMount() {
    const eventId = this.props.match.params.id;
    if (eventId !== undefined && eventId !== null) {
      this.GetListChannelPackage(eventId);
      this.getListAreaByEventId(eventId);
      this.GetListChannel(eventId);
    }
  }

  GetListChannel = (eventId) => {
    const _this = this;
    // axios({
    //   method: "get",
    //   url: GET_CHANNELS,
    //   headers: headerToken(),
    //   params: {
    //     id: eventId,
    //   },
    // })
    axiosWithHeaders("get", GET_CHANNELS, { id: eventId })
      .then(function (response) {
        if (response.status === 200) {
          const data = response.data;
          const result = data.result;
          let arr = [];
          result.map((item, i) => {
            let obj = {};
            obj["value"] = item.id;
            obj["label"] = item.name;
            arr.push(obj);
          });
          _this.setState({
            dataChannel: arr,
            eventId: eventId,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  getListAreaByEventId(eventId) {
    const _this = this;
    // axios({
    //   method: "get",
    //   url: GET_AREA_EVENT,
    //   headers: headerToken(),
    //   params: {
    //     id: eventId,
    //   },
    // })
    axiosWithHeaders("get", GET_AREA_EVENT, { id: eventId })
      .then(function (response) {
        if (response.status === 200) {
          const data = response.data;
          const result = data.result;
          let arr = [];
          result.map((item, i) => {
            let obj = {};
            obj["value"] = item.areaId;
            obj["label"] = item.areaName;
            arr.push(obj);
          });
          _this.setState({
            dataArea: arr,
            eventId: eventId,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  checkData = (obj) => {
    for (var field in obj) {
      if (
        obj[field] === undefined ||
        obj[field] === null ||
        obj[field] === "" ||
        obj[field] === 0
      ) {
        switch (field) {
          case "Name":
            this.messages.show({
              severity: "error",
              summary: "Lỗi",
              detail: "Vui lòng nhập tên suất !",
            });
            break;
          case "Description":
            this.messages.show({
              severity: "error",
              summary: "Lỗi",
              detail: "Vui lòng nhập mô tả về suất!",
            });
            break;
          case "ChannelId":
            this.messages.show({
              severity: "error",
              summary: "Lỗi",
              detail: "Vui lòng chọn kênh !",
            });
            break;
          case "AreaId":
            this.messages.show({
              severity: "error",
              summary: "Lỗi",
              detail: "Vui lòng chọn vùng !",
            });
            break;
          default:
            break;
        }
        return false;
      }
    }
    return true;
  };

  UpdateData = (e) => {
    const _this = this;
    const state = this.state;
    let objUpdate = {};
    objUpdate.Name = state.Name;
    objUpdate.Description = state.Description;
    objUpdate.AreaId = state.AreaId.join(",");
    objUpdate.ChannelId = state.ChannelId;
    const check = this.checkData(objUpdate);
    if (check) {
      objUpdate.AreaId = state.AreaId;
      objUpdate.EventId = state.eventId;
      objUpdate.isDefault = state.isDefault;
      if (state.mode === 1) {
        objUpdate.id = state.id;
      }
      // axios({
      //   method: "post",
      //   url: state.apiUpdate,
      //   headers: headerToken(),
      //   data: objUpdate,
      // })
      axiosWithHeaders("post", state.apiUpdate, objUpdate)
        .then(function (response) {
          if (response.status === 200) {
            const data = response.data;
            // console.log(data, objUpdate)
            if (data.message === "SUCCESS") {
              Swal.fire("Thông báo", "Cập nhật suất thành công !", "success");
            } else {
              Swal.fire("Lỗi", "Cập nhật suất thất bại !", "error");
            }
            _this.GetListChannelPackage(_this.state.eventId);
            _this.setState({
              visible: false,
              Name: "",
              Description: "",
              areaId: [],
              ChannelId: null,
              mode: 0,
              apiUpdate: ADD_CHANNELS_PACKAGE,
              isDefault: false
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  GetListChannelPackage = (eventId) => {
    const _this = this;
    // axios({
    //   method: "get",
    //   url: GET_CHANNELS_PACKAGE,
    //   headers: headerToken(),
    //   params: {
    //     id: eventId,
    //   },
    // })
    axiosWithHeaders("get", GET_CHANNELS_PACKAGE, { id: eventId })
      .then(function (response) {
        if (response.status === 200) {
          const data = response.data;
          let result = data.data;

          // console.log(result)

          result.map((item, i) => {
            let areaId = [];
            let areaName = [];
            if (item["listArea"].length > 0) {
              item["listArea"].map((area, a) => {
                areaId.push(area.id);
                areaName.push(area.name);
              });
            }
            item["areaId"] = areaId;
            item["areaName"] = areaName.join(",");
          });
          _this.setState({
            dataChannelPackage: result,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  onCancel = (e) => {
    this.setState({
      visible: false,
      Name: "",
      Description: "",
      ChannelId: null,
      AreaId: null,
      mode: 0,
      isDefault: false
    });
  };

  updateItem = (e, row) => {
    this.setState({
      id: row.id,
      Name: row.name,
      Description: row.description,
      ChannelId: row.channelId,
      AreaId: row.areaId,
      visible: true,
      mode: 1,
      apiUpdate: UPDATE_CHANNELS_PACKAGE,
      isDefault: row.isDefault
    });
  };

  deleteItem = (e, id) => {
    Swal.fire({
      title: "Thông báo",
      text: "Bạn có muốn xóa suất này không ?",
      type: "warning",
      showCancelButton: true,
      //confirmButtonColor: '#3085d6',
      //cancelButtonColor: '#d33',
      confirmButtonText: "Đồng ý!",
      cancelButtonText: "Trở về",
    }).then((result) => {
      if (result.value) {
        const _this = this;
        // axios({
        //   method: "post",
        //   url: DEL_CHANNELS_PACKAGE,
        //   headers: headerToken(),
        //   data: {
        //     Id: id,
        //   },
        // })
        axiosWithHeaders("post", DEL_CHANNELS_PACKAGE, { Id: id })
          .then(function (response) {
            if (response.status === 200) {
              const data = response.data;
              if (data.message === "SUCCESS") {
                Swal.fire("Thông báo", "Xoá suất thành công !", "success");
              } else {
                Swal.fire("Lỗi", "Xóa suất thất bại !", "error");
              }
              _this.GetListChannelPackage(_this.state.eventId);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  };

  celltemplate = (row, column) => {
    const field = column.field;
    switch (field) {
      case "STT":
        return column.rowIndex + 1;
      case 'isDefault':
        return (
          <InputSwitch checked={row[field]} disabled />
        );
      default:
        return (
          <div
            style={{
              width: "100%",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            <Button
              icon="pi pi-pencil"
              className="p-button-success p-button-raised"
              style={{ marginRight: 7, width: 35 }}
              onClick={(e, uniqueId) => this.updateItem(e, row)}
            />
            <Button
              icon="pi pi-trash"
              className="p-button-danger p-button-raised"
              style={{ width: 35 }}
              onClick={(e, uniqueId) => this.deleteItem(e, row.id)}
            />
          </div>
        );
    }
  };

  render() {
    const footer = (
      <div>
        <Button
          label="Cập nhật"
          icon="pi pi-check"
          onClick={() => this.UpdateData()}
          className="p-button-raised"
        />
        <Button
          label="Hủy"
          icon="pi pi-times"
          onClick={(e) => this.onCancel(e)}
          className="p-button-raised p-button-secondary"
        />
      </div>
    );

    return (
      <div className="p-grid">
        <div className="p-col-12">
          <Dialog
            header="Cập nhật suất"
            footer={footer}
            visible={this.state.visible}
            style={{ width: "600px" }}
            modal={true}
            onHide={(e) => this.onCancel(e)}
          >
            <div className="p-grid">
              <Messages
                ref={(el) => (this.messages = el)}
                style={{ width: "100%" }}
              ></Messages>
            </div>
            <div className="p-grid" style={{ marginTop: 10 }}>
              <div
                className="p-col-4"
                style={{ paddingBottom: 0, textAlign: "right" }}
              >
                <label>Tên suất</label>
              </div>
              <div className="p-col-6" style={{ paddingTop: 0 }}>
                <InputText
                  placeholder="Nhập tên suất...."
                  style={{ width: "100%" }}
                  onChange={(e) => this.setState({ Name: e.target.value })}
                  value={this.state.Name}
                />
              </div>
            </div>
            <div className="p-grid" style={{ marginTop: 5 }}>
              <div
                className="p-col-4"
                style={{ paddingBottom: 0, textAlign: "right" }}
              >
                <label>Mô tả</label>
              </div>
              <div className="p-col-6" style={{ paddingTop: 0 }}>
                <InputTextarea
                  rows={5}
                  style={{ width: "100%" }}
                  value={this.state.Description}
                  onChange={(e) =>
                    this.setState({ Description: e.target.value })
                  }
                  placeholder="Nhập mô tả về suất..."
                />
              </div>
            </div>
            <div className="p-grid" style={{ marginTop: 5 }}>
              <div
                className="p-col-4"
                style={{ paddingBottom: 0, textAlign: "right" }}
              >
                <label>Kênh</label>
              </div>
              <div className="p-col-6" style={{ paddingTop: 0 }}>
                <Dropdown
                  value={this.state.ChannelId}
                  options={this.state.dataChannel}
                  onChange={(e) => {
                    this.setState({ ChannelId: e.value });
                  }}
                  filter={true}
                  filterPlaceholder="Tìm kênh"
                  filterBy="label,value"
                  style={{ width: "100%" }}
                  placeholder="Chọn kênh"
                />
              </div>
            </div>
            <div className="p-grid" style={{ marginTop: 5 }}>
              <div
                className="p-col-4"
                style={{ paddingBottom: 0, textAlign: "right" }}
              >
                <label>Vùng</label>
              </div>
              <div className="p-col-6" style={{ paddingTop: 0 }}>
                <MultiSelect
                  value={this.state.AreaId}
                  options={this.state.dataArea}
                  onChange={(e) => {
                    this.setState({ AreaId: e.value });
                  }}
                  filter={true}
                  filterPlaceholder="Tìm vùng"
                  filterBy="label,value"
                  style={{ width: "100%" }}
                  placeholder="Chọn vùng"
                />
              </div>
              <div
                className="p-col-4"
                style={{ paddingBottom: 0, textAlign: "right" }}
              >
                &nbsp;
              </div>
              <div className="p-col-6" style={{ paddingTop: 0 }}>
                <Checkbox
                  checked={this.state.isDefault}
                  inputId={`chk-chanel-package`}
                  onChange={(e) =>
                    this.setState({ isDefault: e.checked })
                  }

                ></Checkbox>
                <label htmlFor={`chk-chanel-package`} className="p-checkbox-label">
                  &nbsp;Mặc định
                </label>
              </div>
            </div>
          </Dialog>
          <DataTable
            value={this.state.dataChannelPackage}
            scrollable={true}
            scrollHeight={"60vh"}
            rowGroupMode="rowspan"
            sortField="channelName"
            sortOrder={2}
            groupField="channelId"
            paginator={true}
            emptyMessage="Hoạt động này chưa tạo suất nào...."
            rows={25}
            rowsPerPageOptions={[25, 50, 100]}
          >
            <Column field="channelName" header="Kênh" />
            <Column field="name" header="Suất" />
            <Column field="areaName" header="Vùng" />
            <Column field="isDefault" header="Mặc định" body={(rowData, column) => this.celltemplate(rowData, column)} />
            <Column
              body={(rowData, column) => this.celltemplate(rowData, column)}
              style={{ width: 120 }}
              className={"p-col-fixed"}
            />
          </DataTable>
        </div>
        <div style={{ position: "absolute", top: 10, right: 15 }}>
          <Button
            label="THÊM"
            icon="pi pi-plus"
            className="p-button-raised p-button-secondary"
            onClick={() =>
              this.setState({
                visible: true,
                Name: "",
                Description: "",
                ChannelId: null,
                AreaId: [],
                mode: 0,
                apiUpdate: ADD_CHANNELS_PACKAGE,
              })
            }
          />
        </div>
      </div>
    );
  }
}

export default withRouter(ConfigChannelPackage);
