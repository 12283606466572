import React, { 
    Component, 
    lazy, 
    // Suspense 
} from 'react';
import { connect } from 'react-redux';
import LoadingView from '../../loadingview/LoadingView';
// import {getDetailUser} from '../../../crypto/Crypto';
import { Button } from 'primereact/button';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {deCryptUserId} from '../../../crypto/Crypto'
import { 
    REPORT_LIST_SURVEY
} 
from '../../../store/constants/serviceEndpoint';
import { Link } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import { headerToken } from '../../../store/constants/header'; 
import * as actionTypes from '../../../store/constants/actionTypes';
import {axiosWithHeadersDownload} from '../../../store/constants/axiosWrapper';

const Grid = lazy(() => import('../../grid/grid'));
// const axios = require("axios");

class ListInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableId: 2,
            editMode: "inline",
            layoutTitle: "THÔNG TIN",
            isLoading: true
        }
    }

    componentDidMount() {
        let eventId = localStorage.getItem(actionTypes.STORAGE_EVENTID);
        if ((eventId !== undefined && eventId !== null)) {
            this.setState({
                eventId: eventId
            })
        }

        this.setState({
            isLoading: false
        })
    }

    // searchIndexObjectInArray = (str, array) => {
    //     // let idx = -1;
    //     // if (array !== undefined && array !== null && array.length > 0) {
    //     //     array.map((item, i)=>{
    //     //         if(item.Field === str){
    //     //             idx = i;
    //     //         }
    //     //     })
    //     // }
    //     // return idx;
    //     let idx = 0;
    //     if (array !== undefined && array !== null && array.length > 0) {
    //         array.forEach(item => {
    //             if(item.Field === str){
    //               return idx;
    //           }
    //             idx++;
    //         });
    //         return -1;
    //     }
    //     return -1;
    // }

    redirectDetail = (e, row) => {
        e.preventDefault();
        this.props.history.push('/event/infodetail/' + row.id)
    }

    renderRank = (value) => {
        switch (value) {
            case 1:
                return (<div><img src={require('../assets/img/web-09.png')} style={{ height: 30, objectFit: 'contain' }} alt="Vàng" /> Vàng</div>)
            case 2:
                return (<div><img src={require('../assets/img/web-10.png')} style={{ height: 30, objectFit: 'contain' }} alt="Bạc" /> Bạc</div>)
            case 3:
                return (<div><img src={require('../assets/img/web-11.png')} style={{ height: 30, objectFit: 'contain' }} alt="Đồng" /> Đồng</div>)
            default:
                return null;
        }
    }

    report = (e, paramReport) => {
        const _this = this;

        this.setState({
            isLoading: true
        })

        const idxUserId = paramReport.findIndex(x => x.Field === "UserId");//this.searchIndexObjectInArray("UserId", paramReport);
        if (idxUserId > -1) {
            paramReport.splice(idxUserId, 1);
        }

        paramReport.push({
            Field: "UserId",
            TypeField: 8,
            Value: deCryptUserId()
        })

        const idxEventId = paramReport.findIndex(x => x.Field === "EventId");//this.searchIndexObjectInArray("EventId", paramReport);
        if (idxEventId > -1) {
            paramReport.splice(idxEventId, 1);
        }

        const eventId = this.state.eventId;

        paramReport.push({
            Field: "EventId",
            TypeField: 8,
            Value: eventId
        })

        const objRequest = {
            Name: "BAO CAO",
            StoreProcedure: "Report_Store_Channel_Package",
            Parameters: paramReport
        }
        // axios({
        //     method: 'post',
        //     url: REPORT_LIST_SURVEY,
        //     headers: headerToken(),
        //     data: objRequest,
        //     responseType: 'blob'
        // }).then(function (response) {
        //     const url = window.URL.createObjectURL(new Blob([response.data]));
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute('download', `Report.xlsx`);
        //     // 3. Append to html page
        //     document.body.appendChild(link);
        //     // 4. Force download
        //     link.click();
        //     // 5. Clean up and remove the link
        //     link.parentNode.removeChild(link);
        //     Swal.fire('Thông báo', 'Tải báo cáo thành công!', 'success');
        //     _this.setState({
        //         isLoading: false
        //     })

        // }).catch(err => console.log(err));
        axiosWithHeadersDownload("post", REPORT_LIST_SURVEY, objRequest)
        .then(function (response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Report.xlsx`);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            Swal.fire('Thông báo', 'Tải báo cáo thành công!', 'success');
            _this.setState({
                isLoading: false
            })
        })
        .catch(function (error) {
            alert(error.toString());
        })
    }

    celltemplate = (row, column) => {
        const field = column.field;
        switch (field) {
            case 'STT':
                return (column.rowIndex + 1);
            case 'code':
                return (
                    <Link to={`/event/infodetail/${row.id}`}>
                        <span className="info-code">{row.code}</span>
                    </Link>
                );
            case 'range':
                return this.renderRank(row[field]);
            case 'action':
                return (
                    <div style={{ textAlign: "center" }}>
                        <Button
                            icon="pi pi-external-link"
                            className="p-button-secondary p-button-raised"
                            style={{ width: 35 }}
                            onClick={(e, uniqueId) => this.redirectDetail(e, row)}
                        />
                    </div>
                )
            default:
                return null;
        }
    }


    render() {
        const datacontent = (this.state.isLoading) ?
            (
                <div className="p-col-12">
                    <LoadingView />
                </div>
            ) : (
                <Grid
                    tableId={this.state.tableId}
                    filterTitle="Tìm kiếm nâng cao"
                    editMode={this.state.editMode}
                    layoutTitle={this.state.layoutTitle}
                    celltemplate={(row, column) => this.celltemplate(row, column)}
                    clickReport={(e, param) => this.report(e, param)}
                />
            )
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    {datacontent}
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
        events: state.events,
        account: state.account.userInfo
    }
}

export default connect(mapStateToProps, null)(ListInfo);