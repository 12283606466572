import React, { Component } from "react";
import {connect} from 'react-redux';
import {Tree} from 'primereact/tree';
import {Card} from 'primereact/card';
import DetailEvent from './DetailEvent';
import ConfigArea from './ConfigArea';
import TargetArea from './ConfigTargetWithArea';
import ConfigChannel from './ConfigChannel';
import ImageWithChannel from './ImageWithChannel';
import ConfigChannelPackage from './ConfigChannelPackage';
import ConfigChannelPackageImage from './ConfigChannelPackageImage';
import ConfigImage from './ConfigImage';
import ImageProduct from '../imageproduct/listimageproduct';
import {
  GET_AREA_EVENT,
  GET_LIST_AREA
} from '../../../store/constants/serviceEndpoint';
import { removeToken } from '../../../crypto/Crypto'
import * as actions from '../../../store/actions/index';
import * as actionTypes from '../../../store/constants/actionTypes'; 
import * as enums from '../../../common/enum';
import * as router from '../../../common/route';
import { headerToken } from '../../../store/constants/header'; 
import { axiosWithHeaders } from "../../../store/constants/axiosWrapper";
const axios = require("axios");
const _ = require("lodash");

class UpdateEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail:{
        dateFrom: null,
        dateTo: null,
        name: "",
        description: "",
      },
      eventId:null,
      data: 
      [
        {
          "key": -1,
          "label": "Thông tin hoạt động",
          "expanded": true,
          "children": [
            {
              "key": 1,
              "label": "Cài đặt hoạt động",
            }
          ]
        },
        {
          "key": -2,
          "label": "Thông tin vùng diễn ra hoạt động",
          "expanded": true,
          "children": [
            {
              "key": 2,
              "label": "Cài đặt vùng",
            },{
              "key": 9,
              "label": "Cài đặt chỉ tiêu theo vùng",
            }
          ]
        },
        {
          "key": -5,
          "label": "Thông tin hình",
          "expanded": true,
          "children": [
            {
              "key": 5,
              "label": "Cài đặt hình",
            }
          ]
        },
        {
          "key": -3,
          "label": "Thông tin kênh",
          "expanded": true,
          "children": [
            {
              "key": 3,
              "label": "Cài đặt kênh",
            }, {
              "key": 4,
              "label": "Cài đặt hình theo kênh",
            }
          ]
        },
        {
          "key": -4,
          "label": "Thông tin suất",
          "expanded": true,
          "children": [
            {
              "key": 6,
              "label": "Cài đặt suất",
            },{
              "key": 7,
              "label": "Cài đặt hình theo suất",
            }
          ]
        }, {
          "key": -6,
          "label": "Hình ảnh theo sản phẩm",
          "expanded": true,
          "children": [
            {
              "key": 8,
              "label": "Cài đặt hình",
            }
          ]
        },
      ],
      selectedNode: 1,
      expandedKeys:
      {
        "-1": true,
        "-2": true,
        "-3": true,
        "-4": true,
        "-5": true,
        "-6": true
      },
      listArea: [],
      listAreaSelected: [],
      listAreaUnSelect: [],
      dataAreaEvent: []
    };
  }

  componentDidMount(){
    let eventId = localStorage.getItem(actionTypes.STORAGE_EVENTID);
    if((eventId!==undefined&&eventId!==null)){
      this.setState({
          eventId: eventId
      })
      this.getListAreaByEventId(eventId);
    }
    this.getListArea();
  }

  getListArea(){
    const _this = this;
    axiosWithHeaders('get', GET_LIST_AREA, null)
    // axios({
    //   method: 'get',
    //   url: GET_LIST_AREA,
    //   headers: headerToken(),
    //   })
      .then(function (response) {
        if(response.status === enums.RESPONSE_TOKEN_INVALID){
          removeToken();
          actions.auth_ini();
          _this.props.history.push(router.LOGIN);
        }
        if (response.status === enums.RESPONSE_OK) {
          const data = response.data;
          const result = data.result;
          let arr = [];
          result.map((item, i)=>{
            let obj = {}
            obj["value"] = item.id;
            obj["label"] = item.name;
            arr.push(obj);
          })

          setTimeout(()=>{
            const dataSelect = _this.state.listAreaSelected;
            let arrSelect = []
            dataSelect.map((item, i)=>{
              arrSelect.push(item.value);
            });

            let arrRemain = _.filter(arr, (v) => _.indexOf(arrSelect, v.value) === -1)
            _this.setState({
              listArea: arr,
              listAreaUnSelect: arrRemain
            })
          }, 300)

          
        }
      }).catch(function (error) {
          console.log(error);
      })
  }

  getListAreaByEventId(eventId){
    const _this = this;
    // axios({
    //   method: 'get',
    //   url: GET_AREA_EVENT,
    //   headers: headerToken(),
    //   params:{
    //     id: eventId
    //   }
    //   })
      axiosWithHeaders('get', GET_AREA_EVENT, {id: eventId})
      .then(function (response) {
        if(response.status === enums.RESPONSE_TOKEN_INVALID){
          removeToken();
          actions.auth_ini();
          _this.props.history.push(router.LOGIN);
        }
        if (response.status === enums.RESPONSE_OK) {
          const data = response.data;
          const result = data.result;
          let arr = [];
          result.map((item, i)=>{
            let obj = {}
            obj["value"] = item.areaId;
            obj["id"] = item.id;
            obj["label"] = item.areaName;
            arr.push(obj);
          })
          // console.log(result)
          _this.setState({
            listAreaSelected: arr,
            dataAreaEvent: result
          })
        }
      }).catch(function (error) {
          console.log(error);
      })
  }

  reloadData = (idx) =>{
    switch(idx){
      case 1:
          setTimeout(()=>{
            this.getListAreaByEventId(this.state.eventId);
            this.getListArea();
          }, 250);
        break;
      case 2:
        setTimeout(()=>{
          this.getListAreaByEventId(this.state.eventId);
        }, 250);
      break;
      default:
        break;
    }
  } 

  handleClick = () => {
    this.setState({
      visible: true
    });
  };

  onChangeDetail = (e, field, type) =>{
    let obj = {}
    switch(type){
      case 'date':
        obj[field] = e.value;
        break;
      default:
        obj[field] = e.target.value;
        break;
    }
    let detail = this.state.detail;
    if(detail.hasOwnProperty(field)){
      detail[field] = obj[field];
    }
    this.setState({
      detail: detail
    })
  }

  renderContent(){
    const selectNode = this.state.selectedNode;
    switch(selectNode){
      case -1:
      case 1:
        return (
          <Card title="CÀI ĐẶT HOẠT ĐỘNG" style={{position:"relative", padding: `20px 10px`}}>
              <DetailEvent/>
          </Card>
        );
      case -2:
      case 2:
        return(
          <Card title="CÀI ĐẶT VÙNG" style={{position:"relative"}}>
            <ConfigArea dataArea={this.state.listAreaUnSelect} dataAreaSelected={this.state.listAreaSelected} reloadData={(idx)=>this.reloadData(idx)}/>
          </Card>
        )
      case 9:
        return(
          <Card title="CÀI ĐẶT CHỈ TIÊU THEO VÙNG" style={{position:"relative"}}>
            <TargetArea dataAreaEvent={this.state.dataAreaEvent} reloadData={(idx)=>this.reloadData(idx)}/>
          </Card>
        )
      case -3:
      case 3:
            return(
              <Card title="CÀI ĐẶT KÊNH" style={{position:"relative"}}>
                  <ConfigChannel />
              </Card>
            )
      case 4:
          return(
            <Card title="CÀI ĐẶT HÌNH THEO KÊNH" style={{position:"relative"}}>
                <ImageWithChannel/>
            </Card>
          )
      case -5:
      case 5:
          return(
            <Card title="CÀI ĐẶT HÌNH" style={{position:"relative"}}>
                <ConfigImage/>
            </Card>
          )
      case -4:
      case 6:
          return(
            <Card title="CÀI ĐẶT SUẤT" style={{position:"relative"}}>
                <ConfigChannelPackage/>
            </Card>
          )
      case 7:
            return(
              <Card title="CÀI ĐẶT HÌNH THEO SUẤT" style={{position:"relative"}}>
                  <ConfigChannelPackageImage/>
              </Card>
            )
      case -6:
      case 8:
          return(
            <Card title="CÀI ĐẶT HÌNH ẢNH SẢN PHẨM" style={{position:"relative"}}>
                <ImageProduct />
            </Card>
          )
      
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="p-grid" style={{paddingTop: 20}}>
        <div className="p-col-2"></div>
        <div className="p-col-8">
          <div className="p-grid">
            <div className="p-col-3">
              <Tree 
                value={this.state.data} 
                selectionMode="single"
                filter={true}
                selectionKeys={this.state.selectedNode}
                expandedKeys={this.state.expandedKeys}
                style={{width: '100%'}}
                onSelectionChange={(e) => {
                  this.setState({selectedNode: e.value})
                }}
              />
            </div>
            <div className="p-col-9">
                <div className="p-grid">
                  <div className="p-col-12">
                    {
                      this.renderContent()
                    }
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div className="p-col-2"></div>
      </div>
    );
  }

}
function mapStateToProps(state) {
  return {
      area: state.area
  };
}

export default connect(mapStateToProps, {})(UpdateEvent);
