import React, { Component } from 'react';
import { Card } from 'primereact/card';
import { withRouter } from "react-router-dom";
import ChangePassword from './changepassword';
class Setting extends Component {
    render() {
        return (
            <div className='container-fluid d-flex justify-content-center align-items-center' style={{ width: `100%`, height: `100%` }}>
                {/* <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        
                    </div>
                    <div className="col-md-3"></div>
                </div> */}
                <div className='card' style={{ width: "100%", maxWidth: 400, margin: `auto` }}>
                    <div className='card-body'>
                        <div className="row">
                            <div className="col-12" style={{ textAlign: "center", paddingTop: 14 }}>
                                <h4 className="card-title">CÀI ĐẶT TÀI KHOẢN</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12" style={{ textAlign: "center" }}>
                                <label style={{ color: "red" }}>Tài khoản này phải đổi mật khẩu để tiếp tục sử dụng dịch vụ !</label>
                            </div>
                        </div>
                        <ChangePassword />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Setting);