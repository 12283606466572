import React, {Component} from 'react';
import { connect } from 'react-redux'
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import {Messages} from 'primereact/messages';
import * as actions from '../../../store/actions/index';
import * as actionTypes from '../../../store/constants/actionTypes'; 
import { 
    CHANGE_CHANNEL_PACKAGE_SURVEY
} 
from '../../../store/constants/serviceEndpoint';
import * as enums from '../../../common/enum';
import {deCryptUserId} from '../../../crypto/Crypto';
import Swal from 'sweetalert2';
import * as axiosWrapper from '../../../store/constants/axiosWrapper';
// import Swal from 'sweetalert2/dist/sweetalert2.js'
const _ = require("lodash");


class ChangeChannelPackage extends Component{
    constructor(props){
        super(props);
        this.state={
            channelId: null,
            channelPackageId: null,
            surveyId: null,
            note: ""
        }
    }

    componentDidMount(){
        let eventId = localStorage.getItem(actionTypes.STORAGE_EVENTID);
        if(eventId!==undefined&&eventId!==null){
            this.props.getChannel(eventId);
            this.props.getChannelPackage(eventId);
        }
        let {channelId} = this.props;
        if(channelId!==undefined && channelId !==null){
            this.setState({
                channelId: channelId
            })
        }
    }

    componentWillReceiveProps(props){
        let {channelId, channelPackageId, surveyId} = props;
        let state = this.state;
        if(surveyId!==state.surveyId || channelId!== state.channelId||channelPackageId !== state.channelPackageId){
            this.setState({
                channelId: channelId,
                channelPackageId: channelPackageId,
                surveyId: surveyId
            })
        }
    }

    renderChannel(){
        const {channel, channelId} = this.props;
        if(channel !== undefined && channel !== null){
            const {listChannel} = channel;
            let arr = [];
            listChannel.forEach(element => {
                let obj = {
                    value: element.id,
                    label: element.name
                }
                arr.push(obj);
            });
            return (
                <div className="row" style={{marginTop: 10}}>
                    <div className="col-sm-4 text-right">
                        <label style={{paddingTop: 5}}>Kênh</label>
                    </div>
                    <div className="col-sm-8">
                        <Dropdown 
                            value={(this.state.channelId===null)? channelId: this.state.channelId} 
                            options={arr} 
                            onChange={(e) => {
                                this.setState({
                                    channelId: e.value,
                                    channelPackageId: null
                                })
                            }} 
                            placeholder="Chọn kênh..." 
                            style={{width: '100%'}}
                        />
                    </div>
                </div>
            )
        }
        return null;
    }

    renderChannelPackage(){
        const {channel, channelPackageId} = this.props;
        let {channelId} = this.state;
        if(channel !== undefined && channel !== null){
            let {listChannelPackage} = channel;
            let list = _.cloneDeep(listChannelPackage);
            list = _.filter(list, item =>{
                return item.channelId === channelId;
            })

            let arr = [];
            list.forEach(element => {
                let obj = {
                    value: element.id,
                    label: element.name
                }
                arr.push(obj);
            });

            return (
                <div className="row" style={{marginTop: 10}}>
                    <div className="col-sm-4 text-right">
                        <label style={{paddingTop: 5}}>Suất</label>
                    </div>
                    <div className="col-sm-8">
                        <Dropdown 
                            value={(this.state.channelPackageId===null)? channelPackageId: this.state.channelPackageId} 
                            options={arr} 
                            onChange={(e) => {this.setState({channelPackageId: e.value})}} 
                            placeholder="Chọn suất..." 
                            style={{width: '100%'}}
                        />
                    </div>
                </div>
            )
        }
        return null;
    }

    checkData(obj){
        for(var field in obj){
            if(obj[field]===undefined || obj[field] === null || obj[field]===""){
                switch(field){
                    case 'ChannelId':
                        this.messages.show({severity: 'error', summary: 'Kênh', detail: 'Vui lòng chọn kênh...'});
                        return false;
                    case 'ChannelPackageId':
                        this.messages.show({severity: 'error', summary: 'Suất', detail: 'Vui lòng chọn suất...'});
                        return false;
                    case 'Note':
                        this.messages.show({severity: 'error', summary: 'Lý do', detail: 'Vui lòng nhập lý do...'});
                        return false;
                    default:
                        return false;
                }
            }
        }
        return true;
    }

    updateData(){
        let _this = this;
        let {surveyId} = this.props;
        let {channelId, channelPackageId, note} = this.state;
        
        let objUpdate ={            
            ChannelId: channelId,
            ChannelPackageId: channelPackageId,
            Note: note
        }

        let check = this.checkData(objUpdate);
        if(check){
            objUpdate.SurveyId = surveyId;
            objUpdate.UserId = 0;

         //   axios.post(CHANGE_CHANNEL_PACKAGE_SURVEY, objUpdate)
            axiosWrapper.axiosWithHeaders('post',CHANGE_CHANNEL_PACKAGE_SURVEY, objUpdate)
              .then(function (response) {
                let {data} = response;
                switch(data.status){
                    case enums.RESPONSE_OK:
                        if(data.message==="SUCCESS"){
                            Swal.fire("Thông báo", "Cập nhật thành công", "success")
                        }
                        _this.setState({
                            channelId: null,
                            channelPackageId: null,
                            surveyId: null,
                            note: ""
                        })
                        _this.props.onChangeVisible(false);
                        _this.props.reRender();
                        break;
                    default:
                        Swal.fire("Lỗi", data.message, "error")
                        break;
                }
              })
              .catch(function (error) {
                console.log(error);
              });
        }
    }

    render(){
        const footer = (
            <div>
                <Button label="Yes" icon="pi pi-check" onClick={()=> this.updateData()} />
                <Button label="No" icon="pi pi-times" onClick={()=>{
                    this.setState({
                        channelId: null,
                        channelPackageId: null,
                        surveyId: null,
                        note: ""
                    })
                    this.props.onChangeVisible(false);
                }} />
            </div>
        );
        return (
            <Dialog 
                header="THAY ĐỔI KÊNH/SUẤT" 
                visible={this.props.visible} 
                style={{width: 500}} 
                modal={true} 
                onHide={() => this.props.onChangeVisible(false)}
                footer={footer}
            >
                <div className="row">
                    <div className="col-md-12">
                        <Messages ref={(el) => this.messages = el}></Messages>
                    </div>
                </div>
                {
                    this.renderChannel()
                }
                {
                    this.renderChannelPackage()
                }
                <div className="row" style={{marginTop: 10}}>
                    <div className="col-sm-4 text-right">
                        <label style={{paddingTop: 5}}>Lý do</label>
                    </div>
                    <div className="col-sm-8">
                        <InputTextarea 
                            value={this.state.note} 
                            placeholder="Nhập lý do" 
                            onChange={(e) => this.setState({note: e.target.value})}
                            style={{width: '100%'}}
                        />
                    </div>
                </div>
            </Dialog>
        )
    }
}

// export default ChangeChannelPackage;

const mapStateToProps = state => {
    return {
        channel: state.channel
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // onLogout: () => dispatch(actions.logout()),
        getChannel: (eventId) => dispatch(actions.getChannel(eventId)),
        getChannelPackage: (channelId) => dispatch(actions.getChannelPackage(channelId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeChannelPackage);