import "./css.css"
import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut, Pie } from 'react-chartjs-2';
import { Skeleton } from 'primereact/skeleton';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';
import _ from 'lodash';
ChartJS.register(ArcElement, Tooltip, Legend, Title);

function PieChart({ initData, loadingAll, loading }) {
    const [chartData, setChartData] = useState([]);
    const [tableData, setTableData] = useState([]);

    const [listColor, ] = useState(['#e74c3c', '#ffc6ba']);

    const [bgColor, setBgColor] = useState("#FF3333");

    const [totalChartData, setTotalChartData] = useState([]);

    useEffect(() => {
        //process data
        let dataSet = [];
        let tableSet = [];

        let bgColorTotal = "";

        if (initData) {
            const {
                labels,
                result
            } = initData;

            if (labels && result) {
                labels.forEach((label, index) => {
                    let objDataSet = {};
                    let objTable = {
                        label: label,
                    };


                    objDataSet.options = {
                        responsive: true,
                        plugins: {
                            legend: {
                                display: false,
                                position: 'bottom',
                            },
                            title: {
                                display: true,
                                text: label
                            },
                            datalabels: {
                                formatter: (value, ctx) => {
                                    let sum = 0;
                                    let dataArr = ctx.chart.data.datasets[0].data;
                                    dataArr.map(data => {
                                        sum += data;
                                    });
                                    let percentage = (value * 100 / sum).toFixed(2) + "%";
                                    return percentage;
                                },
                                color: '#fff',
                            }

                        }
                    }
                    let lbls = ["Đã thực hiện", "Chưa thực hiện"];
                    let datasets = [];
                    let data = []
                    let backgroundColor = [];
                    let borderColor = [];

                    let dataTable = [];

                    result.forEach((item, idx) => {
                        backgroundColor.push(item.backgroundColor);
                        borderColor.push(item.borderColor);
                        data.push(item.data[index]);
                        dataTable.push(item.data[index]);
                    });


                    let data1 = []
                    let bg1 = []
                    let bd1 = []

                    data1.push(data[1])
                    bg1.push(listColor[0])
                    bd1.push(listColor[0])
                    setBgColor(listColor[0]);
                    bgColorTotal = listColor[0];

                    data1.push(data[0] - data[1] > 0 ? data[0] - data[1] : 0);
                    bg1.push(listColor[1]);
                    bd1.push(listColor[1]);
                    dataTable.push(data[0] - data[1] > 0 ? data[0] - data[1] : 0);


                    objDataSet.labels = lbls;

                    datasets.push({
                        label: '',
                        data: data1,
                        backgroundColor: bg1,
                        borderColor: bd1,
                    });

                    objDataSet.datasets = datasets;
                    objTable.data = dataTable;

                    dataSet.push(objDataSet);
                    tableSet.push(objTable);
                });

                setChartData(dataSet);
                setTableData(tableSet);

                let total = 0;
                let total1 = 0;
                let total2 = 0;

                tableSet.forEach((item, index) => {
                    // total += item.data[0];
                    total1 += item.data[1];
                    total2 += item.data[2];
                });

                // console.log('total', total, total1, total2);

                if (dataSet.length > 0) {
                    const labelTotal = _.cloneDeep(dataSet[0].labels);
                    //  optionTotal = _.cloneDeep(dataSet[0].options);

                    // console.log('optionTotal', optionTotal);

                    // if(totalDataSet){
                    //     const {
                    //         labels,
                    //         datasets
                    //     } = totalDataSet;

                    //     let datasetTotal = _.cloneDeep(datasets[0]);
                    //     console.log('datasetTotal', datasetTotal);
                    //     datasetTotal.data = [total1, total2];
                    //     datasetTotal.backgroundColor = [bgColor, listColor[1]];
                    //     datasetTotal.borderColor = [bgColor, listColor[1]];
                    // }

                    // console.log('totalDataSet', totalDataSet);

                    let totalDataSet = {
                        label: "",
                        backgroundColor: [listColor[0], listColor[1]],
                        borderColor: [listColor[0], listColor[1]],
                        data: [total1, total2],
                    }

                    let totalData = {
                        labels: labelTotal,
                        options: {
                            responsive: true,
                            plugins: {
                                legend: {
                                    display: false,
                                    position: 'bottom',
                                },
                                title: {
                                    display: true,
                                    text: "BIỂU ĐỒ TỔNG"
                                },
                                datalabels: {
                                    formatter: (value, ctx) => {
                                        let sum = 0;
                                        let dataArr = ctx.chart.data.datasets[0].data;
                                        dataArr.map(data => {
                                            sum += data;
                                        });
                                        let percentage = (value * 100 / sum).toFixed(2) + "%";
                                        return percentage;
                                    },
                                    color: '#fff',
                                }

                            }
                        },
                        datasets: [totalDataSet]
                    }

                    // console.log('totalData', totalData);

                    setTotalChartData(totalData);
                } else {
                    setTotalChartData({});
                }
            }
        }

    }, [initData]);

    return (
        <>
            {(loadingAll || loading) && (
                <Skeleton width="100%" height='444px' borderRadius="16px"></Skeleton>
            )}
            {!loadingAll && !loading && tableData.length > 0 && (
                <div className="p-card" style={{ padding: 10, }}>
                    <div style={{ padding: `5px 0px`, borderBottom: `1px solid #c4c4c4`, fontSize: 20 }}>
                        <b>BIỂU ĐỒ KPI</b>
                    </div>
                    <div className="row">
                        <div className="col-4 flex align-items-center">
                            {Object.keys(totalChartData).length > 0 && <Pie data={totalChartData} options={totalChartData.options} />}
                        </div>
                        <div className="col-8">
                            <div className="row justify-content-center">
                                {chartData.map((item, index) => {
                                    return (
                                        <div key={index} className='col-3' >
                                            <Doughnut data={item} options={item.options} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    {tableData.length > 0 && (
                        <>
                            <div className="d-flex" style={{ justifyContent: `center`, padding: 20 }}>
                                <div className="d-flex" style={{ justifyContent: `center`, alignItems: `center` }}>
                                    <div style={{ width: 40, height: 15, backgroundColor: listColor[1] }}></div>
                                    <div style={{ paddingLeft: 5 }}>Chưa thực hiện</div>
                                </div>
                                <div className="d-flex" style={{ justifyContent: `center`, alignItems: `center`, marginLeft: 15 }}>
                                    <div style={{ width: 40, height: 15, backgroundColor: `${bgColor}` }}></div>
                                    <div style={{ paddingLeft: 5 }}>Đã thực hiện</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <table className='table table-bordered chart-table'>
                                        <thead style={{backgroundColor: '#e74c3c'}}>
                                            <tr>
                                                <th></th>
                                                {tableData.map((item, index) => {
                                                    return (
                                                        <th key={index}>{item.label}</th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody style={{backgroundColor: '#ffc6ba'}}>
                                            <tr>
                                                <td>Tổng số</td>
                                                {tableData.map((item, index) => {
                                                    return (
                                                        <td key={index}>{item.data[0]}</td>
                                                    )
                                                })}
                                            </tr>
                                            <tr>
                                                <td>Đã thực hiện</td>
                                                {tableData.map((item, index) => {
                                                    return (
                                                        <td key={index}>{item.data[1]}</td>
                                                    )
                                                })}
                                            </tr>
                                            <tr>
                                                <td>Chưa thực hiện</td>
                                                {tableData.map((item, index) => {
                                                    return (
                                                        <td key={index}>{item.data[2]}</td>
                                                    )
                                                })}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    )}

                    {/* <div>
                        <div className='p-grid' style={{ alignItems: `center`, justifyContent: `center`, padding: `10px 2.5%` }}>
                            
                        </div>
                    </div>
                    
                    */}
                </div>
            )}
        </>
    );
}

PieChart.propTypes = {
    initData: PropTypes.object,
    loadingAll: PropTypes.bool,
    loading: PropTypes.bool,
};

PieChart.defaultProps = {
    initData: {},
    loadingAll: false,
    loading: false,
}

export default PieChart;