import * as actionTypes from '../constants/actionTypes';

const initialState = {
    data: null,
    images: [],
    long: 0,
    lat: 0,
    apporve: 0,
    questions: [],
    store: {
        detail: null,
        channel: null
    },
    message: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CUSTOMER_DETAIL_INI:
            return {
                data: null,
                images: [],
                long: 0,
                lat: 0,
                apporve: 0,
                questions: [],
                store: {
                    detail: null,
                    channel: null
                },
                message: null
            };
        case actionTypes.CUSTOMER_DETAIL_SET:
            return {
                ...state,
                data: action.data,
                images: action.images,
                long: action.long,
                lat: action.lat,
                apporve: action.apporve,
                message: null
            };
        case actionTypes.CUSTOMER_DETAIL_SET_APPROVE:
            return {
                ...state,
                apporve: state.apporve
            }
        case actionTypes.CUSTOMER_DETAIL_SET_QUESTIONS:
            return {
                ...state,
                questions: action.questions
            };
        case actionTypes.CUSTOMER_DETAIL_SET_STORE:
            return {
                ...state,
                store: {
                    detail: action.detail,
                    channel: action.channel
                },
            };
        case actionTypes.CUSTOMER_DETAIL_ERROR:
            return {
                ...state,
                message: action.message
            };
        default:
            return state;
    }
};
export default reducer;