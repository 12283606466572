import * as types from '../constants/actionTypes';

export const event_ini = () => {
    return {
        type: types.EVENT_INI
    };
};

export const event_set = (event) => {
    return {
        type: types.EVENT_SET,
        event
    }
}