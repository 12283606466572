import * as actionTypes from '../constants/actionTypes';
// import { getToken } from '../../crypto/Crypto';

const initialState = {
    accessToken: localStorage.getItem(actionTypes.ACCESSTOKEN_STORAGE) ? localStorage.getItem(actionTypes.ACCESSTOKEN_STORAGE) : null,
    refreshToken: localStorage.getItem(actionTypes.REFRESHTOKEN_STORAGE) ? localStorage.getItem(actionTypes.REFRESHTOKEN_STORAGE) : null,
    isLoading: false,
    isChangedPassword: localStorage.getItem(actionTypes.CHANGE_PASSWORD_STORAGE) ? localStorage.getItem(actionTypes.CHANGE_PASSWORD_STORAGE) === "true" ? true : false : false,
    message: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_INI:
            return {
                accessToken: null,
                refreshToken: null,
                isLoading: false,
                isChangedPassword: false,
                message: null
            }
        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken,
            }
        case actionTypes.AUTH_CHANGE_PASSWORD:
            return {
                ...state,
                isChangedPassword: action.isChangedPassword
            }
        case actionTypes.AUTH_LOADING:
            return {
                ...state,
                isLoading: action.loading
            }
        case actionTypes.AUTH_FAIL:
            return {
                accessToken: null,
                refreshToken: null,
                isLoading: false,
                isChangedPassword: false,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;