import * as actionTypes from '../constants/actionTypes';

const initialState = {
    data: null,
    pageIndex: 1,
    pageTotal: 1,
    status: '',
    message: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CUSTOMER_LIST_INI:
            return {
                data: null,
                pageIndex: 1,
                pageTotal: 1,
                status: '',
                message: null
            }
        case actionTypes.CUSTOMER_LIST_SET:
            return {
                data: action.data,
                pageIndex: action.pageIndex,
                pageTotal: action.pageTotal,
                status: action.status,
                message: action.message
            }
        case actionTypes.CUSTOMER_LIST_ERROR:
            return {
                ...state,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;