import React, { Component, lazy, Suspense, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Col } from 'react-bootstrap'
import ItemEvent from './ItemEvent';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Messages } from 'primereact/messages';
import { ADD_EVENT } from '../../../store/constants/serviceEndpoint';
import { headerToken } from '../../../store/constants/header';
import LoadingView from '../../loadingview/LoadingView';
import * as router from '../../../common/route';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useSelector } from 'react-redux';
import { axiosWithHeaders } from '../../../store/constants/axiosWrapper';
// import * as axiosWrapper from '../../../store/constants/axiosWrapper';
const GridView = lazy(() => import('../../grid/dataview'));
// const GridView = lazy(() => import('../../../view/dataview/index'));

const moment = require("moment");
const axios = require("axios");

// export default function ListEvent(){
//     const account
// } 

class ListEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableId: 6,
            layout: 'grid',
            Name: '',
            Description: '',
            StartDate: null,
            EndDate: null,
            MinPackage: 1,
            MaxPackage: 1,
            RealEndDate: null,
            NumberImageAI: 1
        };
        this.updateData = this.updateData.bind(this);
    }

    componentDidMount() {
    }

    clickAdd = (e) => {
        e.preventDefault();
        this.setState({
            visible: true
        })
    }

    renderGridItem(event) {
        return (
            <Col xs={12} md={3}>
                <ItemEvent itemEvent={event} account={this.props.account} />
            </Col>
        );
    }

    itemTemplate(event, layout) {
        if (event !== undefined && event !== null && Object.keys(event).length > 0)
            return this.renderGridItem(event);
        return null;
    }

    checkData = (obj) => {
        for (var field in obj) {
            if (obj[field] === undefined || obj[field] === null || obj[field] === "" || obj[field] === 0) {
                switch (field) {
                    case 'Name':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập tên hoạt động !' });
                        break;
                    case 'Description':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập mô tả !' });
                        break;
                    case 'NumberImageAI':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập số lượng hình ảnh (gian lận/suất) !' });
                        break;
                    case 'StartDate':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn ngày bắt đầu !' });
                        break;
                    case 'EndDate':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn ngày kết thúc !' });
                        break;
                    case 'MinPackage':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập suất(tối thiểu) !' });
                        break;
                    case 'MaxPackage':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập suất(tối đa) !' });
                        break;
                    case 'RealEndDate':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn ngày kết thúc(thật sự) !' });
                        break;
                    default:
                        break;
                }
                return false;
            }
        }
        return true;
    }

    updateData = (e) => {
        const _this = this;
        let obj = {}
        let state = this.state;
        obj.Name = state.Name;
        obj.StartDate = (state.StartDate === null) ? "" : moment(state.StartDate).format("YYYYMMDD");
        obj.EndDate = (state.EndDate === null) ? "" : moment(state.EndDate).format("YYYYMMDD");
        obj.MinPackage = (state.MinPackage === undefined || state.MinPackage === null || state.MinPackage === 0) ? 0 : parseInt(state.MinPackage);
        obj.MaxPackage = (state.MaxPackage === undefined || state.MaxPackage === null || state.MaxPackage === 0) ? 0 : parseInt(state.MaxPackage);
        obj.RealEndDate = (state.RealEndDate === null) ? "" : moment(state.RealEndDate).format("YYYYMMDD");
        obj.NumberImageAI = (state.NumberImageAI === undefined || state.NumberImageAI === null || state.NumberImageAI === 0) ? 0 : parseInt(state.NumberImageAI);
        obj.Description = state.Description;
        const check = this.checkData(obj);
        if (check) {
            obj.StartDate = moment(state.StartDate).format("YYYY-MM-DD HH:mm:ss");
            obj.EndDate = moment(state.EndDate).format("YYYY-MM-DD 20:00:00");
            obj.RealEndDate = moment(state.RealEndDate).format("YYYY-MM-DD 20:00:00");
            axiosWithHeaders("post", ADD_EVENT, obj)
            .then(function (response) {
                if (response.status === 200) {
                    const data = response.data;
                    const result = data.result;
                    if (result !== null) {
                        Swal.fire("Thông báo", "Thêm hoạt động thành công!", "success");
                        _this.props.history.push(`${router.DETAIL_EVENT}/${result}`);
                    }
                }
            })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }

    render() {
        const footer = (
            <div>
                <Button
                    label="Thêm mới"
                    icon="pi pi-plus"
                    onClick={(e) => this.updateData(e)}
                    className="p-button-raised"
                />
                <Button
                    label="Hủy"
                    icon="pi pi-times"
                    onClick={() => this.setState({
                        visible: false,
                        name: "",
                        description: "",
                        dateFrom: null,
                        dateTo: null
                    })}
                    className="p-button-raised p-button-secondary"
                />
            </div>
        );

        const loading = (
            <Col xs={12}>
                <LoadingView />
            </Col>
        )

        return (
            <Fragment>
                <Dialog
                    header="Thêm mới hoạt động"
                    visible={this.state.visible}
                    style={{ width: 750 }}
                    modal={true}
                    onHide={() => this.setState({ visible: false })}
                    footer={footer}
                >
                    <div className="p-grid" style={{marginTop: 10}}>
                        <Messages ref={(el) => this.messages = el} style={{ width: "100%" }} ></Messages>
                    </div>
                    <div className="p-grid" style={{ marginTop: 5 }}>
                        <div className="p-col-3" style={{ paddingBottom: 0, textAlign: "right" }}>
                            <label>Tên hoạt động</label>
                        </div>
                        <div className="p-col-6" style={{ paddingTop: 0 }}>
                            <InputText
                                placeholder="Nhập tên hoạt động...."
                                style={{ width: "100%" }}
                                onChange={(e) => this.setState({ Name: e.target.value })}
                                value={this.state.Name}
                            />
                        </div>
                    </div>
                    <div className="p-grid" style={{ marginTop: 5 }}>
                        <div className="p-col-3" style={{ paddingBottom: 0, textAlign: "right" }}>
                            <label>Ngày bắt đầu</label>
                        </div>
                        <div className="p-col-3" style={{ paddingTop: 0 }}>
                            <Calendar
                                value={this.state.StartDate}
                                onChange={(e) => this.setState({ StartDate: e.value })}
                                placeholder="Chọn ngày bắt đầu..."
                                dateFormat="dd/mm/yy"
                                maxDate={this.state.EndDate}
                                readOnlyInput
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange="2018:2030"
                            >
                            </Calendar>
                        </div>
                        <div className="p-col-3" style={{ paddingBottom: 0, textAlign: "right" }}>
                            <label>Ngày kết thúc</label>
                        </div>
                        <div className="p-col-3" style={{ paddingTop: 0 }}>
                            <Calendar
                                value={this.state.EndDate}
                                onChange={(e) => this.setState({ EndDate: e.value })}
                                placeholder="Chọn ngày kết thúc..."
                                dateFormat="dd/mm/yy"
                                minDate={this.state.StartDate}
                                readOnlyInput
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange="2018:2030"
                            >
                            </Calendar>
                        </div>
                    </div>
                    <div className="p-grid" style={{ marginTop: 5 }}>
                        <div className="p-col-3" style={{ paddingBottom: 0, textAlign: "right" }}>
                            <label>Số suất(tối thiểu)</label>
                        </div>
                        <div className="p-col-1" style={{ paddingTop: 0 }}>
                            <InputText
                                placeholder="0"
                                style={{ width: "100%", textAlign: "right" }}
                                onChange={(e) => this.setState({ MinPackage: e.target.value })}
                                value={this.state.MinPackage}
                                keyfilter="int"
                            />
                        </div>
                        <div className="p-col-2" style={{ paddingTop: 7 }}>
                            <label>suất</label>
                        </div>
                        <div className="p-col-3" style={{ paddingBottom: 0, textAlign: "right" }}>
                            <label>Số suất(tối đa)</label>
                        </div>
                        <div className="p-col-1" style={{ paddingTop: 0 }}>
                            <InputText
                                placeholder="0"
                                style={{ width: "100%", textAlign: "right" }}
                                onChange={(e) => this.setState({ MaxPackage: e.target.value })}
                                value={this.state.MaxPackage}
                                keyfilter="int"
                            />
                        </div>
                        <div className="p-col-2" style={{ paddingTop: 7 }}>
                            <label>suất</label>
                        </div>
                    </div>
                    <div className="p-grid" style={{ marginTop: 5 }}>
                        <div className="p-col-3" style={{ paddingBottom: 0, textAlign: "right" }}>
                            <label>Số lượng hình</label>
                        </div>
                        <div className="p-col-1" style={{ paddingTop: 0 }}>
                            <InputText
                                placeholder="0"
                                style={{ width: "100%", textAlign: "right" }}
                                onChange={(e) => this.setState({ NumberImageAI: e.target.value })}
                                value={this.state.NumberImageAI}
                                keyfilter="int"
                            />
                        </div>
                        <div className="p-col-2" style={{ paddingTop: 7 }}>
                            <label>(gian lận/suất)</label>
                        </div>

                    </div>
                    <div className="p-grid" style={{ marginTop: 5 }}>
                        <div className="p-col-3" style={{ paddingBottom: 0, textAlign: "right" }}>
                            <label>Ngày kết thúc (thực tế)</label>
                        </div>
                        <div className="p-col-3" style={{ paddingTop: 0 }}>
                            <Calendar
                                value={this.state.RealEndDate}
                                onChange={(e) => this.setState({ RealEndDate: e.value })}
                                placeholder="Chọn ngày kết thúc..."
                                dateFormat="dd/mm/yy"
                                minDate={this.state.StartDate}
                                maxDate={this.state.EndDate}
                                readOnlyInput
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange="2018:2030"
                            >
                            </Calendar>
                        </div>
                    </div>
                    <div className="p-grid" style={{ marginTop: 5 }}>
                        <div className="p-col-3" style={{ paddingBottom: 0, textAlign: "right" }}>
                            <label>Mô tả </label>
                        </div>
                        <div className="p-col-9" style={{ paddingTop: 0 }}>
                            <InputTextarea
                                rows={5} style={{ width: "100%" }}
                                value={this.state.Description}
                                onChange={(e) => this.setState({ Description: e.target.value })}
                                placeholder="Nhập mô tả về hoạt động..."
                            />
                        </div>
                    </div>
                </Dialog>
                <Suspense fallback={loading}>
                    <GridView
                        tableId={this.state.tableId}
                        tableTitle="DANH SÁCH HOẠT ĐỘNG"
                        filterTitle="Tìm kiếm nâng cao"
                        itemTemplate={(item, layout) => this.itemTemplate(item, 'grid')}
                        layout={this.state.layout}
                        addRow={(e) => this.clickAdd(e)}
                        rows={50}
                    />
                </Suspense>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        account: state.account
    }
}

export default withRouter(connect(mapStateToProps, null)(ListEvent));