/* eslint-disable no-array-constructor */
import { biFromHex, biHighIndex, BigInt, biToHex, biToString } from './BigInt';
import { BarrettMu } from './Barrett';

export function RSAKeyPair(encryptionExponent, decryptionExponent, modulus) {
	this.e = biFromHex(encryptionExponent);
	this.d = biFromHex(decryptionExponent);
	this.m = biFromHex(modulus);
	this.digitSize = 2 * biHighIndex(this.m) + 2;
	this.chunkSize = this.digitSize - 11;
	this.radix = 16;
	this.barrett = new BarrettMu(this.m);
}

export function twoDigit(n) {
	return (n < 10 ? "0" : "") + String(n);
}

export function encryptedString(key, s) {
	if (key.chunkSize > key.digitSize - 11) {
		return "Error";
	}
	var a = new Array();
	var sl = s.length;
	var i = 0;
	while (i < sl) {
		a[i] = s.charCodeAt(i);
		i++;
	}
	var al = a.length;
	var result = "";
	var j, k, block;
	for (i = 0; i < al; i += key.chunkSize) {
		block = new BigInt();
		j = 0;
		var x;
		var msgLength = (i + key.chunkSize) > al ? al % key.chunkSize : key.chunkSize;

		var b = new Array();
		for (x = 0; x < msgLength; x++) {
			b[x] = a[i + msgLength - 1 - x];
		}
		b[msgLength] = 0; // marker
		var paddedSize = Math.max(8, key.digitSize - 3 - msgLength);

		for (x = 0; x < paddedSize; x++) {
			b[msgLength + 1 + x] = Math.floor(Math.random() * 254) + 1; // [1,255]
		}
		// It can be asserted that msgLength+paddedSize == key.digitSize-3
		b[key.digitSize - 2] = 2; // marker
		b[key.digitSize - 1] = 0; // marker

		for (k = 0; k < key.digitSize; ++j) {
			block.digits[j] = b[k++];
			block.digits[j] += b[k++] << 8;
		}

		var crypt = key.barrett.powMod(block, key.e);
		var text = key.radix === 16 ? biToHex(crypt) : biToString(crypt, key.radix);
		result += text + " ";
	}
	return result.substring(0, result.length - 1); // Remove last space.
}

export function base64encode(str) {
	var base64EncodeChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
	var out, i, len;
	var c1, c2, c3;
	len = str.length;
	i = 0;
	out = "";
	while (i < len) {
		c1 = str.charCodeAt(i++) & 0xff;
		if (i === len) {
			out += base64EncodeChars.charAt(c1 >> 2);
			out += base64EncodeChars.charAt((c1 & 0x3) << 4);
			out += "==";
			break;
		}
		c2 = str.charCodeAt(i++);
		if (i === len) {
			out += base64EncodeChars.charAt(c1 >> 2);
			out += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
			out += base64EncodeChars.charAt((c2 & 0xF) << 2);
			out += "=";
			break;
		}
		c3 = str.charCodeAt(i++);
		out += base64EncodeChars.charAt(c1 >> 2);
		out += base64EncodeChars.charAt(((c1 & 0x3) << 4) | ((c2 & 0xF0) >> 4));
		out += base64EncodeChars.charAt(((c2 & 0xF) << 2) | ((c3 & 0xC0) >> 6));
		out += base64EncodeChars.charAt(c3 & 0x3F);
	}
	return out;
}
