import React, { Component } from 'react';
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from 'primereact/inputswitch';
import { Messages } from 'primereact/messages';
import { Dropdown } from 'primereact/dropdown';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { withRouter } from 'react-router-dom'
import { DEL_EVENT, DETAIL_EVENT, UPDATE_EVENT } from '../../../store/constants/serviceEndpoint';
// import { headerToken } from '../../../store/constants/header';
import * as router from '../../../common/route';
import { axiosWithHeaders } from '../../../store/constants/axiosWrapper';
// const axios = require("axios");
const moment = require("moment");

class DetailEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            description: '',
            startDate: null,
            endDate: null,
            realEndDate: null,
            minPackage: 1,
            maxPackage: 1,
            id: '',
            isEdit: false,
            isModified: false,
            numberImageAI: 0,
            isName: false,
            isPhone: false,
            isAddress: false,
            isCodeFridge: false,
            isShowTime: false,
            isCapacity: false,
            isNameSignboard: false,
            status: 0,
            listStatus: [
                {
                    value: 1,
                    label: "Đang hoạt động"
                },
                {
                    value: 2,
                    label: "Đã kết thúc"
                }
            ],

        }
    }

    onChangeDetail = (e, field, type) => {
        let state = this.state;
        if (state.hasOwnProperty(field)) {
            switch (type) {
                case 'date':
                    state[field] = e.value;
                    break;
                default:
                    state[field] = e.target.value;
                    break;
            }
        }
        state["isModified"] = true;
        this.setState(state);
    }

    componentDidMount() {
        const eventId = this.props.match.params.id;
        if (eventId !== undefined && eventId !== null) {
            this.GetDetailEvent(eventId);
        }
    }

    GetDetailEvent = (eventId) => {
        const _this = this;
        
        axiosWithHeaders("get", DETAIL_EVENT, { Id: eventId })
        .then(function (response) {
            if (response.status === 200) {
                const data = response.data;
                const result = data.result;
                // console.log(result);
                const state = _this.state;
                for (var field in result) {
                    if (state.hasOwnProperty(field)) {
                        state[field] = result[field];
                        if (field === "startDate" || field === "endDate" || field === "realEndDate") {
                            state[field] = new Date(result[field])
                        }
                    }
                }
                state["isModified"] = false;
                _this.setState(state);
            }
        })
            .catch(function (error) {
                console.log(error);
            })
    }

    checkData = (obj) => {
        for (var field in obj) {
            if (obj[field] === undefined || obj[field] === null || obj[field] === "" || obj[field] === 0) {
                switch (field) {
                    case 'Name':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập tên hoạt động !' });
                        break;
                    case 'Description':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập mô tả !' });
                        break;
                    case 'NumberImageAI':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập số lượng hình ảnh (gian lận/suất) !' });
                        break;
                    case 'StartDate':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn ngày bắt đầu !' });
                        break;
                    case 'EndDate':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn ngày kết thúc !' });
                        break;
                    case 'MinPackage':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập suất(tối thiểu) !' });
                        break;
                    case 'MaxPackage':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập suất(tối đa) !' });
                        break;
                    case 'RealEndDate':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn ngày kết thúc(thật sự) !' });
                        break;
                    default:
                        break;
                }
                return false;
            }
        }
        return true;
    }

    updateData = (e) => {
        let obj = {}
        let state = this.state;
        obj.Name = state.name;
        obj.StartDate = (state.startDate === null) ? "" : moment(state.startDate).format("YYYYMMDD");
        obj.EndDate = (state.endDate === null) ? "" : moment(state.endDate).format("YYYYMMDD");
        obj.MinPackage = (state.minPackage === undefined || state.minPackage === null || state.minPackage === 0) ? 0 : parseInt(state.minPackage);
        obj.MaxPackage = (state.maxPackage === undefined || state.maxPackage === null || state.maxPackage === 0) ? 0 : parseInt(state.maxPackage);
        obj.RealEndDate = (state.realEndDate === null) ? "" : moment(state.realEndDate).format("YYYYMMDD");
        obj.NumberImageAI = (state.numberImageAI === undefined || state.numberImageAI === null || state.numberImageAI === 0) ? 0 : parseInt(state.numberImageAI);
        obj.Description = state.description;


        const check = this.checkData(obj);

        if (check) {
            obj.StartDate = moment(state.startDate).format("YYYY-MM-DD HH:mm:ss");
            obj.EndDate = moment(state.endDate).format("YYYY-MM-DD 20:00:00");
            obj.RealEndDate = moment(state.realEndDate).format("YYYY-MM-DD 20:00:00");
            obj.Id = state.id;
            obj.IsName = state.isName;
            obj.IsPhone = state.isPhone;
            obj.IsAddress = state.isAddress;
            obj.IsCodeFridge = state.isCodeFridge;
            obj.IsShowTime = state.isShowTime;
            obj.IsCapacity = state.isCapacity;
            obj.IsNameSignboard = state.isNameSignboard;
            obj.Status = state.status;

            const _this = this;
            
            axiosWithHeaders("post", UPDATE_EVENT, obj)
            .then(function (response) {
                if (response.status === 200) {
                    const data = response.data;
                    if (data.message === "SUCCESS") {
                        _this.setState({
                            isEdit: false
                        });
                        _this.GetDetailEvent(obj.Id)
                        Swal.fire("Thông báo", "Cập nhật hoạt động thành công !", "success")
                    } else {
                        Swal.fire("Lỗi", "Cập nhật hoạt động thất bại !", "error")
                    }
                }
            })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }

    onCancel = (e) => {
        e.preventDefault();
        const modified = this.state.isModified;
        if (modified) {
            Swal.fire({
                title: 'Thông báo',
                text: "Dữ liệu đã bị chỉnh sửa, bạn có muốn cập nhật không ?",
                type: 'warning',
                showCancelButton: true,
                //confirmButtonColor: '#3085d6',
                //cancelButtonColor: '#d33',
                confirmButtonText: 'Đồng ý!',
                cancelButtonText: 'Trở về'
            }).then((result) => {
                if (result.value) {
                    this.updateData(e);
                }
            })
        } else {
            this.setState({
                isEdit: false
            })
        }
    }

    DeleteEvent = (e, eventId) => {
        e.preventDefault();
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa hoạt động này khỏi danh sách không ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý!',
            cancelButtonText: 'Trở về'
        }).then((result) => {
            if (result.value) {
                const _this = this;
                
                axiosWithHeaders("post", DEL_EVENT, { Id: eventId })
                .then(function (response) {
                    if (response.status === 200) {
                        const data = response.data;
                        if (data.message === "SUCCESS") {
                            Swal.fire("Thông báo", "Xóa hoạt động thành công!", "success");
                            _this.props.history.push(`${router.LIST_EVENT}`);
                        } else {
                            Swal.fire("Lỗi", "Xóa hoạt động không thành công!", "error");
                        }
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        Swal.fire("Lỗi", "Xóa hoạt động không thành công!", "error");
                    })
            }
        })
    }

    render() {
        const state = this.state;
        const data = state;
        return (
            <div>
                <div className="p-grid">
                    <div className="p-col-12" style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Messages ref={(el) => this.messages = el} style={{ width: "100%" }} ></Messages>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Tên hoạt động : </label>
                    </div>
                    <div className="p-col-9" >
                        {
                            (state["isEdit"] === false) && (
                                <div style={{ paddingTop: 4 }}>
                                    <h4>{state.name}</h4>
                                </div>
                            )
                        }
                        {
                            (state["isEdit"] === true) && (
                                <InputText
                                    value={state.name}
                                    placeholder="Nhập tên hoạt động..."
                                    onChange={(e, field, type) => this.onChangeDetail(e, 'name', 'string')}
                                    style={{ width: '100%' }}
                                />
                            )
                        }
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Ngày bắt đầu : </label>
                    </div>
                    <div className="p-col-9">
                        {
                            (state["isEdit"] === false) && (
                                <div style={{ paddingTop: 6 }}>
                                    <label>{moment(state.startDate).format("DD/MM/YYYY")}</label>
                                </div>
                            )
                        }
                        {
                            (state["isEdit"] === true) && (
                                <Calendar
                                    value={state.startDate}
                                    onChange={(e, field, type) => this.onChangeDetail(e, 'startDate', 'date')}
                                    maxDate={state.endDate}
                                    placeholder="Chọn từ ngày"
                                    style={{ width: 175 }}
                                    dateFormat="dd/mm/yy"
                                    readOnlyInput
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange="2018:2030"
                                >
                                </Calendar>
                            )
                        }
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Ngày kết thúc : </label>
                    </div>
                    <div className="p-col-3">
                        {
                            (state["isEdit"] === false) && (
                                <div style={{ paddingTop: 6 }}>
                                    <label>{moment(data.endDate).format("DD/MM/YYYY")}</label>
                                </div>
                            )
                        }
                        {
                            (state["isEdit"] === true) && (
                                <Calendar
                                    value={state.endDate}
                                    onChange={(e, field, type) => this.onChangeDetail(e, 'endDate', 'date')}
                                    minDate={state.startDate}
                                    placeholder="Chọn ngày kết thúc"
                                    style={{ width: 175 }}
                                    dateFormat="dd/mm/yy"
                                    readOnlyInput
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange="2018:2030"
                                >
                                </Calendar>
                            )
                        }
                    </div>
                    <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Ngày kết thúc (thực tế) : </label>
                    </div>
                    <div className="p-col-3">
                        {
                            (state["isEdit"] === false) && (
                                <div style={{ paddingTop: 6 }}>
                                    <label>{moment(data.realEndDate).format("DD/MM/YYYY")}</label>
                                </div>
                            )
                        }
                        {
                            (state["isEdit"] === true) && (
                                <Calendar
                                    value={state.realEndDate}
                                    onChange={(e, field, type) => this.onChangeDetail(e, 'realEndDate', 'date')}
                                    minDate={state.startDate}
                                    maxDate={state.endDate}
                                    placeholder="Chọn ngày kết thúc"
                                    style={{ width: 175 }}
                                    dateFormat="dd/mm/yy"
                                    readOnlyInput
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange="2018:2030"
                                >
                                </Calendar>
                            )
                        }
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Số lượng suất (tối thiểu) : </label>
                    </div>
                    {
                        (state["isEdit"] === false) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <label>{state.minPackage} (suất)</label>
                            </div>
                        )
                    }
                    {
                        (state["isEdit"] === true) && (
                            <div className="p-col-3" style={{ paddingBottom: 0 }}>
                                <div className="p-grid">
                                    <div className="p-col-4">
                                        <InputText
                                            value={state.minPackage}
                                            placeholder="0"
                                            onChange={(e, field, type) => this.onChangeDetail(e, 'minPackage', 'string')}
                                            style={{ width: '100%', textAlign: "right" }}
                                        />
                                    </div>
                                    <div className="p-col-8" style={{ paddingTop: 13 }}>
                                        <label>suất</label>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Số lượng suất (tối đa) : </label>
                    </div>
                    {
                        (state["isEdit"] === false) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <label>{state.maxPackage} (suất)</label>
                            </div>
                        )
                    }
                    {
                        (state["isEdit"] === true) && (
                            <div className="p-col-3" style={{ paddingBottom: 0 }}>
                                <div className="p-grid">
                                    <div className="p-col-4">
                                        <InputText
                                            value={state.maxPackage}
                                            placeholder="0"
                                            onChange={(e, field, type) => this.onChangeDetail(e, 'maxPackage', 'string')}
                                            style={{ width: '100%', textAlign: "right" }}
                                        />
                                    </div>
                                    <div className="p-col-8" style={{ paddingTop: 13 }}>
                                        <label>suất</label>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="p-grid">
                    <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Số lượng hình (gian lận/suất) : </label>
                    </div>
                    {
                        (state["isEdit"] === false) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <label>{state.numberImageAI}</label>
                            </div>
                        )
                    }
                    {
                        (state["isEdit"] === true) && (
                            <div className="p-col-3" style={{ paddingBottom: 0 }}>
                                <div className="p-grid">
                                    <div className="p-col-4">
                                        <InputText
                                            value={state.numberImageAI}
                                            placeholder="0"
                                            onChange={(e, field, type) => this.onChangeDetail(e, 'numberImageAI', 'string')}
                                            style={{ width: '100%', textAlign: "right" }}
                                        />
                                    </div>
                                    <div className="p-col-8" style={{ paddingTop: 13 }}>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Cho phép nhập số điện thoại : </label>
                    </div>
                    {
                        (state["isEdit"] === false) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <InputSwitch checked={state.isPhone} onChange={(e) => this.setState({ isPhone: e.value })} disabled />
                            </div>
                        )
                    }
                    {
                        (state["isEdit"] === true) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <InputSwitch checked={state.isPhone} onChange={(e) => this.setState({ isPhone: e.value })} />
                            </div>
                        )
                    }
                </div>
                <div className="p-grid">
                    <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Cho phép nhập địa chỉ : </label>
                    </div>
                    {
                        (state["isEdit"] === false) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <InputSwitch checked={state.isAddress} onChange={(e) => this.setState({ isAddress: e.value })} disabled />
                            </div>
                        )
                    }
                    {
                        (state["isEdit"] === true) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <InputSwitch checked={state.isAddress} onChange={(e) => this.setState({ isAddress: e.value })} />
                            </div>
                        )
                    }

                    <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Cho phép nhập mã tủ lạnh : </label>
                    </div>
                    {
                        (state["isEdit"] === false) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <InputSwitch checked={state.isCodeFridge} onChange={(e) => this.setState({ isCodeFridge: e.value })} disabled />
                            </div>
                        )
                    }
                    {
                        (state["isEdit"] === true) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <InputSwitch checked={state.isCodeFridge} onChange={(e) => this.setState({ isCodeFridge: e.value })} />
                            </div>
                        )
                    }
                </div>
                <div className="p-grid">
                    <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Trạng thái hoạt động</label>
                    </div>
                    <div className="p-col-3">
                        <Dropdown
                            value={state.status}
                            options={state.listStatus}
                            onChange={(e) => { this.setState({ status: e.value }) }}
                            placeholder="Chọn trạng thái"
                            disabled={(!state["isEdit"]) ? true : false}
                        />
                    </div>
                    <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Hiển thị thời gian : </label>
                    </div>
                    {
                        (state["isEdit"] === false) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <InputSwitch checked={state.isShowTime} onChange={(e) => this.setState({ isShowTime: e.value })} disabled />
                            </div>
                        )
                    }
                    {
                        (state["isEdit"] === true) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <InputSwitch checked={state.isShowTime} onChange={(e) => this.setState({ isShowTime: e.value })} />
                            </div>
                        )
                    }
                </div>
                <div className="p-grid">
                    <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Hiển thị Biển (Tên Biển) : </label>
                    </div>
                    {
                        (state["isEdit"] === false) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <InputSwitch checked={state.isNameSignboard} onChange={(e) => this.setState({ isNameSignboard: e.value })} disabled />
                            </div>
                        )
                    }
                    {
                        (state["isEdit"] === true) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <InputSwitch checked={state.isNameSignboard} onChange={(e) => this.setState({ isNameSignboard: e.value })} />
                            </div>
                        )
                    }
                    <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Hiển thị Sức chứa (bao nhiêu khách) : </label>
                    </div>
                    {
                        (state["isEdit"] === false) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <InputSwitch checked={state.isCapacity} onChange={(e) => this.setState({ isCapacity: e.value })} disabled />
                            </div>
                        )
                    }
                    {
                        (state["isEdit"] === true) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <InputSwitch checked={state.isCapacity} onChange={(e) => this.setState({ isCapacity: e.value })} />
                            </div>
                        )
                    }
                </div>
                <div className='p-grid'>
                <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Hiển thị nhập tên : </label>
                    </div>
                    {
                        (state["isEdit"] === false) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <InputSwitch checked={state.isName} onChange={(e) => this.setState({ isName: e.value })} disabled />
                            </div>
                        )
                    }
                    {
                        (state["isEdit"] === true) && (
                            <div className="p-col-3" style={{ paddingTop: 13 }}>
                                <InputSwitch checked={state.isName} onChange={(e) => this.setState({ isName: e.value })} />
                            </div>
                        )
                    }
                </div>
                <div className="p-grid">
                    <div className="p-col-3" style={{ textAlign: "right", paddingTop: 13 }}>
                        <label>Miêu tả : </label>
                    </div>
                    <div className="p-col-9">
                        <InputTextarea
                            rows={5} style={{ width: "100%" }}
                            value={data.description}
                            onChange={(e, field, type) => this.onChangeDetail(e, 'description', 'string')}
                            placeholder="Nhập mô tả về hoạt động..."
                            disabled={(state.isEdit === false) ? true : false}
                        />
                    </div>
                    {
                        (state["isEdit"] === false) && (
                            <div style={{ position: "absolute", top: 10, right: 15 }}>
                                <Button
                                    label="XÓA"
                                    tooltip="Xóa hoạt động"
                                    icon="pi pi-trash"
                                    className="p-button-raised p-button-danger"
                                    onClick={(e, idx) => this.DeleteEvent(e, state.id)}
                                    style={{ marginRight: 5 }}
                                />
                                <Button
                                    label="SỬA"
                                    tooltip="Sửa hoạt động"
                                    icon="pi pi-pencil"
                                    className="p-button-raised p-button-secondary"
                                    onClick={() => this.setState({ isEdit: true })}
                                />
                            </div>
                        )
                    }
                    {
                        (state["isEdit"] === true) && (
                            <div className="p-col-12" style={{ textAlign: "center" }}>
                                <Button
                                    label="HỦY"
                                    icon="pi pi-times"
                                    className="p-button-raised p-button-secondary"
                                    onClick={(e) => this.onCancel(e)}
                                    style={{ marginRight: 10 }}
                                />
                                <Button
                                    label="CẬP NHẬT"
                                    icon="pi pi-pencil"
                                    className="p-button-raised p-button-info"
                                    onClick={(e) => this.updateData(e)}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}
export default withRouter(DetailEvent)