import { combineReducers } from 'redux';
import common from './common';
import commonService from './commonService';
import auth from './auth';
import account from './account';
import storeList from './storeList';
import storeDetail from './storeDetail';
import location from './location';
import area from './area';
//Thêm eventId
import events from './events';
//Thêm channel
import channel from './channel';
import filter from './filter';
import grid from './grid';
import report from './report';
import dropdown from './dropdown';

const reducers = combineReducers({
    common,
    commonService,
    auth,
    account,
    storeList,
    storeDetail,
    location,
    area,
    //Thêm eventId
    events,
    channel,
    filter,
    grid,
    report,
    dropdown
});

export default reducers;