import React from "react";
import './LoadingView.css';
export default class LoadingView extends React.Component {
    render() {
        //const { classes } = this.props;
        return (
            <div className="loading">
                <div className="loader"></div>
                <span>Vui lòng chờ... đang tải dữ liệu</span>
            </div>
        );
    }
}