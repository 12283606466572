import * as actionTypes from '../constants/actionTypes';

const initialState = {
    coords: {
        latitude: 0,
        longitude: 0
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOCATION_INI:
            return {
                coords: {
                    latitude: 0,
                    longitude: 0
                }
            }
        case actionTypes.LOCATION_SET:
            return {
                coords: {
                    latitude: action.data.coords.latitude,
                    longitude: action.data.coords.longitude
                }
            }
        default:
            return state;
    }
}

export default reducer;