import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { RadioButton } from 'primereact/radiobutton';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from 'primereact/dropdown';
// import {ProgressSpinner} from 'primereact/progressspinner';
import LoadingView from '../../loadingview/LoadingView';
import {
    IMPORT_USER_RTMM,
    IMPORT_USER_ASM,
    IMPORT_USER_TSM,
    IMPORT_USER_SR,
    IMPORT_USER_EVENT,
    IMPORT_USER_SUPPLIER,
    IMPORT_SUPPLIER,
    LIST_EVENT,
    GET_LIST_AREA,
    IMPORT_REDO_SURVEY,
    IMPORT_VOLUME_SURVEY,
    IMPORT_CHANGE_USERSTOREUSER,
    IMPORT_CHANGE_CODE_STORE,
    IMPORT_CHANGE_SUPPLIER_STORE,
    IMPORT_DEL_STORE,
    IMPORT_RMEM,
    IMPORT_STORE_UPDATE_SUPPLIER,
    IMPORT_USER_SUPPORTER,
    IMPORT_USER_UPDATE,
    IMPORT_DEL_SURVEY,
    LIST_EVENT_ACTIVE
}
    from '../../../store/constants/serviceEndpoint';
import {
    baseUrl
} from '../../../common/config'
import { headerToken } from '../../../store/constants/header';
import * as axiosWrapper from '../../../store/constants/axiosWrapper';

class ImportUser extends Component {
    constructor() {
        super();
        this.state = {
            apiImport: '',
            labelUpload: 'Chưa chọn file...',
            dataImport: [],
            file: null,
            loading: false,
            eventId: 0,
            listEvent: [],
            listArea: [],
            listTemplate: [
                {
                    value: 0,
                    label: 'Template RTMM'
                }, {
                    value: 1,
                    label: 'Template ASM'
                }, {
                    value: 2,
                    label: 'Template TSM'
                }, {
                    value: 3,
                    label: 'Template SR'
                }, {
                    value: 4,
                    label: 'Template UserEvent'
                }, {
                    value: 5,
                    label: 'Template UserSupplier'
                }, {
                    value: 6,
                    label: 'Template Supplier'
                }, {
                    value: 7,
                    label: 'Template Redo Survey'
                }, {
                    value: 8,
                    label: 'Template Volume Survey'
                }, {
                    value: 9,
                    label: 'Template User Change Store'
                }, {
                    value: 10,
                    label: 'Template Change Code Store'
                }, {
                    value: 11,
                    label: 'Template Change Supplier In ALL Store'
                }, {
                    value: 12,
                    label: 'Template Del Store'
                }, {
                    value: 13,
                    label: 'Template RMEM'
                }, {
                    value: 14,
                    label: 'Template Store Update Supplier'
                }, {
                    value: 15,
                    label: 'Template User Supporter'
                }, {
                    value: 16,
                    label: 'Template Update User'
                }, {
                    value: 17,
                    label: 'Template Del Survey'
                }
            ],
            templateId: null,
            tableLayout: 1,
            visibleTable: false,
            totalRow: 0,
            isLoading: true,
            disabledImport: false,
            areaId: 0,
        }

    }

    componentDidMount() {
        this.GetListEvent();
        this.getListArea();
        this.setState({
            isLoading: false,
        })
    }


    selectFile = (event) => {
        const files = event.target.files;
        if ((files.length > 0)) {
            this.setState({
                file: files[0],
                labelUpload: files[0].name
            });
        } else {
            this.setState({
                file: null,
                labelUpload: 'Chưa chọn file...',
            });
        }
    }

    triggerOpenUpload = (event) => {
        event.preventDefault();
        document.getElementById("fileImport").click();
    }

    downloadTemplate = (e) => {
        const idx = this.state.templateId
        switch (idx) {
            case 0:
                setTimeout(() => {
                    const response = {
                        file: baseUrl + "/template/UserRTMM.xlsx",
                    };
                    window.open(response.file);
                }, 500);
                break;
            case 1:
                setTimeout(() => {
                    const response = {
                        file: baseUrl + "/template/UserASM.xlsx",
                    };
                    window.open(response.file);
                }, 500);
                break;
            case 2:
                setTimeout(() => {
                    const response = {
                        file: baseUrl + "/template/UserTSM.xlsx",
                    };
                    window.open(response.file);
                }, 500);
                break;
            case 3:
                setTimeout(() => {
                    const response = {
                        file: baseUrl + "/template/UserSR.xlsx",
                    };
                    window.open(response.file);
                }, 500);
                break;
            case 4:
                setTimeout(() => {
                    const response = {
                        file: baseUrl + "/template/UserEvent.xlsx",
                    };
                    window.open(response.file);
                }, 500);
                break;
            case 5:
                setTimeout(() => {
                    const response = {
                        file: baseUrl + "/template/UserSupplier.xlsx",
                    };
                    window.open(response.file);
                }, 500);
                break;
            case 6:
                setTimeout(() => {
                    const response = {
                        file: baseUrl + "/template/Supplier.xlsx",
                    };
                    window.open(response.file);
                }, 500);
                break;
            case 7:
                setTimeout(() => {
                    const response = {
                        file: baseUrl + "/template/Redo_Survey.xlsx",
                    };
                    window.open(response.file);
                }, 500);
                break;
            case 8:
                setTimeout(() => {
                    const response = {
                        file: baseUrl + "/template/VolumeSurvey.xlsx",
                    };
                    window.open(response.file);
                }, 500);
                break;
            case 9:
                setTimeout(() => {
                    const response = {
                        file: baseUrl + "/template/UserChangeStore.xlsx",
                    };
                    window.open(response.file);
                }, 500);
                break;
            case 10:
                setTimeout(() => {
                    const response = {
                        file: baseUrl + "/template/TemplateChangCodeStore.xlsx",
                    };
                    window.open(response.file);
                }, 500);
                break;
            case 11:
                setTimeout(() => {
                    const response = {
                        file: baseUrl + "/template/TemplateChangeSupplierStore.xlsx",
                    };
                    window.open(response.file);
                }, 500);
                break;
            case 12:
                setTimeout(() => {
                    const response = {
                        file: baseUrl + "/template/TemplateDelStore.xlsx",
                    };
                    window.open(response.file);
                }, 500);
                break;
            case 13:
                setTimeout(() => {
                    const response = {
                        file: baseUrl + "/template/UserRMEM.xlsx",
                    };
                    window.open(response.file);
                }, 500);
                break;
            case 14:
                    setTimeout(() => {
                        const response = {
                            file: baseUrl + "/template/TemplateUpdateStoreSupplier.xlsx",
                        };
                        window.open(response.file);
                    }, 500);
                    break;
            case 15:
                    setTimeout(() => {
                        const response = {
                            file: baseUrl + "/template/UserSupporter.xlsx",
                        };
                        window.open(response.file);
                    }, 500);
                    break;
            case 16:
                        setTimeout(() => {
                            const response = {
                                file: baseUrl + "/template/UserUpdate.xlsx",
                            };
                            window.open(response.file);
                        }, 500);
                        break;
            case 17:
                            setTimeout(() => {
                                const response = {
                                    file: baseUrl + "/template/TemplateDelSurvey.xlsx",
                                };
                                window.open(response.file);
                            }, 500);
                            break;
            default:
                break;
        }
    }

    getListArea() {
        const _this = this;
        axiosWrapper.axiosWithHeaders('get',GET_LIST_AREA,null)
        .then(function (response) {
            if (response.status === 200) {
                const data = response.data;
                const result = data.result;
                let arr = [];
                result.map((item, i) => {
                    let obj = {}
                    obj["value"] = item.id;
                    obj["label"] = item.name;
                    arr.push(obj);
                })

                _this.setState({
                    listArea: arr
                })

            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    GetListEvent = () => {
        const _this = this;
        axiosWrapper.axiosWithHeaders('post',LIST_EVENT_ACTIVE,null).then(function (response) {
            if (response.status === 200) {
                const { result } = response.data;
                if (result !== undefined && result !== null && result.length > 0) {
                    let arr = [];
                    (result).forEach(item => {
                        arr.push({
                            "value": item.id,
                            "label": item.name
                        })
                    })
                    arr = arr.reverse()
                    _this.setState({
                        listEvent: arr
                    });
                }
            }
        })
            .catch(function (error) {
                console.log(error);
            })
    }

    ImportData = (e) => {
        e.preventDefault();

        let apiImport = this.state.apiImport;
        const _this = this;
        if (apiImport === "") {
            Swal.fire("Lỗi", "Vui lòng chọn chế độ import !", "error");
            return;
        }

        let file = this.state.file;

        if (file === null) {
            Swal.fire("Lỗi", "Vui lòng chọn file !", "error");
            return;
        }

        if (apiImport === "") {
            Swal.fire("Lỗi", "Vui lòng chọn chế độ import !", "error");
            return;
        }

        if (apiImport === "import-user-event" || apiImport === "import-change-userstoreuser") {
            if (this.state.eventId === null || this.state.eventId === "0" || this.state.eventId === 0) {
                Swal.fire("Lỗi", "Vui lòng chọn hoạt động !", "error");
                return;
            }


        }

        if (apiImport === "import-change-code-store" || apiImport === "import-change-supplier-list-store" || apiImport === "import-del-store") {
            if (this.state.eventId === null || this.state.eventId === "0" || this.state.eventId === 0) {
                Swal.fire("Lỗi", "Vui lòng chọn hoạt động !", "error");
                return;
            }

            if (this.state.areaId === null || this.state.areaId === "0" || this.state.areaId === 0) {
                Swal.fire("Lỗi", "Vui lòng chọn vùng !", "error");
                return;
            }
        }


        let apiImportURI = "";
        switch (this.state.apiImport) {
            case 'import-user-rtmm':
                apiImportURI = IMPORT_USER_RTMM;
                break;
            case 'import-user-asm':
                apiImportURI = IMPORT_USER_ASM;
                break;
            case 'import-user-tsm':
                apiImportURI = IMPORT_USER_TSM;
                break;
            case 'import-user-sr':
                apiImportURI = IMPORT_USER_SR;
                break;
            case 'import-user-event':
                apiImportURI = IMPORT_USER_EVENT;
                break;
            case 'import-user-supplier':
                apiImportURI = IMPORT_USER_SUPPLIER;
                break;
            case 'import-supplier':
                apiImportURI = IMPORT_SUPPLIER;
                break;
            case 'import-redo-survey':
                apiImportURI = IMPORT_REDO_SURVEY;
                break;
            case 'import-volume-survey':
                apiImportURI = IMPORT_VOLUME_SURVEY;
                break;
            case 'import-change-userstoreuser':
                apiImportURI = IMPORT_CHANGE_USERSTOREUSER;
                break;
            case 'import-change-code-store':
                apiImportURI = IMPORT_CHANGE_CODE_STORE;
                break;
            case 'import-change-supplier-list-store':
                apiImportURI = IMPORT_CHANGE_SUPPLIER_STORE;
                break;
            case 'import-del-store':
                apiImportURI = IMPORT_DEL_STORE;
                break;
            case 'import-user-rmem':
                apiImportURI = IMPORT_RMEM;
                break;
            case 'import-change-supplier-store':
                apiImportURI = IMPORT_STORE_UPDATE_SUPPLIER;
                break;
            case 'import-user-supporter':
                apiImportURI = IMPORT_USER_SUPPORTER;
                break;
            case 'import-user-update':
                    apiImportURI = IMPORT_USER_UPDATE;
                    break;
            case 'import-del-survey':
                    apiImportURI = IMPORT_DEL_SURVEY;
                    break;
            default:
                apiImportURI = "";
                break;
        }




        var fr = new FormData();
        if (apiImportURI === IMPORT_USER_EVENT || apiImportURI === IMPORT_USER_SUPPLIER
            || apiImportURI === IMPORT_CHANGE_USERSTOREUSER
            || apiImport === IMPORT_CHANGE_CODE_STORE

        ) {
            fr.append("EventId", this.state.eventId);
            fr.append("AreaId", 0);
        }

        //   console.log(apiImport, IMPORT_CHANGE_CODE_STORE)

        if (apiImport === "import-change-code-store" 
        || apiImport === "import-change-supplier-list-store"
        || apiImport === "import-change-supplier-store"
         || apiImport === "import-del-store"
         || apiImport === "import-del-survey"
         ) {
            //  console.log(1333)
            fr.append("EventId", this.state.eventId);
            fr.append("AreaId", this.state.areaId);
        }

        // console.log(this.state.eventId, this.state.areaId);

        fr.append("file", file);

        this.setState({
            loading: true,
            visibleTable: false,
            totalRow: 0,
            isLoading: true,
            disabledImport: true
        })

        axiosWrapper.axiosWithHeadersFormData('post',apiImportURI,fr)
        .then(function (response) {
            if (response.status === 200) {
                const data = response.data;
                const listError = data.listError;
                _this.setState({
                    dataImport: listError,
                    totalRow: listError.length,
                    loading: false,
                    visibleTable: true,
                    isLoading: false,
                    disabledImport: false,
                    // areaId: 0,
                    // eventId: 0,
                });
                Swal.fire("Thông báo", "Import hoàn tất!", "info");
            }
        })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    loading: false,
                    totalRow: 0,
                    visibleTable: false,
                    isLoading: false,
                    disabledImport: false,
                    // areaId: 0,
                    // eventId: 0,
                })
            })
    }

    render() {
        const datacontent = (this.state.isLoading) ?
            (
                <div className="p-col-12">
                    <LoadingView />
                </div>
            ) : (
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Fieldset legend="Chế độ import">
                                <div className="p-grid">
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb1" name="importuser" value="import-user-rtmm" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 1,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-user-rtmm'} />
                                        <label htmlFor="rb1" className="p-radiobutton-cus p-radiobutton-label">Import Regional Trade Marketing Manager</label>
                                    </div>
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb2" name="importuser" value="import-user-asm" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 1,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-user-asm'} />
                                        <label htmlFor="rb2" className="p-radiobutton-cus p-radiobutton-label">Import Area Sales Manager</label>
                                    </div>
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb3" name="importuser" value="import-user-tsm" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 1,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-user-tsm'} />
                                        <label htmlFor="rb3" className="p-radiobutton-cus p-radiobutton-label">Import Territory Sales Manager</label>
                                    </div>
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb4" name="importuser" value="import-user-sr" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 1,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-user-sr'} />
                                        <label htmlFor="rb4" className="p-radiobutton-cus p-radiobutton-label">Import Sale Retail</label>
                                    </div>
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb44" name="importuser" value="import-user-supporter" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 1,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-user-supporter'} />
                                        <label htmlFor="rb44" className="p-radiobutton-cus p-radiobutton-label">Import Supporter</label>
                                    </div>
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb5" name="importuser" value="import-user-event" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 2,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-user-event'} />
                                        <label htmlFor="rb5" className="p-radiobutton-cus p-radiobutton-label">Import UserEvent</label>
                                    </div>
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb6" name="importuser" value="import-user-supplier" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 2,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-user-supplier'} />
                                        <label htmlFor="rb6" className="p-radiobutton-cus p-radiobutton-label">Import UserSupplier</label>
                                    </div>
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb7" name="importuser" value="import-supplier" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 2,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-supplier'} />
                                        <label htmlFor="rb7" className="p-radiobutton-cus p-radiobutton-label">Import Supplier</label>
                                    </div>
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb8" name="importuser" value="import-redo-survey" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 2,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-redo-survey'} />
                                        <label htmlFor="rb8" className="p-radiobutton-cus p-radiobutton-label">Import Redo-Survey</label>
                                    </div>

                                    {/* Thêm import volumne */}
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb9" name="importuser" value="import-volume-survey" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 2,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-volume-survey'} />
                                        <label htmlFor="rb9" className="p-radiobutton-cus p-radiobutton-label">Import Volume Survey</label>
                                    </div>
                                    {/* Thêm import change user store user */}
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb10" name="importuser" value="import-change-userstoreuser" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 2,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-change-userstoreuser'} />
                                        <label htmlFor="rb10" className="p-radiobutton-cus p-radiobutton-label">Import Change UserStore</label>
                                    </div>
                                    {/* Thêm import change code store */}
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb11" name="importuser" value="import-change-code-store" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 2,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-change-code-store'} />
                                        <label htmlFor="rb11" className="p-radiobutton-cus p-radiobutton-label">Import Change Code Store</label>
                                    </div>
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb12" name="importuser" value="import-change-supplier-list-store" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 2,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-change-supplier-list-store'} />
                                        <label htmlFor="rb12" className="p-radiobutton-cus p-radiobutton-label">Import Change Supllier In ALL Store</label>
                                    </div>
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb15" name="importuser" value="import-change-supplier-store" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 2,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-change-supplier-store'} />
                                        <label htmlFor="rb15" className="p-radiobutton-cus p-radiobutton-label">Import Store Update Supplier</label>
                                    </div>
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb13" name="importuser" value="import-del-store" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 2,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-del-store'} />
                                        <label htmlFor="rb13" className="p-radiobutton-cus p-radiobutton-label">Import Del Store</label>
                                    </div>
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb14" name="importuser" value="import-user-rmem" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 1,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-user-rmem'} />
                                        <label htmlFor="rb14" className="p-radiobutton-cus p-radiobutton-label">Import RMEM</label>
                                    </div>
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb16" name="importuser" value="import-user-update" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 1,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-user-update'} />
                                        <label htmlFor="rb16" className="p-radiobutton-cus p-radiobutton-label">Import Update USER</label>
                                    </div>
                                    <div className="p-col-3">
                                        <RadioButton inputId="rb17" name="importuser" value="import-del-survey" onChange={(e) => this.setState({
                                            apiImport: e.value,
                                            // file: null,
                                            // labelUpload: 'Chưa chọn file...',
                                            loading: false,
                                            tableLayout: 1,
                                            visibleTable: false,
                                            areaId: 0,
                                            eventId: 0,
                                        })} checked={this.state.apiImport === 'import-del-survey'} />
                                        <label htmlFor="rb17" className="p-radiobutton-cus p-radiobutton-label">Import DEL SURVEY</label>
                                    </div>
                                </div>
                            </Fieldset>
                            {
                                (this.state.apiImport === "import-user-event"
                                    || this.state.apiImport === "import-change-userstoreuser"
                                    || this.state.apiImport === "import-change-code-store"
                                    || this.state.apiImport === "import-change-supplier-list-store"
                                    || this.state.apiImport === "import-change-supplier-store"
                                    || this.state.apiImport === "import-del-store"
                                    || this.state.apiImport === "import-del-survey"
                                ) && (
                                    <Fieldset legend="ĐIỀU KIỆN BỔ SUNG">
                                        <div className="p-grid">
                                            <div className="p-col-3">
                                                <div className="p-grid">
                                                    <div className="p-col-4" style={{ textAlign: "right", paddingTop: 13, paddingBottom: 0 }}>
                                                        <label style={{ fontSize: 14 }}>Hoạt động</label>
                                                    </div>
                                                    <div className="p-col-6" style={{ paddingBottom: 0 }}>
                                                        <Dropdown
                                                            value={this.state.eventId}
                                                            options={this.state.listEvent}
                                                            onChange={(e) => this.setState({ eventId: e.value })}
                                                            className="p-button-raised p-button-info"
                                                            placeholder="Chọn hoạt động"
                                                            filter={true}
                                                            filterPlaceholder={"Tìm kiếm hoạt động"}
                                                            filterBy="label,value"
                                                            filterMatchMode="contains"
                                                            style={{ width: "100%" }}
                                                            tooltip={"Chọn hoạt động !"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {(this.state.apiImport === "import-change-code-store"
                                                || this.state.apiImport === "import-change-supplier-list-store"
                                                || this.state.apiImport === "import-change-supplier-store"
                                                || this.state.apiImport === "import-del-store"
                                                || this.state.apiImport === "import-del-survey"
                                            ) && (
                                                    <div className="p-col-3">
                                                        <div className="p-grid">
                                                            <div className="p-col-4" style={{ textAlign: "right", paddingTop: 13, paddingBottom: 0 }}>
                                                                <label style={{ fontSize: 14 }}>Vùng</label>
                                                            </div>
                                                            <div className="p-col-6" style={{ paddingBottom: 0 }}>
                                                                <Dropdown
                                                                    value={this.state.areaId}
                                                                    options={this.state.listArea}
                                                                    onChange={(e) => this.setState({ areaId: e.value })}
                                                                    className="p-button-raised p-button-info"
                                                                    placeholder="Chọn vùng"
                                                                    filter={true}
                                                                    filterPlaceholder={"Tìm kiếm vùng"}
                                                                    filterBy="label,value"
                                                                    filterMatchMode="contains"
                                                                    style={{ width: "100%" }}
                                                                    tooltip={"Chọn vùng !"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </Fieldset>
                                )
                            }
                        </div>
                        <div className="p-col-3">
                            <input
                                id="fileImport"
                                type="file"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={(event) => this.selectFile(event)}
                                className="d-none"
                            />
                            <Button label="Chọn file..." icon="pi pi-folder-open" className="p-button-raised p-button-info" onClick={(event) => this.triggerOpenUpload(event)} />
                            <label style={{ paddingLeft: 5 }}>
                                {this.state.labelUpload}
                            </label>
                        </div>
                        <div className="p-col-6">
                            <div className="p-grid">
                                <div className="p-col-3">
                                    <Dropdown
                                        value={this.state.templateId}
                                        options={this.state.listTemplate}
                                        onChange={(e) => this.setState({ templateId: e.value })}
                                        className="p-cust-width"
                                        placeholder="Chọn template"
                                        filter={true}
                                        filterPlaceholder={"Tìm kiếm template"}
                                        filterBy="label,value"
                                        filterMatchMode="contains"
                                        style={{ width: '100%' }}
                                        tooltip={"Chọn template !"}
                                    />
                                </div>
                                <div className="p-col-9">
                                    <Button label="Tải file"
                                        icon="pi pi-download"
                                        className="p-button-raised p-button-info"
                                        onClick={(e) => this.downloadTemplate(e)}
                                        disabled={(this.state.templateId === null) ? true : false}
                                        style={{ marginRight: 10 }}
                                    />
                                    <Button
                                        label="Import"
                                        icon="pi pi-file-excel"
                                        className="p-button-warning p-button-raised"
                                        onClick={(e) => this.ImportData(e)}
                                        disabled={this.state.disabledImport}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-col-3">

                        </div>
                    </div>
                </div>
            )
        return (
            <div className="p-grid" style={{ marginTop: 20 }}>
                {datacontent}
                <div className="p-col-12">
                    {
                        (this.state.tableLayout === 1) && (this.state.visibleTable === true) && (
                            <Fieldset legend="DANH SÁCH LỖI">
                                <div className="p-grid">
                                    <div className="p-col-12" style={{ textAlign: "right" }}>
                                        <label>Tổng số: <span style={{ color: "red" }}>{this.state.totalRow}</span> dòng</label>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <DataTable
                                        value={this.state.dataImport}
                                        emptyMessage="Chưa có dữ liệu"
                                        paginator={true}
                                        rows={50}
                                        rowsPerPageOptions={[50, 100, 200]}
                                        scrollable={true}
                                        scrollHeight={"50vh"}
                                    >
                                        <Column field="userName" header="Tài khoản" className="p-col-fixed" style={{ width: 175 }} />
                                        <Column field="fullName" header="Họ và tên" className="p-col-fixed" style={{ width: 175 }} />
                                        <Column field="level" header="Loại tài khoản" className="p-col-fixed" style={{ width: 100 }} />
                                        <Column field="regionalTradeMarketingManagerId" header="RTMM" className="p-col-fixed" style={{ width: 175 }} />
                                        <Column field="areaSalesManagerId" header="ASM" className="p-col-fixed" style={{ width: 175 }} />
                                        <Column field="territorySalesManagerId" header="TSM" className="p-col-fixed" style={{ width: 175 }} />
                                        <Column field="exception" header="Nội dung" className="p-col-fixed" style={{ width: 500 }} />
                                    </DataTable>
                                </div>
                            </Fieldset>
                        )
                    }
                    {
                        (this.state.tableLayout === 2) && (this.state.visibleTable === true) && (
                            <Fieldset legend="DANH SÁCH LỖI">
                                <div className="p-grid">
                                    <div className="p-col-12" style={{ textAlign: "right" }}>
                                        <label>Tổng số: <span style={{ color: "red" }}>{this.state.totalRow}</span> dòng</label>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <DataTable
                                        value={this.state.dataImport}
                                        emptyMessage="Chưa có dữ liệu"
                                        paginator={true}
                                        rows={50}
                                        rowsPerPageOptions={[50, 100, 200]}
                                        scrollable={true}
                                        scrollHeight={"50vh"}
                                    >
                                        <Column field="code" header="Mã" className="p-col-fixed" />
                                        <Column field="name" header="Họ và tên" className="p-col-fixed" />
                                        <Column field="exception" header="Nội dung" className="p-col-fixed" />
                                    </DataTable>
                                </div>
                            </Fieldset>

                        )
                    }
                </div>
            </div>
        )
    }
}

export default ImportUser;