import * as actionTypes from '../constants/actionTypes';

const initialState = {
    listChannel: [],
    listChannelPackage: [],
    message: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CHANNEL_INI:
            return state;
        case actionTypes.CHANNEL_SET:
            return {
                ...state,
                listChannel: action.listChannel
            }
        case actionTypes.CHANNEL_PACKAGE_SET:
            return {
                ...state,
                listChannel: state.listChannel,
                listChannelPackage: action.listChannelPackage
            }
        default:
            return state;
    }
}

export default reducer;