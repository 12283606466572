import * as config from '../../common/config';

export const AUTH_CODE_GET = `${config.baseUrl}/check-token`;
export const USER_LOGIN = `${config.baseUrl}/user-login-detail`;
export const USER_LOGIN_JWT = `${config.baseUrl}/user-login-jwt`;
export const USER_CHECK_TOKEN = `${config.baseUrl}/user-check-token`;
export const USER_CHANGE_PASSWORD = `${config.baseUrl}/user-change-password`;
export const USER_GET_INFO = `${config.baseUrl}/user-get-info`;
export const USER_GET = `${config.baseUrl}/user-get`;

export const USER_GET_INFO_ADMIN = `${config.baseUrl}/user-get-info-admin`;

export const PROVINCE_GET_ALL = `${config.baseUrl}/province-get-all`;
export const GET_EVENTS_VALID_DATE = `${config.baseUrl}/get-events-valid-date`;
export const GET_EVENT_IMAGES = `${config.baseUrl}/get-event-images`;
export const GET_CHANNEL_IMAGES = `${config.baseUrl}/get-channel_image`;      // GET CHANNELS BY EVENTID
export const CHANNEL_PACKAGE_GET_BY_CHANNELID = `${config.baseUrl}/channel-package-get-by-channelId`;    // LẤY SUẤT (30-40)
export const GET_PACKAGE_IMAGES = `${config.baseUrl}/get-package-images`;

export const STORETYPE_GET_ALL = `${config.baseUrl}/storetype-get-all`;
export const STORE_GET_ALL = `${config.baseUrl}/store-get-all`;
export const STORE_GET_DETAIL = `${config.baseUrl}/store-get-detail`;
export const STORE_ADD = `${config.baseUrl}/store-add`;

export const UPDATE_APPROVE = `${config.baseUrl}/Store/UpdateApprove`;
export const LIST_QUESTION = `${config.baseUrl}/api/server/listquestion`;
export const DETAIL_STORE = `${config.baseUrl}/api/server/detailstore`;
export const GET_LIST_AREA = `${config.baseUrl}/get-areas`;
//Tuan them
export const GET_FILTER_BY_TABLEID = `${config.baseUrl}/filter-get-table`;
export const GET_DATADROPDOWN_FILTER = `${config.baseUrl}/filter-get-data-filter`;
export const GET_DATADROPDOWN = `${config.baseUrl}/filter-get-datadropdown`;
//DATAGRID
export const GET_DATAGRID = `${config.baseUrl}/grid-load-default-table`;
export const GET_COLUMNGRID = `${config.baseUrl}/grid-column-get-column`;
export const GET_SETTINGGRID = `${config.baseUrl}/grid-get-table-detail`;
//EventController
export const ADD_EVENT = `${config.baseUrl}/add-event`;
export const DEL_EVENT = `${config.baseUrl}/delete-event`;
export const DETAIL_EVENT = `${config.baseUrl}/detail-event`;
export const UPDATE_EVENT = `${config.baseUrl}/update-event`;
export const LIST_EVENT = `${config.baseUrl}/get-event-full`;
export const LIST_EVENT_ACTIVE = `${config.baseUrl}/get-event-active`;
//AreaEventController
export const GET_AREA_EVENT = `${config.baseUrl}/get-area-event`;
export const ADD_ARR_AREA_EVENT = `${config.baseUrl}/add-area-event-array`;
export const DEL_ARR_AREA_EVENT = `${config.baseUrl}/del-area-event-array`;
export const UPDATE_AREA_EVENT_TARGET = `${config.baseUrl}/update-area-event-target`;
//ChannelController
export const GET_CHANNELS = `${config.baseUrl}/get-channel`; 
export const ADD_CHANNELS = `${config.baseUrl}/add-channel`; 
export const UPDATE_CHANNELS = `${config.baseUrl}/update-channel`; 
export const DEL_CHANNELS = `${config.baseUrl}/del-channel`; 
//ChannelPackageController
export const ADD_CHANNELS_PACKAGE = `${config.baseUrl}/add-channel-packagee`; 
export const GET_CHANNELS_PACKAGE = `${config.baseUrl}/get-channel-package`; 
export const DEL_CHANNELS_PACKAGE = `${config.baseUrl}/del-channel-packagee`; 
export const UPDATE_CHANNELS_PACKAGE = `${config.baseUrl}/update-channel-packagee`; 
//ChannelImageController
export const GET_IMAGES_EVENTS = `${config.baseUrl}/get-all-channel-images-by-event`; 
export const DEL_CHANNELS_IMAGES = `${config.baseUrl}/del-channel-image`
export const GET_CHANNELS_IMAGES = `${config.baseUrl}/get-channel-images`
export const ADD_CHANNELS_IMAGES = `${config.baseUrl}/add-channel-image`
export const UPDATE_CHANNELS_IMAGES = `${config.baseUrl}/update-channel-image`
//EventImageController
export const GET_EVENT_LIST_IMAGE = `${config.baseUrl}/get-all-typeimage`; 
export const ADD_TYPE_IMAGE = `${config.baseUrl}/add-type-image`; 
export const DEL_TYPE_IMAGE = `${config.baseUrl}/del-type-image`; 
export const UPDATE_TYPE_IMAGE = `${config.baseUrl}/update-type-image`; 
//PackageImageController
export const GET_PACKAGE_IMAGES_EVENTID = `${config.baseUrl}/get-package-images-EventId`; 
export const DEL_PACKAGE_IMAGES = `${config.baseUrl}/del-package-image`; 
export const ADD_PACKAGE_IMAGES = `${config.baseUrl}/add-package-image`; 
export const UPD_PACKAGE_IMAGES = `${config.baseUrl}/update-package-image`; 
//ImportController
export const IMPORT_USER_RTMM = `${config.baseUrl}/import-user-rtmm`; 
export const IMPORT_USER_ASM = `${config.baseUrl}/import-user-asm`; 
export const IMPORT_USER_TSM = `${config.baseUrl}/import-user-tsm`; 
export const IMPORT_USER_SR = `${config.baseUrl}/import-user-sr`; 
export const IMPORT_USER_EVENT = `${config.baseUrl}/import-user-event`; 
export const IMPORT_USER_SUPPLIER = `${config.baseUrl}/import-user-supplier`; 
export const IMPORT_STORE = `${config.baseUrl}/import-store`;
export const IMPORT_SUPPLIER = `${config.baseUrl}/import-supplier`;  
export const IMPORT_REDO_SURVEY = `${config.baseUrl}/import-redo-survey`;  
export const IMPORT_VOLUME_SURVEY = `${config.baseUrl}/import-volume-survey`;
export const CHANGE_CHANNEL_PACKAGE_SURVEY = `${config.baseUrl}/change-channel-package-survey`;
export const IMPORT_CHANGE_USERSTOREUSER = `${config.baseUrl}/import-change-userstoreuser`; 
export const IMPORT_CHANGE_CODE_STORE = `${config.baseUrl}/import-change-code-store`; 
export const IMPORT_CHANGE_SUPPLIER_STORE = `${config.baseUrl}/import-change-supplier-list-store`; 
export const IMPORT_STORE_UPDATE_SUPPLIER = `${config.baseUrl}/import-change-supplier-store`; 
export const IMPORT_USER_SUPPORTER = `${config.baseUrl}/import-user-supporter`; 
export const IMPORT_USER_UPDATE = `${config.baseUrl}/import-user-update`; 
export const IMPORT_DEL_SURVEY = `${config.baseUrl}/import-del-survey`; 
export const IMPORT_DEL_STORE = `${config.baseUrl}/import-del-store`; 
export const IMPORT_RMEM = `${config.baseUrl}/import-user-rmem`; 
//UserController
export const USER_RESET_PASSWORD = `${config.baseUrl}/user-reset-password`; 
export const UPDATE_USER = `${config.baseUrl}/update-user`; 
export const CREATE_USER = `${config.baseUrl}/user-register`;
export const REMOVE_USER = `${config.baseUrl}/remove-user`; 
//SurveyController
export const DETAIL_SURVEY_ADMIN = `${config.baseUrl}/detail-survey-admin`; 
export const DETAIL_SURVEY_LAYER = `${config.baseUrl}/detail-survey-admin-layer`; 
export const DETAIL_SURVEY_ADMIN_GUILD = `${config.baseUrl}/detail-survey-admin-guild`; 
export const ACCEPT_SURVEY_STATUS = `${config.baseUrl}/accept-survey-status`; 
export const ACCEPT_SURVEY_VOLUME = `${config.baseUrl}/accept-survey-volume`; 
export const REMOVE_SURVEY_ADMIN = `${config.baseUrl}/remove-survey-admin`; 
export const REDO_SURVEY_ADMIN = `${config.baseUrl}/redo-survey-admin`; 
export const REDO_SURVEY_LAYER_RTMM= `${config.baseUrl}/redo-submit-layer`; 
//StoreController
export const STORE_REMOVE_ALL_SURVEY = `${config.baseUrl}/store-remove-all-survey`; 
export const STORE_DELETE = `${config.baseUrl}/store-delete`; 
export const STORE_UPDATE = `${config.baseUrl}/store-update`; 
export const STORE_DETAIL = `${config.baseUrl}/store-detail`; 
export const STORE_DETAIL_USER_STORE = `${config.baseUrl}/store-detail-user-store`; 
export const STORE_CHANGE_SR = `${config.baseUrl}/store-change-sr`; 
//ReportController
export const REPORT_LIST_SURVEY = `${config.baseUrl}/reportlistsurvey`; 
//ProductController
export const GET_PRODUCT = `${config.baseUrl}/get-products`; 
export const ADD_PRODUCT = `${config.baseUrl}/add-product`; 
export const DEL_PRODUCT = `${config.baseUrl}/del-product`; 
export const UPD_PRODUCT = `${config.baseUrl}/update-product`; 
//ImageProductController
export const GET_IMAGE_PRODUCT = `${config.baseUrl}/get-image-products`; 
export const ADD_IMAGE_PRODUCT = `${config.baseUrl}/add-image-product`; 
export const DEL_IMAGE_PRODUCT = `${config.baseUrl}/del-image-product`; 
export const UPD_IMAGE_PRODUCT = `${config.baseUrl}/update-image-product`; 
//DashboardController
export const DASHBOARD_CHANNEL_PACKAGE = `${config.baseUrl}/dashboard-channel-package`; 
export const DASHBOARD_CHANNEL_PACKAGE_STATUS = `${config.baseUrl}/dashboard-channel-package-status`; 
export const DASHBOARD_KPI = `${config.baseUrl}/dashboard-kpi`; 
export const DASHBOARD_STORE_RUN = `${config.baseUrl}/dashboard-store-run`; 
//AI
export const GET_COCA_BOUNDINGBOX = `${config.baseApiAI}/get-coca-boundingbox`;

export const DEL_SURVEY_LAYER = `${config.baseUrl}/del-survey-layer`;

export const REPORT_DOWNLOAD_LIST_USER = `${config.baseUrl}/reportlistuser`;

export const REPORT_DOWNLOAD_LIST_STORE = `${config.baseUrl}/reportliststore`;
export const REFRESHTOKEN = `${config.baseUrl}/refreshtoken`;


