
// export const HOME = '/home';
export const HOME = '/listevent';
export const LOGIN = '/login';
export const CHANGE_PASSWORD = '/event/setting';
export const SURVEY = '/survey';
export const ADD_CUSTOMER = '/add-customer';
export const CUSTOMER = '/customer';
export const LIST_INFO = '/event/listinfo';

//detailInfo cũ sẽ chạy uniqueundifier để link từ report chạy được
export const DETAIL_INFO = '/event/detailinfo';
//detailInfo mới và detailLayer sẽ gọi từ đây
export const DETAIL_LAYER = '/event/infolayer';
export const DETAIL_INFO_NEW = '/event/infodetail';


export const LIST_EVENT = '/listevent';
export const DETAIL_EVENT = '/detailevent';
export const LIST_STORE = '/event/liststore';
export const DETAIL_STORE = '/event/detailstore';
export const IMPORT_STORE = '/event/importstore';
export const LIST_CHANNEL = '/listchannel';
export const LIST_CHANNEL_BACKPACK = '/listchannelbackpack';
//Danh sách nhân viên
export const LIST_USER = '/event/listuser';
export const DETAIL_USER = '/event/detailuser';
export const IMPORT_USER = '/event/importuser';
export const ADD_USER = '/event/adduser';
//Cài đặt
export const SETTING = '/event/setting';
//Danh sách nhà phân phối
export const LIST_SUPPLIER = '/listsupplier';
export const DETAIL_SUPPLIER = '/event/detailsupplier';
//danh sách sản phẩm
export const LIST_PRODUCT = '/listproduct';
//danh sách hình ảnh sản phẩm
export const LIST_IMAGE_PRODUCT = '/listimageproduct'
//Dashboard
export const DASHBOARD = '/dashboard'
//ListInfoChannelPackage
export const INFO_CHANNEL_PACKAGE = '/event/infochannelpackage'
//danh sách layer
export const LIST_LAYER = '/event/listlayer'
//Bổ sung import
export const IMPORT = '/event/import'
