import * as actionTypes from '../constants/actionTypes';
import { deCryptUserId, deCryptClient } from '../../crypto/Crypto';
import { CACHE_USER_INFO } from '../constants/actionTypes'
// import {getDetailUser} from '../../crypto/Crypto'
import jwt_decode from "jwt-decode";
function checkForceChangePassword() {
    let forceChangePassword = localStorage.getItem(actionTypes.CHANGE_PASSWORD_STORAGE);
    switch (forceChangePassword){
        case undefined:
            return false;
        case null:
            return false;
        case 'false':
            return false;
        case 'true':
            return true;
        default:
            return false;
    }
}

const getInfoUser = () =>{
    let infoUser = localStorage.getItem(actionTypes.ACCESSTOKEN_STORAGE);
    if(infoUser){
        var decodedHeader =  jwt_decode(infoUser);
                 
        return decodedHeader;
    }
    return null;
}

const getRoleUser = () =>{
    let infoUser = localStorage.getItem(actionTypes.ACCESSTOKEN_STORAGE);
    if(infoUser){
        var decodedHeader =  jwt_decode(infoUser);
        return decodedHeader.roleId;
    }
    return 0;
}

const initialState = {
    userId: deCryptUserId(),
    userInfo: getInfoUser(),
    isChangePassword: checkForceChangePassword(),    // for the first time of changing password
    message: null,
    level: getRoleUser()
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ACCOUNT_INI:
            return {...state};
        case actionTypes.ACCOUNT_SET:
            // console.log(action.user.roleId)
            // console.log(action.user)
            return {
                ...state,
                userInfo: action.user,
                level: action.user.roleId,
                message: null
            }
        case actionTypes.ACCOUNT_SET_CHANGE_PASSWORD:
            localStorage.setItem(actionTypes.CHANGE_PASSWORD_STORAGE, true);
            return {
                ...state,
                isChangePassword: true
            }
        case actionTypes.ACCOUNT_CHANGE:
            return {
                ...state,
                isChangePassword: checkForceChangePassword()
            }
        case actionTypes.ACCOUNT_MESSAGE:
            return {
                ...state,
                userId: null,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;