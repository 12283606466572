import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import LoadingView from '../../loadingview/LoadingView';
const Grid = lazy(() => import('../../grid/grid'));
//const GridNoPaging = lazy(() => import('../../components/grid/gridNoPaging'));


class ListSupplier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableId: 5,
            editMode: "inline",
            layoutTitle: "NHÀ PHÂN PHỐI",
            customParam: {}
        }
    }

    componentDidMount() {
    }

    celltemplate = (row, column) =>{
        const field = column.field;
        switch (field){
            case 'STT':
                return (column.rowIndex + 1)
            default:
                return null;
        }
    }

    redirectDetail = (e, row) => {
        e.preventDefault();
        this.props.history.push('/event/detailsupplier/'+ row.id)
    }

    render() {
        const loading = (
            <div className="p-col-12">
                <LoadingView />
            </div>
        )
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <Suspense fallback={loading}>
                        <Grid
                            tableId={this.state.tableId}
                            filterTitle="Tìm kiếm nâng cao"
                            editMode={this.state.editMode}
                            layoutTitle={this.state.layoutTitle}
                            onClickView={(e, objRow) => this.redirectDetail(e, objRow)}
                            celltemplate={(row, column)=>this.celltemplate(row, column)}
                        />
                    </Suspense>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        // LoginReducer: state.LoginReducer
    };
}

export default connect(mapStateToProps, {})(ListSupplier);