import * as types from '../constants/actionTypes';
import {
    GET_FILTER_BY_TABLEID
} from '../constants/serviceEndpoint';

import {
    headerToken,
} from '../constants/header';

import {
    deCryptClient,
} from '../../crypto/Crypto';

import {
    getDropdownData
} from './index';

import { confirmDialog } from 'primereact/confirmdialog';
import * as enums from '../../common/enum';
import { axiosWithHeaders } from '../constants/axiosWrapper';

const axios = require("axios");
const _ = require("lodash");

export function filterInit() {
    return {
        type: types.INIT_GRID
    }
}

export function filterUpdateList(listFilter) {
    return {
        type: types.UPDATE_LIST_FILTER,
        listFilter
    }
}

export function filterUpdateLoading(loading) {
    return {
        type: types.UPDATE_FILTER_LOADING,
        loading
    }
}

export function getFilterByTableId(tableId) {
    return async dispatch => {
        dispatch(filterUpdateLoading(true));
        
        axiosWithHeaders("get", GET_FILTER_BY_TABLEID, {
            id: tableId
        })
            .then(function (response) {
                const {
                    data
                } = response;
                if (data.status === enums.RESPONSE_OK) {
                    dispatch(filterUpdateList(data.result));
                    let userInfo = localStorage.getItem(types.CACHE_USER_INFO);
                    let eventId = localStorage.getItem(types.STORAGE_EVENTID)
                    if (userInfo && eventId) {
                        userInfo = deCryptClient(userInfo, types.SECRET_KEY_TOKEN);
                        let listFilter = [...data.result];
                        listFilter = _.filter(listFilter, function (o) { return (o.type === "dropdown") });
                        // console.log(listFilter);
                        let arrDropdown = []
                        listFilter.forEach(item => {
                            let itemParameter = []

                            //2019/11/19 thêm userId để phân quyền
                            let objUserId = {
                                Field: 'userId',
                                TypeField: 8,
                                Value: userInfo.id
                            }

                            itemParameter.push(objUserId);

                            //2019/12/07 thêm eventId để loadđata
                            let objEventId = {
                                Field: 'eventId',
                                TypeField: 8,
                                Value: eventId
                            }

                            itemParameter.push(objEventId);

                            if (!item.filterParams) {
                                let obj = {
                                    Name: "data" + item.field,
                                    StoreProcedure: "Dropdown_GetData",
                                }
                                let activity = item.sqlSelect;
                                let objParam = {}
                                if (activity !== undefined && activity !== null && activity.length > 0) {
                                    objParam.Field = activity.split("=")[0];
                                    objParam.TypeField = 12;
                                    objParam.Value = activity.split("=")[1];
                                    itemParameter.push(objParam);
                                }
                                obj.Parameters = itemParameter;
                                arrDropdown.push(obj);
                            }

                            if (item.filterParams) {
                                // console.log(1);
                                let params = item.filterParams;
                                let obj = {
                                    Name: "data" + item.field,
                                    StoreProcedure: "Dropdown_GetData",
                                }
                                let activity = item.sqlSelect;
                                let objParam = {}
                                if (activity !== undefined && activity !== null && activity.length > 0) {
                                    objParam.Field = activity.split("=")[0];
                                    objParam.TypeField = 12;
                                    objParam.Value = activity.split("=")[1];
                                    itemParameter.push(objParam);
                                }

                                params.split(",").forEach((fields) => {
                                    if (userInfo.hasOwnProperty(fields) && userInfo[fields] !== null) {
                                        let objFilter = {}
                                        objFilter.Field = fields;
                                        objFilter.TypeField = 12;
                                        objFilter.Value = userInfo[fields];
                                        itemParameter.push(objFilter);
                                    }
                                })
                                obj.Parameters = itemParameter;
                            }
                            // userInfo["data" + item.field] = []
                        })
                        dispatch(getDropdownData(arrDropdown))
                    }
                } else {
                    confirmDialog({
                        message: `Lỗi ! Mã lỗi ${data.status}! ${data.message} dòng 55`,
                        header: 'Bộ lọc tìm kiếm',
                        icon: 'pi pi-exclamation-triangle',
                        rejectClassName: "d-none",
                        accept: () => {
                            return;
                        },
                    });
                    return;
                }
            })
            .catch(function (error) {
                confirmDialog({
                    message: `Lỗi! ${error.toString()} dòng 68`,
                    header: 'Bộ lọc tìm kiếm',
                    icon: 'pi pi-exclamation-triangle',
                    rejectClassName: "d-none",
                    accept: () => {
                        return;
                    },
                });
                return;
            }).finally(() => {
                dispatch(filterUpdateLoading(false));
            })
    }
}
