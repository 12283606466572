import React, { Component } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { withRouter } from "react-router-dom";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { enCryptServer } from '../../../crypto/Crypto'
import { Messages } from 'primereact/messages';
import {
    USER_GET_INFO,
    GET_LIST_AREA,
    GET_DATADROPDOWN,
    USER_RESET_PASSWORD,
    UPDATE_USER,
    USER_GET
} from '../../../store/constants/serviceEndpoint';
import { headerToken } from '../../../store/constants/header';
import { STORAGE_EVENTNAME } from '../../../store/constants/actionTypes';
import * as axiosWrapper from '../../../store/constants/axiosWrapper';

class DetailUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            listArea: [],
            dataRole: [{
                value: 1,
                label: 'TSM'
            }, {
                value: 2,
                label: 'ASM'
            }, {
                value: 3,
                label: 'RTMM'
            }, {
                value: 5,
                label: 'SR'
            }, {
                value: 7,
                label: 'Marketing'
            }, {
                value: 8,
                label: 'RMEM'
            }
                , {
                value: 9,
                label: 'Supporter'
            }
                , {
                value: 10,
                label: 'CallCenter'
            }

            ],
            roleId: null,
            areaId: null,
            userName: '',
            fullName: '',
            dataRTMM: [],
            regionalTradeMarketingManagerId: null,
            dataASM: [],
            areaSalesManagerId: null,
            dataTSM: [],
            dataStoreType: []
        }
    }

    componentDidMount() {
        const userId = this.props.match.params.id;
        if (userId !== undefined && userId !== null) {
            this.GetDetailUser(userId);
        }
        this.getListArea();
        this.getDataCombobox("dataStoreType", "StoreType_ListStoreType");
    }

    getListArea() {
        const _this = this;
        // axios({
        //     method: 'get',
        //     url: GET_LIST_AREA,
        //     headers: headerToken(),
        // })
        axiosWrapper.axiosWithHeaders('get', GET_LIST_AREA, null)
            .then(function (response) {
                if (response.status === 200) {
                    const data = response.data;
                    const result = data.result;
                    let arr = [];
                    result.map((item, i) => {
                        let obj = {}
                        obj["value"] = item.id;
                        obj["label"] = item.name;
                        arr.push(obj);
                    })
                    _this.setState({
                        listArea: arr,
                    })
                }
            }).catch(function (error) {
                console.log(error);
            })
    }

    backToList = (e) => {
        this.props.history.push("/event/listuser");
    }

    GetDetailUser = (userId) => {
        const _this = this;
        // var deCode = enCryptServer([userId]);
        // axios({
        //     method: 'post',
        //     url: USER_GET_INFO,
        //     headers: headerToken(),
        //     data: deCode
        // })
        axiosWrapper.axiosWithHeaders('post', USER_GET, { Id: userId })
            .then(function (response) {
                if (response.status === 200) {
                    const data = response.data;
                    // console.log(data)
                    const user = data.data;
                    let state = _this.state;
                    if (user !== undefined && user !== null && Object.keys(user).length > 0) {
                        state["fullName"] = user.fullName;
                        state["areaId"] = user.areaId;
                        state["roleId"] = user.level;
                        state["userName"] = user.userName;
                        state["regionalTradeMarketingManagerId"] = user.regionalTradeMarketingManagerId;
                        state["areaSalesManagerId"] = user.areaSalesManagerId;
                        state["territorySalesManagerId"] = user.territorySalesManagerId;
                        state["storeTypeId"] = user.storeTypeId;
                        state["Id"] = user.id;
                        _this.setState(state);
                        setTimeout(() => {
                            _this.getDataCombobox("dataRTMM", "[MemBer_GetList_RTMM]");
                            _this.getDataCombobox("dataASM", "[MemBer_GetList_ASM]");
                            _this.getDataCombobox("dataTSM", "[MemBer_GetList_TSM]")
                        }, 250)
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    getDataCombobox = (name, storeProcedure) => {
        let obj = {}
        obj["Name"] = name;
        obj["StoreProcedure"] = storeProcedure;
        switch (name) {
            case 'dataRole':
                obj["Parameters"] = [];
                break;
            case 'dataRTMM':
                const areaId = this.state.areaId;
                if (areaId !== null) {
                    let objParam = {
                        Field: "AreaId",
                        TypeField: 22,
                        Value: areaId
                    }
                    let arr = [];
                    arr.push(objParam);
                    obj["Parameters"] = arr;
                }

                break;
            case 'dataASM':
                const regionalTradeMarketingManagerId = this.state.regionalTradeMarketingManagerId;
                if (regionalTradeMarketingManagerId !== null) {
                    let objParam = {
                        Field: "RTMMId",
                        TypeField: 22,
                        Value: regionalTradeMarketingManagerId
                    }
                    let arr = [];
                    arr.push(objParam);
                    obj["Parameters"] = arr;
                }
                break;
            case 'dataTSM':
                const areaSalesManagerId = this.state.areaSalesManagerId;
                if (areaSalesManagerId !== null) {
                    let objParam = {
                        Field: "ASMId",
                        TypeField: 22,
                        Value: areaSalesManagerId
                    }
                    let arr = [];
                    arr.push(objParam);
                    obj["Parameters"] = arr;
                }
                break;
            case 'dataStoreType':
                obj["Parameters"] = [];
                break;
            default:
                break;
        }

        const objResponese = {
            Data: obj,
            Token: "31FCC2DD-C370-447C-9BF7-DF7C1E42021E",
        }

        const _this = this;
        // axios({
        //     method: 'post',
        //     url: GET_DATADROPDOWN,
        //     headers: headerToken(),
        //     data: objResponese
        // })
        axiosWrapper.axiosWithHeaders('post', GET_DATADROPDOWN, objResponese)
            .then(function (response) {
                if (response.status === 200) {
                    const data = response.data;
                    const result = data.result;
                    if (result !== undefined && result !== null && result.length > 0) {
                        let arr = []
                        result.map(item => {
                            let obj = {}
                            obj["value"] = item.id;
                            obj["label"] = item.fullName;
                            arr.push(obj)
                        })

                        let state = _this.state;
                        state[name] = arr;
                        _this.setState(state);
                    }

                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    resetPassword = (e) => {
        // const _this = this;
        const userId = this.state.Id;
        const username = this.state.userName;
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn đặt lại mật khẩu cho nhân viên này không ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý!',
            cancelButtonText: 'Trở về'
        }).then((result) => {
            if (result.value) {
                if (userId !== undefined && userId !== null && userId !== "" && username !== undefined && username !== null && username !== "") {
                    // axios({
                    //     method: 'post',
                    //     url: USER_RESET_PASSWORD,
                    //     headers: headerToken(),
                    //     data: {
                    //         Id: userId,
                    //         UserName: username
                    //     }
                    // })
                    axiosWrapper.axiosWithHeaders('post', USER_RESET_PASSWORD, {
                        Id: userId,
                        UserName: username
                    })
                        .then(function (response) {
                            if (response.status === 200) {
                                const data = response.data;
                                if (data.message === "SUCCESS") {
                                    Swal.fire("Thông báo", "Cập nhật mật khẩu thành công !", "success")
                                } else {
                                    Swal.fire("Lỗi", "Cập nhật mật khẩu thất bại !", "error")
                                }
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                }
            }
        })
    }

    onChangeValue = (e, field, type) => {
        let state = this.state;
        switch (type) {
            case 'dropdown':
                state[field] = e.value;
                break;
            default:
                state[field] = e.target.value;
                break;
        }
        this.setState(state)

        switch (field) {
            case 'areaId':
                setTimeout(() => {
                    this.getDataCombobox("dataRTMM", "[MemBer_GetList_RTMM]");
                }, 250)
                break;
            case 'regionalTradeMarketingManagerId':
                setTimeout(() => {
                    this.getDataCombobox("dataASM", "[MemBer_GetList_ASM]");
                }, 250)
                break;
            case 'areaSalesManagerId':
                this.getDataCombobox("dataTSM", "[MemBer_GetList_TSM]");
                break;
            default:
                break;
        }
    }

    checkData = (obj) => {
        for (var field in obj) {
            if (obj[field] === undefined || obj[field] === null || obj[field] === "" || obj[field] === 0) {
                switch (field) {
                    case 'FullName':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập tên nhân viên !' });
                        break;
                    case 'UserName':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập tài khoản !' });
                        break;
                    // case 'StoreTypeId':
                    //     this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn loại cửa hàng !' });
                    //     break;
                    case 'AreaId':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn vùng !' });
                        break;
                    case 'Level':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn quyền truy cập !' });
                        break;
                    case 'RegionalTradeMarketingManagerId':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn RTMM !' });
                        break;
                    case 'AreaSalesManagerId':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn ASM !' });
                        break;
                    case 'TerritorySalesManagerId':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn TSM !' });
                        break;
                    default:
                        break;
                }
                return false;
            }
        }
        return true;
    }

    updateUser = (e) => {
        const state = this.state;
        let objUpdate = {}
        // objUpdate.RegionalTradeMarketingManagerId =0
        // objUpdate.AreaSalesManagerId =0
        // objUpdate.TerritorySalesManagerId =0
        objUpdate.FullName = state.fullName;
        objUpdate.UserName = state.userName;
        objUpdate.AreaId = state.areaId;
        objUpdate.Level = state.roleId;
        if (state.roleId === 2 || state.roleId === 1 || state.roleId === 5 || state.roleId === 9) {
            objUpdate.RegionalTradeMarketingManagerId = state.regionalTradeMarketingManagerId;
        }
        if (state.roleId === 1 || state.roleId === 5 || state.roleId === 9) {
            objUpdate.AreaSalesManagerId = state.areaSalesManagerId;
        }
        if (state.roleId === 5 || state.roleId === 9) {
            objUpdate.TerritorySalesManagerId = state.territorySalesManagerId;
         
        }
        objUpdate.StoreTypeId = state.storeTypeId;
        const check = this.checkData(objUpdate);
        if (check) {
            objUpdate.Id = state.Id;
            const _this = this;
            // axios({
            //     method: 'post',
            //     url: UPDATE_USER,
            //     headers: headerToken(),
            //     data: objUpdate
            // })
            axiosWrapper.axiosWithHeaders('post', UPDATE_USER, objUpdate)
                .then(function (response) {
                    if (response.status === 200) {
                        console.log(response)
                        Swal.fire("Thông báo", "Cập nhật nhân viên thành công!", "success");
                         _this.GetDetailUser(_this.state.Id);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }

    render() {
        return (
            <>
                <div style={{ fontSize: 24, marginTop: 20, color: `#de1f27`, fontWeight: 900 }}>{localStorage.getItem(STORAGE_EVENTNAME)}</div>
                <div className="row" style={{ marginTop: 10 }}>
                    <div className="col-sm-1 col-md-2"></div>
                    <div className="col-12 col-sm-10 col-md-8">
                        <div className='card d-flex justify-content-center' style={{ width: `100%`, maxWidth: 500, margin: `0 auto` }}>
                            <div class="card-body">
                                <div className="row">
                                    <div className="col-1 col-md-2 d-flex justify-content-center align-items-center" style={{ padding: 5 }}>
                                        <Button
                                            icon="pi pi-chevron-left"
                                            className="p-button-raised p-button-secondary"
                                            onClick={(e) => this.backToList(e)}
                                            tooltip="Trở về danh sách"
                                        />
                                    </div>
                                    <div className="col-10 col-md-8 d-flex justify-content-center align-items-center" style={{ textAlign: "center", paddingTop: 14 }}>
                                        <h4 className="card-title">CẬP NHẬT NHÂN VIÊN</h4>
                                    </div>
                                    <div className="col-1 col-md-2 d-flex justify-content-center align-items-center"></div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-12">
                                        <Messages ref={(el) => this.messages = el}></Messages>
                                    </div>

                                    {/* <div className="form-panel">
                                    <div className="panel-content">
                                        <Messages ref={(el) => this.messages = el}></Messages>
                                        <div className="row">
                                            <div className="col-12 col-md-3 d-flex justify-content-end align-items-center">
                                                <label>Tên nhân viên</label>
                                            </div>
                                            <div className="col-12 col-md-5 d-flex justify-content-center align-items-center">
                                                <InputText placeholder="Nhập tên nhân viên..." onChange={(e, field, type) => this.onChangeValue(e, 'fullName', 'string')} value={this.state.fullName} style={{ width: "100%" }} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>Tài khoản</label>
                                            </div>
                                            <div className="p-col-5">
                                                <InputText placeholder="Nhập tài khoản..." onChange={(e, field, type) => this.onChangeValue(e, 'userName', 'string')} value={this.state.userName} style={{ width: "100%" }} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>Loại cửa hàng</label>
                                            </div>
                                            <div className="p-col">
                                                <Dropdown
                                                    value={this.state.storeTypeId}
                                                    options={this.state.dataStoreType}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'storeTypeId', 'dropdown')}
                                                    className="p-cust-width"
                                                    placeholder={"Chọn loại cửa hàng"}
                                                    filter={true}
                                                    filterPlaceholder="Tìm kiếm loại cửa hàng"
                                                    filterBy="label,value"
                                                    filterMatchMode="contains"
                                                    tooltip={"Chọn loại cửa hàng"}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>Vùng</label>
                                            </div>
                                            <div className="p-col">
                                                <Dropdown
                                                    value={this.state.areaId}
                                                    options={this.state.listArea}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'areaId', 'dropdown')}
                                                    className="p-cust-width"
                                                    placeholder={"Chọn vùng"}
                                                    filter={true}
                                                    filterPlaceholder="Tìm kiếm vùng"
                                                    filterBy="label,value"
                                                    filterMatchMode="contains"
                                                    tooltip={"Chọn vùng"}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>Quyền truy cập</label>
                                            </div>
                                            <div className="p-col">
                                                <Dropdown
                                                    value={this.state.roleId}
                                                    options={this.state.dataRole}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'roleId', 'dropdown')}
                                                    className="p-cust-width"
                                                    placeholder={"Chọn quyền truy cập"}
                                                    filter={true}
                                                    filterPlaceholder="Tìm kiếm quyền truy cập"
                                                    filterBy="label,value"
                                                    filterMatchMode="contains"
                                                    tooltip={"Chọn quyền truy cập"}
                                                />
                                            </div>
                                        </div>
                                        {
                                            (this.state.roleId === 2 || this.state.roleId === 1 || this.state.roleId === 5 || this.state.roleId === 9) && (
                                                <div className="row">
                                                    <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                        <label>RTMM</label>
                                                    </div>
                                                    <div className="p-col">
                                                        <Dropdown
                                                            value={this.state.regionalTradeMarketingManagerId}
                                                            options={this.state.dataRTMM}
                                                            onChange={(e, field, type) => this.onChangeValue(e, 'regionalTradeMarketingManagerId', 'dropdown')}
                                                            className="p-cust-width"
                                                            placeholder={"Chọn RTMM"}
                                                            filter={true}
                                                            filterPlaceholder="Tìm kiếm RTMM"
                                                            filterBy="label,value"
                                                            filterMatchMode="contains"
                                                            tooltip={"Chọn RTMM"}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            (this.state.roleId === 1 || this.state.roleId === 5 || this.state.roleId === 9) && (
                                                <div className="row">
                                                    <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                        <label>ASM</label>
                                                    </div>
                                                    <div className="p-col">
                                                        <Dropdown
                                                            value={this.state.areaSalesManagerId}
                                                            options={this.state.dataASM}
                                                            onChange={(e, field, type) => this.onChangeValue(e, 'areaSalesManagerId', 'dropdown')}
                                                            className="p-cust-width"
                                                            placeholder={"Chọn ASM"}
                                                            filter={true}
                                                            filterPlaceholder="Tìm kiếm ASM"
                                                            filterBy="label,value"
                                                            filterMatchMode="contains"
                                                            tooltip={"Chọn ASM"}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            (this.state.roleId === 5 || this.state.roleId === 9) && (
                                                <div className="row">
                                                    <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                        <label>TSM</label>
                                                    </div>
                                                    <div className="p-col">
                                                        <Dropdown
                                                            value={this.state.territorySalesManagerId}
                                                            options={this.state.dataTSM}
                                                            onChange={(e, field, type) => this.onChangeValue(e, 'territorySalesManagerId', 'dropdown')}
                                                            className="p-cust-width"
                                                            placeholder={"Chọn TSM"}
                                                            filter={true}
                                                            filterPlaceholder="Tìm kiếm TSM"
                                                            filterBy="label,value"
                                                            filterMatchMode="contains"
                                                            tooltip={"Chọn TSM"}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div> */}
                                </div>

                                <div className='row' style={{ marginTop: 20 }}>
                                    <div className='col-sm-3 d-flex justify-content-start align-items-center'>
                                        <label htmlFor='lblFullName'>Tên nhân viên</label>
                                    </div>
                                    <div className='col-sm-6 d-flex justify-content-start align-items-center'>
                                        <InputText id="lblFullName" placeholder="Nhập tên nhân viên..." onChange={(e, field, type) => this.onChangeValue(e, 'fullName', 'string')} value={this.state.fullName} style={{ width: "100%" }} />
                                    </div>
                                </div>
                                <div className='row' style={{ marginTop: 10 }}>
                                    <div className='col-sm-3 d-flex justify-content-start align-items-center'>
                                        <label htmlFor='lblUserName'>Tài khoản</label>
                                    </div>
                                    <div className='col-sm-6 d-flex justify-content-start align-items-center'>
                                        <InputText id="lblUserName" placeholder="Nhập tài khoản..." onChange={(e, field, type) => this.onChangeValue(e, 'userName', 'string')} value={this.state.userName} style={{ width: "100%" }} />
                                    </div>
                                </div>
                                {/* <div className='row' style={{ marginTop: 10 }}>
                                    <div className='col-sm-3 d-flex justify-content-start align-items-center'>
                                        <label htmlFor='lblStoreType'>Loại cửa hàng</label>
                                    </div>
                                    <div className='col-sm-6 d-flex justify-content-start align-items-center'>
                                        <Dropdown
                                            value={this.state.storeTypeId}
                                            options={this.state.dataStoreType}
                                            onChange={(e, field, type) => this.onChangeValue(e, 'storeTypeId', 'dropdown')}
                                            className="p-cust-width"
                                            style={{ width: "100%" }}
                                            placeholder={"Chọn loại cửa hàng"}
                                            filter={true}
                                            filterPlaceholder="Tìm kiếm loại cửa hàng"
                                            filterBy="label,value"
                                            filterMatchMode="contains"
                                            tooltip={"Chọn loại cửa hàng"}
                                            disabled
                                        />
                                    </div>
                                </div> */}
                                <div className='row' style={{ marginTop: 10 }}>
                                    <div className='col-sm-3 d-flex justify-content-start align-items-center'>
                                        <label>Vùng</label>
                                    </div>
                                    <div className='col-sm-6 d-flex justify-content-start align-items-center'>
                                        <Dropdown
                                            value={this.state.areaId}
                                            options={this.state.listArea}
                                            onChange={(e, field, type) => this.onChangeValue(e, 'areaId', 'dropdown')}
                                            className="p-cust-width"
                                            placeholder={"Chọn vùng"}
                                            filter={true}
                                            style={{ width: "100%" }}
                                            filterPlaceholder="Tìm kiếm vùng"
                                            filterBy="label,value"
                                            filterMatchMode="contains"
                                            tooltip={"Chọn vùng"}
                                        />
                                    </div>
                                </div>
                                <div className='row' style={{ marginTop: 10 }}>
                                    <div className='col-sm-3 d-flex justify-content-start align-items-center'>
                                        <label>Quyền truy cập</label>
                                    </div>
                                    <div className='col-sm-6 d-flex justify-content-start align-items-center'>
                                        <Dropdown
                                            value={this.state.roleId}
                                            options={this.state.dataRole}
                                            onChange={(e, field, type) => this.onChangeValue(e, 'roleId', 'dropdown')}
                                            className="p-cust-width"
                                            placeholder={"Chọn quyền truy cập"}
                                            filter={true}
                                            filterPlaceholder="Tìm kiếm quyền truy cập"
                                            filterBy="label,value"
                                            style={{ width: "100%" }}
                                            filterMatchMode="contains"
                                            tooltip={"Chọn quyền truy cập"}
                                        />
                                    </div>
                                </div>
                                {
                                    (this.state.roleId === 2 || this.state.roleId === 1 || this.state.roleId === 5 || this.state.roleId === 9) && (
                                        <div className='row' style={{ marginTop: 10 }}>
                                            <div className='col-sm-3 d-flex justify-content-start align-items-center'>
                                                <label>RTMM</label>
                                            </div>
                                            <div className='col-sm-6 d-flex justify-content-start align-items-center'>
                                                <Dropdown
                                                    value={this.state.regionalTradeMarketingManagerId}
                                                    options={this.state.dataRTMM}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'regionalTradeMarketingManagerId', 'dropdown')}
                                                    className="p-cust-width"
                                                    placeholder={"Chọn RTMM"}
                                                    filter={true}
                                                    filterPlaceholder="Tìm kiếm RTMM"
                                                    filterBy="label,value"
                                                    filterMatchMode="contains"
                                                    tooltip={"Chọn RTMM"}
                                                    style={{ width: "100%" }}

                                                />
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    (this.state.roleId === 1 || this.state.roleId === 5 || this.state.roleId === 9) && (
                                        <div className='row' style={{ marginTop: 10 }}>
                                            <div className='col-sm-3 d-flex justify-content-start align-items-center'>
                                                <label>ASM</label>
                                            </div>
                                            <div className='col-sm-6 d-flex justify-content-start align-items-center'>
                                                <Dropdown
                                                    value={this.state.areaSalesManagerId}
                                                    options={this.state.dataASM}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'areaSalesManagerId', 'dropdown')}
                                                    className="p-cust-width"
                                                    placeholder={"Chọn ASM"}
                                                    filter={true}
                                                    filterPlaceholder="Tìm kiếm ASM"
                                                    filterBy="label,value"
                                                    filterMatchMode="contains"
                                                    style={{ width: "100%" }}
                                                    tooltip={"Chọn ASM"}
                                                />
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    (this.state.roleId === 5 || this.state.roleId === 9) && (
                                        <div className='row' style={{ marginTop: 10 }}>
                                            <div className='col-sm-3 d-flex justify-content-start align-items-center'>
                                                <label>TSM</label>
                                            </div>
                                            <div className='col-sm-6 d-flex justify-content-start align-items-center'>
                                                <Dropdown
                                                    value={this.state.territorySalesManagerId}
                                                    options={this.state.dataTSM}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'territorySalesManagerId', 'dropdown')}
                                                    className="p-cust-width"
                                                    placeholder={"Chọn TSM"}
                                                    filter={true}
                                                    filterPlaceholder="Tìm kiếm TSM"
                                                    filterBy="label,value"
                                                    filterMatchMode="contains"
                                                    tooltip={"Chọn TSM"}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                    )
                                }

                                <div className="row" style={{ paddingTop: 25 }}>
                                    <div className="p-col-12" style={{ textAlign: "center" }}>
                                        <Button
                                            label="CẬP NHẬT"
                                            icon="pi pi-pencil"
                                            style={{ marginRight: 5 }}
                                            onClick={(e) => this.updateUser(e)}
                                        />
                                        <Button
                                            label="ĐẶT LẠI MẬT KHẨU"
                                            icon="pi pi-unlock"
                                            className="p-button-raised p-button-warning"
                                            onClick={(e) => this.resetPassword(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4"></div>
                </div>
            </>
        )
    }
}

export default withRouter(DetailUser);