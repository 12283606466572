import React, { Component } from "react";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Messages } from "primereact/messages";
import { Button } from "primereact/button";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { UPDATE_AREA_EVENT_TARGET } from "../../../store/constants/serviceEndpoint";
import { deCryptUserId, removeToken } from "../../../crypto/Crypto";
import * as actions from "../../../store/actions/index";
// import * as actionTypes from '../../../store/constants/actionTypes';
import * as enums from "../../../common/enum";
import * as router from "../../../common/route";
import { headerToken } from "../../../store/constants/header";
import { withRouter } from "react-router-dom";
import { axiosWithHeaders } from "../../../store/constants/axiosWrapper";
const axios = require("axios");
// const _ = require("lodash");
class TargetWithArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataAreaEvent: [],
      visibled: false,
      target: 0,
      createStore: true,
      eventId: null,
      areaId: null,
      isLayer: true,
    };
    this.UpdateData = this.UpdateData.bind(this);
  }

  componentWillUnmount() {
    this.setState = {
      dataAreaEvent: [],
      visible: false,
      target: 0,
      eventId: null,
      areaId: null,
    };
  }

  updateItem = (e, row) => {
    this.setState({
      visible: true,
      target: row.target,
      eventId: row.eventId,
      areaId: row.areaId,
      id: row.id,
      createStore: row.createStore,
      isLayer: row.isLayer
    });
  };

  reloadData = (idx) => {
    return this.props.reloadData(idx);
  };

  UpdateData = (e) => {
    const _this = this;
    let obj = {};
    obj.Target = this.state.target;
    const check = this.checkData(obj);
    if (check) {
      obj.AreaId = this.state.areaId;
      obj.EventId = this.state.eventId;
      obj.CreatedBy = deCryptUserId();
      obj.Id = this.state.id;
      obj.CreateStore = this.state.createStore;
      obj.isLayer = this.state.isLayer;
      // axios({
      //   method: "post",
      //   url: UPDATE_AREA_EVENT_TARGET,
      //   headers: headerToken(),
      //   data: obj,
      // })
      axiosWithHeaders("post", UPDATE_AREA_EVENT_TARGET, obj)
        .then(function (response) {
          if (response.status === enums.RESPONSE_TOKEN_INVALID) {
            removeToken();
            actions.auth_ini();
            _this.props.history.push(router.LOGIN);
          }
          if (response.status === enums.RESPONSE_OK) {
            const data = response.data;
            if (data.message === "SUCCESS") {
              Swal.fire(
                "Thông báo",
                "Cập nhật vùng hoạt động thành công !",
                "success"
              );
            } else {
              Swal.fire("Lỗi", "Cập nhật vùng hoạt động thất bại !", "error");
            }
            _this.setState({
              visible: false,
            });
            _this.reloadData(2);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  checkData = (obj) => {
    for (var field in obj) {
      if (
        obj[field] === undefined ||
        obj[field] === null ||
        obj[field] === "" ||
        obj[field] === 0
      ) {
        switch (field) {
          case "Target":
            this.messages.show({
              severity: "error",
              summary: "Lỗi",
              detail: "Vui lòng nhập chỉ tiêu !",
            });
            break;
          default:
            break;
        }
        return false;
      }
    }
    return true;
  };

  celltemplate = (row, column) => {
    // console.log(row)
    const field = column.field;
    switch (field) {
      case "createStore":
        return (
          <div
            style={{
              width: "100%",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            <InputSwitch checked={row.createStore} disabled />
          </div>
        );
        case "isLayer":
            return (
              <div
                style={{
                  width: "100%",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                <InputSwitch checked={row.isLayer} disabled />
              </div>
            );
      default:
        return (
          <div
            style={{
              width: "100%",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            <Button
              icon="pi pi-pencil"
              className="p-button-success p-button-raised"
              style={{ marginRight: 7, width: 35 }}
              onClick={(e, uniqueId) => this.updateItem(e, row)}
            />
          </div>
        );
    }
  };

  render() {
    const footer = (
      <div>
        <Button
          label="Cập nhật"
          icon="pi pi-check"
          onClick={(e) => this.UpdateData(e)}
          className="p-button-raised p-button-info"
        />
        <Button
          label="Hủy"
          className="p-button-raised p-button-secondary"
          icon="pi pi-times"
          onClick={() =>
            this.setState({
              visible: false,
              target: 0,
              eventId: null,
              areaId: null,
            })
          }
        />
      </div>
    );
    return (
      <div className="p-grid">
        <Dialog
          header="Cài đặt chỉ tiêu theo vùng"
          footer={footer}
          visible={this.state.visible}
          style={{ width: "600px" }}
          modal={true}
          onHide={(e) =>
            this.setState({
              visible: false,
              target: 0,
              eventId: null,
              areaId: null,
            })
          }
        >
          <div className="p-grid">
            <Messages
              ref={(el) => (this.messages = el)}
              style={{ width: "100%" }}
            ></Messages>
          </div>
          <div className="p-grid" style={{ marginTop: 5 }}>
            <div
              className="p-col-4 d-flex align-items-center justify-content-end"
              style={{ textAlign: "right" }}
            >
              <label>Chỉ tiêu</label>
            </div>
            <div className="p-col-4">
              <InputText
                placeholder="0"
                style={{ width: "100%", color: "red", textAlign: "right" }}
                onChange={(e) => this.setState({ target: e.target.value })}
                value={this.state.target}
              />
            </div>
          </div>
          <div className="p-grid" style={{ marginTop: 5 }}>
            <div className="p-col-4 d-flex align-items-center justify-content-end">
              <label>Tạo STORE SFA</label>
            </div>
            <div className="p-col-8 d-flex align-items-center">
              <InputSwitch
                checked={this.state.createStore}
                onChange={(e) => this.setState({ createStore: e.value })}
              />
            </div>
          </div>
          <div className="p-grid" style={{ marginTop: 5 }}>
            <div className="p-col-4 d-flex align-items-center justify-content-end">
              <label>Cho phép lưu nháp</label>
            </div>
            <div className="p-col-8 d-flex align-items-center">
              <InputSwitch
                checked={this.state.isLayer}
                onChange={(e) => this.setState({ isLayer: e.value })}
              />
            </div>
          </div>
        </Dialog>
        <div className="p-col-12">
          <DataTable
            value={this.props.dataAreaEvent}
            emptyMessage="Hoạt động này chưa tạo hình nào...."
            style={{ marginTop: "30px" }}
          >
            <Column field="areaName" header="Tên vùng" />
            <Column field="target" header="Chỉ tiêu" />            
            <Column
              field="createStore"
              header="Tạo cửa hàng"
              body={(row, column) => this.celltemplate(row, column)}
              style={{ width: 120 }}
              className={"p-col-fixed"}
            />
            <Column
              field="isLayer"
              header="Lưu nháp"
              body={(row, column) => this.celltemplate(row, column)}
              style={{ width: 120 }}
              className={"p-col-fixed"}
            />
            <Column
              body={(row, column) => this.celltemplate(row, column)}
              style={{ width: 120 }}
              className={"p-col-fixed"}
            />
          </DataTable>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    area: state.area,
  };
}

export default withRouter(connect(mapStateToProps, {})(TargetWithArea));
