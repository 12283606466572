import React, {Fragment} from 'react';
import { Container } from 'react-bootstrap';
import NavMenu from './nav';
import './style.css';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import {useHistory} from "react-router-dom"

const Template = ({children}) =>{
    const history = useHistory();
    const auth = useSelector(state => state.auth.accessToken);
    
    useEffect(() => {
        if(!auth){
            history.push("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);


    return (
        <Fragment>
            <NavMenu />
            <Container fluid>
                {children}
            </Container>
        </Fragment>
    )
}

export default Template;