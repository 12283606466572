import React, {Component} from 'react';
import {Button} from 'primereact/button';
import {Fieldset} from 'primereact/fieldset';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {Dropdown} from 'primereact/dropdown';
import LoadingView from '../../loadingview/LoadingView';
import * as api  from '../../../store/constants/serviceEndpoint';
import {deCryptUserId} from '../../../crypto/Crypto'
import { headerToken } from '../../../store/constants/header'; 
import * as axiosWrapper from '../../../store/constants/axiosWrapper';

class Download extends Component{

    constructor() {
        super();
        this.state = {
            apiImport: '',
            labelUpload: 'Chưa chọn file...',
            dataImport:[],
            file: null,
            listEvent:[],
            listArea:[],
            areaId: null,
            eventId: null,
            visibleTable: false,
            totalRow: 0,
            isLoading: true, 
            disabledImport: false
        }

    }

    componentDidMount(){
        this.GetListEvent();
        this.setState({
            isLoading: false
        })
    }
    GetListEvent = () =>{
        const _this = this;
        axiosWrapper.axiosWithHeaders('POST',api.LIST_EVENT,null)
        .then(function (response) {
            if (response.status === 200) {
                const data = response.data;
                
                let arr = [];
                (data.result).map(item=>{
                    arr.push({
                        "value": item.id,
                        "label": item.name
                    })
                })
                
                _this.setState({
                    listEvent: arr
                })
            }
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    reportStore = (e) => {
        const _this = this;
        const eventId = this.state.eventId;
        if(eventId == 0 || eventId == undefined || eventId == null){
            Swal.fire('Thông báo', 'Vui lòng chọn hoạt động', 'error');
            return;
        }
        this.setState({
            isLoading: true
        })
      
      
        let  paramReport =[];
        paramReport.push({
            Field: "EventId",
            TypeField: 8,
            Value: eventId
        })

        const objRequest = {
            Name: "BAO CAO",
            StoreProcedure: "Report_Store_Event",
            Parameters: paramReport
        }
        axiosWrapper.axiosWithHeadersDownload('post',api.REPORT_DOWNLOAD_LIST_STORE,objRequest)
        // axios({
        //     method: 'post',
        //     url: api.REPORT_DOWNLOAD_LIST_STORE,
        //     headers: headerToken(),
        //     data: objRequest,
        //     responseType: 'blob'
        // })
        .then(function (response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Report.xlsx`);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            Swal.fire('Thông báo', 'Tải báo cáo thành công!', 'success');
            _this.setState({
                isLoading: false
            })

        }).catch(err => console.log(err));
    }

    reportUser = (e) => {
        const _this = this;
        this.setState({
            isLoading: true
        })

        // axios({
        //     method: 'post',
        //     url: api.REPORT_DOWNLOAD_LIST_USER,
        //     headers: headerToken(),
        //     responseType: 'blob'
        // })
        axiosWrapper.axiosWithHeadersDownload('post',api.REPORT_DOWNLOAD_LIST_USER,null)
        .then(function (response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Report.xlsx`);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            Swal.fire('Thông báo', 'Tải báo cáo thành công!', 'success');
            _this.setState({
                isLoading: false
            })

        }).catch(err => console.log(err));
    }
    render(){
        const datacontent = (this.state.isLoading) ?
        (
            <div className="p-col-12">
                <LoadingView />
            </div>
        ):(
            <div className= "p-col-12">
                <div className="p-grid">
                                    <div className="p-col-fixed" style={{width:200, textAlign:"right", paddingTop: 13}}>
                                        <label>Hoạt động</label>
                                    </div>
                                    <div className="p-col">
                                        <Dropdown
                                            value={this.state.eventId}
                                            options={this.state.listEvent}
                                            onChange={(e) => this.setState({eventId: e.value})}
                                            className="p-button-raised p-button-info"
                                            placeholder="Chọn hoạt động"
                                            filter={true}
                                            filterPlaceholder={"Tìm kiếm hoạt động"}
                                            filterBy="label,value"
                                            filterMatchMode="contains"
                                            tooltip={"Chọn hoạt động !"}
                                        />
                                    </div>
                </div>
                <div className="p-col-6" style={{textAlign:"center"}}>
                        <Button 
                        label="Tải Danh sách nhân viên trên hệ thống" 
                        icon="pi pi-download" 
                        className="p-button-secondary p-button-raised"
                        onClick={(e, index)=>this.reportUser(e)}
                        style={{marginRight: 10}}
                    />

                    <Button 
                        label="Tải danh sách cửa hàng" 
                        icon="pi pi-download" 
                        className="p-button-secondary p-button-raised"
                        onClick={(e, index)=>this.reportStore(e)}
                        style={{marginRight: 10}}
                    />

                </div>
                <div className="p-col-3">

                </div>
            </div>
        )

        return (
            <div className="p-grid" style={{marginTop: 20}}>
                {datacontent}
               
            </div>
        )
    }
}

export default Download;