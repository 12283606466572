import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Messages } from 'primereact/messages';
import { Button } from "primereact/button";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { GET_EVENT_LIST_IMAGE, ADD_TYPE_IMAGE, DEL_TYPE_IMAGE, UPDATE_TYPE_IMAGE } from '../../../store/constants/serviceEndpoint';
import { headerToken } from '../../../store/constants/header';
import { withRouter } from 'react-router-dom'
import { axiosWithHeaders } from '../../../store/constants/axiosWrapper';
const axios = require("axios");
class ConfigImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listImage: [],
            apiUpdate: ADD_TYPE_IMAGE,
            mode: 0, /* 0: insert, 1: update */
            Name: '',
            Description: ''
        };
    }

    componentDidMount() {
        const eventId = this.props.match.params.id;
        if (eventId !== undefined && eventId !== null) {
            this.GetImageByEvent(eventId);
        }
    }

    checkData = (obj) => {
        for (var field in obj) {
            if (obj[field] === undefined || obj[field] === null || obj[field] === "" || obj[field] === 0) {
                switch (field) {
                    case 'Name':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập tên hình !' });
                        break;
                    case 'desc':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập mô tả về hình!' });
                        break;
                    default:
                        break;
                }
                return false;
            }
        }
        return true;
    }

    UpdateData = (e) => {
        const _this = this;
        const state = this.state;
        let objUpdate = {}
        objUpdate.Name = state.Name;
        objUpdate.Description = state.Description;
        const check = this.checkData(objUpdate);
        if (check) {
            objUpdate.EventId = state.eventId;
            objUpdate.Order = state.Order;
            if (state.mode === 1) {
                objUpdate.Id = state.id;
            }

            // axios({
            //     method: 'post',
            //     url: _this.state.apiUpdate,
            //     headers: headerToken(),
            //     data: objUpdate
            // })
            axiosWithHeaders("post", _this.state.apiUpdate, objUpdate)
            .then(function (response) {
                if (response.status === 200) {
                    const data = response.data;
                    if (data.message === "SUCCESS") {
                        Swal.fire("Thông báo", "Cập nhật cài đặt hình thành công !", "success")
                    } else {
                        Swal.fire("Lỗi", "Cập nhật cài đặt hình thất bại !", "error")
                    }
                    _this.GetImageByEvent(_this.state.eventId);
                    _this.setState({
                        visible: false,
                        Name: '',
                        Description: '',
                        apiUpdate: ''
                    })
                }
            })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }

    celltemplate = (row, column) => {
        const field = column.field;
        switch (field) {
            default:
                return (
                    <div style={{ width: '100%', display: "inline-block", textAlign: "center" }}>
                        <Button
                            icon="pi pi-pencil"
                            className="p-button-success p-button-raised"
                            style={{ marginRight: 7, width: 35 }}
                            onClick={(e, uniqueId) => this.updateItem(e, row)}
                        />
                        <Button
                            icon="pi pi-trash"
                            className="p-button-danger p-button-raised"
                            style={{ width: 35 }}
                            onClick={(e, uniqueId) => this.deleteItem(e, row.id)}
                        />
                    </div>
                );
        }
    }

    updateItem = (e, row) => {
        this.setState({
            visible: true,
            Name: row.name,
            Description: row.description,
            id: row.id,
            apiUpdate: UPDATE_TYPE_IMAGE,
            mode: 1,
            Order: row.order
        })
    }

    deleteItem = (e, id) => {
        // console.log(id);
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn cài đặt hình này không ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý!',
            cancelButtonText: 'Trở về'
        }).then((result) => {
            if (result.value) {
                const _this = this;
                // axios({
                //     method: 'post',
                //     url: DEL_TYPE_IMAGE,
                //     headers: headerToken(),
                //     data: {
                //         Id: id
                //     }
                // })
                axiosWithHeaders("post", DEL_TYPE_IMAGE, {Id: id})
                .then(function (response) {
                    if (response.status === 200) {
                        const data = response.data;
                        if (data.message === "SUCCESS") {
                            Swal.fire("Thông báo", "Xoá cài đặt thành công !", "success")
                        } else {
                            Swal.fire("Lỗi", "Xóa cài đặt thất bại !", "error")
                        }
                        _this.GetImageByEvent(_this.state.eventId);
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                    })
            }
        })
    }

    onCancel = (e) => {
        // e.preventDefault();
        this.setState({
            visible: false,
            Name: '',
            Description: '',
            apiUpdate: "",
            Order: 0
        })
    }

    GetImageByEvent = (eventId) => {
        const _this = this;
        // axios({
        //     method: 'get',
        //     url: GET_EVENT_LIST_IMAGE,
        //     headers: headerToken(),
        //     params: {
        //         id: eventId
        //     }
        // })
        axiosWithHeaders("get", GET_EVENT_LIST_IMAGE, {id: eventId})
        .then(function (response) {
            if (response.status === 200) {
                const data = response.data;
                const result = data.result;
                // console.log(result);
                _this.setState({
                    listImage: result,
                    eventId: eventId
                })
            }
        })
            .catch(function (error) {
                console.log(error);
            })
    }

    render() {
        const footer = (
            <div>
                <Button
                    label="Cập nhật"
                    icon="pi pi-check"
                    onClick={(e, Id) => this.UpdateData(e)}
                    className="p-button-raised p-button-info"
                />
                <Button
                    label="Hủy"
                    className="p-button-raised p-button-secondary"
                    icon="pi pi-times"
                    onClick={() => this.setState({
                        visible: false,
                        name: "",
                        description: "",
                        apiUpdate: "",
                        Order: 0
                    })}
                />
            </div>
        );

        return (
            <div className="p-grid">
                <Dialog
                    header="Cập nhật hình"
                    footer={footer}
                    visible={this.state.visible}
                    style={{ width: "600px" }}
                    modal={true}

                    onHide={(e) => this.onCancel(e)}
                >
                    <div className="p-grid">
                        <Messages ref={(el) => this.messages = el} style={{ width: "100%" }} ></Messages>
                    </div>
                    <div className="p-grid" style={{ marginTop: 5 }}>
                        <div className="p-col-3" style={{ paddingBottom: 0, textAlign: "right" }}>
                            <label>Tên hình</label>
                        </div>
                        <div className="p-col-6" style={{ paddingTop: 0 }}>
                            <InputText
                                placeholder="Nhập tên hình...."
                                style={{ width: "100%" }}
                                onChange={(e) => this.setState({ Name: e.target.value })}
                                value={this.state.Name}
                            />
                        </div>
                    </div>
                    <div className="p-grid" style={{ marginTop: 5 }}>
                        <div className="p-col-3" style={{ paddingBottom: 0, textAlign: "right" }}>
                            <label>Mô tả </label>
                        </div>
                        <div className="p-col-9" style={{ paddingTop: 0 }}>
                            <InputTextarea
                                rows={5} style={{ width: "100%" }}
                                value={this.state.Description}
                                onChange={(e) => this.setState({ Description: e.target.value })}
                                placeholder="Nhập mô tả về hình..."
                            />
                        </div>
                    </div>
                    <div className="p-grid" style={{ marginTop: 5 }}>
                        <div className="p-col-3" style={{ paddingBottom: 0, textAlign: "right" }}>
                            <label>Thứ tự hiển thị </label>
                        </div>
                        <div className="p-col-1" style={{ paddingTop: 0 }}>
                            <InputText
                                placeholder="0"
                                style={{ width: "100%", color: "red", textAlign: "right" }}
                                onChange={(e) => this.setState({ Order: e.target.value })}
                                value={this.state.Order}
                            />
                        </div>
                    </div>
                </Dialog>
                <DataTable value={this.state.listImage}
                    emptyMessage="Hoạt động này chưa tạo hình nào...."
                    style={{ marginTop: '30px' }}>
                    <Column field="name" header="Tên hình" />
                    <Column field="description" header="Mô tả" />
                    <Column
                        field="order"
                        header="Thứ tự"
                        style={{ width: 60 }}
                        className={"p-col-fixed"}
                    />
                    <Column
                        body={(row, column) => this.celltemplate(row, column)}
                        style={{ width: 120 }}
                        className={"p-col-fixed"}
                    />
                </DataTable>
                <div style={{ position: "absolute", top: 10, right: 15 }}>
                    <Button
                        label="THÊM"
                        icon="pi pi-plus"
                        className="p-button-raised p-button-secondary"
                        onClick={() => this.setState({
                            visible: true,
                            Name: '',
                            Description: '',
                            apiUpdate: ADD_TYPE_IMAGE,
                            mode: 0,
                            Order: 0
                        })}
                    />
                </div>
            </div>
        )
    }
}

export default withRouter(ConfigImage);
