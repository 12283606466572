import React, { Component } from "react";
import { connect } from 'react-redux';
import { Button } from "primereact/button";
import { PickList } from 'primereact/picklist';
import { Card } from 'primereact/card';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { ADD_ARR_AREA_EVENT, DEL_ARR_AREA_EVENT } from '../../../store/constants/serviceEndpoint';
import { headerToken } from '../../../store/constants/header';
import { withRouter } from 'react-router-dom'
import { removeToken } from '../../../crypto/Crypto'
import * as actions from '../../../store/actions/index';
// import * as actionTypes from '../../../store/constants/actionTypes'; 
import * as enums from '../../../common/enum';
import * as router from '../../../common/route';
import { axiosWithHeaders } from "../../../store/constants/axiosWrapper";
const axios = require("axios");
class ConfigArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listArea: [],
      listAreaSelected: [],
      isEdit: false,
      isModified: false,
      eventId: null
    };
    this.areaTemplate = this.areaTemplate.bind(this);
  }

  componentDidMount() {
    const eventId = this.props.match.params.id;
    this.setState({
      eventId: eventId
    })
  }

  componentWillUnmount() {
    this.setState({
      listArea: [],
      listAreaSelected: [],
      isEdit: false,
      isModified: false
    })
  }

  onMoveToSource = (event) => {
    Swal.fire({
      title: 'Thông báo',
      text: "Bạn có muốn loại bỏ những vùng này khỏi hoạt động không ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Đồng ý!',
      cancelButtonText: 'Trở về'
    }).then((result) => {
      if (result.value) {
        const _this = this;
        let dataDelete = {}
        let arrSelect = event.value;
        let arrDelete = [];
        arrSelect.map((item, i) => {
          arrDelete.push(item.id)
        })
        dataDelete["list"] = arrDelete;

        // axios({
        //   method: 'post',
        //   url: DEL_ARR_AREA_EVENT,
        //   headers: headerToken(),
        //   data: dataDelete
        //   })
        axiosWithHeaders("post", DEL_ARR_AREA_EVENT, dataDelete)
          .then(function (response) {
            if (response.status === enums.RESPONSE_TOKEN_INVALID) {
              removeToken();
              actions.auth_ini();
              _this.props.history.push(router.LOGIN);
            }
            if (response.status === enums.RESPONSE_OK) {
              const data = response.data;
              if (data.message === "SUCCESS") {
                Swal.fire("Thông báo", "Cập nhật vùng hoạt động thành công !", "success")
              } else {
                Swal.fire("Lỗi", "Cập nhật vùng hoạt động thất bại !", "error")
              }
              _this.reloadData(1);
            }
          })
          .catch(function (error) {
            console.log(error);
          })
      }
    })

  }

  onMoveAllToSource = (event) => {
    Swal.fire({
      title: 'Thông báo',
      text: "Bạn có muốn loại bỏ những vùng này khỏi hoạt động không ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Đồng ý!',
      cancelButtonText: 'Trở về'
    }).then((result) => {
      if (result.value) {
        const _this = this;
        let dataDelete = {}
        let arrSelect = event.value;
        let arrDelete = [];
        arrSelect.map((item, i) => {
          arrDelete.push(item.id)
        })
        dataDelete["list"] = arrDelete;
        // axios({
        //   method: 'post',
        //   url: DEL_ARR_AREA_EVENT,
        //   headers: headerToken(),
        //   data: dataDelete
        // })
        axiosWithHeaders("post", DEL_ARR_AREA_EVENT, dataDelete)
        .then(function (response) {
          if (response.status === enums.RESPONSE_TOKEN_INVALID) {
            removeToken();
            actions.auth_ini();
            _this.props.history.push(router.LOGIN);
          }
          if (response.status === enums.RESPONSE_OK) {
            const data = response.data;
            if (data.message === "SUCCESS") {
              Swal.fire("Thông báo", "Cập nhật vùng hoạt động thành công !", "success")
            } else {
              Swal.fire("Lỗi", "Cập nhật vùng hoạt động thất bại !", "error")
            }
            _this.reloadData(1);
          }
        })
          .catch(function (error) {
            console.log(error);
          })
      }
    })
  }

  onMoveToTarget = (event) => {
    const _this = this;
    let dataUpdate = {}
    let arrSelect = event.value;
    let arrUpdate = [];
    arrSelect.map((item, i) => {
      let obj = {}
      obj["EventId"] = this.state.eventId;
      obj["AreaId"] = item.value;
      arrUpdate.push(obj)
    })
    dataUpdate["Data"] = arrUpdate;
    // axios({
    //   method: 'post',
    //   url: ADD_ARR_AREA_EVENT,
    //   headers: headerToken(),
    //   data: dataUpdate
    // })
    axiosWithHeaders("post", ADD_ARR_AREA_EVENT, dataUpdate)
    .then(function (response) {
      if (response.status === enums.RESPONSE_TOKEN_INVALID) {
        removeToken();
        actions.auth_ini();
        _this.props.history.push(router.LOGIN);
      }
      if (response.status === enums.RESPONSE_OK) {
        const data = response.data;
        if (data.message === "SUCCESS") {
          Swal.fire("Thông báo", "Cập nhật vùng hoạt động thành công !", "success")
        } else {
          Swal.fire("Lỗi", "Cập nhật vùng hoạt động thất bại !", "error")
        }
        _this.reloadData(1);
      }
    })
      .catch(function (error) {
        console.log(error);
      })
  }

  onMoveAllToTarget = (event) => {
    const _this = this;
    let dataUpdate = {}
    let arrSelect = event.value;
    let arrUpdate = [];
    arrSelect.map((item, i) => {
      let obj = {}
      obj["EventId"] = this.state.eventId;
      obj["AreaId"] = item.value;
      arrUpdate.push(obj)
    })
    dataUpdate["Data"] = arrUpdate;
    // axios({
    //   method: 'post',
    //   url: ADD_ARR_AREA_EVENT,
    //   headers: headerToken(),
    //   data: dataUpdate
    // })
    axiosWithHeaders("post", ADD_ARR_AREA_EVENT, dataUpdate)
    .then(function (response) {
      if (response.status === enums.RESPONSE_TOKEN_INVALID) {
        removeToken();
        actions.auth_ini();
        _this.props.history.push(router.LOGIN);
      }
      if (response.status === enums.RESPONSE_OK) {
        const data = response.data;
        if (data.message === "SUCCESS") {
          Swal.fire("Thông báo", "Cập nhật vùng hoạt động thành công !", "success")
        } else {
          Swal.fire("Lỗi", "Cập nhật vùng hoạt động thất bại !", "error")
        }
        _this.reloadData(1);
      }
    })
      .catch(function (error) {
        console.log(error);
      })
  }

  areaTemplate(area) {
    return (
      <div className="p-clearfix">
        <div style={{ fontSize: '14px' }}>{area.label}</div>
      </div>
    );
  }

  reloadData = (idx) => {
    this.setState({
      isEdit: false,
      isModified: false
    })
    return this.props.reloadData(idx);
  }

  renderText() {
    const data = this.props.dataAreaSelected;
    let text = "";
    if (data !== undefined && data !== null && data.length > 0) {
      data.map((item) => {
        text += (text === "") ? item.label : ", " + item.label;
      });
      return text;
    }
  }

  onCancel = (e) => {
    e.preventDefault();
    const modified = this.state.isModified;
    if (modified) {
      Swal.fire({
        title: 'Thông báo',
        text: "Dữ liệu đã bị chỉnh sửa, bạn có muốn cập nhật không ?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý!',
        cancelButtonText: 'Trở về'
      }).then((result) => {
        if (result.value) {
          this.setState({
            isEdit: false
          })
        }
      })
    } else {
      this.setState({
        isEdit: false
      })
    }
  }

  render() {
    const props = this.props;
    return (
      <div className="p-grid" style={{ paddingTop: 40 }}>
        <div className="p-col-12" style={{ paddingTop: 13 }}>
          {
            (props.dataAreaSelected !== undefined && props.dataAreaSelected !== null && props.dataAreaSelected.length > 0) && (
              <label>Các vùng đang diễn ra hoạt động: <span style={{ fontSize: 18, color: "red" }}>{this.renderText()}</span></label>
            )
          }
        </div>
        {
          (this.state.isEdit) && (
            <div className="p-col-12">
              <Card>
                <div className="p-grid">
                  <div className="p-col-12">
                    <PickList
                      source={this.props.dataArea}
                      target={this.props.dataAreaSelected}
                      itemTemplate={this.areaTemplate}
                      sourceHeader="Danh sách vùng"
                      targetHeader="Đang chạy"
                      onMoveToSource={(event) => this.onMoveToSource(event)}
                      onMoveAllToSource={(event) => this.onMoveAllToSource(event)}
                      onMoveToTarget={(event) => this.onMoveToTarget(event)}
                      onMoveAllToTarget={(event) => this.onMoveAllToTarget(event)}
                      responsive={true}
                      sourceStyle={{ height: '300px' }}
                      targetStyle={{ height: '300px' }}
                    >
                    </PickList>
                    <div className="p-col-12" style={{ textAlign: "center", paddingTop: 40 }}>
                      <Button
                        label="HỦY"
                        icon="pi pi-times"
                        className="p-button-raised p-button-secondary"
                        onClick={(e) => this.onCancel(e)}
                        style={{ marginRight: 10 }}
                      />
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          )
        }
        <div style={{ position: "absolute", top: 10, right: 15 }}>
          <Button
            label="SỬA"
            icon="pi pi-pencil"
            className="p-button-raised p-button-secondary"
            onClick={() => this.setState({ isEdit: true })}
          />
        </div>
      </div>
    )
  }

}
function mapStateToProps(state) {
  return {
    area: state.area
  };
}

export default withRouter(connect(mapStateToProps, {})(ConfigArea));
