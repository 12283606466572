import * as actionTypes from '../constants/actionTypes';

const initialState = {
    gridSetting: null,
    columns: [],
    value: [],
    loading: false,
    loadingData: false,
    loadingColumns: false,
    first: 0,
    rows: 50,
    page: 1,
    totalRecords: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_GRID:
            return {
                ...state
            }
        case actionTypes.UPDATE_GRID_SETTING:
            return {
                ...state,
                gridSetting: action.gridSetting
            }
        case actionTypes.UPDATE_GRID_COLUMNS:
            return {
                ...state,
                columns: action.columns
            }
        case actionTypes.UPDATE_GRID_LOADING:
            return {
                ...state,
                loading: action.loading
            }
        case actionTypes.UPDATE_GRID_LOADING_DATA:
            return {
                ...state,
                loadingData: action.loadingData
            }
        case actionTypes.UPDATE_GRID_LOADING_COLUMN:
            return {
                ...state,
                loadingColumns: action.loadingColumns
            }
        case actionTypes.UPDATE_GRID_DATA:
            return {
                ...state,
                value: action.data,
                first: action.first,
                totalRecords: action.totalRecords,
                page: action.page,
                rows: action.rows
            }
        default:
            return state;
    }
}

export default reducer;