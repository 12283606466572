import * as actionTypes from '../constants/actionTypes';

const initialState = {
    data: null,
    loading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_FILTER:
            return {
                ...state
            }
        case actionTypes.UPDATE_DROPDOWN_DATA:
            let dataSave = null;
            let dataDropdown = {...state.data};
            const receivedData = {...action.data};
            if(receivedData){                
                if(!dataDropdown){
                    dataSave = receivedData;
                }else{
                    dataSave = {...dataDropdown};

                    for(const field in receivedData){
                        dataSave[field] = receivedData[field];
                    }
                }
            }            
            return {
                ...state,
                data: dataSave
            }
        case actionTypes.UPDATE_DROPDOWN_LOADING:
            return {
                ...state,
                loading: action.loading
            }
        default:
            return state;
    }
}

export default reducer;