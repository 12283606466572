import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { deCryptUserId, enCryptServer, encryptAES } from '../../../crypto/Crypto'
import { Messages } from 'primereact/messages';
import { USER_CHANGE_PASSWORD } from '../../../store/constants/serviceEndpoint';
import { headerToken } from '../../../store/constants/header';
import { connect } from 'react-redux';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as types from '../../../store/constants/actionTypes';
import { account_set_change_password } from '../../../store/actions/account';
import * as actions from '../../../store/actions/index';
import { withRouter } from "react-router-dom";
import * as axiosWrapper from '../../../store/constants/axiosWrapper';
// const axios = require("axios");
class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldpassword: '',
            password: '',
            repassword: '',
            visibleError: 1
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    checkPasswordMatch = (e) => {
        const password = this.state.password;
        const repassword = this.state.repassword;
        if (password === repassword) {
            this.setState({
                visibleError: 1
            })
        } else {
            this.setState({
                visibleError: 0
            })
        }

    }

    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     console.log(nextProps)
    // }

    checkData(obj) {
        for (var field in obj) {
            if (obj[field] === undefined || obj[field] === null || obj[field] === "") {
                switch (field) {
                    case 'oldpassword':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập mật khẩu cũ !' });
                        break;
                    case 'password':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập mật khẩu mới !' });
                        break;
                    case 'repassword':
                        this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập lại mật khẩu !' });
                        break;
                    default:
                        break;
                }
                return false;
            }
        }

        if (obj["password"] === obj["oldpassword"]) {
            this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Mật khẩu mới không được trùng với mật khẩu cũ !' });
            return false;
        }

        if (obj["password"] !== obj["repassword"]) {
            this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Mật khẩu mới không trùng khớp !' });
            return false;
        }

        if (obj["password"].length < 6) {
            this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Mật khẩu không được ít hơn 6 kí tự !' });
            return false;
        }

        return true;
    }

    onLogout = () => {
        localStorage.removeItem("EventId");
        this.props.changeEvent(null);
        this.props.onLogout();
    }

    // ChangePassword = (e) => {


    // }

    onSubmit = (e) => {
        e.preventDefault();
        const _this = this;

        console.log(`a`)

        // const userId = deCryptUserId();
        const { oldpassword, password, repassword } = this.state;

        let objUpdate = {
            oldpassword: oldpassword,
            password: password,
            repassword: repassword
        }

        const check = this.checkData(objUpdate);


        objUpdate.oldpassword = `${encryptAES(oldpassword)}`
        objUpdate.password = `${encryptAES(password)}`
        objUpdate.repassword = `${encryptAES(repassword)}`

        console.log(objUpdate)
        if (check) {
            var deCode = objUpdate;
            // axios({
            //         method: 'post',
            //         url: USER_CHANGE_PASSWORD,
            //         headers: headerToken(),
            //         data: JSON.stringify(deCode)
            //     })
            axiosWrapper.axiosWithHeaders("POST", USER_CHANGE_PASSWORD, deCode)
                .then(function (response) {
                    // console.log(response);
                    if (response.data && response.status === 200) {
                        const {
                            status,
                            message
                        } = response.data;
                        if (status === 200) {
                            // account_set_change_password();
                            localStorage.removeItem(types.CHANGE_PASSWORD_STORAGE);
                            Swal.fire("Thông báo", "Đổi mật khẩu thành công", "success");
                            _this.onLogout();
                        } else {
                            Swal.fire("Lỗi", message, "error")
                        }

                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }

    render() {
        return (
            <form onSubmit={(e) => this.onSubmit(e)}>
                <div className='row'>
                    <div className='col-12'>
                        <Messages ref={(el) => this.messages = el} />
                    </div>
                </div>
                <div className='row' style={{ paddingTop: 20 }}>
                    <div className='col-12'>
                        <label>Mật khẩu cũ</label>
                    </div>
                </div>
                <div className='row' >
                    <div className='col-12'>
                        <InputText
                            placeholder="Nhập mật khẩu cũ..."
                            type="password"
                            value={this.state.oldpassword}
                            style={{ width: "100%" }}
                            onChange={(e) => this.setState({ oldpassword: e.target.value })}
                        />
                    </div>
                </div>
                <div className='row' style={{ paddingTop: 10 }}>
                    <div className='col-12'>
                        <label>Mật khẩu mới</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Password
                            placeholder="Nhập mật khẩu mới..."
                            promptLabel="Vui lòng nhập mật khẩu"
                            weakLabel="Yếu"
                            mediumLabel="Trung bình"
                            strongLabel="Mạnh"
                            style={{ width: "100%" }}
                            value={this.state.password}
                            onChange={(e) => this.setState({ password: e.target.value })}
                        />
                    </div>
                </div>

                <div className='row' style={{ paddingTop: 10 }}>
                    <div className='col-12'>
                        <label>Nhập lại mật khẩu</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Password
                            placeholder="Nhập lại mật khẩu mới..."
                            promptLabel="Vui lòng nhập mật khẩu"
                            weakLabel="Yếu"
                            mediumLabel="Trung bình"
                            strongLabel="Mạnh"
                            style={{ width: "100%" }}
                            value={this.state.repassword}
                            onChange={(e) => this.setState({ repassword: e.target.value })}
                        />
                    </div>
                </div>

                <div className='row' style={{ paddingTop: 30 }}>
                    <div className='col-12 text-center'>
                        <Button label="Cập nhật" icon="pi pi-pencil" className="p-button-raised p-button-success" type='submit' />
                    </div>
                </div>
            </form>
        )
    }
}

const mapPropsToState = state => {
    return {
        account: state.account.userInfo,
        accessToken: state.auth.accessToken,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout()),
        changeEvent: (eventId) => dispatch(actions.event_set(eventId)),
    }
}

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(ChangePassword));