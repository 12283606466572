import React, { Component } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { withRouter } from "react-router-dom";
import { Toast } from 'primereact/toast';
import { deCryptUserId } from '../../../crypto/Crypto';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import * as enums from '../../../common/enum';
import * as router from '../../../common/route';
import {
    STORE_DETAIL,
    LIST_EVENT,
    GET_LIST_AREA,
    GET_DATADROPDOWN,
    STORE_UPDATE
} from '../../../store/constants/serviceEndpoint';
import { headerToken } from '../../../store/constants/header';
import * as axiosWrapper from '../../../store/constants/axiosWrapper';
import { STORAGE_EVENTNAME } from '../../../store/constants/actionTypes';

class DetailStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Code: '',
            StoreName: '',
            OwnerName: '',
            dataRange: [
                {
                    value: 0,
                    label: "Không xếp hạng"
                }, {
                    value: 1,
                    label: "Vàng"
                }, {
                    value: 2,
                    label: "Bạc"
                }, {
                    value: 3,
                    label: "Đồng"
                }
            ],
            range: null,
            phoneNumber: '',
            address: '',
            note: '',
            listArea: [],
            areaId: null,
            dataProvince: [],
            provinceId: null,
            dataRTMM: [],
            regionalTradeMarketingManagerId: null,
            dataSupplier: [],
            supplierId: null,
            userId: null,
        }
    }

    componentDidMount() {
        const storeId = this.props.match.params.id;
        if (storeId !== undefined && storeId !== null) {
            this.getDetailStore(storeId);
        }
        this.GetListEvent();
        this.getListArea();
    }



    getListArea() {
        const _this = this;
        // axios({
        //     method: 'get',
        //     url: GET_LIST_AREA,
        //     headers: headerToken(),
        // })
        axiosWrapper.axiosWithHeaders('get', GET_LIST_AREA, null)
            .then(function (response) {
                if (response.status === 200) {
                    const data = response.data;
                    const result = data.result;
                    // let arr = [];
                    // result.map((item, i)=>{
                    // let obj = {}
                    // obj["value"] = item.id;
                    // obj["label"] = item.name;
                    // arr.push(obj);
                    // })

                    _this.setState({
                        listArea: result
                    })

                }
            }).catch(function (error) {
                console.log(error);
            })
    }

    getDataCombobox = (name, storeProcedure) => {
        let obj = {}
        obj["Name"] = name;
        obj["StoreProcedure"] = storeProcedure;
        const areaId = this.state.areaId;
        switch (name) {
            case 'dataProvince':
                if (areaId !== null) {
                    let objParam = {
                        Field: "AreaId",
                        TypeField: 22,
                        Value: areaId
                    }
                    let arr = [];
                    arr.push(objParam);
                    obj["Parameters"] = arr;
                }
                break;
            case 'dataRTMM':
                if (areaId !== null) {
                    let objParam = {
                        Field: "AreaId",
                        TypeField: 22,
                        Value: areaId
                    }
                    let arr = [];
                    arr.push(objParam);
                    obj["Parameters"] = arr;
                }

                break;
            case 'dataASM':
                const regionalTradeMarketingManagerId = this.state.regionalTradeMarketingManagerId;
                if (regionalTradeMarketingManagerId !== null) {
                    let objParam = {
                        Field: "RTMMId",
                        TypeField: 22,
                        Value: regionalTradeMarketingManagerId
                    }
                    let arr = [];
                    arr.push(objParam);
                    obj["Parameters"] = arr;
                }
                break;
            case 'dataTSM':
                const areaSalesManagerId = this.state.areaSalesManagerId;
                if (areaSalesManagerId !== null) {
                    let objParam = {
                        Field: "ASMId",
                        TypeField: 22,
                        Value: areaSalesManagerId
                    }
                    let arr = [];
                    arr.push(objParam);
                    obj["Parameters"] = arr;
                }
                break;
            case 'dataSR':
                const territorySalesManagerId = this.state.territorySalesManagerId;
                if (territorySalesManagerId !== null) {
                    let objParam = {
                        Field: "TSMId",
                        TypeField: 22,
                        Value: territorySalesManagerId
                    }
                    let arr = [];
                    arr.push(objParam);
                    obj["Parameters"] = arr;
                }
                break;
            case 'dataSupplier':
                const userId = this.state.userId;
                if (userId !== null) {
                    let objParam = {
                        Field: "userId",
                        TypeField: 22,
                        Value: userId
                    }
                    let arr = [];
                    arr.push(objParam);
                    obj["Parameters"] = arr;
                }
                break;
            default:
                break;
        }

        const objResponese = {
            Data: obj,
            Token: "31FCC2DD-C370-447C-9BF7-DF7C1E42021E",
        }

        const _this = this;
        // axios({
        //     method: 'post',
        //     url: GET_DATADROPDOWN,
        //     headers: headerToken(),
        //     data: objResponese
        // })
        axiosWrapper.axiosWithHeaders('post', GET_DATADROPDOWN, objResponese)
            .then(function (response) {
                if (response.status === 200) {
                    const data = response.data;
                    const result = data.result;
                    if (result !== undefined && result !== null && result.length > 0) {
                        let arr = []
                        let state = _this.state;
                        state[name] = result;
                        _this.setState(state);
                    }

                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    GetListEvent = () => {
        const _this = this;
        // axios({
        //     method: 'post',
        //     url: LIST_EVENT,
        //     headers: headerToken(),
        // })
        axiosWrapper.axiosWithHeaders('post', LIST_EVENT, null)
            .then(function (response) {
                if (response.status === 200) {
                    const data = response.data;
                    _this.setState({
                        listEvent: data.result
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    getDetailStore = (storeId) => {
        const _this = this;
        // axios({
        //     method: 'get',
        //     url: STORE_DETAIL,
        //     headers: headerToken(),
        //     params: {
        //         id: storeId,
        //     }
        // })
        axiosWrapper.axiosWithHeaders('get', STORE_DETAIL, { id: storeId })
            .then(function (response) {
                if (response.status == enums.RESPONSE_TOKEN_INVALID) {
                    this.props.history.push(router.LOGIN);
                }
                if (response.status === enums.RESPONSE_OK) {
                    const data = response.data;
                    const result = data.result;

                    let state = _this.state;
                    if (result !== undefined && result !== null && Object.keys(result).length > 0) {
                        state.code = result.code;
                        state.storeName = result.storeName;
                        state.ownerName = result.ownerName;
                        state.phoneNumber = result.phoneNumber;
                        state.range = result.range;
                        state.address = (result.address === null) ? "" : result.address;
                        state.note = (result.note === null) ? "" : result.note;
                        state.lat = result.lat;
                        state.long = result.long;
                        state.areaId = result.areaId;
                        state.provinceId = result.provinceId;
                        state.eventId = result.eventId;
                        state.supplierId = result.supplierId;
                    }

                    const userStore = data.userStore;
                    if (userStore !== undefined && userStore !== null && Object.keys(userStore).length > 0) {
                        state.regionalTradeMarketingManagerId = userStore.regionalTradeMarketingManagerId;
                        state.areaSalesManagerId = userStore.areaSalesManagerId;
                        state.territorySalesManagerId = userStore.territorySalesManagerId;
                        state.userId = userStore.id;
                        state.Id = result.id;
                    }
                    // console.log(data);
                    _this.setState(state)

                    setTimeout(() => {
                        _this.getDataCombobox("dataRTMM", "[MemBer_GetList_RTMM]");
                        _this.getDataCombobox("dataProvince", "Province_GetByAreaId");
                        _this.getDataCombobox("dataASM", "[MemBer_GetList_ASM]");
                        _this.getDataCombobox("dataTSM", "[MemBer_GetList_TSM]");
                        _this.getDataCombobox("dataSR", "[MemBer_GetList_SR]");
                        _this.getDataCombobox("dataSupplier", "[Supplier_List_userId]");
                    }, 250)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    backToList = (e) => {
        this.props.history.push("/event/liststore");
    }

    onChangeValue = (e, field, type) => {
        let state = this.state;
        switch (type) {
            case 'dropdown':
                state[field] = e.value;
                break;
            default:
                state[field] = e.target.value;
                break;
        }
        this.setState(state)

        switch (field) {
            case 'areaId':
                setTimeout(() => {
                    this.getDataCombobox("dataRTMM", "[MemBer_GetList_RTMM]");
                    this.getDataCombobox("dataProvince", "Province_GetByAreaId");
                }, 250)
                break;
            case 'regionalTradeMarketingManagerId':
                setTimeout(() => {
                    this.getDataCombobox("dataASM", "[MemBer_GetList_ASM]");
                }, 250)
                break;
            case 'areaSalesManagerId':
                setTimeout(() => {
                    this.getDataCombobox("dataTSM", "[MemBer_GetList_TSM]");
                }, 250)
                break;
            case 'territorySalesManagerId':
                setTimeout(() => {
                    this.getDataCombobox("dataSR", "[MemBer_GetList_SR]");
                }, 250)
                break;
            case 'userId':
                setTimeout(() => {
                    this.getDataCombobox("dataSupplier", "[Supplier_List_userId]");
                }, 250)
                break;
            default:
                break;
        }
    }

    checkData = (obj) => {
        for (var field in obj) {
            if (obj[field] === undefined || obj[field] === null || obj[field] === "") {
                switch (field) {
                    case 'Code':
                        this.growl.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập mã cửa hàng !' });
                        break;
                    case 'StoreName':
                        this.growl.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập tên cửa hàng !' });
                        break;
                    case 'OwnerName':
                        this.growl.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập tên liên hệ !' });
                        break;
                    case 'PhoneNumber':
                        this.growl.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập di động liên hệ !' });
                        break;
                    case 'Range':
                        this.growl.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn hạng !' });
                        break;
                    case 'Address':
                        this.growl.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập địa chỉ !' });
                        break;
                    // case 'Lat':
                    //     this.growl.show({severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập kinh độ !'});
                    //     break;
                    // case 'Long':
                    //     this.growl.show({severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập vĩ độ !'});
                    //     break;
                    case 'AreaId':
                        this.growl.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn vùng !' });
                        break;
                    case 'ProvinceId':
                        this.growl.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn tỉnh thành !' });
                        break;
                    case 'RegionalTradeMarketingManagerId':
                        this.growl.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn RTMM !' });
                        break;
                    case 'AreaSalesManagerId':
                        this.growl.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn ASM !' });
                        break;
                    case 'TerritorySalesManagerId':
                        this.growl.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn TSM !' });
                        break;
                    case 'UserId':
                        this.growl.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn SR !' });
                        break;
                    case 'SupplierId':
                        this.growl.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn nhà phân phối !' });
                        break;
                    case 'EventId':
                        this.growl.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng chọn hoạt động !' });
                        break;
                    default:
                        break;
                }
                return false;
            }
        }
        return true;
    }

    updateData = (e) => {
        const state = this.state;
        let obj = {}
        obj.Code = state.code;
        obj.StoreName = state.storeName;
        obj.OwnerName = state.ownerName;
        obj.PhoneNumber = state.phoneNumber;
        obj.Range = state.range;
        obj.Address = state.address;
        obj.AreaId = state.areaId;
        obj.ProvinceId = state.provinceId;
        obj.RegionalTradeMarketingManagerId = state.regionalTradeMarketingManagerId;
        obj.AreaSalesManagerId = state.areaSalesManagerId;
        obj.TerritorySalesManagerId = state.territorySalesManagerId;
        obj.UserId = state.userId;
        obj.SupplierId = state.supplierId;
        obj.EventId = state.eventId;
        const check = this.checkData(obj);
        if (check) {
            obj.Id = state.Id;

            const _this = this;
            // axios({
            //     method: 'post',
            //     url: STORE_UPDATE,
            //     headers: headerToken(),
            //     data: obj
            // })
            axiosWrapper.axiosWithHeaders('post', STORE_UPDATE, obj)
                .then(function (response) {
                    if (response.status === 200) {
                        Swal.fire("Thông báo", "Cập nhật cửa hàng thành công!", "success");
                        _this.getDetailStore(_this.state.Id);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }

    render() {
        return (
            <>
                <div style={{ fontSize: 24, marginTop: 20, color: `#de1f27`, fontWeight: 900 }}>{localStorage.getItem(STORAGE_EVENTNAME)}</div>

                <div className="p-grid" style={{ marginTop: 10 }}>
                    <div className="p-col-3"></div>
                    <div className="p-col-6">
                        <Card style={{ width: "100%" }}>
                            <div className="p-grid">
                                <div className="p-col-2">
                                    <Button
                                        icon="pi pi-chevron-left"
                                        className="p-button-raised p-button-secondary"
                                        onClick={(e) => this.backToList(e)}
                                        tooltip="Trở về danh sách"
                                    />
                                </div>
                                <div className="p-col-8" style={{ textAlign: "center", paddingTop: 14 }}>
                                    <h4 className="card-title">CẬP NHẬT CỬA HÀNG</h4>
                                </div>
                                <div className="p-col-2"></div>
                            </div>
                            <div className="p-grid">
                                <div className="form-panel">
                                    <div className="panel-content">
                                        <Toast ref={(el) => this.growl = el} />
                                        <div className="p-grid">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>Mã cửa hàng</label>
                                            </div>
                                            <div className="p-col-6">
                                                <InputText
                                                    placeholder="Nhập mã cửa hàng..."
                                                    value={this.state.code}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'code', 'string')}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>Tên cửa hàng</label>
                                            </div>
                                            <div className="p-col-6">
                                                <InputText
                                                    placeholder="Nhập tên cửa hàng..."
                                                    value={this.state.storeName}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'storeName', 'string')}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>Tên liên hệ</label>
                                            </div>
                                            <div className="p-col-6">
                                                <InputText
                                                    placeholder="Nhập tên liên hệ..."
                                                    value={this.state.ownerName}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'ownerName', 'string')}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>Di động</label>
                                            </div>
                                            <div className="p-col-6">
                                                <InputText
                                                    placeholder="Nhập sđt liên hệ..."
                                                    value={this.state.phoneNumber}
                                                    onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>Hạng</label>
                                            </div>
                                            <div className="p-col-3">
                                                <Dropdown
                                                    value={this.state.range}
                                                    options={this.state.dataRange}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'range', 'dropdown')}
                                                    className="p-button-raised p-button-info"
                                                    placeholder="Chọn hạng"
                                                    filter={true}
                                                    filterPlaceholder={"Tìm kiếm hạng"}
                                                    filterBy="label,value"
                                                    filterMatchMode="contains"
                                                    tooltip={"Chọn hạng !"}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>Địa chỉ</label>
                                            </div>
                                            <div className="p-col-6">
                                                <InputTextarea
                                                    rows={5}
                                                    cols={30}
                                                    value={this.state.address}
                                                    placeholder="Nhập địa chỉ..."
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'address', 'string')}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="p-grid">
                                        <div className="p-col-fixed" style={{width:200, textAlign:"right", paddingTop: 13}}>
                                            <label>Ghi chú</label>
                                        </div>
                                        <div className="p-col-6">
                                            <InputTextarea 
                                                rows={5} 
                                                cols={30} 
                                                value={this.state.note} 
                                                placeholder="Nhập ghi chú..."
                                                onChange={(e, field, type) => this.onChangeValue(e, 'note', 'string')}
                                                style={{width: "100%"}}
                                            />
                                        </div>
                                    </div> */}
                                        {/* <div className="p-grid">
                                        <div className="p-col-fixed" style={{width:200, textAlign:"right", paddingTop: 13}}>
                                            <label>Kinh độ</label>
                                        </div>
                                        <div className="p-col">
                                            <InputText placeholder="Nhập kinh độ..." value={this.state.lat} onChange={(e, field, type) => this.onChangeValue(e, 'lat', 'string')}/>
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-fixed" style={{width:200, textAlign:"right", paddingTop: 13}}>
                                            <label>Vĩ độ</label>
                                        </div>
                                        <div className="p-col">
                                            <InputText placeholder="Nhập vĩ độ..." value={this.state.long} onChange={(e, field, type) => this.onChangeValue(e, 'long', 'string')}/>
                                        </div>
                                    </div> */}
                                        <div className="p-grid">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>Vùng</label>
                                            </div>
                                            <div className="p-col-5">
                                                <Dropdown
                                                    value={this.state.areaId}
                                                    options={this.state.listArea}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'areaId', 'dropdown')}
                                                    className="p-button-raised p-button-info"
                                                    placeholder="Chọn vùng"
                                                    filter={true}
                                                    optionValue="id"
                                                    optionLabel="name"
                                                    filterPlaceholder={"Tìm kiếm vùng"}
                                                    filterBy="id,name"
                                                    filterMatchMode="contains"
                                                    tooltip={"Chọn vùng !"}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>Tỉnh thành</label>
                                            </div>
                                            <div className="p-col-5">
                                                <Dropdown
                                                    value={this.state.provinceId}
                                                    options={this.state.dataProvince}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'provinceId', 'dropdown')}
                                                    className="p-button-raised p-button-info"
                                                    placeholder="Chọn Tỉnh thành"
                                                    filter={true}
                                                    optionValue="id"
                                                    optionLabel="fullName"
                                                    filterPlaceholder={"Tìm kiếm tỉnh thành"}
                                                    filterBy="id,fullName"
                                                    filterMatchMode="contains"
                                                    tooltip={"Chọn chọn vùng để có tỉnh thành !"}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>RTMM</label>
                                            </div>
                                            <div className="p-col-5">
                                                <Dropdown
                                                    value={this.state.regionalTradeMarketingManagerId}
                                                    options={this.state.dataRTMM}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'regionalTradeMarketingManagerId', 'dropdown')}
                                                    className="p-button-raised p-button-info"
                                                    placeholder="Chọn RTMM"
                                                    optionValue="id"
                                                    optionLabel="fullName"
                                                    filter={true}
                                                    filterPlaceholder={"Tìm kiếm RTMM"}
                                                    filterBy="id,fullName"
                                                    filterMatchMode="contains"
                                                    tooltip={"Chọn vùng để có RTMM !"}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>ASM</label>
                                            </div>
                                            <div className="p-col-5">
                                                <Dropdown
                                                    value={this.state.areaSalesManagerId}
                                                    options={this.state.dataASM}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'areaSalesManagerId', 'dropdown')}
                                                    className="p-button-raised p-button-info"
                                                    placeholder="Chọn ASM"
                                                    optionValue="id"
                                                    optionLabel="fullName"
                                                    filter={true}
                                                    filterPlaceholder={"Tìm kiếm ASM"}
                                                    filterBy="id,fullName"
                                                    filterMatchMode="contains"
                                                    tooltip={"Chọn RTMM để có ASM !"}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>TSM</label>
                                            </div>
                                            <div className="p-col-5">
                                                <Dropdown
                                                    value={this.state.territorySalesManagerId}
                                                    options={this.state.dataTSM}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'territorySalesManagerId', 'dropdown')}
                                                    className="p-button-raised p-button-info"
                                                    placeholder="Chọn TSM"
                                                    optionValue="id"
                                                    optionLabel="fullName"
                                                    filter={true}
                                                    filterPlaceholder={"Tìm kiếm TSM"}
                                                    filterBy="id,fullName"
                                                    filterMatchMode="contains"
                                                    tooltip={"Chọn ASM để có TSM !"}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>SR</label>
                                            </div>
                                            <div className="p-col-5">
                                                <Dropdown
                                                    value={this.state.userId}
                                                    options={this.state.dataSR}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'userId', 'dropdown')}
                                                    className="p-button-raised p-button-info"
                                                    placeholder="Chọn SR"
                                                    filter={true}
                                                    optionValue="id"
                                                    optionLabel="fullName"
                                                    filterPlaceholder={"Tìm kiếm SR"}
                                                    filterBy="id,fullName"
                                                    filterMatchMode="contains"
                                                    tooltip={"Chọn TSM để có SR !"}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>Nhà phân phối</label>
                                            </div>
                                            <div className="p-col-5">
                                                <Dropdown
                                                    value={this.state.supplierId}
                                                    options={this.state.dataSupplier}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'supplierId', 'dropdown')}
                                                    className="p-button-raised p-button-info"
                                                    placeholder="Chọn nhà phân phối"
                                                    filter={true}
                                                    optionValue="id"
                                                    optionLabel="name"
                                                    filterPlaceholder={"Tìm kiếm nhà phân phối"}
                                                    filterBy="id,name"
                                                    filterMatchMode="contains"
                                                    tooltip={"Chọn nhà phân phối !"}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-fixed" style={{ width: 200, textAlign: "right", paddingTop: 13 }}>
                                                <label>Hoạt động</label>
                                            </div>
                                            <div className="p-col-5">
                                                <Dropdown
                                                    value={this.state.eventId}
                                                    options={this.state.listEvent}
                                                    onChange={(e, field, type) => this.onChangeValue(e, 'eventId', 'dropdown')}
                                                    className="p-button-raised p-button-info"
                                                    placeholder="Chọn hoạt động"
                                                    filter={true}
                                                    optionValue="id"
                                                    optionLabel="name"
                                                    filterPlaceholder={"Tìm kiếm hoạt động"}
                                                    filterBy="id,name"
                                                    filterMatchMode="contains"
                                                    tooltip={"Chọn hoạt động !"}
                                                    style={{ width: "100%" }}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="p-grid" style={{ paddingTop: 25 }}>
                                <div className="p-col-12" style={{ textAlign: "center" }}>
                                    <Button label="CẬP NHẬT" icon="pi pi-pencil" onClick={(e) => this.updateData(e)} />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="p-col-3"></div>
                </div>
            </>
        )
    }
}

export default withRouter(DetailStore);