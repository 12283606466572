import * as actionTypes from '../constants/actionTypes';

const initialState = {
    loading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_REPORT:
            return {
                ...state
            }
        case actionTypes.UPDATE_REPORT_LOADING:
            return {
                ...state,
                loading: action.loading
            }
        default:
            return state;
    }
}

export default reducer;