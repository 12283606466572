import * as types from '../constants/actionTypes';
import {
    GET_DATADROPDOWN_FILTER
} from '../constants/serviceEndpoint';

import {
    headerToken,
} from '../constants/header';

import { confirmDialog } from 'primereact/confirmdialog';
import * as enums from '../../common/enum';
import { axiosWithHeaders } from '../constants/axiosWrapper';

const axios = require("axios");
const _ = require("lodash");

export function dropdownInit() {
    return {
        type: types.INIT_DROPDOWN
    }
}

export function dropdownUpdateData(data) {
    return {
        type: types.UPDATE_DROPDOWN_DATA,
        data
    }
}

export function dropdownUpdateLoading(loading) {
    return {
        type: types.UPDATE_DROPDOWN_LOADING,
        loading
    }
}


export function getDropdownData(arrDropdown, field) {   
    return async dispatch => {
        const objResponese = {
            Data: arrDropdown
        }
        dispatch(dropdownUpdateLoading(true))
        // axios({
        //     method: 'post',
        //     url: GET_DATADROPDOWN_FILTER,
        //     headers: headerToken(),
        //     data: objResponese
        // })
        axiosWithHeaders("post", GET_DATADROPDOWN_FILTER, objResponese)
        .then(function (response) {
            const {
                data
            } = response;

            if (data.status === enums.RESPONSE_OK) {
                let { result } = data;
                if (result) {
                    dispatch(dropdownUpdateData(result));
                }
            } else {
                confirmDialog({
                    message: `Lỗi ! Mã lỗi ${data.status}! ${data.message}`,
                    header: 'Dữ liệu dropdown !',
                    icon: 'pi pi-exclamation-triangle',
                    rejectClassName: "d-none",
                    accept: () => {
                        return;
                    },
                });
                return;
            }
        }).catch(function (error) {
            confirmDialog({
                message: `Lỗi! ${error.toString()} dòng 73`,
                header: 'Dữ liệu dropdown ',
                icon: 'pi pi-exclamation-triangle',
                rejectClassName: "d-none",
                accept: () => {
                    return;
                },
            });
            return;
        }).finally(()=>{
            dropdownUpdateLoading(false);
        })
    }
}



