import * as actionTypes from '../constants/actionTypes';

const initialState = {
    listFilter: [],
    loading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INIT_FILTER:
            return {
                ...state
            }
        case actionTypes.UPDATE_LIST_FILTER:
            return {
                ...state,
                listFilter: action.listFilter
            }
        default:
            return state;
    }
}

export default reducer;