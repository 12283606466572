import React, { useMemo } from 'react';

const Maps = ({ lat, long, mapHeight }) => {
    const renderMaps = useMemo(() => {
        // console.log(lat, long, mapHeight);
        if (lat && long && mapHeight) {
            
            let locationUrl = "";
            if(Math.abs(parseFloat(lat).toFixed(6)) <=90) {
                locationUrl = parseFloat(lat).toFixed(6) + "," + parseFloat(long).toFixed(6);
            }else{
                locationUrl = parseFloat(long).toFixed(6) + "," + parseFloat(lat).toFixed(6)
            }
            let urlMap = "https://maps.google.com/maps?q=" + locationUrl + "&output=embed";
            //const urlMap = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`
            // console.log(urlMap);
            return (
                <iframe title="Bản đồ" width="100%" height={mapHeight} scrolling="no" src={urlMap} style={{ border: 0 }}></iframe>
            )
        }
        return null;
    }, [lat, long, mapHeight]);

    return (
        <div className="p-grid">
            <div className="p-md-12 p-col-12" style={{ paddingTop: 0, paddingBottom: 0 }}>
                {renderMaps}
            </div>
        </div>
    )
}
// class Maps extends Component {

//     renderMap(lat, long, mapHeight) {
//         console.log(lat, long);
//         const urlMap = "https://maps.google.com/maps?q=" + lat + "," + long + "&output=embed"
//         return (
//             <iframe title="Bản đồ" width="100%" height={mapHeight} scrolling="no" src={urlMap} style={{ border: 0 }}></iframe>
//         )
//     }


//     render() {
//         return (
//            
//             )
//     }
// }

export default Maps;