import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import LoadingView from '../../loadingview/LoadingView';
import { Dialog } from 'primereact/dialog';
import {
    STORE_DELETE,
    STORE_DETAIL_USER_STORE,
    STORE_CHANGE_SR
}
    from '../../../store/constants/serviceEndpoint';
import * as actions from '../../../store/actions/index';
import * as actionTypes from '../../../store/constants/actionTypes';
import * as enums from '../../../common/enum';
import { removeToken } from '../../../crypto/Crypto'
import { headerToken } from '../../../store/constants/header';
import { Dropdown } from 'primereact/dropdown';
import * as router from '../../../common/route';
import { axiosWithHeaders } from '../../../store/constants/axiosWrapper';
const Grid = lazy(() => import('../../grid/grid'));
const axios = require("axios");
class ListStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableId: 1,
            editMode: "inline",
            layoutTitle: "CỬA HÀNG",
            visible: false,
            listUser: [],
            userSR: null,
            id: null
        }
    }

    componentDidMount() {
        let eventId = localStorage.getItem(actionTypes.STORAGE_EVENTID);
        if ((eventId !== undefined && eventId !== null)) {
            this.setState({
                eventId: eventId
            })
        }
    }

    componentWillUnmount() {
        this.setState({
            eventId: null
        })
    }

    renderRank = (value) => {
        switch (value) {
            case 1:
                return (<div><img src={require('../assets/img/web-09.png')} style={{ height: 30, objectFit: 'contain' }} alt="Vàng" /> Vàng</div>)
            case 2:
                return (<div><img src={require('../assets/img/web-10.png')} style={{ height: 30, objectFit: 'contain' }} alt="Bạc" /> Bạc</div>)
            case 3:
                return (<div><img src={require('../assets/img/web-11.png')} style={{ height: 30, objectFit: 'contain' }} alt="Đồng" /> Đồng</div>)
            default:
                return null;
        }
    }

    celltemplate = (row, column) => {
        const field = column.field;
        const userInfo = this.props.account;
      

        if (userInfo !== undefined && userInfo !== null && Object.keys(userInfo).length > 0) {
            switch (field) {
                case 'STT':
                    return (column.rowIndex + 1)
                case 'range':
                    return this.renderRank(row[field]);
                case 'action':
                
                    if (userInfo.roleId == 6) {
                        return (
                            <div style={{ textAlign: "center" }}>
                                <Button
                                    icon="pi pi-pencil"
                                    className="p-button-success p-button-raised"
                                    style={{ marginRight: 7, width: 35 }}
                                    onClick={(e, uniqueId) => this.updateStore(e, row.id)}
                                />
                                <Button
                                    icon="pi pi-pencil"
                                    className="p-button-warning p-button-raised"
                                    style={{ marginRight: 7, width: 35 }}
                                    onClick={(e, uniqueId) => this.GetDetailUserStore(e, row)}
                                />
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-danger p-button-raised"
                                    style={{ width: 35 }}
                                    onClick={(e, uniqueId) => this.deleteStore(e, row.id)}
                                />
                            </div>
                        )
                    }
                    return null;
                default:
                    return null;
            }
        }
    }

    GetDetailUserStore(e, row) {
        const _this = this;
        const eventId = this.state.eventId;
        const { id, storeTypeId } = row;
        // axios({
        //     method: 'get',
        //     url: STORE_DETAIL_USER_STORE,
        //     headers: headerToken(),
        //     params: {
        //         StoreId: id,
        //         EventId: eventId,
        //         StoreTypeId: storeTypeId
        //     }
        // })
        axiosWithHeaders("get", STORE_DETAIL_USER_STORE, {
            StoreId: id,
            EventId: eventId,
            StoreTypeId: storeTypeId
        })
            .then(function (response) {
                if (response.data.status === enums.RESPONSE_TOKEN_INVALID) {
                    removeToken();
                    _this.props.auth_ini();
                    _this.props.history.push(router.LOGIN);
                }
                if (response.data.status === enums.RESPONSE_OK) {
                    const { listUser, result } = response.data;
                    if (result !== undefined && result !== null && Object.keys(result).length > 0) {
                        _this.setState({
                            userSR: result.userId,
                            storeId: result.storeId,
                            visible: true,
                            id: result.id,
                        })
                    }
                    if (listUser !== undefined && listUser !== null && listUser.length > 0) {
                        let arr = [];
                        // listUser.map((item)=>{
                        //     let obj = {}
                        //     obj.value = item.id;
                        //     obj.label = item.fullName
                        //     arr.push(obj);
                        // })
                        listUser.forEach(item => {
                            let obj = {}
                            obj.value = item.id;
                            obj.label = item.fullName
                            arr.push(obj);
                        });

                        _this.setState({
                            listUser: arr
                        })
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    deleteStore = (e, storeId) => {
        const _this = this;
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa cửa hàng này ra khỏi hệ thống không ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý!',
            cancelButtonText: 'Trở về'
        }).then((result) => {
            if (result.value) {
                // axios({
                //     method: 'post',
                //     url: STORE_DELETE,
                //     headers: headerToken(),
                //     data: {
                //         Id: storeId
                //     }
                // })
                axiosWithHeaders("post", STORE_DELETE, {
                    Id: storeId
                })
                .then(function (response) {
                    if (response.data.status === enums.RESPONSE_TOKEN_INVALID) {
                        removeToken();
                        _this.props.auth_ini();
                        _this.props.history.push(router.LOGIN);
                    }
                    if (response.data.status === enums.RESPONSE_OK) {
                        Swal.fire("Thông báo", "Xóa cửa hàng thành công !", "success");
                        _this.setState({
                            storeId: storeId
                        })
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        })
    }

    updateStore = (e, id) => {
        e.preventDefault();
        this.props.history.push('/event/detailstore/' + id)
    }

    clickImport = (e) => {
        e.preventDefault();
        this.props.history.push('/event/importstore');
    }

    StoreChangeSR = (e) => {
        const _this = this;
        const storeId = this.state.storeId;
        const userId = this.state.userSR;
        const id = this.state.id;

        // axios({
        //     method: 'post',
        //     url: STORE_CHANGE_SR,
        //     headers: headerToken(),
        //     data: {
        //         StoreId: storeId,
        //         UserId: userId,
        //         Id: id
        //     }
        // })
        axiosWithHeaders("post", STORE_CHANGE_SR, {
            StoreId: storeId,
            UserId: userId,
            Id: id
        })
        .then(function (response) {
            if (response.data.status === enums.RESPONSE_TOKEN_INVALID) {
                removeToken();
                _this.props.auth_ini();
                _this.props.history.push(router.LOGIN);
            }
            if (response.data.status === enums.RESPONSE_OK) {
                Swal.fire("Thông báo", "Cập nhật SR thành công !", "success");
                _this.setState({
                    uniqueId: userId,
                    visible: false
                })
            }
            if (response.data.status === 999) {
                Swal.fire("Lỗi", response.data.message, "error");
                _this.setState({
                    visible: false
                })
            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        const loading = (
            <div className="p-col-12">
                <LoadingView />
            </div>
        )

        const footer = (
            <div>
                <Button label="Cập nhật" icon="pi pi-check" onClick={(e) => this.StoreChangeSR(e)} />
                <Button label="Bỏ qua" icon="pi pi-times" className="p-button-secondary p-button-raised" onClick={(e) => this.setState({ storeId: null, userSR: null, visible: false })} />
            </div>
        );

        return (
            <div className="p-grid">
                <Dialog
                    header="Thay đổi SR"
                    visible={this.state.visible}
                    style={{ width: '400px' }}
                    modal={true}
                    onHide={() => this.setState({ visible: false })}
                    footer={footer}
                >
                    <div className="p-grid">
                        <div className="p-col-3">
                            <label style={{ paddingTop: 7 }}>SR</label>
                        </div>
                        <div className="p-col-9">
                            <Dropdown
                                value={this.state.userSR}
                                options={this.state.listUser}
                                onChange={(e) => this.setState({ userSR: e.value })}
                                className="p-cust-width"
                                placeholder="Chọn SR"
                                filter={true}
                                filterPlaceholder={"Tìm kiếm SR"}
                                filterBy="label,value"
                                filterMatchMode="contains"
                                style={{ width: "100%" }}
                                tooltip="Vui lòng chọn SR"
                            />
                        </div>
                    </div>
                </Dialog>
                <div className="p-col-12">
                    <Suspense fallback={loading}>
                        <Grid
                            tableId={this.state.tableId}
                            filterTitle="Tìm kiếm nâng cao"
                            editMode={this.state.editMode}
                            layoutTitle={this.state.layoutTitle}
                            celltemplate={(row, column) => this.celltemplate(row, column)}
                            clickImport={(e) => this.clickImport(e)}
                            uniqueId={this.state.uniqueId}
                        />
                    </Suspense>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
        events: state.events,
        account: state.account.userInfo
    }
}

const mapDispatchToProps = dispatch => {
    return {
        auth_ini: () => dispatch(actions.auth_ini())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListStore);