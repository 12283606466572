//AUTH
export const AUTH_INI = 'AUTH_INI';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const AUTH_LOADING = 'AUTH_LOADING';
// ACCOUNT
export const ACCOUNT_INI = 'ACCOUNT_INI';
export const ACCOUNT_SET = 'ACCOUNT_SET';
export const ACCOUNT_SET_CHANGE_PASSWORD = 'ACCOUNT_SET_CHANGE_PASSWORD';
export const ACCOUNT_MESSAGE = 'ACCOUNT_MESSAGE';
export const ACCOUNT_CHANGE = 'ACCOUNT_CHANGE';
// COMMON
export const COMMON_INI = 'COMMON_INI';
export const COMMON_SET_TITLE = 'COMMON_SET_TITLE';
export const COMMON_SET_ROUTER = 'COMMON_SET_ROUTER';
export const COMMON_SET_EVENT = 'COMMON_SET_EVENT';
export const COMMON_SET_STORETYPE = 'COMMON_SET_STORETYPE';
export const COMMON_SET_EVENT_STORETYPE = 'COMMON_SET_EVENT_STORETYPE';
// GLOBAL SERVICE
export const SERVICE_INI = 'SERVICE_INT';
export const SERVICE_SET_PROVINCE = 'SERVICE_SET_PROVINCE';
export const SERVICE_SET_EVENT = 'SERVICE_SET_EVENT';
export const SERVICE_SET_CHANNEL = 'SERVICE_SET_CHANNEL';
export const SERVICE_SET_CHANNEL_PACKAGE = 'SERVICE_SET_CHANNEL_PACKAGE';
export const SERVICE_SET_TYPE_IMAGE = 'SERVICE_SET_TYPE_IMAGE';
export const SERVICE_SET_STORE_TYPE = 'SERVICE_SET_STORE_TYPE';
export const SERVICE_SET_MESSAGE = 'SERVICE_SET_MESSAGE';
// CUSTOMER_LIST
export const CUSTOMER_LIST_INI = 'CUSTOMER_LIST_INI';
export const CUSTOMER_LIST_SET = 'CUSTOMER_LIST_SET';
export const CUSTOMER_LIST_ERROR = 'CUSTOMER_LIST_ERROR';
// CUSTOMER_DETAIL
export const CUSTOMER_DETAIL_INI = 'CUSTOMER_DETAIL_INI';
export const CUSTOMER_DETAIL_SET = 'CUSTOMER_DETAIL_SET';
export const CUSTOMER_DETAIL_SET_APPROVE = 'CUSTOMER_DETAIL_SET_APPROVE';
export const CUSTOMER_DETAIL_SET_QUESTIONS = 'CUSTOMER_DETAIL_SET_QUESTIONS';
export const CUSTOMER_DETAIL_SET_STORE = 'CUSTOMER_DETAIL_SET_STORE';
export const CUSTOMER_DETAIL_ERROR = 'CUSTOMER_DETAIL_ERROR';
// LOCATION
export const LOCATION_INI = 'LOCATION_INI';
export const LOCATION_SET = 'LOCATION_SET';

// STORAGE
export const BROWSER_TOKEN_STORAGE = "_c0k3t0J_zBen_";
export const CHANGE_PASSWORD_STORAGE = "_i5ch4n9e9_";
export const SECRET_KEY_TOKEN = "authen_token";
export const SECRET_KEY_EVEN_STORE = "event_store";
export const ROLE_STORAGE = 'COCA_ROLE';
export const EVENT_STORAGE = '_3Ve8N1D_';
export const STORETYPE_STORAGE = '_5t0r34e_';
export const TOKEN_STORAGE = '_6u0h343e_';
export const DETAIL_USER_STORAGE = '_3u5j3s9y_';
export const STORAGE_INFO = '_3Z5XZ3s9y_';
export const STORAGE_EVENTID = '_3rrObm_';
export const STORAGE_EVENTNAME = 'STORAGE_EVENTNAME';
export const CACHE_USER_INFO = '_4rarzzm_';
export const ACCESSTOKEN_STORAGE = "_i5124659e9_";
export const REFRESHTOKEN_STORAGE = "_i1466n9e9_";


// AREA
export const AREA_LIST_INI = 'AREA_LIST_INI';
export const AREA_LIST_SET = 'AREA_LIST_SET';
export const AREA_LIST_ERROR = 'AREA_LIST_ERROR';

// CHANNEL
export const CHANNEL_LIST_INI = 'CHANNEL_LIST_INI';
export const CHANNEL_LIST_SET = 'CHANNEL_LIST_SET';
export const CHANNEL_LIST_ERROR = 'CHANNEL_LIST_ERROR';

//EVENTS
export const EVENT_INI = 'EVENT_INI';
export const EVENT_SET = 'EVENT_SET';

//CHANNEL
export const CHANNEL_INI = 'CHANNEL_INI';
export const CHANNEL_SET = 'CHANNEL_SET';
export const CHANNEL_PACKAGE_SET = 'CHANNEL_PACKAGE_SET';

//Control grid
export const INIT_GRID = 'INIT_GRID';
export const UPDATE_GRID_SETTING = 'UPDATE_GRID_SETTING';
export const UPDATE_GRID_DATA = 'UPDATE_GRID_DATA';
export const UPDATE_GRID_COLUMNS = 'UPDATE_GRID_COLUMNS';
export const UPDATE_GRID_LOADING = 'UPDATE_GRID_LOADING';
export const UPDATE_GRID_LOADING_DATA = 'UPDATE_GRID_LOADING_DATA';
export const UPDATE_GRID_LOADING_COLUMN = 'UPDATE_GRID_LOADING_COLUMN';

//Control filter
export const INIT_FILTER = 'INIT_FILTER';
export const UPDATE_FILTER_LOADING = 'UPDATE_FILTER_LOADING';
export const UPDATE_LIST_FILTER = 'UPDATE_LIST_FILTER';

//Control report
export const INIT_REPORT = 'INIT_REPORT';
export const UPDATE_REPORT_LOADING = 'UPDATE_REPORT_LOADING';

//Control dropdown
export const INIT_DROPDOWN = 'INIT_DROPDOWN';
export const UPDATE_DROPDOWN_LOADING = 'UPDATE_DROPDOWN_LOADING';
export const UPDATE_DROPDOWN_DATA = 'UPDATE_DROPDOWN_DATA';