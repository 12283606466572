import * as types from '../constants/actionTypes';
import { GET_CHANNELS_PACKAGE, GET_CHANNELS } from '../constants/serviceEndpoint';
import * as enums from '../../common/enum';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as axiosWrapper from '../constants/axiosWrapper';
const axios = require("axios");

export function channelInit() {
    return {
        type: types.CHANNEL_INI
    }
}

export function channelSet(listChannel) {
    return {
        type: types.CHANNEL_SET,
        listChannel
    }
}

export function channelPackageSet(listChannelPackage) {
    return {
        type: types.CHANNEL_PACKAGE_SET,
        listChannelPackage
    }
}

export function getChannel(eventId) {
    // channelInit()
    return async dispatch => {
        let headers = {
            'Content-Type': 'application/json'
            // 'x-api-token': token
        }
        // axios.get(GET_CHANNELS, {
        //     headers: headers,
        //     params: {
        //         id: eventId
        //     }
        // })
        axiosWrapper.axiosWithHeaders('get',GET_CHANNELS,{
            id: eventId
        })
        .then(function (response) {
            const {data} = response;
            switch (data.status){
                case enums.RESPONSE_OK:
                    let {result} = data;
                    dispatch(channelSet(result))
                    break;
                default:
                    Swal.fire("Lỗi", data.message, "error")
                    dispatch(channelInit())
                    break;
            }
        })
        .catch(function (error) {
            Swal.fire("Lỗi", error.toString(), "error");
            console.log(error);
        })
    }
}

export function getChannelPackage(eventId) {
    return async dispatch => {
        let headers = {
            'Content-Type': 'application/json'
            // 'x-api-token': token
        }
        // axios.get(GET_CHANNELS_PACKAGE, {
        //     headers: headers,
        //     params: {
        //         id: eventId
        //     }
        // })   
        axiosWrapper.axiosWithHeaders('get',GET_CHANNELS_PACKAGE,{
            id: eventId
        })     
        .then(function (response) {
            const {data} = response;
            switch (data.status){
                case enums.RESPONSE_OK:
                    let result = data.data;
                    dispatch(channelPackageSet(result))
                    break;
                default:
                    Swal.fire("Lỗi", data.message, "error")
                    dispatch(channelInit())
                    break;
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
    }
}