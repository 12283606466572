import * as types from '../constants/actionTypes';
import {
    REPORT_LIST_SURVEY
} from '../constants/serviceEndpoint';
import {
    headerToken,
} from '../constants/header';

import { confirmDialog } from 'primereact/confirmdialog';
import * as enums from '../../common/enum';
import {axiosWithHeadersDownload} from '../../store/constants/axiosWrapper';

const axios = require("axios");
const moment = require("moment");

export function reportInit() {
    return {
        type: types.INIT_REPORT
    }
}

export function reportUpdateLoading(loading) {
    return {
        type: types.UPDATE_REPORT_LOADING,
        loading
    }
}


export function exportReport(param, gridTitle, eventId, userId) {
    return async dispatch => {
        let paramReport = [...param];

        const idxUserId = paramReport.findIndex(x => x.Field === "UserId");
        if (idxUserId > -1) {
            paramReport.splice(idxUserId, 1);
        }

        paramReport.push({
            Field: "UserId",
            TypeField: 8,
            Value: userId
        })

        const idxEventId = paramReport.findIndex(x => x.Field === "EventId");
        if (idxEventId > -1) {
            paramReport.splice(idxEventId, 1);
        }

        paramReport.push({
            Field: "EventId",
            TypeField: 8,
            Value: eventId
        })

        paramReport.forEach((item) => {
            const {
                TypeField
            } = item;

            if (TypeField === 4) {
                item.Value = (item.Value) ? moment(item.Value).format("YYYYMMDD") : item.Value;
            }
        })

        const objRequest = {
            Name: "BAO CAO",
            StoreProcedure: "Report_Store_Channel_Package",
            Parameters: paramReport
        }


        dispatch(reportUpdateLoading(true));

        // axios({
        //     method: 'post',
        //     url: REPORT_LIST_SURVEY,
        //     headers: headerToken(),
        //     data: objRequest,
        //     responseType: 'blob'
        // })
        axiosWithHeadersDownload("post", REPORT_LIST_SURVEY, objRequest)
        .then(function (response) {
            if (response.status === enums.RESPONSE_OK) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Report.xlsx`);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
            } else {
                confirmDialog({
                    message: `Lỗi ! Không thể tải report ! ${response.status}`,
                    header: gridTitle,
                    icon: 'pi pi-exclamation-triangle',
                    rejectClassName: "d-none",
                    accept: () => {
                        return;
                    },
                });
                return;
            }

        }).catch((err) => {
            confirmDialog({
                message: `Lỗi ! ${err.toString()} dòng 64`,
                header: gridTitle,
                icon: 'pi pi-exclamation-triangle',
                rejectClassName: "d-none",
                accept: () => {
                    return;
                },
            });
            return;
        }).finally(() => {
            dispatch(reportUpdateLoading(false));
        });
    }
}