import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import LoadingView from '../../loadingview/LoadingView';
import { Button } from 'primereact/button';
import {
    USER_RESET_PASSWORD,
    REMOVE_USER
}
    from '../../../store/constants/serviceEndpoint';
// // import { withRouter } from 'react-router-dom'
import * as actions from '../../../store/actions/index';
import { headerToken } from '../../../store/constants/header';
// import * as types from '../../../store/';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as axiosWrapper from '../../../store/constants/axiosWrapper';
const Grid = lazy(() => import('../../grid/grid'));
class ListUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableId: 3,
            editMode: "inline",
            layoutTitle: "NHÂN VIÊN",
            customParam: {},
            role: null,
            userId: null
        }
        this.resetPassword = this.resetPassword.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    deleteUser = (e, userId) => {
        const _this = this;
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa nhân viên này ra khỏi hệ thống không ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý!',
            cancelButtonText: 'Trở về'
        }).then((result) => {
            if (result.value) {
                // axios({
                //     method: 'post',
                //     url: REMOVE_USER,
                //     headers: headerToken(),
                //     data: {
                //         Id: userId
                //     }
                // })
                axiosWrapper.axiosWithHeaders('post', REMOVE_USER, { Id: userId })
                    .then(function (response) {
                        if (response.status === 200) {
                            Swal.fire("Thông báo", "Xóa nhân viên thành công !", "success");
                            _this.setState({
                                userId: userId
                            })
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        })
    }

    resetPassword = (e, userId, username) => {
        console.log(userId, username)
        // const _this = this;
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn đặt lại mật khẩu cho nhân viên này không ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý!',
            cancelButtonText: 'Trở về'
        }).then((result) => {
            if (result.value) {
                
                // axios({
                //     method: 'post',
                //     url: USER_RESET_PASSWORD,
                //     headers: headerToken(),
                //     data: {
                //         Id: userId,
                //         UserName: username
                //     }
                // })
                axiosWrapper.axiosWithHeaders('post', USER_RESET_PASSWORD, {
                    Id: userId,
                    UserName: username
                })
                    .then(function (response) {
                        if (response.status === 200) {
                            const data = response.data;
                            if (data.message === "SUCCESS") {
                                Swal.fire("Thông báo", "Cập nhật mật khẩu thành công !", "success")
                            } else {
                                Swal.fire("Lỗi", "Cập nhật mật khẩu thất bại !", "error")
                            }
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            }
        })
    }

    celltemplate = (row, column) => {
        //console.log(row, column)
        let { level } = this.props;
        // console.log(level)
        const field = column.field;
        switch (field) {
            case 'range':
                return null;
            case 'action':
                if (level === "6") {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Button
                                icon="pi pi-pencil"
                                className="p-button-success p-button-raised"
                                style={{ marginRight: 7, width: 35 }}
                                onClick={(e, uniqueId) => this.updateUser(e, row.id)}
                            />
                            <Button
                                icon="pi pi-trash"
                                className="p-button-danger p-button-raised"
                                style={{ width: 35 }}
                                onClick={(e, uniqueId) => this.deleteUser(e, row.id)}
                            />
                        </div>
                    )
                }

                if (level === "3") {
                    return (
                        <div style={{ textAlign: "center" }}>
                            {/* <Button
                                tooltip="Đặt lại mật khẩu cho user"
                                icon="pi pi-unlock"
                                className="p-button-raised p-button-warning"
                                
                            /> */}
                            <button className='p-button p-component p-button-raised p-button-warning p-button-icon-only' onClick={(e, id, username) => {
                                    console.log(row.id, row.username)
                                    this.resetPassword(e, row.id, row.username)
                                }}>
                                <i className="pi pi-unlock" style={{fontSize: 12}}></i>
                            </button>
                        </div>
                    )
                }
                return null;
            default:
                return null;


        }
    }

    updateUser = (e, id) => {
        e.preventDefault();
        this.props.history.push('/event/detailuser/' + id)
    }

    clickImport = (e) => {
        e.preventDefault();
        this.props.history.push('/event/importuser');
    }

    onAdd = (e) => {
        e.preventDefault();
        this.props.history.push('/event/adduser');
    }

    render() {
        const loading = (
            <div className="p-col-12">
                <LoadingView />
            </div>
        )
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <Suspense fallback={loading}>
                        <Grid
                            tableId={this.state.tableId}
                            filterTitle="Tìm kiếm nâng cao"
                            editMode={this.state.editMode}
                            layoutTitle={this.state.layoutTitle}
                            celltemplate={(row, column) => this.celltemplate(row, column)}
                            clickImport={(e) => this.clickImport(e)}
                            uniqueId={this.state.userId}
                            addRow={(e) => this.onAdd(e)}
                        />
                    </Suspense>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    // console.log(state.account)
    return {
        auth: state.auth,
        events: state.events,
        account: state.account,
        level: state.account.level,
    }
}


export default connect(mapStateToProps, null)(ListUser);