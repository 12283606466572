import React, {Component} from 'react';
import {deCryptUserId} from '../../../crypto/Crypto'
import { connect } from 'react-redux';
import { 
    GET_PRODUCT,
    ADD_PRODUCT,
    UPD_PRODUCT,
    DEL_PRODUCT,
} 
from '../../../store/constants/serviceEndpoint';
import * as actions from '../../../store/actions/index';
// import * as actionTypes from '../../../store/constants/actionTypes';
import * as enums from '../../../common/enum';
import { removeToken } from '../../../crypto/Crypto'
import { headerToken } from '../../../store/constants/header';
import * as router from '../../../common/route'; 
import {DataTable} from 'primereact/datatable';
import { Button } from 'primereact/button';
import {Column} from 'primereact/column';
import {Dialog} from 'primereact/dialog';
import {Messages} from 'primereact/messages';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import Swal from 'sweetalert2/dist/sweetalert2.js'
// const Grid = lazy(() => import('../../grid/grid'));
const axios = require("axios");
// const moment = require("moment");

class ListProduct extends Component{
    constructor(props){
        super(props);
        this.state={
            listProduct:[],
            apiUpdate: ADD_PRODUCT,
            name: "",
            description: "",
            titleDialog: "",
            mode: 0,
            id: null,
            createdBy: null,
            createDate: null
        }
    }

    componentDidMount(){
        this.getListProduct();
    }

    getListProduct = () =>{
        const _this = this;
        axios({
            method: 'post',
            url: GET_PRODUCT,
            headers: headerToken(),
        }).then(function (response) {
            if(response.data.status === enums.RESPONSE_TOKEN_INVALID){
                removeToken();
                _this.props.auth_ini();
                _this.props.history.push(router.LOGIN);
            }
            if (response.data.status === enums.RESPONSE_OK) {
                const {result} = response.data;
                if(result!==undefined && result!==null && result.length > 0){
                    _this.setState({
                        listProduct: result
                    })
                }
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    addProduct = (e) =>{
        this.setState({
            visible: true, 
            titleDialog: "THÊM MỚI SẢN PHẨM",
            name: "",
            description: "",
            apiUpdate: ADD_PRODUCT,
            mode: 0
        })
    }

    updateProduct = (e, row) =>{
        this.setState({
            visible: true, 
            titleDialog: "CẬP NHẬT SẢN PHẨM",
            name: row.name,
            description: row.description,
            apiUpdate: UPD_PRODUCT,
            id: row.id,
            createdBy: row.createdBy,
            createdDate: row.createdDate,
            mode: 1
        })
    }

    deleteProduct = (e, id) => {
        const _this = this;
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa sản phẩm này không ?",
            type: 'warning',
            showCancelButton: true,
            //confirmButtonColor: '#3085d6',
            //cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý!',
            cancelButtonText: 'Trở về'
        }).then((result) => {
            if (result.value) {
                axios({
                method: 'post',
                url: DEL_PRODUCT,
                headers: headerToken(),
                data: {
                    Id: id
                }
                }).then(function (response) {
                    if(response.data.status === enums.RESPONSE_TOKEN_INVALID){
                        removeToken();
                        _this.props.auth_ini();
                        _this.props.history.push(router.LOGIN);
                    }
                    if (response.data.status === enums.RESPONSE_OK) {
                        Swal.fire("Thông báo", "Xóa sản phẩm thành công !", "success")
                        _this.resetData();
                        _this.getListProduct();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
            }
        })
    }

    updateData = (e) => {
        const _this = this;
        let objUpdate = {}
        objUpdate.Name = this.state.name;
        objUpdate.Description = this.state.description;
        const check = this.checkData(objUpdate);
        if(check){
            if(this.state.mode===0){
                objUpdate.CreatedBy = deCryptUserId();
                // objUpdate.CreatedDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            }else{
                objUpdate.CreatedBy = this.state.createdBy;
                // objUpdate.CreatedDate = moment(this.state.createdDate).format("YYYY-MM-DD HH:mm:ss");
                objUpdate.Id = this.state.id
            }

            axios({
                method: 'post',
                url: this.state.apiUpdate,
                headers: headerToken(),
                data: objUpdate
                }).then(function (response) {
                    if(response.data.status === enums.RESPONSE_TOKEN_INVALID){
                        removeToken();
                        _this.props.auth_ini();
                        _this.props.history.push(router.LOGIN);
                    }
                    if (response.data.status === enums.RESPONSE_OK) {
                        Swal.fire("Thông báo", "Cập nhật sản phẩm thành công !", "success")
                        _this.resetData();
                        _this.getListProduct();
                    }
                }).catch(function (error) {
                    console.log(error);
                })
        }
    }

    resetData = () =>{
        this.setState({
            visible: false, 
            titleDialog: "THÊM MỚI SẢN PHẨM",
            name: "",
            description: "",
            apiUpdate: ADD_PRODUCT,
            mode: 0
        })
    }

    checkData = (obj) =>{
        for(var field in obj){
            if(obj[field]===undefined||obj[field]===null||obj[field]===""||obj[field]=== 0){
                switch(field){
                    case 'Name':
                        this.messages.show({severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập tên sản phẩm !'});
                        break;
                    case 'desc':
                    case 'Description':
                        this.messages.show({severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập mô tả sản phẩm!'});
                        break;
                    default:
                        break;
                }
                return false;
            }
        }
        return true;
    }

    celltemplate = (row, column) =>{
        const field = column.field;
        const userInfo = this.props.account.userInfo;
        if(userInfo!==undefined&&userInfo!==null&&Object.keys(userInfo).length > 0){
            switch (field){
                case 'STT':
                    return (column.rowIndex + 1)
                case 'range':
                    return this.renderRank(row[field]);
                case 'action':
                    if(userInfo.level === 6){
                        return (
                            <div style={{textAlign:"center"}}>
                                <Button
                                    icon="pi pi-pencil"
                                    className="p-button-success p-button-raised"
                                    style={{ marginRight: 7, width: 35 }}
                                    onClick={(e, uniqueId) => this.updateProduct(e, row)}
                                />
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-danger p-button-raised"
                                    style={{ width: 35 }}
                                    onClick={(e,  uniqueId) => this.deleteProduct(e, row.id)}
                                />
                            </div>
                        )
                    }
                    return null;
                default:
                    return null;
            }
        }
    }

    render(){
        const footer  = (
            <div>
                <Button
                    label="Cập nhật"
                    icon="pi pi-check"
                    onClick={(e, Id) => this.updateData(e)}
                    className="p-button-raised p-button-info"
                />
                <Button 
                    label="Hủy" 
                    className="p-button-raised p-button-secondary" 
                    icon="pi pi-times" 
                    onClick={()=>this.setState({
                        visible: false,
                        name: "",
                        description: "",
                        apiUpdate: "",
                        mode: 0
                    })} 
                />
                </div>
            );
        return (
            <div className="p-grid" style={{marginTop: 20}}>
                <Dialog header={this.state.titleDialog} 
                        visible={this.state.visible} 
                        style={{width: 600}} 
                        modal={true} 
                        onHide={() => this.setState({visible: false})}
                        footer={footer}
                        >
                    <Messages ref={(el) => this.messages = el}></Messages>
                    <div className="p-grid">
                        <div className="p-col-3" style={{paddingTop: 13, textAlign: "right"}}>
                        <label>Tên sản phẩm</label>
                        </div>
                        <div className="p-col-9">
                            <InputText 
                                style={{width: '100%'}}
                                placeholder="Nhập tên sản phẩm..."
                                onChange={(e)=>this.setState({name: e.target.value})}
                                value={this.state.name}
                            />
                        </div>
                        <div className="p-col-3" style={{paddingTop: 13, textAlign: "right"}}>
                        <label>Mô tả sản phẩm</label>
                        </div>
                        <div className="p-col-9">
                            <InputTextarea 
                                rows={5} style={{width: "100%"}} 
                                value={this.state.description} 
                                onChange={(e)=>this.setState({description: e.target.value})}
                                placeholder="Nhập mô tả về sản phẩm..."
                            />
                        </div>
                    </div>
                </Dialog>
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-8">
                            <h4>DANH SÁCH SẢN PHẨM</h4>
                        </div>
                        <div className="p-col-4" style={{textAlign:"right"}}>
                            <Button
                                label="Thêm mới"
                                tooltip="Thêm mới sản phẩm"
                                icon="pi pi-plus"
                                className="p-button-raised"
                                style={{ marginRight: 5 }}
                                onClick={(e) => this.addProduct(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className="p-col-12">
                    <DataTable
                        value={this.state.listProduct}
                        ref={(el) => { this.dt = el; }}
                        emptyMessage="Chưa có sản phẩm nào được tạo. Vui lòng thêm sản phẩm"
                        paginator={true}                   
                        rowsPerPageOptions={[50, 100, 200]}
                        scrollable={true}
                        scrollHeight={"87.5vh"}
                        resizableColumns={true}
                        rows={50}
                        reorderableColumns={true}
                    >
                        <Column field="STT" header="STT" className="p-col-fixed" style={{width: 60}} body={(row, cell)=>this.celltemplate(row, cell)}/>
                        <Column field="name" header="Tên sản phẩm" style={{width: 400}}/>
                        <Column field="description" header="Mô tả sản phẩm" />
                        <Column field="action" className="p-col-fixed" style={{width: 120}} body={(row, cell)=>this.celltemplate(row, cell)}/>
                    </DataTable>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        events: state.events,
        account: state.account
    }
}

const mapDispatchToProps = dispatch => {
    return {
        auth_ini: () => dispatch(actions.auth_ini()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListProduct);