import { AUTH_CODE_GET } from '../store/constants/serviceEndpoint';
import { BROWSER_TOKEN_STORAGE, SECRET_KEY_TOKEN ,TOKEN_STORAGE,DETAIL_USER_STORAGE, ACCESSTOKEN_STORAGE} from '../store/constants/actionTypes';
import SimpleCrypto from "simple-crypto-js";
import { setMaxDigits } from './BigInt';
import { RSAKeyPair, base64encode, encryptedString } from './RSA';
var CryptoJS = require("crypto-js");

export function enCryptServer(args) {
    let browserToken = localStorage.getItem(BROWSER_TOKEN_STORAGE);
    if (browserToken) {
        let data = JSON.parse(browserToken);
        if (data["_e"] && data["_m"] && data["_c"] && data["_i"]) {
            setMaxDigits(131);
            let key = new RSAKeyPair(data["_e"], "", data["_m"]);
            let encodeArgs = "";
            for (let i = 0; i < args.length; i++) {
                encodeArgs += "\\" + base64encode(args[i]);
            }
            let posx = encryptedString(key, data["_c"] + encodeArgs);
            return { id: data["_i"], posx: posx };
        }
        else {
            return null;
        }
    }
    return null;
}

export function enCryptServerToken(args) {
    let browserToken = localStorage.getItem(BROWSER_TOKEN_STORAGE);
    if (browserToken) {
        let data = JSON.parse(browserToken);
        if (data["_e"] && data["_m"] && data["_c"] && data["_i"]) {
            setMaxDigits(131);
            let key = new RSAKeyPair(data["_e"], "", data["_m"]);
            let encodeArgs = "";
            for (let i = 0; i < args.length; i++) {
                encodeArgs += "\\" + base64encode(args[i]);
            }
            let posx = encryptedString(key, data["_c"] + encodeArgs);
            return posx
        }
        else {
            return null;
        }
    }
    return null;
}

export function getCode(id) {
    fetch(AUTH_CODE_GET, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: id })
    }).then(response => {
        return response.json();
    }).then((data) => {
        if (data) {
            let browserToken = localStorage.getItem(BROWSER_TOKEN_STORAGE);
            data["_i"] = id;
            if (browserToken) {
                let code = JSON.parse(browserToken);
                if (code["_t"]) {
                    let decipherText = deCryptLoginTokenAndUserName();
                    data["_t"] = enCryptClient(decipherText, SECRET_KEY_TOKEN);
                }
                else {
                    data["_t"] = enCryptClient(",", SECRET_KEY_TOKEN);
                }
            }
            else {
                data["_t"] = enCryptClient(",", SECRET_KEY_TOKEN);
            }
            localStorage.setItem(BROWSER_TOKEN_STORAGE, JSON.stringify(data));
            let token = deCryptLoginToken();
            var dataToken = enCryptServerToken([token]);
            localStorage.setItem(TOKEN_STORAGE, JSON.stringify(dataToken));
        }
    }).catch(err => {
        console.log(err);
    });
}
/* 
    Get CID
*/
export function getCid() {
    let browserToken = localStorage.getItem(BROWSER_TOKEN_STORAGE);
    if (browserToken) {
        let data = JSON.parse(browserToken);
        if (data["_i"]) {
            return data["_i"];
        }
        return "";
    }
    else {
        return "";
    }
}
/*REMOVE TOKEN */
export function removeToken() {
    let browserToken = localStorage.getItem(BROWSER_TOKEN_STORAGE);
    if (browserToken) {
        let data = JSON.parse(browserToken);
        if (data["_t"]) {
            data["_t"] = "";
            localStorage.setItem(BROWSER_TOKEN_STORAGE, JSON.stringify(data));
            return true;
        }
        return true;
    }
    else {
        return true;
    }
}

/* 
    Get getTokenEncrypt
*/
export function getTokenEncrypt() {
    let browserToken = localStorage.getItem(TOKEN_STORAGE);
    if (browserToken) {
        let data = JSON.parse(browserToken);
        if (data) {
            return data;
        }
        return "";
    }
    else {
        return "";
    }
}

// export function getDetailUser() {
//     let browserToken = localStorage.getItem(DETAIL_USER_STORAGE);
//     if (browserToken) {
//         let data = JSON.parse(browserToken);
//        if (data["n"] && data["p"] && data["f"] && data["l"]) {
//         return {'name':data["n"] ,'phone':data["p"], 'forceChangePassword': data["f"], 'level': data["l"]};
//     }
//         return null;
//     }
//     else {
//         return null;
//     }
// }

/**
 * encrypt and storage success info (loginToken, loginUser) by [_t] with value "[loginToken],[userName]"
 * @param {*} loginToken successful loginToken
 * @param {*} loginUser username
 */
export function updateLoginTokenEncrypt(loginToken, userName) {
    let browserToken = localStorage.getItem(BROWSER_TOKEN_STORAGE);
    if (browserToken) {
        let data = JSON.parse(browserToken);
        let auth = `${loginToken},${userName}`;
        data["_t"] = enCryptClient(auth, SECRET_KEY_TOKEN);
        var dataToken = enCryptServerToken([loginToken]);
        localStorage.setItem(BROWSER_TOKEN_STORAGE, JSON.stringify(data));
        localStorage.setItem(TOKEN_STORAGE, JSON.stringify(dataToken));
    }
}

/**
 * decrypt and get userId in localStorage
 */

export function deCryptUserId() {
    return "";
    // let browserToken = localStorage.getItem(BROWSER_TOKEN_STORAGE);
    // if (browserToken) {
    //     let data = JSON.parse(browserToken);
    //     if (data["_t"]) {
    //         let _t = deCryptClient(data["_t"], SECRET_KEY_TOKEN);
    //         let parseTokenArr = _t.split(',');
    //         return parseTokenArr[1];
    //     }
    //     return "";
    // }
    // else {
    //     return "";
    // }
}

/**
 * decrypt and get loginToken in localStorage
 */
export function deCryptLoginToken() {
    
    let browserToken = localStorage.getItem(BROWSER_TOKEN_STORAGE);
    if (browserToken) {
        let data = JSON.parse(browserToken);
        if (data["_t"]) {
            let _t = deCryptClient(data["_t"], SECRET_KEY_TOKEN);
            let parseTokenArr = _t.split(',');
            return parseTokenArr[0];
        }
        return "";
    }
    else {
        return "";
    }
}

export function getToken() {
    
    let browserToken = localStorage.getItem(ACCESSTOKEN_STORAGE);
    if (browserToken) {
        return browserToken;
    }
    else {
        return "";
    }
}


/**
 * decrypt and get userName in localStorage
 */
export function deCryptLoginTokenAndUserName() {
    let browserToken = localStorage.getItem(BROWSER_TOKEN_STORAGE);
    if (browserToken) {
        let data = JSON.parse(browserToken);
        if (data["_t"]) {
            let _t = deCryptClient(data["_t"], SECRET_KEY_TOKEN);
            let parseTokenArr = _t.split(',');
            let loginToken = parseTokenArr[0];
            let userName = parseTokenArr[1];
            return `${loginToken},${userName}`;
        }
        return ",";
    }
    else {
        return ",";
    }
}

export function enCryptClient(decipherText, _secretKey) {
    var simpleCrypto = new SimpleCrypto(_secretKey);
    return simpleCrypto.encrypt(decipherText);
}

export function deCryptClient(cipherText, _secretKey) {
    var simpleCrypto = new SimpleCrypto(_secretKey);
    return simpleCrypto.decrypt(cipherText);
}

export function encryptAES(cipherText) {
    var key = CryptoJS.enc.Utf8.parse('13383B09B7AF47C4A408834779C72712');  
    var iv = CryptoJS.enc.Utf8.parse('8080808080808080');  
    var encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(cipherText), key,  
    {
        keySize: 128 / 8,   
        iv: iv,  
        mode: CryptoJS.mode.CBC,  
        padding: CryptoJS.pad.Pkcs7 
    });  
    return encryptedlogin;
}