// import React, { Component, lazy } from 'react'
// import { TableSurvey, TableApprove } from './tableSurvey'
// import { ProgressSpinner } from 'primereact/progressspinner';
// import { deCryptUserId } from '../../../crypto/Crypto'
// import {
//     REPORT_LIST_SURVEY,
//     DASHBOARD_CHANNEL_PACKAGE,
//     DASHBOARD_CHANNEL_PACKAGE_STATUS
// }
//     from '../../../store/constants/serviceEndpoint';
// import * as actions from '../../../store/actions/index';
// import { headerToken } from '../../../store/constants/header';
// import * as actionTypes from '../../../store/constants/actionTypes';
// import * as enums from '../../../common/enum';
// import { removeToken, deCryptClient } from '../../../crypto/Crypto';
// import Swal from 'sweetalert2/dist/sweetalert2.js'
// import * as router from '../../../common/route';

// const FilterGrid = lazy(() => import('../../filters/index'));
// const axios = require("axios");

// class Dashboard extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             tableId: 7,
//             filterTitle: "TÌM KIẾM NÂNG CAO",
//             eventId: null,
//             param: [
// { "Field": "areaId", "TypeField": 8, "Value": "" },
// { "Field": "regionalTradeMarketingManagerId", "TypeField": 8, "Value": "" },
// { "Field": "areaSalesManagerId", "TypeField": 8, "Value": "" },
// { "Field": "territorySalesManagerId", "TypeField": 8, "Value": "" },
// { "Field": "saleRetailId", "TypeField": 8, "Value": "" },
// { "Field": "dateFrom", "TypeField": 12, "Value": null },
// { "Field": "dateTo", "TypeField": 12, "Value": null },
// { "Field": "storeTypeId", "TypeField": 8, "Value": "" },
// { "Field": "channelId", "TypeField": 8, "Value": "" },
// { "Field": "channelPackageId", "TypeField": 8, "Value": "" }
//             ],
//             dataSurveyAll: [],
//             dataSurveyFilterStatus: []
//         }
//     }

//     componentDidMount() {
//         let eventId = localStorage.getItem(actionTypes.STORAGE_EVENTID);
//         if ((eventId !== undefined && eventId !== null)) {
//             this.setState({
//                 eventId: eventId
//             })
//         }

//         setTimeout(() => {
//             this.GetDataChannelPackage(this.state.param);
//             this.GetDataChannelPackageStatus(this.state.param);
//         }, 500)
//         // this.GetDataChannelPackage(this.state.param);
//     }

//     GetDataChannelPackage = (paramFilter) => {

//         this.setState({
//             isLoading: true,
//             // dataSurveyAll:[]
//         })

//         let param = [];

//         let eventId = localStorage.getItem(actionTypes.STORAGE_EVENTID);
//         let storageFilter = JSON.parse(localStorage.getItem(actionTypes.STORAGE_INFO));
//         const tableId = this.state.tableId;
//         if (storageFilter !== undefined && storageFilter !== null && Object.keys(storageFilter).length > 0) {

//             let objByEventTableId = storageFilter[eventId];
//             if (objByEventTableId !== undefined && objByEventTableId !== null && Object.keys(objByEventTableId).length > 0 && objByEventTableId.hasOwnProperty(tableId)) {
//                 let dataFilter = deCryptClient(objByEventTableId[tableId], actionTypes.SECRET_KEY_TOKEN)
//                 if (JSON.parse(dataFilter) !== undefined && JSON.parse(dataFilter) !== null && Object.keys(JSON.parse(dataFilter)).length > 0) {
//                     JSON.parse(dataFilter).forEach(item => {
//                         param.push(item);
//                     });
//                 } else {
//                     return;
//                 }
//             } else {
//                 param = paramFilter
//             }
//         } else {
//             param = paramFilter
//             // return;
//         }

//         const idxUserId = param.findIndex(x => x.Field === "UserId");//this.searchIndexObjectInArray("UserId", param);
//         if (idxUserId > -1) {
//             param.splice(idxUserId, 1);
//         }

//         param.push({
//             Field: "UserId",
//             TypeField: 8,
//             Value: deCryptUserId()
//         })

//         const idxEventId = param.findIndex(x => x.Field === "EventId");//this.searchIndexObjectInArray("EventId", param);
//         if (idxEventId > -1) {
//             param.splice(idxEventId, 1);
//         }

//         param.push({
//             Field: "EventId",
//             TypeField: 8,
//             Value: this.state.eventId
//         })

//         const objRequestAll = {
//             Name: "BAO CAO",
//             StoreProcedure: "Dashboard_ListChannel",
//             Parameters: param
//         }

//         const _this = this;
//         axios({
//             method: 'post',
//             url: DASHBOARD_CHANNEL_PACKAGE,
//             headers: headerToken(),
//             data: objRequestAll
//         }).then(function (response) {
//             const { data } = response;
//             switch (data.status) {
//                 case enums.RESPONSE_OK:
//                     const { result } = data;
//                     _this.setState({
//                         isLoading: false,
//                         dataSurveyAll: result
//                     })
//                     break;
//                 default:
//                     Swal.fire("Lỗi", data.message, "error");
//                     removeToken();
//                     actions.auth_ini();
//                     _this.props.history.push(router.LOGIN);
//                     break;
//             }
//         })
//             .catch(function (error) {
//                 console.log(error);
//             })

//     }

//     GetDataChannelPackageStatus = (paramFilter) => {

//         this.setState({
//             isLoading: true,
//             // dataSurveyFilterStatus:[]
//         })

//         let param = [];

//         let eventId = localStorage.getItem(actionTypes.STORAGE_EVENTID);
//         let storageFilter = JSON.parse(localStorage.getItem(actionTypes.STORAGE_INFO));
//         const tableId = this.state.tableId;
//         if (storageFilter !== undefined && storageFilter !== null && Object.keys(storageFilter).length > 0) {
//             let objByEventTableId = storageFilter[eventId];
//             if (objByEventTableId !== undefined && objByEventTableId !== null && Object.keys(objByEventTableId).length > 0 && objByEventTableId.hasOwnProperty(tableId)) {
//                 let dataFilter = deCryptClient(objByEventTableId[tableId], actionTypes.SECRET_KEY_TOKEN)
//                 if (JSON.parse(dataFilter) !== undefined && JSON.parse(dataFilter) !== null && Object.keys(JSON.parse(dataFilter)).length > 0) {
//                     JSON.parse(dataFilter).forEach((item) => {
//                         param.push(item);
//                     })
//                 } else {
//                     return;
//                 }
//             } else {
//                 param = paramFilter
//             }
//         } else {
//             param = paramFilter
//             // return;
//         }

//         const idxUserId = param.findIndex(x => x.Field === "UserId");//this.searchIndexObjectInArray("UserId", param);
//         if (idxUserId > -1) {
//             param.splice(idxUserId, 1);
//         }

//         param.push({
//             Field: "UserId",
//             TypeField: 8,
//             Value: deCryptUserId()
//         })

//         const idxEventId = param.findIndex(x => x.Field === "EventId");//this.searchIndexObjectInArray("EventId", param);
//         if (idxEventId > -1) {
//             param.splice(idxEventId, 1);
//         }

//         param.push({
//             Field: "EventId",
//             TypeField: 8,
//             Value: eventId
//         })

//         const objRequestFilterStatus = {
//             Name: "BAO CAO",
//             StoreProcedure: "Dashboard_ListChannel_FilterStatus",
//             Parameters: param
//         }

//         const _this = this;
//         axios({
//             method: 'post',
//             url: DASHBOARD_CHANNEL_PACKAGE_STATUS,
//             headers: headerToken(),
//             data: objRequestFilterStatus
//         }).then(function (response) {
//             const { data } = response;
//             switch (data.status) {
//                 case enums.RESPONSE_OK:
//                     const { result } = data;
//                     _this.setState({
//                         isLoading: false,
//                         dataSurveyFilterStatus: result
//                     })
//                     break;
//                 default:
//                     Swal.fire("Lỗi", data.message, "error");
//                     removeToken();
//                     actions.auth_ini();
//                     _this.props.history.push(router.LOGIN);
//                     break;
//             }
//         })
//             .catch(function (error) {
//                 console.log(error);
//             })

//     }

//     getFilterParam = (e, param, reloadData) => {
//         this.GetDataChannelPackage(param);
//         this.GetDataChannelPackageStatus(param);
//     }

//     // searchIndexObjectInArray = (str, array) => {
//     //     // let idx = -1;
//     //     // if (array !== undefined && array !== null && array.length > 0) {
//     //     //     array.map((item, i)=>{
//     //     //         if(item.Field === str){
//     //     //             idx = i;
//     //     //         }
//     //     //     })
//     //     // }
//     //     // return idx;
//     //     let idx = 0;
//     //     if (array !== undefined && array !== null && array.length > 0) {
//     //         array.forEach(item => {
//     //             if(item.Field === str){
//     //               return idx;
//     //           }
//     //             idx++;
//     //         });
//     //         return -1;
//     //     }
//     //     return -1;
//     // }

//     reportParam = (e, paramReport) => {
//         const _this = this;
//         this.setState({
//             isLoading: true
//         })


//     }

//     celltemplate = (row, column) => {
//         const field = column.field;
//         switch (field) {
//             case 'nameSurvey':
//                 return (
//                     <div>
//                         {
//                             "Số " + row["packageName"].toLowerCase() + " " + row["channelName"].toLowerCase() + " đã trưng bày"
//                         }
//                     </div>
//                 )
//             case 'nameSurveyStatus':
//                 return (
//                     <div>
//                         {
//                             "Số " + row["packageName"].toLowerCase() + " " + row["channelName"].toLowerCase() + " đã chấm điểm"
//                         }
//                     </div>
//                 )
//             default:
//                 return null;
//         }
//     }

//     render() {
//         const datacontent = (this.state.isLoading) ?
//             (
//                 <div className="p-grid">
//                     <div className="p-col-12" style={{ textAlign: "center", marginTop: 80 }}>
//                         <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
//                     </div>
//                 </div>
//             ) : (
//                 <div className="p-grid">
//                     <div className="p-col-3">
//                     </div>
//                     <div className="p-col-3">
//                         <TableSurvey data={this.state.dataSurveyAll} celltemplate={(row, cell) => this.celltemplate(row, cell)} loading={this.state.isLoading} />
//                     </div>
//                     <div className="p-col-3">
//                         <TableApprove data={this.state.dataSurveyFilterStatus} celltemplate={(row, cell) => this.celltemplate(row, cell)} loading={this.state.isLoading} />
//                     </div>
//                     <div className="p-col-3">

//                     </div>
//                 </div>
//             )
//         return (
//             <div className="p-grid">
// <div className="p-col-12" style={{ paddingTop: 0 }}>
//     <div className="p-grid">
//         <div className="p-col-12">
//             <FilterGrid
//                 tableId={this.state.tableId}
//                 filterTitle={this.state.filterTitle}
//                 filterData={(e, param, reloadData) => this.getFilterParam(e, param, reloadData)}
//                 showReport={"single"}
//                 reportParam={(e, param) => this.reportParam(e, param)}
//             />
//         </div>
//     </div>
// </div>
//                 <div className="p-col-12">
//                     <div className="p-grid">
//                         <div className="p-col-12">

//                         </div>
//                     </div>
//                     {
//                         datacontent
//                     }
//                 </div>
//             </div>
//         )
//     }
// }

// export default Dashboard;

import React, { useState, useEffect, lazy } from 'react';
import * as actionTypes from '../../../store/constants/actionTypes';
import PropTypes from 'prop-types';
import { removeToken, deCryptClient } from '../../../crypto/Crypto';
import _ from 'lodash';
import { ProgressSpinner } from 'primereact/progressspinner';
import { deCryptUserId } from '../../../crypto/Crypto';
import { headerToken } from '../../../store/constants/header';
import * as axiosWrapper from '../../../store/constants/axiosWrapper';
import {
    REPORT_LIST_SURVEY,
    DASHBOARD_CHANNEL_PACKAGE,
    DASHBOARD_CHANNEL_PACKAGE_STATUS,
    DASHBOARD_STORE_RUN,
    DASHBOARD_KPI
} from '../../../store/constants/serviceEndpoint';
import * as enums from '../../../common/enum';
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import moment from 'moment';
import PieChart from '../chart/pie';
import LineChart from '../chart/line';
import StackedBar from '../chart/stackedBar';
import TableStackedBar from '../chart/tableStackBar';

const FilterGrid = lazy(() => import('../../filters/index2'));

const filterDefine = [
    {
        "id": 32,
        "field": "areaId", "title": "Vùng",
        "className": "p-col-12 p-md-12 p-lg-3", "placeHolder": "Chọn vùng", "tooltip": "Chọn vùng",
        "sqlSelect": "activity=areafilter", "type": "dropdown", "typeReturn": "string", "defaultValue": "null", "typeField": 8, "filterParams": null
    },
    { "id": 35, "field": "dateFrom-dateTo", "title": "Từ ngày - đến ngày", "className": "p-col-12 p-md-12 p-lg-6", "placeHolder": null, "tooltip": null, "sqlSelect": null, "type": "datetime", "typeReturn": "datetime", "defaultValue": '', "typeField": 4, "filterParams": null }
]

const Dashboard = ({ tableId, filterTitle, initParams, history }) => {
    // const [paramFilter, setParamFilter] = useState(initParams);
    // const [dataSurveyAll, setDataSurveyAll] = useState([]);
    const [dataSurveyFilterStatus, setDataSurveyFilterStatus] = useState([]);
    //data chart kpi
    const [data3, setData3] = useState(null);
    //data chart store run
    const [data4, setData4] = useState([]);
    const [eventId, setEventId] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);


    useEffect(() => {
        let eventId = localStorage.getItem(actionTypes.STORAGE_EVENTID);
        // console.log("eventId", eventId);
        let storageFilter = JSON.parse(localStorage.getItem(actionTypes.STORAGE_INFO));
        let filterParams = [];

        setLoading(true);
        if (eventId) {
            setEventId(parseInt(eventId));
            if (storageFilter) {
                let objByEventTableId = storageFilter[eventId];
                if (Object.keys(objByEventTableId).length > 0) {
                    let dataFilter = deCryptClient(objByEventTableId[tableId], actionTypes.SECRET_KEY_TOKEN)
                    if(typeof(dataFilter) === 'string'){
                        dataFilter = JSON.parse(dataFilter);
                    }
                    filterParams = dataFilter;
                } else {
                    filterParams = _.cloneDeep(initParams);
                }
            }
            else {
                filterParams = _.cloneDeep(initParams);
            }

            // console.log("filterParams", filterParams);
            filterParams.map((item, idx) => {
                if (item.Field === "dateFrom" || item.Field === "dateTo") {
                    item.Value = (!item.Value) ? '' : item.Value
                }
    
                if (item.Field === "areaId") {
                    item.Value = (item.Value === "") ? 0 : item.Value;
                }
            })

            Promise.all(
                [
                    // GetDataChannelPackage(eventId, initParams),
                    GetDataChannelPackageStatus(eventId, filterParams),
                    GetChartKPI(eventId, filterParams),
                    GetChartStoreRun(eventId, filterParams)
                ])
                .then(() => {
                    setLoading(false);
                })
        }




        // console.log("storageFilter", storageFilter);
        // const tableId = this.state.tableId;
        // if (storageFilter !== undefined && storageFilter !== null && Object.keys(storageFilter).length > 0) {
        //     let objByEventTableId = storageFilter[eventId];
        //     if (objByEventTableId !== undefined && objByEventTableId !== null && Object.keys(objByEventTableId).length > 0 && objByEventTableId.hasOwnProperty(tableId)) {
        //         let dataFilter = deCryptClient(objByEventTableId[tableId], actionTypes.SECRET_KEY_TOKEN)
        //         if (JSON.parse(dataFilter) !== undefined && JSON.parse(dataFilter) !== null && Object.keys(JSON.parse(dataFilter)).length > 0) {
        //             JSON.parse(dataFilter).forEach((item) => {
        //                 param.push(item);
        //             })
        //         } else {
        //             return;
        //         }
        //     } else {
        //         param = paramFilter
        //     }
        // } else {
        //     param = paramFilter
        //     // return;
        // }
        // console.log("initParams", history);
        //eslint-disable-next-line
    }, [])

    // const GetDataChannelPackage = (eventId, objFilter) => {
    //     let params = _.cloneDeep(objFilter);

    //     const idxUserId = params.findIndex(x => x.Field === "UserId");
    //     if (idxUserId > -1) {
    //         params.splice(idxUserId, 1);
    //     }

    //     params.push({
    //         Field: "UserId",
    //         TypeField: 8,
    //         Value: deCryptUserId()
    //     })

    //     const idxEventId = params.findIndex(x => x.Field === "EventId");
    //     if (idxEventId > -1) {
    //         params.splice(idxEventId, 1);
    //     }

    //     params.push({
    //         Field: "EventId",
    //         TypeField: 8,
    //         Value: eventId
    //     })

    //     const objRequestAll = {
    //         Name: "BAO CAO",
    //         StoreProcedure: "Dashboard_ListChannel",
    //         Parameters: params
    //     }

    //     setLoading1(true);

    //     axios({
    //         method: 'post',
    //         url: DASHBOARD_CHANNEL_PACKAGE,
    //         headers: headerToken(),
    //         data: objRequestAll
    //     }).then((response) => {
    //         if (response.data && response.data.status === enums.RESPONSE_OK) {
    //             const {
    //                 data: {
    //                     status,
    //                     // result,
    //                     message
    //                 }
    //             } = response;
    //             // console.log(response.data)
    //             if (status === enums.RESPONSE_OK) {
    //                 setDataSurveyAll(response.data);
    //             } else {
    //                 Swal.fire("Lỗi", message, "error");
    //             }
    //         }
    //     })
    //         .catch(function (error) {
    //             console.log(error);
    //         }).finally(() => {
    //             setLoading1(false);
    //         });
    // }

    const GetDataChannelPackageStatus = (eventId, objFilter) => {
        let params = _.cloneDeep(objFilter);

        const idxUserId = params.findIndex(x => x.Field === "UserId");
        if (idxUserId > -1) {
            params.splice(idxUserId, 1);
        }

        params.push({
            Field: "UserId",
            TypeField: 8,
            Value: deCryptUserId()
        })

        const idxEventId = params.findIndex(x => x.Field === "EventId");
        if (idxEventId > -1) {
            params.splice(idxEventId, 1);
        }

        params.push({
            Field: "EventId",
            TypeField: 8,
            Value: eventId
        })

        const objRequestFilterStatus = {
            Name: "BAO CAO",
            StoreProcedure: "Dashboard_ListChannel_FilterStatus",
            Parameters: params
        }

        setLoading2(true);

        // axios({
        //     method: 'post',
        //     url: DASHBOARD_CHANNEL_PACKAGE_STATUS,
        //     headers: headerToken(),
        //     data: objRequestFilterStatus
        // })
        axiosWrapper.axiosWithHeaders('post',DASHBOARD_CHANNEL_PACKAGE_STATUS,objRequestFilterStatus)
        .then((response) => {
            if (response.data && response.data.status === enums.RESPONSE_OK) {
                const {
                    data: {
                        status,
                        // result,
                        message
                    }
                } = response;
                if (status === enums.RESPONSE_OK) {
                    setDataSurveyFilterStatus(response.data);
                } else {
                    Swal.fire("Lỗi", message, "error");
                }
            }
        })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {
                setLoading2(false);
            });
    }

    const getFilterParam = (e, param, reloadData) => {
        let objFilter = _.cloneDeep(param);
        setLoading(true);

        objFilter.map((item, idx) => {
            if (item.Field === "dateFrom" || item.Field === "dateTo") {
                item.Value = (!item.Value) ? '' : item.Value
            }

            if (item.Field === "areaId") {
                item.Value = (item.Value === "") ? 0 : item.Value;
            }
        })

        Promise.all([
            // GetDataChannelPackage(eventId, objFilter),
            GetDataChannelPackageStatus(eventId, objFilter),
            GetChartKPI(eventId, objFilter),
            GetChartStoreRun(eventId, objFilter)])
            .then(() => {
                setLoading(false);
            })
    }

    const GetChartKPI = (eventId, objFilter) => {
        let params = _.cloneDeep(objFilter);

        const idxEventId = params.findIndex(x => x.Field === "EventId");
        if (idxEventId > -1) {
            params.splice(idxEventId, 1);
        }

        params.push({
            Field: "eventId",
            TypeField: 8,
            Value: eventId
        })

        let objParam = {};

        params.forEach((item, idx) => {
            if (item.Field === "eventId") {
                objParam[item.Field] = parseInt(item.Value);
            } else {
                objParam[item.Field] = item.Value;
                if (item.TypeField === 8) {
                    objParam[item.Field] = item.Value === "" ? 0 : parseInt(item.Value);
                }
            }
        });


        const objRequest = {
            Name: "BAO CAO",
            StoreProcedure: "Dashboard_KPI",
            Parameters: params,
            ...objParam
        }


        setLoading3(true);

        // axios({
        //     method: 'post',
        //     url: DASHBOARD_KPI,
        //     headers: headerToken(),
        //     data: objRequest
        // })
        axiosWrapper.axiosWithHeaders('post',DASHBOARD_KPI,objRequest)
        .then((response) => {
            if (response.data && response.data.status === enums.RESPONSE_OK) {
                const {
                    data: {
                        status,
                        message
                    }
                } = response;

                if (status === enums.RESPONSE_OK) {
                    setData3(response.data);
                } else {
                    Swal.fire("Lỗi", message, "error");
                }
            }
        })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {
                setLoading3(false);
            });
    }

    const GetChartStoreRun = (eventId, objFilter) => {
        let params = _.cloneDeep(objFilter);

        const idxEventId = params.findIndex(x => x.Field === "EventId");
        if (idxEventId > -1) {
            params.splice(idxEventId, 1);
        }

        params.push({
            Field: "eventId",
            TypeField: 8,
            Value: eventId
        })

        let objParam = {};

        params.forEach((item, idx) => {
            if (item.Field === "eventId") {
                objParam[item.Field] = parseInt(item.Value);
            } else {
                objParam[item.Field] = item.Value;
                if (item.TypeField === 8) {
                    objParam[item.Field] = item.Value === "" ? 0 : parseInt(item.Value);
                }
            }
        });

        const objRequest = {
            Name: "BAO CAO",
            StoreProcedure: "Dashboard_Running",
            Parameters: params,
            ...objParam
        }

        setLoading4(true);

        // axios({
        //     method: 'post',
        //     url: DASHBOARD_STORE_RUN,
        //     headers: headerToken(),
        //     data: objRequest
        // })
        axiosWrapper.axiosWithHeaders('post',DASHBOARD_STORE_RUN,objRequest)
        .then((response) => {
            if (response.data && response.data.status === enums.RESPONSE_OK) {
                const {
                    data: {
                        status,
                        // result,
                        message
                    }
                } = response;

                if (status === enums.RESPONSE_OK) {
                    setData4(response.data);
                } else {
                    Swal.fire("Lỗi", message, "error");
                }
            }
        })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {
                setLoading4(false);
            });
    }

    //tình trạng dạng stack bar chart

    //kpi dạng pie

    //tiến độ dạng line
    const reportParam = (e, param) => {
        setLoading(true);
        const objFilter = _.cloneDeep(param);

        const idxUserId = objFilter.findIndex(x => x.Field === "UserId");//this.searchIndexObjectInArray("UserId", paramReport);
        if (idxUserId > -1) {
            objFilter.splice(idxUserId, 1);
        }

        objFilter.push({
            Field: "UserId",
            TypeField: 8,
            Value: deCryptUserId()
        })

        const idxEventId = objFilter.findIndex(x => x.Field === "EventId");//this.searchIndexObjectInArray("EventId", paramReport);
        if (idxEventId > -1) {
            objFilter.splice(idxEventId, 1);
        }

        objFilter.push({
            Field: "EventId",
            TypeField: 8,
            Value: eventId
        })

        const objRequest = {
            Name: "BAO CAO",
            StoreProcedure: "Report_Store_Channel_Package",
            Parameters: objFilter
        }

        // axios({
        //     method: 'post',
        //     url: REPORT_LIST_SURVEY,
        //     headers: headerToken(),
        //     data: objRequest,
        //     responseType: 'blob'
        // })
        axiosWrapper.axiosWithHeadersDownload('post',REPORT_LIST_SURVEY,objRequest)
        .then(function (response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Report.xlsx`);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            Swal.fire('Thông báo', 'Tải báo cáo thành công!', 'success');
            setLoading(false);
        }).catch(err => console.log(err));
    }


    return (
        <>
            <div className='p-grid'>
                <div className="p-col-12" style={{ paddingTop: 0 }}>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <FilterGrid
                                tableId={tableId}
                                filterTitle={filterTitle}
                                filters={filterDefine}
                                filterData={(e, param, reloadData) => getFilterParam(e, param, reloadData)}
                                showReport={"single"}
                                reportParam={(e, param) => reportParam(e, param)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col-9'>
                    <PieChart initData={data3} loadingAll={loading} loading={loading3} />
                </div>
            </div>
            <div className='row justify-content-center' style={{ marginTop: 20 }}>
                <div className='col-9'>
                    <div className='row'>
                        <div className='col-7'>
                            <LineChart initData={data4} loadingAll={loading} loading={loading4} />
                        </div>
                        <div className='col-5'>
                            <StackedBar initData={dataSurveyFilterStatus} loadingAll={loading} loading={loading2} />
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: 20 }}>
                        <div className='col-12'>
                            <TableStackedBar initData={dataSurveyFilterStatus} loadingAll={loading} loading={loading2} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

Dashboard.propTypes = {
    tableId: PropTypes.number,
    filterTitle: PropTypes.string,
    // eventId: PropTypes.number,
    initParams: PropTypes.array,
    // dataSurveyAll: PropTypes.array,
    // dataSurveyFilterStatus: PropTypes.array,
}

Dashboard.defaultProps = {
    tableId: 999,
    filterTitle: "TÌM KIẾM NÂNG CAO",
    initParams: [
        {
            Field: "areaId",
            TypeField: 8,
            Value: ""
        },
        {
            Field: "dateFrom",
            TypeField: 12,
            Value: ''
        },
        {
            Field: "dateTo",
            TypeField: 12,
            Value: ''
        }
    ]
}

export default Dashboard;