import * as actionTypes from '../constants/actionTypes';

const getEventId = () =>{
    let eventId = localStorage.getItem(actionTypes.STORAGE_EVENTID);
    if(eventId){
        return eventId;
    }
    return null
}

const initialState = {
    eventId: getEventId(),
    message: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EVENT_INI:
            return state;
        case actionTypes.EVENT_SET:
            return {
                ...state,
                eventId: action.event
            }
        default:
            return state;
    }
}

export default reducer;