import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import { TabView,TabPanel } from 'primereact/tabview';
import ImportUser from '../user/importuser';
import ImportStore from '../store/importstore';
import Download from './download';


const Import = props => {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div style={{paddingTop: 30}}>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="CỬA HÀNG">
                    <ImportStore />
                </TabPanel>
                <TabPanel header="IMPORT KHÁC">
                    <ImportUser />
                </TabPanel> 
                <TabPanel header="DOWNLOAD">
                    <Download />
                </TabPanel>                   
            </TabView>
        </div>
    );
}

export default Import;