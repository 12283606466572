import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Checkbox} from 'primereact/checkbox';
import { Button } from "primereact/button";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Dialog } from "primereact/dialog";
import {Dropdown} from 'primereact/dropdown';
import { GET_IMAGES_EVENTS, 
         DEL_CHANNELS_IMAGES, 
         GET_CHANNELS, 
         GET_EVENT_LIST_IMAGE,
         ADD_CHANNELS_IMAGES,
         UPDATE_CHANNELS_IMAGES
        } from '../../../store/constants/serviceEndpoint';
import { headerToken } from '../../../store/constants/header'; 
import { withRouter } from 'react-router-dom'
import { axiosWithHeaders } from '../../../store/constants/axiosWrapper';
const axios = require("axios");
class ImageWithChannel extends Component{
    constructor(props){
        super(props);
        this.state={
            listImage:[],
            dataChannel:[],
            dataChannelImage: [],
            isRequire: false,
            Name: "",
            Description:"",
            mode: 0,
            ChannelId: null,
            EventImageId: null,
            apiUpdate: ADD_CHANNELS_IMAGES
        }
        this.UpdateData = this.UpdateData.bind(this);
    }

    componentDidMount(){
        const eventId = this.props.match.params.id;
        if(eventId !== undefined && eventId!==null ){
            this.GetImageByEvent(eventId);
            this.GetListChannel(eventId);
            this.GetListImageByEvent(eventId);
        }
    }

    GetListChannel = (eventId) =>{
        const _this = this;
        // axios({
        //     method: 'get',
        //     url: GET_CHANNELS,
        //     headers: headerToken(),
        //     params: {
        //     id: eventId
        //     }
        // })
        axiosWithHeaders("get", GET_CHANNELS, {id: eventId})
        .then(function (response) {
            if (response.status === 200) {
            const data = response.data;
            const result = data.result;
            let arr = [];
            result.map((item, i)=>{
                let obj = {}
                obj["value"] = item.id;
                obj["label"] = item.name;
                arr.push(obj);
            })
            _this.setState({
                dataChannel: arr,
                eventId: eventId
            })
            }
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    celltemplate = (row, column) =>{
        const field = column.field;
        switch (field){
            case 'isRequire':
                return (<Checkbox checked={row["isRequire"]} disabled></Checkbox>)
            default:
                return (
                    <div style={{ width: '100%', display: "inline-block", textAlign: "center" }}>
                        <Button
                        icon="pi pi-pencil"
                        className="p-button-success p-button-raised"
                        style={{ marginRight: 7, width: 35 }}
                        onClick={(e, uniqueId) => this.updateItem(e, row)}
                        />
                        <Button
                        icon="pi pi-trash"
                        className="p-button-danger p-button-raised"
                        style={{ width: 35 }}
                        onClick={(e, uniqueId) => this.deleteItem(e, row.id)}
                        disabled={(row.id!==null)?false : true}
                        />
                    </div>
                );
        }
    }

    UpdateData(e){
        const _this = this;
        let objUpdate = {};
        let state = this.state;
        objUpdate["ChannelId"] = state.ChannelId;
        objUpdate["EventImageId"] = state.EventImageId;
        objUpdate["IsRequied"] = (state.isRequire===false)? 0 : 1;
        
        if(state.mode===1){
            objUpdate["id"] = state.id;
        }

        // axios({
        //     method: 'post',
        //     url: _this.state.apiUpdate,
        //     headers: headerToken(),
        //     data: objUpdate
        // })
        axiosWithHeaders("post", _this.state.apiUpdate, objUpdate)
        .then(function (response) {
            if (response.status === 200) {
            const data = response.data;
                if(data.message==="SUCCESS"){
                    Swal.fire("Thông báo", "Cập nhật cài đặt thành công !", "success")
                }else{
                    Swal.fire("Lỗi", "Cập nhật cài đặt thất bại !", "error")
                }
                _this.GetImageByEvent(_this.state.eventId);
                _this.setState({
                    visible: false,
                    ChannelId: null,
                    EventImageId: null,
                    mode: 0,
                    apiUpdate: ""
                })
            }
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    updateItem = (e, row)=>{
        let state = this.state;
        state["EventImageId"] = row.eventImageId;
        state["ChannelId"] = row.channelId;
        state["isRequire"] = row.isRequire;
        state["visible"] = true;
        if(row.id === null){
            state["apiUpdate"] = ADD_CHANNELS_IMAGES;
            state["mode"] = 0;
        }else{
            state["apiUpdate"] = UPDATE_CHANNELS_IMAGES;
            state["mode"] = 1;
            state["id"] = row.id;
        }
        this.setState(state);
    }

    onCancel = (e) =>{
        // e.preventDefault();
        this.setState({
            visible: false,
            ChannelId: null,
            EventImageId: null,
            mode: 0,
            apiUpdate: ""
        })
    }

    deleteItem = (e, id)=>{
        Swal.fire({
            title: 'Thông báo',
            text: "Bạn có muốn xóa cài đặt này không ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý!',
            cancelButtonText: 'Trở về'
            }).then((result) => {
            if (result.value) {
                const _this = this;
                // axios({
                //     method: 'post',
                //     url: DEL_CHANNELS_IMAGES,
                //     headers: headerToken(),
                //     data: {
                //         Id: id
                //     }
                // })
                axiosWithHeaders("post", DEL_CHANNELS_IMAGES, {Id: id})
                .then(function (response) {
                    if (response.status === 200) {
                        const data = response.data;
                        if(data.message==="SUCCESS"){
                            Swal.fire("Thông báo", "Xoá cài đặt thành công !", "success")
                        }else{
                            Swal.fire("Lỗi", "Xóa cài đặt thất bại !", "error")
                        }
                        _this.GetImageByEvent(_this.state.eventId);
                        }
                    })
                .catch(function (error) {
                    console.log(error);
                })
                }
            })
    }

    GetImageByEvent = (eventId) =>{
        const _this = this;
        // axios({
        //     method: 'post',
        //     url: GET_IMAGES_EVENTS,
        //     headers: headerToken(),
        //     params: {
        //         eventId: eventId
        //     }
        // })
        axiosWithHeaders("post", GET_IMAGES_EVENTS, {eventId: eventId})
        .then(function (response) {
            if (response.status === 200) {
                const data = response.data;
                const result = data.result;
                _this.setState({
                    dataChannelImage: result,
                    eventId: eventId
                })
            }
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    GetListImageByEvent = (eventId) =>{
        const _this = this;
        // axios({
        //     method: 'get',
        //     url: GET_EVENT_LIST_IMAGE,
        //     headers: headerToken(),
        //     params: {
        //     id: eventId
        //     }
        // })
        axiosWithHeaders("get", GET_EVENT_LIST_IMAGE, {id: eventId})
        .then(function (response) {
            if (response.status === 200) {
                const data = response.data;
                const result = data.result;
                let arr = []
                result.map((item, i)=>{
                    let obj = {}
                    obj["value"] = item.id;
                    obj["label"] = item.name;
                    arr.push(obj);
                })

                _this.setState({
                    listImage: arr,
                    eventId: eventId
                })
            }
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    render(){
        const footer = (
            <div>
              <Button
                label="Cập nhật"
                icon="pi pi-check"
                onClick={() => this.UpdateData()}
                className="p-button-raised"
              />
              <Button 
                label="Hủy" 
                icon="pi pi-times" 
                onClick={(e)=>this.onCancel(e)}
                className="p-button-raised p-button-secondary"
              />
            </div>
          )

        return(
            <div className="p-grid">
                <Dialog
                header="Cập nhật hình theo kênh"
                footer={footer}
                visible={this.state.visible}
                style={{ width: "600px" }}
                modal={true}
                onHide={(e)=>this.onCancel(e)}
                >
                    <div className="p-grid" style={{ marginTop:5 }}>
                        <div className="p-col-4" style={{paddingBottom: 0, textAlign:"right"}}>
                            <label>Kênh</label>
                        </div>
                        <div className="p-col-6" style={{paddingTop: 0}}>
                            <Dropdown 
                                value={this.state.ChannelId} 
                                options={this.state.dataChannel} 
                                onChange={(e) => {
                                    this.setState({ChannelId: e.value})
                                }} 
                                filter={true} 
                                filterPlaceholder="Tìm kênh" 
                                filterBy="label,value" 
                                placeholder="Chọn kênh"
                                disabled
                            />
                        </div>
                    </div>
                    <div className="p-grid" style={{ marginTop:5 }}>
                        <div className="p-col-4" style={{paddingBottom: 0, textAlign:"right"}}>
                            <label>Loại hình</label>
                        </div>
                        <div className="p-col-6" style={{paddingTop: 0}}>
                            <Dropdown 
                                value={this.state.EventImageId} 
                                options={this.state.listImage} 
                                onChange={(e) => {this.setState({EventImageId: e.value})}} 
                                filter={true} 
                                filterPlaceholder="Tìm hình" 
                                filterBy="label,value" 
                                placeholder="Chọn loại hình"
                                disabled
                            />
                        </div>
                    </div>
                    <div className="p-grid" style={{ marginTop:5 }}>
                        <div className="p-col-4" style={{paddingBottom: 0, textAlign:"right"}}>
                        </div>
                        <div className="p-col-6" style={{paddingTop: 0}}>
                            <Checkbox checked={this.state.isRequire} inputId="cb2" onChange={e => this.setState({isRequire: e.checked})}></Checkbox>
                            <label htmlFor="cb2" className="p-checkbox-label">Bắt buộc ?</label>
                        </div>
                    </div>
                </Dialog>
                <DataTable value={this.state.dataChannelImage} rowGroupMode="rowspan" sortField="channelName" sortOrder={1} groupField="channelName"
                    style={{marginTop:'30px'}}>    
                    <Column field="channelName" header="Kênh" />       
                    <Column field="nameImage" header="Tên hình" />
                    <Column field="isRequire" header="Bắt buộc" body={(row, column)=>this.celltemplate(row, column)} style={{textAlign:"center"}}/>
                    <Column 
                        body={(row, column)=>this.celltemplate(row, column)} 
                        style={{ width: 120 }}
                        className={"p-col-fixed"}
                        />
                </DataTable>
            </div>
        )
    }
}

export default withRouter(ImageWithChannel);