import * as types from '../constants/actionTypes';
import {
    GET_SETTINGGRID,
    GET_DATAGRID,
    GET_COLUMNGRID
} from '../constants/serviceEndpoint';
import {
    headerToken,
} from '../constants/header';

import {
    deCryptUserId
} from '../../crypto/Crypto'

import { confirmDialog } from 'primereact/confirmdialog';
import * as enums from '../../common/enum';
import { axiosWithHeaders } from '../constants/axiosWrapper';

const axios = require("axios");
const moment = require("moment");

export function gridInit() {
    return {
        type: types.INIT_GRID
    }
}

export function gridUpdateLoading(loading) {
    return {
        type: types.UPDATE_GRID_LOADING,
        loading
    }
}

export function gridUpdateSetting(gridSetting) {
    return {
        type: types.UPDATE_GRID_SETTING,
        gridSetting
    }
}

export function gridUpdateColumns(columns) {
    return {
        type: types.UPDATE_GRID_COLUMNS,
        columns
    }
}

export function gridUpdateLoadingData(loadingData) {
    return {
        type: types.UPDATE_GRID_LOADING_DATA,
        loadingData
    }
}

export function gridUpdateLoadingColumns(loadingColumns) {
    return {
        type: types.UPDATE_GRID_LOADING_COLUMN,
        loadingColumns
    }
}

export function gridUpdateData(data, first, totalRecords, page, rows) {
    return {
        type: types.UPDATE_GRID_DATA,
        data,
        first,
        totalRecords,
        page,
        rows
    }
}


export function getSettingGrid(gridId, gridTitle, eventId, userId) {
    return async dispatch => {
        dispatch(gridUpdateLoading(true));
        
        axiosWithHeaders("get", GET_SETTINGGRID, {
            TableId: gridId,
            UserId: deCryptUserId()
        })
            .then(function (response) {
                const {
                    data
                } = response;

                if (data) {
                    if (data.status === enums.RESPONSE_OK) {
                        if (data.result !== undefined && data.result !== null) {
                            dispatch(gridUpdateSetting(data.result[0]));
                            dispatch(gridUpdateLoading(true));
                            dispatch(getDataGrid([], 0, 50, data.result[0], parseInt(eventId), userId))
                        }
                    }
                } else {
                    confirmDialog({
                        message: `Không thể lấy thông tin lưới từ database !`,
                        header: `${gridTitle}`,
                        icon: 'pi pi-exclamation-triangle',
                        rejectClassName: "d-none",
                        accept: () => {
                            return;
                        },
                    });
                    return;
                }
            })
            .catch(function (error) {
                confirmDialog({
                    message: `Lỗi cài đặt lưới !. ${error.toString()}`,
                    header: `Lỗi`,
                    icon: 'pi pi-exclamation-triangle',
                    rejectClassName: "d-none",
                    accept: () => {
                        return;
                    },
                });
                return;
            })
            .finally(() => {
                dispatch(gridUpdateLoading(false));
            })
    }
}

export function getColumnGrid(gridId, gridTitle) {
    return async dispatch => {
        dispatch(gridUpdateLoadingColumns(true));
        axios({
            method: 'get',
            url: GET_COLUMNGRID,
            headers: headerToken(),
            params: {
                TableId: gridId,
                UserId: deCryptUserId()
            }
        })
            .then(function (response) {
                const {
                    data
                } = response;
                if (data) {
                    if (data.status === enums.RESPONSE_OK) {
                        if (data.result) {
                            dispatch(gridUpdateColumns(data.result))
                        }
                    }
                } else {
                    confirmDialog({
                        message: `Không thể lấy danh sách cột từ database !`,
                        header: `${gridTitle}`,
                        icon: 'pi pi-exclamation-triangle',
                        rejectClassName: "d-none",
                        accept: () => {
                            return;
                        },
                    });
                    return;
                }
            })
            .catch(function (error) {
                confirmDialog({
                    message: `Lỗi lấy danh sách cột của lưới !. ${error.toString()}`,
                    header: `Lỗi`,
                    icon: 'pi pi-exclamation-triangle',
                    rejectClassName: "d-none",
                    accept: () => {
                        return;
                    },
                });
                return;
            })
            .finally(() => {
                dispatch(gridUpdateLoadingColumns(false));
            })
    }
}

export function getDataGrid(param, page, rows, gridSetting, eventId, userId) {
    return async dispatch => {
        let listParam = [...param];

        const idxPageNo = listParam.findIndex(x => x.Field === "PageNo");
        if (idxPageNo > -1) {
            listParam.splice(idxPageNo, 1);
        }

        listParam.push({
            Field: "PageNo",
            TypeField: 8,
            Value: page + 1
        })

        const idxRows = listParam.findIndex(x => x.Field === "Rows");
        if (idxRows > -1) {
            listParam.splice(idxRows, 1);
        }

        listParam.push({
            Field: "Rows",
            TypeField: 8,
            Value: rows
        })

        const idxUserId = listParam.findIndex(x => x.Field === "UserId");

        if (idxUserId > -1) {
            listParam.splice(idxUserId, 1);
        }

        listParam.push({
            Field: "UserId",
            TypeField: 8,
            Value: userId
        })

        if (eventId) {
            const idxEventId = listParam.findIndex(x => x.Field === "EventId");
            if (idxEventId > -1) {
                listParam.splice(idxEventId, 1);
            }

            listParam.push({
                Field: "EventId",
                TypeField: 8,
                Value: eventId
            })
        }

        listParam.forEach((item) => {
            const {
                TypeField
            } = item;

            if (TypeField === 12) {
                item.Value = (moment(item.Value).isValid()) ? moment(item.Value).format("YYYYMMDD") : item.Value;
            }
        })

        const objRequest = {
            Data: {
                Name: gridSetting.gridTitle,
                StoreProcedure: gridSetting.sqlQuery,
                Parameters: listParam
            }
        }

        dispatch(gridUpdateLoadingData(true));

        axios({
            method: 'post',
            url: GET_DATAGRID,
            headers: headerToken(),
            data: objRequest
        }).then(function (response) {
            const { data } = response;
            if (data.status === enums.RESPONSE_OK) {
                if (data.result) {
                    const firstRow = { ...data.result[0] }
                    dispatch(gridUpdateData(data.result, page * rows, firstRow.countRow, page, rows));
                }
            } else {
                confirmDialog({
                    message: `Lỗi!. Mã lỗi ${data.status} ${data.message}`,
                    header: `${gridSetting.gridTitle}`,
                    icon: 'pi pi-exclamation-triangle',
                    rejectClassName: "d-none",
                    accept: () => {
                        return;
                    },
                });
                return;
            }
        })
            .catch(function (error) {
                confirmDialog({
                    message: `Lỗi tải dữ liệu lưới !. ${error.toString()}`,
                    header: `${gridSetting.gridTitle}`,
                    icon: 'pi pi-exclamation-triangle',
                    rejectClassName: "d-none",
                    accept: () => {
                        return;
                    },
                });
                return;
            })
            .finally(() => {
                dispatch(gridUpdateLoadingData(false));
            })
    }
}