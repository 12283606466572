import React, {Component} from 'react';
import { connect } from 'react-redux'
import {Card} from 'primereact/card';
import Maps from '../maps/maps';
import {Button} from 'primereact/button';
// import * as actions from '../../../store/actions/index';

class StoreInfo extends Component{

    templateRank(value) {
        switch (value) {
            case 1:
                return (
                    <div>
                        <img src={require('../assets/img/web-09.png')} style={{ height: 30, objectFit: 'contain' }} alt="gold"/> Vàng
                    </div>
                    )
                //break;
            case 2:
                return (
                    <div>
                        <img src={require('../assets/img/web-10.png')} style={{ height: 30, objectFit: 'contain' }} alt="silver"/> Bạc
                    </div>
                    )
                //break;
            case 3:
                return (
                    <div>
                        <img src={require('../assets/img/web-11.png')} style={{ height: 30, objectFit: 'contain' }} alt=""/> Đồng
                    </div>
                    )
                //break;
            default:
                return '';
                // break;
        }
    }

    renderApproveStatus(ID) {
        switch (ID) {
            case 4:
                return (
                    <div style={{marginTop: -2}}>
                        <img src={require('../assets/img/cancel.svg')} style={{ height: 30, objectFit: 'contain', paddingRight: 15 }} alt="Không đạt"/>
                        <span style={{ fontWeight: 'bolder', color: '#BF1E2E' }}>Không đạt</span>
                    </div>
                )
            case 2:
                return (
                    <div style={{marginTop: -2}}>
                        <img src={require('../assets/img/pending.svg')} style={{ height: 30, objectFit: 'contain', paddingRight: 15 }} alt="Đang chờ"/>
                        <span style={{ fontWeight: 'bolder', color: '#BF1E2E' }}>Đang chờ</span>
                    </div>
                )
            case 3:
                return (
                    <div style={{marginTop: -2}}>
                        <img src={require('../assets/img/approve.svg')} style={{ height: 30, objectFit: 'contain', paddingRight: 15 }} alt="Đạt"/>
                        <span style={{ fontWeight: 'bolder', color: '#BF1E2E' }}>Đạt</span>
                    </div>
                )            
            default:
                return '';
                // break;
        }
    }

    render(){
        const props = this.props;
        const infoUser = this.props.account.userInfo;
        return (
            <Card style={{width:"100%"}}>
                <div className="p-grid">
                    <div className="p-xl-9 p-lg-9 p-md-12 p-col-12" style={{textAlign:"center", paddingTop: 15}}>
                        <h4 className="card-title">THÔNG TIN CỬA HÀNG</h4>
                    </div>
                    <div className="p-xl-3 p-lg-3 p-md-12 p-col-12" style={{textAlign:"right"}}>
                        {
                            infoUser.level=== "6" && (props.showRemove === true) && (
                                <Button label="Xóa toàn bộ suất" className="p-button-raised p-button-danger" icon="pi pi-trash" onClick={()=>this.props.deleteAllChannel()}/>
                            )
                        }
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-xl-8 p-lg-8 p-md-12 p-col-12" style={{paddingTop: 15}}>
                        <div className="p-grid">
                            <div className="p-xl-3 p-lg-3 p-md-5 p-col-5" style={{textAlign:"right"}}>
                                <label>Code SFA : </label>
                            </div>
                            <div className="p-xl-6 p-lg-3 p-md-7 p-col-7">
                                <label className="card-title">{props.data.code}</label>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-xl-3 p-lg-3 p-md-5 p-col-5" style={{textAlign:"right"}}>
                                <label>Tên cửa hàng : </label>
                            </div>                                
                            <div className="p-xl-6 p-lg-3 p-md-7 p-col-7">
                                <label style={{fontWeight:"bold"}}>{props.data.storeName}</label>
                            </div>                                
                        </div>
                        <div className="p-grid">
                            <div className="p-xl-3 p-lg-3 p-md-5 p-col-5" style={{textAlign:"right"}}>
                                <label>Tên liên hệ : </label>
                            </div>
                            <div className="p-xl-6 p-lg-3 p-md-7 p-col-7">
                                <label>{props.data.ownerName}</label> - <label>{props.data.phoneNumber}</label>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-xl-3 p-lg-3 p-md-5 p-col-5" style={{textAlign:"right"}}>
                                <label>Hạng : </label>
                            </div>
                            <div className="p-xl-6 p-lg-3 p-md-7 p-col-7">
                            {
                                this.templateRank(props.data.range)
                            }
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-xl-3 p-lg-3 p-md-5 p-col-5" style={{textAlign:"right"}}>
                                <label>RTMM : </label>
                            </div>
                            <div className="p-xl-6 p-lg-3 p-md-7 p-col-7">
                                <label>{props.data.infoRTMM}</label>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-xl-3 p-lg-3 p-md-5 p-col-5" style={{textAlign:"right"}}>
                                <label>ASM : </label>
                            </div>
                            <div className="p-xl-6 p-lg-3 p-md-7 p-col-7">
                                <label>{props.data.infoASM}</label>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-xl-3 p-lg-3 p-md-5 p-col-5" style={{textAlign:"right"}}>
                                <label>TSM : </label>
                            </div>
                            <div className="p-xl-6 p-lg-3 p-md-7 p-col-7">
                                <label>{props.data.infoTSM}</label>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-xl-3 p-lg-3 p-md-5 p-col-5" style={{textAlign:"right"}}>
                                <label>SR : </label>
                            </div>
                            <div className="p-xl-6 p-lg-3 p-md-7 p-col-7">
                                <label>{props.data.infoSR}</label>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-xl-3 p-lg-3 p-md-5 p-col-5" style={{textAlign:"right"}}>
                                <label>NPP : </label>
                            </div>
                            <div className="p-xl-6 p-lg-3 p-md-7 p-col-7">
                                <label>{props.data.supplier}</label>
                            </div>
                        </div>
                    </div>
                    <div className="p-xl-4 p-lg-4 p-md-12 p-col-12" style={{textAlign:"right"}}>
                        <div className="p-grid">
                            <div className="p-xl-6 p-lg-3 p-md-5 p-col-5" style={{textAlign:"right"}}>
                                <label>Trạng thái duyệt</label>
                            </div>
                            <div className="p-xl-6 p-lg-3 p-md-7 p-col-7">
                                {
                                    this.renderApproveStatus(props.data.filterStatus)
                                }
                            </div>
                            <div className="p-xl-12 p-lg-12 p-md-12 p-col-12">
                                <Maps lat={props.data.lat} long={props.data.long} mapHeight={200}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        )
    }

}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        events: state.events,
        account: state.account
    }
}


export default connect(mapStateToProps, null)(StoreInfo);