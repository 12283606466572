import * as types from "../constants/actionTypes";
import {
//   USER_LOGIN,
//   USER_CHECK_TOKEN,
//   USER_GET_INFO_ADMIN,
  USER_LOGIN_JWT,
} from "../constants/serviceEndpoint";
import {
//   enCryptServer,
//   updateLoginTokenEncrypt,
//   // deCryptClient,
//   enCryptClient,
  encryptAES,
} from "../../crypto/Crypto";
// import { confirmDialog } from "primereact/confirmdialog";
import jwt_decode from "jwt-decode";
import { axiosWithHeadersNoToken } from "../constants/axiosWrapper";
import * as e from "../../common/enum";

export const auth_ini = () => {
  return {
    type: types.AUTH_INI,
  };
};

export const auth_success = (accessToken, refreshToken) => {
  return {
    type: types.AUTH_SUCCESS,
    accessToken,
    refreshToken,
  };
};

export const auth_change_password = (isChangedPassword) => {
  return {
    type: types.CHANGE_PASSWORD_STORAGE,
    isChangedPassword,
  };
};

export const auth_loading = (loading) => {
  return {
    type: types.AUTH_LOADING,
    loading,
  };
};

export const auth_fail = (message) => {
  return {
    type: types.AUTH_FAIL,
    message,
  };
};

export const account_set = (user) => {
  return {
      type: types.ACCOUNT_SET,
      user
  }
}

// function getUserInfo(id) {
//     return dispatch => {
//         var deCode = enCryptServer([id.toString()]);
//         fetch(`${USER_GET_INFO_ADMIN}`, {
//             method: 'POST',
//             headers: { "Content-Type": "application/json" },
//             body: JSON.stringify(deCode)
//         }).then((response) => response.json())
//             .then((data) => {
//                 if (data.status && data.status === 200) {
//                     dispatch(account_set(data.result));
//                     localStorage.setItem(types.CACHE_USER_INFO, enCryptClient(JSON.stringify(data.result), types.SECRET_KEY_TOKEN))
//                 }
//             }).catch(err => {
//                 confirmDialog({
//                     message: `Không thể lấy thông tin user ! ${err.toString()}, dòng 66`,
//                     header: 'Thông tin user',
//                     icon: 'pi pi-exclamation-triangle',
//                     rejectClassName: "d-none",
//                     accept: () =>{
//                         return;
//                     },
//                 });
//                 return;
//             });
//     }
// }

export function login(username, password, func) {
  return async (dispatch) => {
    var objUser = { UserName: username, Password: `${encryptAES(password)}` };
    axiosWithHeadersNoToken("POST", USER_LOGIN_JWT, objUser)
      .then((response) => {
        // console.log(response)
        if (response.data && response.status === e.RESPONSE_OK) {
          const {
            accesstoken,
            refreshtoken,
            forceChangePassword,
            // uploadTime,
            status,
            message,
          } = response.data;
          if (status === e.RESPONSE_OK) {
            dispatch(auth_change_password(!forceChangePassword));
            dispatch(auth_success(accesstoken, refreshtoken));
            localStorage.setItem(
              types.ACCESSTOKEN_STORAGE,
              accesstoken
            );

            console.log(jwt_decode(accesstoken));

            localStorage.setItem(
              types.BROWSER_TOKEN_STORAGE,
              refreshtoken
            );
            localStorage.setItem(
              types.CHANGE_PASSWORD_STORAGE,
              !forceChangePassword
            );

            if(typeof func === "function"){
              func(!forceChangePassword);
            }            
            var decodedHeader =  jwt_decode(accesstoken);
            dispatch(account_set(decodedHeader));
          } else {
            alert(message);
            dispatch(auth_ini());
          }
        }
      })
      .catch((error) => {
        alert(error);
      });
    // fetch(USER_LOGIN_JWT, {
    //     method: 'POST',
    //     headers: headers,
    //     body: JSON.stringify(objUser)
    // }).then((response) => response.json())
    //     .then((data) => {
    //         if (data.status && data.status === 200) {
    //            //console.log(data)
    //             const {accesstoken, refreshtoken,forceChangePassword} = data
    //             localStorage.setItem(types.CHANGE_PASSWORD_STORAGE, forceChangePassword);
    //             localStorage.setItem(types.ACCESSTOKEN_STORAGE, accesstoken);
    //             localStorage.setItem(types.REFRESHTOKEN_STORAGE,refreshtoken);
    //            // updateLoginTokenEncrypt(data.data.token, data.data.id);
    //             dispatch(account_change());
    //             dispatch(auth_success(accesstoken));
    //             var decodedHeader =  jwt_decode(accesstoken);
    //            // console.log(decodedHeader)
    //             dispatch(account_set(decodedHeader));
    //             localStorage.setItem(types.CACHE_USER_INFO, enCryptClient(JSON.stringify(decodedHeader), types.SECRET_KEY_TOKEN))
    //             // localStorage.setItem(types.DETAIL_USER_STORAGE,JSON.stringify(
    //             //     {
    //             //         "n": data.data.name,
    //             //         "p": data.data.phone==="" ? "": data.data.phone,
    //             //         'f': data.data.forceChangePassword,
    //             //         'l': data.data.level
    //             //     })
    //             // );
    //             // dispatch(getUserInfo(data.data.id));
    //         } else {
    //             confirmDialog({
    //                 message: `${data.message} ! Mã lỗi ${data.status}`,
    //                 header: 'Lỗi',
    //                 icon: 'pi pi-exclamation-triangle',
    //                 rejectClassName: "d-none",
    //                 accept: () =>{
    //                     return;
    //                 },
    //             });
    //             dispatch(auth_ini());
    //             return;
    //         }
    //     }).catch(err => {
    //         confirmDialog({
    //             message: `Đăng nhập thất bại ! ${err.toString()}`,
    //             header: 'Lỗi',
    //             icon: 'pi pi-exclamation-triangle',
    //             rejectClassName: "d-none",
    //             accept: () =>{
    //                 return;
    //             },
    //         });
    //         return;
    //     });
  };
}

export function logout() {
  console.log("logout");
  // localStorage.removeItem(types.DETAIL_USER_STORAGE);
  // localStorage.removeItem(types.ACCESSTOKEN_STORAGE);
  // localStorage.removeItem(types.REFRESHTOKEN_STORAGE);
  // localStorage.removeItem(types.CHANGE_PASSWORD_STORAGE);
  // localStorage.removeItem(types.CACHE_USER_INFO);
  localStorage.clear();
  return (dispatch) => {
    // updateLoginTokenEncrypt("", "");
    dispatch(auth_ini());
    window.location.href = "/";
  };
}

// export function checkAuth(token) {
//   return (dispatch) => {
//     fetch(`${USER_CHECK_TOKEN}/${token}`, {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (!data) {
//           updateLoginTokenEncrypt("", "");
//           dispatch(auth_ini());
//         }
//       })
//       .catch((err) => {
//         updateLoginTokenEncrypt("", "");
//         dispatch(auth_ini());
//       });
//   };
// }
