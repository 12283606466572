// Page Title
export const TITLE_HOME = "COKE AUDIT";
export const TITLE_EVENT_TET = "AUDIT TẾT 2020";
export const TITLE_EVENT_NUTRI = "AUDIT NUTRIBOOST";
export const TITLE_STORE_SFA = "SFA";
export const TITLE_STORE_NON_SFA = "NON - SFA";
// Type of event => components/fontend/page/home.js
export const EVENT_TET = "EVENT_TET";
export const EVENT_NUTRI = "EVENT_NUTRI";
// Type of store => components/fontend/page/home.js
export const STORE_SFA = "STORE_SFA";
export const STORE_NON_SFA = "703e132f-db2c-4190-be5d-ffffd2b45ac6";
// parse ranks to images => components/fontend/ui/customer/rank.js
export const GOLD = 1;
export const SILVER = 2;
export const BRONZE = 3;

// Respone API
export const RESPONSE_OK = 200;
export const RESPONSE_NON_AUTHORITATIVE_INFORMATION = 203;
export const RESPONSE_BAD_REQUEST = 400;
export const RESPONSE_UNAUTHORIZED = 401;
export const RESPONSE_FORBIDDEN = 403;
export const RESPONSE_NOT_FOUND = 404;
export const RESPONSE_METHOD_NOT_ALLOWED = 405;
export const RESPONSE_REQUEST_TIMEOUT = 408;
export const RESPONSE_UNSUPPORTED_MEDIA_TYPE = 415;
export const RESPONSE_INTERNAL_SERVER_ERROR = 500;
export const RESPONSE_BAD_GATEWAY = 502;
export const RESPONSE_SERVICE_UNAVAILABLE = 503;
export const RESPONSE_GATEWAY_TIMEOUT = 504;
export const RESPONSE_TOKEN_INVALID = 600;
export const RESPONSE_USER_NOT_FOUND = 601;
export const RESPONSE_USER_SUSPEND = 602;
export const RESPONSE_FEATURE_SUSPEND = 603;
export const RESPONSE_PERMISSION_DENIED = 604;
export const RESPONSE_NO_DATA_FOUND = 605;
export const RESPONSE_UPDATE_FAILED = 606;
export const RESPONSE_EXCEPTION = 999;

//Bổ sung cho layer
export const SURVEY_LAYER = 6;
export const SURVEY_24HLAYER = 7;
export const SURVEY_RTMM_OPEN_LAYER = 8;

export const version = 2;
