import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import * as actions from "../../store/actions";
import "../admin/assets/css/login/login.css";
// import { useDispatch, useSelector } from "react-redux";

// const moment = require("moment");
class ViewLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      title: "CHƯƠNG TRÌNH AUDIT",
      isLoading: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }



  // componentDidMount() { }

  onSubmit = (e) => {
    e.preventDefault();
    this.messages.clear();

    if(this.state.username.trim() === ""){
        this._handlePopupError("error", "Tài khoản", "không được để trống !");
        return;
    }
    if(this.state.password.trim() === ""){
        this._handlePopupError("error", "Mật khẩu", "không được để trống !");
        return;
    }

    this.props.login(this.state.username, this.state.password, (isChangePass) => this.loginEnd(isChangePass));
  };


  _handleShowError = (type, title, content) => {
    this.messages.show({
      severity: type,
      summary: title,
      detail: content,
      life: 5000,
      closable: false,
    });
  };

  _handleClearError = () => {
    this.messages.clear();
  };

  _handlePopupError = (type, title, content) => {
    this.growl.show({
      life: 5000,
      severity: type,
      summary: title,
      detail: content,
    });
  };

  loginEnd = (isChangePassword) => {
    // console.log("loginEnd", isChangePassword, this.props);
    if (isChangePassword) {
      this.props.history.push("/event/setting");
    }else{
      this.props.history.push("/");
    }
  }

  _handleLogin() {
    // const username = this.state.username.trim();
    // const password = this.state.password.trim();
    // if (username === "") {
    //     this._handlePopupError("error", "Tài khoản", "không được để trống !");
    // } else {
    //     if (password === "") {
    //         this._handlePopupError("error", "Mật khẩu", "không được để trống !");
    //     } else {
    //         this._handleClearError();
    //         this.props.login(username, password);
    //     }
    // }
  }

  // handleKeyUp = (event) => {
  //     if (event.keyCode === 13) {
  //         this._handleLogin();
  //     }
  // };

  render() {
    const { loading } = 0; //this.props.loadingModuleReducer;
    return loading < 1 ? (
      <ProgressSpinner style={{ textAlign: "center", width: "100%" }} />
    ) : (
      <div className="form-login">
        <Toast ref={(el) => (this.growl = el)} />
        <form className="login-layout" onSubmit={(e) => this.onSubmit(e)}>
          <Card>
            {
              <div className="p-grid" style={{ marginTop: -90 }}>
                <div className="p-col-3"></div>
                <div className="p-col-6">
                  <img
                    src={require("../admin/assets/img/cocacola.PNG")}
                    className="form-logo"
                    alt="hình tượng trưng"
                  />
                </div>
                <div className="p-col-3"></div>
                <div
                  className="p-col-12"
                  style={{ textAlign: "center", marginTop: 20 }}
                >
                  <label className="form-title">{this.state.title}</label>
                </div>
              </div>
            }

            <div className="p-grid" style={{ marginTop: 20 }}>
              <div
                className="p-col-12"
                style={{ paddingBottom: 0, paddingTop: 0 }}
              >
                <Messages
                  ref={(el) => (this.messages = el)}
                  style={{ width: "100%" }}
                ></Messages>
              </div>
              <div className="p-col-12" style={{ paddingBottom: 0 }}>
                <label>Tài khoản</label>
              </div>
              <div
                className="p-col-12"
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <div className="p-inputgroup">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      height: 32,
                      backgroundColor: "#fff",
                      borderRight: "unset",
                    }}
                  >
                    <i className="pi pi-user"></i>
                  </span>
                  <InputText
                    style={{ width: "100%", height: 32 }}
                    placeholder="Nhập tài khoản"
                    type="text"
                    value={this.state.username}
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ username: e.target.value });
                    }}
                    className="inputText"
                  />
                </div>
              </div>
              <div className="p-col-12" style={{ paddingBottom: 0 }}>
                <label>Mật khẩu</label>
              </div>
              <div
                className="p-col-12"
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <div className="p-inputgroup">
                  <span
                    className="p-inputgroup-addon"
                    style={{
                      height: 32,
                      backgroundColor: "#fff",
                      borderRight: "unset",
                    }}
                  >
                    <i className="pi pi-lock"></i>
                  </span>
                  <InputText
                    style={{ width: "100%", height: 32 }}
                    placeholder="Nhập mật khẩu"
                    type="password"
                    value={this.state.password}
                    onChange={(e) => {
                      e.preventDefault();
                      this.setState({ password: e.target.value });
                    }}
                    className="inputText"
                  />
                </div>
              </div>
              <div
                className="p-col-12"
                style={{ paddingTop: 30, textAlign: "center" }}
              >
                <Button
                  type="submit"
                  icon="pi pi-sign-in"
                  className="p-button-raised p-button-danger btn-login"
                  label="Đăng nhập"
                  // onClick={() => this._handleLogin()}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </Card>
        </form>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password, func) =>
      dispatch(actions.login(username, password, func)),
  };
};

export default connect(null, mapDispatchToProps)(ViewLogin);
