
export function GetURL() {
    var enviroment = 2;
    var baseUrlLink = 'https://api.windowaudit.com.vn/';
    switch (enviroment) {
        case 0:
            baseUrlLink = 'https://localhost:44338';
            break;
        case 1:
            baseUrlLink = 'https://api.windowaudit.com.vn';
            break;
        case 2:
            baseUrlLink = 'https://testapi.windowaudit.com.vn';
            break;    
        default:
            baseUrlLink = '';
            break;
    }
    return baseUrlLink;
}
export const baseUrl = GetURL();
export const X_API_KEY = 'zXETppKnKk';
export const TOKEN_STORAGE = 'COCA_TOKEN';
export const USERNAME_STORAGE = 'COCA_USERN';
export const ROLE_STORAGE = 'COCA_ROLE';
export const baseApiAI = 'https://ai.tengroup.com.vn/v1';
export const PAGE_SIZE = 5;