import React, { Fragment } from 'react';
import { Card } from 'primereact/card';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { withRouter } from "react-router-dom";
import * as router from '../../../common/route';
import * as actions from '../../../store/actions/index';
import * as actionTypes from '../../../store/constants/actionTypes';
import moment from 'moment';

const ItemEvent = ({ itemEvent, account, history, changeEvent }) => {
    const changeUpdate = (idx) => {
        var obj = {
            [idx]: {}
        }

        localStorage.setItem(actionTypes.STORAGE_INFO, JSON.stringify(obj));
        localStorage.setItem(actionTypes.STORAGE_EVENTID, idx);
        history.push(`${router.DETAIL_EVENT}/${idx}`);
    }

    const changePage = (idx, name) => {
        console.log(idx, name);

        changeEvent(idx);
        var obj = {
            [idx]: {}
        }

        localStorage.setItem(actionTypes.STORAGE_INFO, JSON.stringify(obj));
        localStorage.setItem(actionTypes.STORAGE_EVENTID, idx);
        localStorage.setItem(actionTypes.STORAGE_EVENTNAME, name);
        history.push(router.LIST_INFO + '/' + idx);
    }

    const renderBody = () => {
        if (itemEvent) {
            const {
                id,
                status,
                name,
                startDate,
                endDate,
                description,
                // nameStatus
            } = itemEvent

            return (
                <Card style={{ width: '100%', position: "relative", minHeight: 160 }} className={status === 1 ? "ui-card-shadow" : "ui-card-shadow card-event-end"}>
                    <Row>
                        <Col xs={3}>
                            <img alt="Card" src={`${process.env.PUBLIC_URL}/img/logococacola.jpg`} style={{ width: "100%", height: 80, objectFit: "contain" }} />
                        </Col>
                        <Col xs={9}>
                            <Row>
                                <Col xs={12}>
                                    <h4 className="event-name" title={name}>{name}</h4>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <span>Ngày bắt đầu: <b>{moment(startDate).format("DD/MM/YYYY")}</b></span>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <span>Ngày kết thúc: <b>{moment(endDate).format("DD/MM/YYYY")}</b></span>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: 5 }}>
                                <Col xs={12} className="event-desc">
                                    <span title={description}>Mô tả: {description}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div style={{position: "absolute", bottom: 5, right: 5}}>
                            {
                                (account && account.level === "6") && (
                                    <Button
                                        tooltip="Cập nhật hoạt động"
                                        icon="pi pi-pencil"
                                        className="p-button-raised p-button-success"
                                        onClick={()=> changeUpdate(id)}
                                        style={{ marginRight: 5 }}
                                        tooltipOptions={{ position: 'left' }}
                                    />
                                )
                            }
                            <Button
                                tooltip="Chi tiết"
                                tooltipOptions={{ position: 'left' }}
                                onClick={() => changePage(id, name)}
                                icon="pi pi-external-link"
                                className="p-button-raised p-button-secondary"
                            />
                    </div>

                </Card>
            )
        } else {
            return null;
        }
    }


    return (
        <Fragment>
            <Row style={{ paddingTop: 10, height: `100%` }}>
                <Col xs={12}>
                    {renderBody()}
                </Col>
            </Row>
        </Fragment>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        changeEvent: (eventId) => dispatch(actions.event_set(eventId)),
    }
}

export default withRouter(connect(null, mapDispatchToProps)(ItemEvent));
