import React, {Component} from 'react';
import {Button} from 'primereact/button';
import {Fieldset} from 'primereact/fieldset';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {Dropdown} from 'primereact/dropdown';
import LoadingView from '../../loadingview/LoadingView';
import { 
    IMPORT_STORE,
    LIST_EVENT_ACTIVE,
    GET_LIST_AREA
    } 
from '../../../store/constants/serviceEndpoint';
import { headerToken } from '../../../store/constants/header'; 
import * as axiosWrapper from '../../../store/constants/axiosWrapper';

class ImportStore extends Component{

    constructor() {
        super();
        this.state = {
            apiImport: '',
            labelUpload: 'Chưa chọn file...',
            dataImport:[],
            file: null,
            listEvent:[],
            listArea:[],
            areaId: null,
            eventId: null,
            visibleTable: false,
            totalRow: 0,
            isLoading: true, 
            disabledImport: false
        }

    }

    componentDidMount(){
        this.GetListChannel();
        this.getListArea();
        this.setState({
            isLoading: false
        })
    }

    selectFile = (event) => {
        const files = event.target.files;
        if (files[0].size < 1048576) {
            this.setState({
                file: files[0],
                labelUpload: files[0].name
            });
        } else {
            Swal.fire('Lỗi', 'File có dung lượng lớn hơn 1MB', 'error')
            this.setState({
                file: null,
                labelUpload: 'Chưa chọn file...',
            });
        }
    }

    triggerOpenUpload = (event) => {
        event.preventDefault();
        document.getElementById("fileImport").click();
    }

    downloadTemplate = (e, idx) =>{
        switch(idx){
            case 0:
                setTimeout(() => {
                    const response = {
                        file: "https://api.windowaudit.com.vn/template/Outlet.xlsx",
                    };
                    window.open(response.file);
                }, 500);
                break;
            default:
                break;
        }
    }

    getListArea(){
        const _this = this;
        axiosWrapper.axiosWithHeaders('GET',GET_LIST_AREA)
        .then(function (response) {
            if (response.status === 200) {
              const data = response.data;
              const result = data.result;
              let arr = [];
              result.map((item, i)=>{
                let obj = {}
                obj["value"] = item.id;
                obj["label"] = item.name;
                arr.push(obj);
              })

              _this.setState({
                listArea: arr
              })
    
            }
          }).catch(function (error) {
              console.log(error);
          })
      }

    GetListChannel = () =>{
        const _this = this;
        axiosWrapper.axiosWithHeaders('post',LIST_EVENT_ACTIVE)
        .then(function (response) {
            if (response.status === 200) {
                const data = response.data;
                
                let arr = [];
                (data.result).map(item=>{
                    arr.push({
                        "value": item.id,
                        "label": item.name
                    })
                })
                
                _this.setState({
                    listEvent: arr
                })
            }
        })
        .catch(function (error) {
            console.log(error);
        })
    }

    ImportData = (e) =>{       
        e.preventDefault();
        let file = this.state.file;
        if(file===null){
            Swal.fire("Lỗi", "Vui lòng chọn file !", "error");
            return;
        }

        if(this.state.eventId===null){
            Swal.fire("Lỗi", "Vui lòng chọn hoạt động !", "error");
            return;
        }

        if(this.state.areaId===null){
            Swal.fire("Lỗi", "Vui lòng chọn vùng !", "error");
            return;
        }

        this.setState({
            loading: true,
            visibleTable: false,
            totalRow: 0,
            isLoading: true,
            disabledImport: true
        })

        var fr = new FormData();
        fr.append("EventId", this.state.eventId);
        fr.append("AreaId", this.state.areaId);
        fr.append("file", file);
        const _this = this;
        axiosWrapper.axiosWithHeadersFormData('post',IMPORT_STORE,fr)
        .then(function (response) {
            if (response.status === 200) {
                const data = response.data;
                const listError = data.listError;
                _this.setState({
                    dataImport: listError,
                    loading: false,
                    visibleTable: true,
                    totalRow: listError.length,
                    isLoading: false,
                    disabledImport: false
                })
                Swal.fire("Thông báo", "Import hoàn tất!", "info");
            }
        })
        .catch(function (error) {
            console.log(error);
            _this.setState({
                loading: false,
                visibleTable: false,
                totalRow: 0,
                isLoading: false,
                disabledImport: false
            })
        })
    }

    render(){
        const datacontent = (this.state.isLoading) ?
        (
            <div className="p-col-12">
                <LoadingView />
            </div>
        ):(
            <div className= "p-col-12">
                <div className="p-grid">
                <div className="p-col-12">
                    <Fieldset legend="Thông tin import">
                        <div className="p-grid">
                            <div className="p-col-3">
                                <div className="p-grid">
                                    <div className="p-col-fixed" style={{width:200, textAlign:"right", paddingTop: 13}}>
                                        <label>Hoạt động</label>
                                    </div>
                                    <div className="p-col">
                                        <Dropdown
                                            value={this.state.eventId}
                                            options={this.state.listEvent}
                                            onChange={(e) => this.setState({eventId: e.value})}
                                            className="p-button-raised p-button-info"
                                            placeholder="Chọn hoạt động"
                                            filter={true}
                                            filterPlaceholder={"Tìm kiếm hoạt động"}
                                            filterBy="label,value"
                                            filterMatchMode="contains"
                                            tooltip={"Chọn hoạt động !"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-3">
                                <div className="p-grid">
                                    <div className="p-col-fixed" style={{width:200, textAlign:"right", paddingTop: 13}}>
                                        <label>Vùng</label>
                                    </div>
                                    <div className="p-col">
                                        <Dropdown
                                            value={this.state.areaId}
                                            options={this.state.listArea}
                                            onChange={(e) => this.setState({areaId: e.value})}
                                            className="p-button-raised p-button-info"
                                            placeholder="Chọn vùng"
                                            filter={true}
                                            filterPlaceholder={"Tìm kiếm vùng"}
                                            filterBy="label,value"
                                            filterMatchMode="contains"
                                            tooltip={"Chọn vùng !"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-3">
                    <input
                        id="fileImport"
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(event) => this.selectFile(event)}
                        className="d-none"
                    />
                    <Button label="Chọn file..." icon="pi pi-folder-open" onClick={(event) => this.triggerOpenUpload(event)} />
                    <label style={{ paddingLeft: 5 }}>
                        {this.state.labelUpload}
                    </label>
                </div>
                <div className="p-col-6" style={{textAlign:"center"}}>
                    <Button 
                        label="Import" 
                        icon="pi pi-file-excel" 
                        className="p-button-warning p-button-raised"
                        onClick={(e)=>this.ImportData(e)}
                        style={{marginRight: 10}}
                        disabled={this.state.disabledImport}
                    />

                    <Button 
                        label="Template" 
                        icon="pi pi-download" 
                        className="p-button-secondary p-button-raised"
                        onClick={(e, index)=>this.downloadTemplate(e, 0)}
                        style={{marginRight: 10}}
                    />

                </div>
                <div className="p-col-3">

                </div>
                </div>
            </div>
        )

        return (
            <div className="p-grid" style={{marginTop: 20}}>
                {datacontent}
                <div className="p-col-12">
                    {
                        (this.state.visibleTable === true) && (
                            <Fieldset legend="DANH SÁCH LỖI">
                                <div className="p-grid">
                                    <div className="p-col-12" style={{textAlign:"right"}}>
                                        <label>Tổng số: <span style={{color:"red"}}>{this.state.totalRow}</span> dòng</label>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <DataTable 
                                        value={this.state.dataImport} 
                                        emptyMessage="Chưa có dữ liệu"
                                        paginator={true}
                                        rows={50}
                                        rowsPerPageOptions={[50, 100, 200]}
                                        scrollable={true}
                                        scrollHeight={"50vh"}
                                        >
                                        <Column field="code" header="Mã CH" className="p-col-fixed" style={{width: 175}}/>
                                        <Column field="storeName" header="Tên cửa hàng" className="p-col-fixed" style={{width: 175}}/>
                                        <Column field="address" header="Địa chỉ" className="p-col-fixed" style={{width: 300}}/>
                                        <Column field="province" header="Tỉnh thành" className="p-col-fixed" style={{width: 175}}/>
                                        <Column field="district" header="Quận huyện" className="p-col-fixed" style={{width: 175}}/>
                                        <Column field="exception" header="Nội dung" className="p-col-fixed" style={{width: 500}}/>
                                    </DataTable>
                                </div>
                            </Fieldset>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default ImportStore;