import { getTokenEncrypt, getCid } from '../../crypto/Crypto';

export function header() {
    return {
        'Content-Type': 'application/json'
    }
}

export function headerToken() {
    return {    
        'Content-Type': 'application/json',
        'coken' :getTokenEncrypt(),
        'cid':getCid()
    }
}
