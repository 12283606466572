import React from 'react';
import Countdown from "react-countdown";
const moment = require("moment");

const renderer = (props) => {
    let {formatted, days} = props;
    let {hours, minutes, seconds} = formatted;
    return (<b style={{color: "red"}}>
        {(days === 0)? "": days + " ngày - "} {hours}:{minutes}:{seconds}
    </b>)
};

const CountDownSurvey = (props) =>{
    let timerCount = moment(props.timerCount).valueOf();
    return (
        <Countdown date={timerCount} renderer={renderer} />
    )
}

export default CountDownSurvey;