import React, { Component } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
// import { Message } from "primereact/message";
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import {
  GET_PACKAGE_IMAGES_EVENTID,
  DEL_PACKAGE_IMAGES,
  ADD_PACKAGE_IMAGES,
  UPD_PACKAGE_IMAGES,
  GET_CHANNELS,
  GET_EVENT_LIST_IMAGE,
  GET_CHANNELS_PACKAGE
} from '../../../store/constants/serviceEndpoint';
import { headerToken } from '../../../store/constants/header';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import "./event.css";
import { withRouter } from 'react-router-dom'
import { axiosWithHeaders } from '../../../store/constants/axiosWrapper';
const axios = require("axios");
const _ = require("lodash");

const multiGroupBy = (array, group, ...restGroups) => {
  if (!group) {
    return array;
  }
  const currGrouping = _.groupBy(array, group);
  if (!restGroups.length) {
    return currGrouping;
  }
  return _.transform(currGrouping, (result, value, key) => {
    result[key] = multiGroupBy(value, ...restGroups);
  }, {});
};

class ConfigChannelPackageImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataChannelPackage: [],
      dataChannel: [],
      dataArea: [],
      Name: '',
      Description: '',
      ChannelId: null,
      AreaId: [],
      apiUpdate: ADD_PACKAGE_IMAGES,
    };
    // this.UpdateData = this.UpdateData.bind(this);
    // this.onChangeArea = this.onChangeArea.bind(this);
  }

  componentDidMount() {
    const eventId = this.props.match.params.id;
    if (eventId !== undefined && eventId !== null) {
      this.GetPackageImageEventId(eventId);
      this.GetListImageByEvent(eventId);
      this.GetListChannel(eventId);
      this.GetListChannelPackage(eventId)
    }
  }

  GetListChannel = (eventId) => {
    const _this = this;
    // axios({
    //   method: 'get',
    //   url: GET_CHANNELS,
    //   headers: headerToken(),
    //   params: {
    //     id: eventId
    //   }
    // })
    axiosWithHeaders("get", GET_CHANNELS, { id: eventId })
      .then(function (response) {
        if (response.status === 200) {
          const data = response.data;
          const result = data.result;
          let arr = [];
          result.map((item, i) => {
            let obj = {}
            obj["value"] = item.id;
            obj["label"] = item.name;
            arr.push(obj);
          })
          _this.setState({
            dataChannel: arr,
            eventId: eventId
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  GetListChannelPackage = (eventId) => {
    const _this = this;
    // axios({
    //   method: 'get',
    //   url: GET_CHANNELS_PACKAGE,
    //   headers: headerToken(),
    //   params: {
    //     id: eventId
    //   }
    // })
    axiosWithHeaders("get", GET_CHANNELS_PACKAGE, { id: eventId })
    .then(function (response) {
      if (response.status === 200) {
        const data = response.data;
        const result = data.data;
        let arr = []
        result.map((item, i) => {
          let obj = {}
          obj["value"] = item.id;
          obj["label"] = item.name;
          arr.push(obj);
        })

        _this.setState({
          listPackage: arr,
          eventId: eventId
        })
      }
    })
      .catch(function (error) {
        console.log(error);
      })
  }

  GetPackageImageEventId = (eventId) => {
    const _this = this;
    // axios({
    //   method: 'get',
    //   url: GET_PACKAGE_IMAGES_EVENTID,
    //   headers: headerToken(),
    //   params: {
    //     eventId: eventId
    //   }
    // })
    axiosWithHeaders("get", GET_PACKAGE_IMAGES_EVENTID, { eventId: eventId })
    .then(function (response) {
      if (response.status === 200) {
        const data = response.data;
        const result = data.result;
        const datas = multiGroupBy(result, 'channelName');
        let arr = [];
        for (var field in datas) {
          let obj = {}
          obj["channelName"] = field;
          obj["dataPackage"] = datas[field];
          arr.push(obj);
        }
        _this.setState({
          dataChannelPackage: arr
        })
      }
    })
      .catch(function (error) {
        console.log(error);
      })
  }

  onCancel = (e) => {
    // e.preventDefault();
    this.setState({
      visible: false,
      ChannelId: null,
      EventImageId: null,
      PackageId: null,
      mode: 0,
      apiUpdate: ""
    })
  }

  GetListImageByEvent = (eventId) => {
    const _this = this;
    // axios({
    //   method: 'get',
    //   url: GET_EVENT_LIST_IMAGE,
    //   headers: headerToken(),
    //   params: {
    //     id: eventId
    //   }
    // })
    axiosWithHeaders("get", GET_EVENT_LIST_IMAGE, { id: eventId })
    .then(function (response) {
      if (response.status === 200) {
        const data = response.data;
        const result = data.result;
        let arr = []
        result.map((item, i) => {
          let obj = {}
          obj["value"] = item.id;
          obj["label"] = item.name;
          arr.push(obj);
        })

        _this.setState({
          listImage: arr,
          eventId: eventId
        })
      }
    })
      .catch(function (error) {
        console.log(error);
      })
  }

  updateItem = (e, row) => {
    console.log(row);
    let state = this.state;
    state["EventImageId"] = row.eventImageId;
    state["ChannelId"] = row.channelId;
    state["isRequire"] = row.isRequire;
    state["PackageId"] = row.packageId;
    state["visible"] = true;
    if (row.id === null) {
      state["apiUpdate"] = ADD_PACKAGE_IMAGES;
      state["mode"] = 0;
    } else {
      state["apiUpdate"] = UPD_PACKAGE_IMAGES;
      state["mode"] = 1;
      state["id"] = row.id;
    }
    this.setState(state);
  }

  deleteItem = (e, id) => {
    Swal.fire({
      title: 'Thông báo',
      text: "Bạn có muốn xóa cài đặt này không ?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Đồng ý!',
      cancelButtonText: 'Trở về'
    }).then((result) => {
      if (result.value) {
        const _this = this;
        // axios({
        //   method: 'post',
        //   url: DEL_PACKAGE_IMAGES,
        //   headers: headerToken(),
        //   data: {
        //     Id: id
        //   }
        // })
        axiosWithHeaders("post", DEL_PACKAGE_IMAGES, { Id: id })
        .then(function (response) {
          if (response.status === 200) {
            const data = response.data;
            if (data.message === "SUCCESS") {
              Swal.fire("Thông báo", "Xoá cài đặt thành công !", "success")
            } else {
              Swal.fire("Lỗi", "Xóa cài đặt thất bại !", "error")
            }
            _this.GetPackageImageEventId(_this.state.eventId);
          }
        })
          .catch(function (error) {
            console.log(error);
          })
      }
    })
  }

  celltemplate = (row, column) => {
    const field = column.field;
    switch (field) {
      case 'isRequire':
        return (<Checkbox checked={row["isRequire"]} disabled></Checkbox>)
      case 'action':
        return (
          <div style={{ width: '100%', display: "inline-block", textAlign: "center" }}>
            <Button
              icon="pi pi-pencil"
              className="p-button-success p-button-raised"
              style={{ marginRight: 7, width: 35 }}
              onClick={(e, uniqueId) => this.updateItem(e, row)}
            />
            <Button
              icon="pi pi-trash"
              className="p-button-danger p-button-raised"
              style={{ width: 35 }}
              onClick={(e, uniqueId) => this.deleteItem(e, row.id)}
              disabled={(row.id !== null) ? false : true}
            />
          </div>
        );
      default:
        return (
          <DataTable
            value={row.dataPackage}
            headerColumnGroup={null}
            rowGroupMode="rowspan"
            sortField="packageName"
            // sortOrder={1} 
            groupField="packageId"
            emptyMessage="Hoạt động này chưa tạo suất nào...."
          >
            <Column field="packageName" header="Suất" />
            <Column field="nameImage" header="Tên hình" />
            <Column field="isRequire" header="Bắt buộc" body={(rowData, column) => this.celltemplate(rowData, column)} style={{ textAlign: "center" }} className={"p-col-fixed"} />
            <Column field="action" body={(rowData, column) => this.celltemplate(rowData, column)} style={{ textAlign: "center", width: 120 }}
              className={"p-col-fixed"}
            />
          </DataTable>
        );
    }
  }

  UpdateData() {
    const _this = this;
    let objUpdate = {};
    let state = this.state;
    objUpdate["ChannelId"] = state.ChannelId;
    objUpdate["EventImageId"] = state.EventImageId;
    objUpdate["PackageId"] = state.PackageId;
    objUpdate["IsRequied"] = (state.isRequire === false) ? 0 : 1;

    if (state.mode === 1) {
      objUpdate["id"] = state.id;
    }

    // axios({
    //   method: 'post',
    //   url: _this.state.apiUpdate,
    //   headers: headerToken(),
    //   data: objUpdate
    // })
    axiosWithHeaders("post", _this.state.apiUpdate, objUpdate)
    .then(function (response) {
      if (response.status === 200) {
        const data = response.data;
        if (data.message === "SUCCESS") {
          Swal.fire("Thông báo", "Cập nhật cài đặt thành công !", "success")
        } else {
          Swal.fire("Lỗi", "Cập nhật cài đặt thất bại !", "error")
        }
        _this.GetPackageImageEventId(_this.state.eventId);
        _this.setState({
          visible: false,
          ChannelId: null,
          EventImageId: null,
          PackageId: null,
          mode: 0,
          apiUpdate: ""
        })
      }
    })
      .catch(function (error) {
        console.log(error);
      })
  }

  render() {
    const footer = (
      <div>
        <Button
          label="Cập nhật"
          icon="pi pi-check"
          onClick={() => this.UpdateData()}
          className="p-button-raised"
        />
        <Button
          label="Hủy"
          icon="pi pi-times"
          onClick={(e) => this.onCancel(e)}
          className="p-button-raised p-button-secondary"
        />
      </div>
    )

    return (
      <div className="p-grid">
        <div className="p-col-12">
          <Dialog
            header="Cập nhật hình theo suất"
            footer={footer}
            visible={this.state.visible}
            style={{ width: "600px" }}
            modal={true}
            onHide={(e) => this.onCancel(e)}
          >
            <div className="p-grid" style={{ marginTop: 5 }}>
              <div className="p-col-4" style={{ paddingBottom: 0, textAlign: "right" }}>
                <label>Kênh</label>
              </div>
              <div className="p-col-6" style={{ paddingTop: 0 }}>
                <Dropdown
                  value={this.state.ChannelId}
                  options={this.state.dataChannel}
                  onChange={(e) => {
                    this.setState({ ChannelId: e.value })
                  }}
                  filter={true}
                  filterPlaceholder="Tìm kênh"
                  filterBy="label,value"
                  placeholder="Chọn kênh"
                  disabled
                />
              </div>
            </div>
            <div className="p-grid" style={{ marginTop: 5 }}>
              <div className="p-col-4" style={{ paddingBottom: 0, textAlign: "right" }}>
                <label>Suất</label>
              </div>
              <div className="p-col-6" style={{ paddingTop: 0 }}>
                <Dropdown
                  value={this.state.PackageId}
                  options={this.state.listPackage}
                  onChange={(e) => {
                    this.setState({ PackageId: e.value })
                  }}
                  filter={true}
                  filterPlaceholder="Tìm suất"
                  filterBy="label,value"
                  placeholder="Chọn suất"
                  disabled
                />
              </div>
            </div>
            <div className="p-grid" style={{ marginTop: 5 }}>
              <div className="p-col-4" style={{ paddingBottom: 0, textAlign: "right" }}>
                <label>Loại hình</label>
              </div>
              <div className="p-col-6" style={{ paddingTop: 0 }}>
                <Dropdown
                  value={this.state.EventImageId}
                  options={this.state.listImage}
                  onChange={(e) => { this.setState({ EventImageId: e.value }) }}
                  filter={true}
                  filterPlaceholder="Tìm hình"
                  filterBy="label,value"
                  placeholder="Chọn loại hình"
                  disabled
                />
              </div>
            </div>
            <div className="p-grid" style={{ marginTop: 5 }}>
              <div className="p-col-4" style={{ paddingBottom: 0, textAlign: "right" }}>
              </div>
              <div className="p-col-6" style={{ paddingTop: 0 }}>
                <Checkbox checked={this.state.isRequire} inputId="cb2" onChange={e => this.setState({ isRequire: e.checked })}></Checkbox>
                <label htmlFor="cb2" className="p-checkbox-label">Bắt buộc ?</label>
              </div>
            </div>
          </Dialog>
          <DataTable
            value={this.state.dataChannelPackage}
            rowGroupMode="rowspan"
            sortField="ChannelName"
            sortOrder={1}
            groupField="channelId"
            emptyMessage="Hoạt động này chưa tạo suất nào...."
          >
            <Column field="channelName" header="Kênh" style={{ width: 120 }} />
            <Column header="Hình theo suất" body={(rowData, column) => this.celltemplate(rowData, column)} />
          </DataTable>
        </div>
      </div>
    );
  }

}

export default withRouter(ConfigChannelPackageImage);
