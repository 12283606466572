import React, { Component } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
// import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Messages } from "primereact/messages";
import { Checkbox } from "primereact/checkbox";
import {
  GET_CHANNELS,
  ADD_CHANNELS,
  UPDATE_CHANNELS,
  DEL_CHANNELS,
} from "../../../store/constants/serviceEndpoint";
import { headerToken } from "../../../store/constants/header";
// import { Message } from "primereact/message";
import { TabView, TabPanel } from "primereact/tabview";
import Swal from "sweetalert2/dist/sweetalert2.js";
// import {Dropdown} from 'primereact/dropdown';
// import "./event.css";
import { withRouter } from "react-router-dom";
import { axiosWithHeaders } from "../../../store/constants/axiosWrapper";
const axios = require("axios");
const _ = require("lodash");
class ConfigChannel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      activeIndex: 0,
      dataChannel: [],
      description: "",
      name: "",
      eventId: null,
      isDefault: false
      // isEdit: false
    };
  }

  componentDidMount() {
    const eventId = this.props.match.params.id;
    if (eventId !== undefined && eventId !== null) {
      this.GetListChannel(eventId);
    }
  }

  GetListChannel = (eventId) => {
    const _this = this;
    // axios({
    //   method: "get",
    //   url: GET_CHANNELS,
    //   headers: headerToken(),
    //   params: {
    //     id: eventId,
    //   },
    // })
    axiosWithHeaders("get", GET_CHANNELS, {id: eventId})
      .then(function (response) {
        if (response.status === 200) {
          const data = response.data;
          const result = data.result;
          _this.setState({
            dataChannel: result,
            eventId: eventId,
          });
          _this.updateEditor();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  updateEditor() {
    const dataChannel = this.state.dataChannel;
    let state = this.state;
    dataChannel.map((item, i) => {
      state["isEdit" + i] = false;
    });
    this.setState(state);
  }

  addTab = () => {
    this.setState({
      visible: true,
    });
  };

  onChangeValue = (e, field, idx) => {
    e.preventDefault();
    if (idx === null) {
      let obj = {};
      obj[field] = e.target.value;

      if (field === "isDefault") {
        obj[field] = e.checked;
      }
      this.setState(obj);
    } else {
      let dataChannel = this.state.dataChannel;
      if (dataChannel.length > 0) {
        dataChannel.map((channel, i) => {
          if (channel.id === idx) {
            channel[field] = e.target.value;
            if (field === "isDefault") {
              channel[field] = e.checked;
            }
            channel["isModified"] = true;
          }
        });
      }
      this.setState({
        dataChannel: dataChannel,
      });
    }
  };

  checkData = (obj) => {
    for (var field in obj) {
      if (
        obj[field] === undefined ||
        obj[field] === null ||
        obj[field] === "" ||
        obj[field] === 0
      ) {
        switch (field) {
          case "Name":
            this.messages.show({
              severity: "error",
              summary: "Lỗi",
              detail: "Vui lòng nhập tên kênh !",
            });
            break;
          case "desc":
          case "Description":
            this.messages.show({
              severity: "error",
              summary: "Lỗi",
              detail: "Vui lòng nhập mô tả !",
            });
            break;
          default:
            break;
        }
        return false;
      }
    }
    return true;
  };

  addChannel = (e) => {
    e.preventDefault();
    const _this = this;
    let objChannel = {};
    let obj = {};
    obj.Name = this.state.name;
    obj.desc = this.state.description;
    const check = this.checkData(obj);
    if (check) {
      obj.EventId = this.state.eventId;
      obj.IsDefault = this.state.isDefault;
      objChannel["NewChannel"] = obj;
      // axios({
      //   method: "post",
      //   url: ADD_CHANNELS,
      //   headers: headerToken(),
      //   data: objChannel,
      // })
      axiosWithHeaders("post", ADD_CHANNELS, objChannel)
        .then(function (response) {
          if (response.status === 200) {
            const data = response.data;
            if (data.message === "SUCCESS") {
              Swal.fire("Thông báo", "Thêm mới thành công !", "success");
            } else {
              Swal.fire("Lỗi", "Cập nhật vùng hoạt động thất bại !", "error");
            }
            _this.GetListChannel(_this.state.eventId);
            _this.setState({
              visible: false,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  updateData = (e, id) => {
    const _this = this;
    e.preventDefault();
    let obj = {};
    let dataChannel = this.state.dataChannel;
    dataChannel = _.filter(dataChannel, (o) => {
      return o.id === id;
    });
    obj.Name = dataChannel[0].name;
    obj.Description = dataChannel[0].desc;
    const check = this.checkData(obj);
    if (check) {
      obj.EventId = this.state.eventId;
      obj.IsDefault = dataChannel[0].isDefault;
      obj.Id = id;

      // console.log(obj);

      // axios({
      //   method: "post",
      //   url: UPDATE_CHANNELS,
      //   headers: headerToken(),
      //   data: obj,
      // })
      axiosWithHeaders("post", UPDATE_CHANNELS, obj)
        .then(function (response) {
          if (response.status === 200) {
            const data = response.data;
            if (data.message === "SUCCESS") {
              Swal.fire("Thông báo", "Cập nhật thành công !", "success");
            } else {
              Swal.fire("Lỗi", "Cập nhật vùng hoạt động thất bại !", "error");
            }
            _this.GetListChannel(_this.state.eventId);
            _this.setState({
              description: "",
              name: "",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  delChannel = (e, id) => {
    const _this = this;
    Swal.fire({
      title: "Thông báo",
      text: "Bạn có muốn xóa kênh này không ?",
      type: "warning",
      showCancelButton: true,
      //confirmButtonColor: '#3085d6',
      //cancelButtonColor: '#d33',
      confirmButtonText: "Đồng ý!",
      cancelButtonText: "Trở về",
    }).then((result) => {
      if (result.value) {
        // axios({
        //   method: "post",
        //   url: DEL_CHANNELS,
        //   headers: headerToken(),
        //   data: {
        //     Id: id,
        //   },
        // })
        axiosWithHeaders("post", DEL_CHANNELS, {Id: id})
          .then(function (response) {
            if (response.status === 200) {
              const data = response.data;
              if (data.message === "SUCCESS") {
                Swal.fire("Thông báo", "Xoá kênh thành công !", "success");
              } else {
                Swal.fire("Lỗi", "Xóa kênh thất bại !", "error");
              }
              _this.GetListChannel(_this.state.eventId);
              _this.setState({
                activeIndex: 0,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  };

  updateEdit = (idx) => {
    let obj = {};
    obj["isEdit" + idx] = true;
    this.setState(obj);
  };

  onCancel = (e, i) => {
    e.preventDefault();
    const listchannel = this.state.dataChannel;
    const modified = listchannel[i].isModified;
    let state = this.state;
    if (modified) {
      Swal.fire({
        title: "Thông báo",
        text: "Dữ liệu đã bị chỉnh sửa, bạn có muốn cập nhật không ?",
        type: "warning",
        showCancelButton: true,
        //confirmButtonColor: '#3085d6',
        //cancelButtonColor: '#d33',
        confirmButtonText: "Đồng ý!",
        cancelButtonText: "Trở về",
      }).then((result) => {
        if (result.value) {
          state["isEdit" + i] = false;
          this.setState(state);
        }
      });
    } else {
      state["isEdit" + i] = false;
      this.setState(state);
    }
  };

  renderContentTab() {
    const dataChannel = this.state.dataChannel;
    // console.log(dataChannel);
    const state = this.state;
    if (dataChannel.length > 0) {
      let html = [];
      dataChannel.map((channel, i) => {
        html.push(
          <TabPanel header={channel.name} key={i}>
            <div className="p-grid">
              <div
                className="p-col-2"
                style={{ paddingTop: 13, textAlign: "right" }}
              >
                <label>Kênh</label>
              </div>
              <div className="p-col-10">
                <InputText
                  style={{ width: "100%" }}
                  placeholder="Nhập tên kênh..."
                  value={channel.name}
                  onChange={(e, field, idx) =>
                    this.onChangeValue(e, "name", channel.id)
                  }
                  disabled={state["isEdit" + i] === false ? true : false}
                />
              </div>
              <div
                className="p-col-2"
                style={{ paddingTop: 13, textAlign: "right" }}
              >
                <label>Mô tả</label>
              </div>
              <div className="p-col-10">
                <InputTextarea
                  rows={5}
                  style={{ width: "100%" }}
                  value={channel.desc}
                  placeholder="Nhập mô tả về hoạt động..."
                  onChange={(e, field, idx) =>
                    this.onChangeValue(e, "desc", channel.id)
                  }
                  disabled={state["isEdit" + i] === false ? true : false}
                />
              </div>
              <div className="p-col-2"></div>
              <div className="p-col-10">
                <Checkbox
                  checked={channel.isDefault}
                  inputId={`chk-${channel.id}`}
                  onChange={(e) =>
                    this.onChangeValue(e, "isDefault", channel.id)
                  }
                  disabled={state["isEdit" + i] === false ? true : false}
                ></Checkbox>
                <label htmlFor={`chk-${channel.id}`} className="p-checkbox-label">
                  &nbsp;Mặc định
                </label>
              </div>
            </div>
            <div className="p-grid">
              {state["isEdit" + i] === true && (
                <div className="p-col-12" style={{ textAlign: "center" }}>
                  <Button
                    label="HỦY"
                    icon="pi pi-times"
                    className="p-button-raised p-button-secondary"
                    onClick={(e) => this.onCancel(e, i)}
                    style={{ marginRight: 10 }}
                  />
                  <Button
                    label="XÓA"
                    icon="pi pi-trash"
                    className="p-button-raised p-button-danger"
                    onClick={(e, id) => this.delChannel(e, channel.id)}
                    style={{ marginRight: 10 }}
                  />
                  <Button
                    label="CẬP NHẬT"
                    icon="pi pi-pencil"
                    className="p-button-raised p-button-success"
                    onClick={(e, id) => this.updateData(e, channel.id)}
                  />
                </div>
              )}
              {state["isEdit" + i] === false && (
                <div className="p-col-12" style={{ textAlign: "center" }}>
                  <Button
                    label="SỬA"
                    icon="pi pi-pencil"
                    className="p-button-raised p-button-secondary"
                    onClick={(idx) => this.updateEdit(i)}
                  />
                </div>
              )}
            </div>
          </TabPanel>
        );
      });
      return html;
    }
    return null;
  }

  renderTab() {
    const dataChannel = this.state.dataChannel;
    if (dataChannel.length > 0) {
      return (
        <TabView
          activeIndex={this.state.activeIndex}
          onTabChange={(e) => this.setState({ activeIndex: e.index })}
        >
          {this.renderContentTab()}
        </TabView>
      );
    }
    return (
      <div>
        Kênh chưa được tạo trên event này. Vui lòng bấm thêm để tạo kênh mới...
      </div>
    );
  }

  render() {
    const footer = (
      <div>
        <Button
          label="Cập nhật"
          icon="pi pi-check"
          onClick={(e, Id) => this.addChannel(e)}
          className="p-button-raised p-button-info"
        />
        <Button
          label="Hủy"
          className="p-button-raised p-button-secondary"
          icon="pi pi-times"
          onClick={() =>
            this.setState({
              visible: false,
              name: "",
              description: "",
              isDefault: false
            })
          }
        />
      </div>
    );

    return (
      <div className="p-grid">
        <div className="p-col-12" style={{ paddingTop: 50 }}>
          {this.renderTab()}
        </div>
        <Dialog
          header="Cập nhật kênh"
          footer={footer}
          visible={this.state.visible}
          style={{ width: 500 }}
          modal={true}
          onHide={() =>
            this.setState({
              visible: false,
            })
          }
        >
          <Messages ref={(el) => (this.messages = el)}></Messages>
          <div className="p-grid">
            <div
              className="p-col-2"
              style={{ paddingTop: 13, textAlign: "right" }}
            >
              <label>Kênh</label>
            </div>
            <div className="p-col-10">
              <InputText
                style={{ width: "100%" }}
                placeholder="Nhập tên kênh..."
                onChange={(e, field, idx) =>
                  this.onChangeValue(e, "name", null)
                }
                value={this.state.name}
              />
            </div>
            <div
              className="p-col-2"
              style={{ paddingTop: 13, textAlign: "right" }}
            >
              <label>Mô tả</label>
            </div>
            <div className="p-col-10">
              <InputTextarea
                rows={5}
                style={{ width: "100%" }}
                value={this.state.description}
                onChange={(e, field, idx) =>
                  this.onChangeValue(e, "description", null)
                }
                placeholder="Nhập mô tả về hoạt động..."
              />
            </div>
            <div
              className="p-col-2"
              style={{ paddingTop: 13, textAlign: "right" }}
            >
              
            </div>
            <div className="p-col-10">
            <Checkbox
                  checked={this.state.isDefault}
                  inputId={`chk-add`}
                  onChange={(e) =>
                    this.onChangeValue(e, "isDefault", null)
                  }                  
                ></Checkbox>
                <label htmlFor={`chk-add`} className="p-checkbox-label">
                  &nbsp;Mặc định
                </label>
            </div>
          </div>
        </Dialog>
        <div style={{ position: "absolute", top: 10, right: 15 }}>
          <Button
            label="THÊM"
            icon="pi pi-plus"
            className="p-button-raised p-button-secondary"
            onClick={() => this.setState({ visible: true })}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(ConfigChannel);
