import React, { Component } from 'react';
import '../info/lightBox.css';
import { connect } from 'react-redux'
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

// import Lightbox from '../lightbox/Lightboxs';
import { Fieldset } from 'primereact/fieldset';
import Swal from 'sweetalert2/dist/sweetalert2.js'
// import {DeferredContent} from 'primereact/deferredcontent';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Messages } from 'primereact/messages';
import Lightbox from '../../Lightbox-component/index';
/*03/01/2020 thêm loading*/
import LoadingView from '../../loadingview/LoadingView';
// import Lightbox from 'react-lightbox-component';
import {
    DETAIL_SURVEY_ADMIN,
    ACCEPT_SURVEY_STATUS,
    ACCEPT_SURVEY_VOLUME,
    REMOVE_SURVEY_ADMIN,
    REDO_SURVEY_ADMIN,
    STORE_REMOVE_ALL_SURVEY,
    GET_PRODUCT,
    GET_COCA_BOUNDINGBOX
}
    from '../../../store/constants/serviceEndpoint';
import * as enums from '../../../common/enum';
import { removeToken } from '../../../crypto/Crypto'
import { headerToken } from '../../../store/constants/header';
import { withRouter } from 'react-router-dom';
import * as router from '../../../common/route';
import * as actions from '../../../store/actions/index';
import * as actionTypes from '../../../store/constants/actionTypes';
// import { baseUrl } from '../../../common/config'; 
import StoreInfo from '../info/storeInfo';
import ChangeChannel from '../info/changeChannelPackage';
import Maps from '../maps/maps';
import * as axiosWrapper from '../../../store/constants/axiosWrapper';

const defaultImage = require("../assets/img/noimage.jpg")
const moment = require("moment")

class DetailInfoInt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailstore: {},
            listInfo: [],
            dataImage: [],
            isOpen: false,
            visible: false,
            titleDialog: "",
            contentDialog: "",
            reason: "",
            steps: 0,
            storeId: null,
            surveyId: null,
            listproduct: [],
            isLoading: false,
            visibleDialog: false,
            channelId: null,
            channelPackageId: null
        }
    }

    componentDidMount() {
        let eventId = this.props.match.params.eventId;
        let storeId = this.props.match.params.storeId;

        if (eventId === undefined || eventId === null) {
            eventId = localStorage.getItem(actionTypes.STORAGE_EVENTID);
        }

        if (storeId === undefined || storeId === null) {
            storeId = this.props.match.params.id;
        }

        this.setState({
            eventId: eventId,
            storeId: storeId
        })

        if (storeId !== undefined && storeId !== null) {
            this.getDetailSurvey(storeId);
        }
        this.getListProduct();
    }

    reRender() {
        this.getDetailSurvey(this.state.storeId);
    }

    getListProduct = () => {
        const _this = this;
        // axios({
        //     method: 'post',
        //     url: GET_PRODUCT,
        //     headers: headerToken(),
        // })
        axiosWrapper.axiosWithHeaders('post', GET_PRODUCT)
            .then(function (response) {
                let { data } = response;
                switch (data.status) {
                    case enums.RESPONSE_OK:

                        let { result } = data;
                        if (result !== undefined && result !== null && result.length > 0) {
                            _this.setState({
                                listproduct: result
                            })
                        } else {
                            Swal.fire("Lỗi", "Vui lòng thêm sản phẩm mới", "error");
                        }
                        break;
                    default:
                        Swal.fire("Lỗi", data.message, "error")
                        removeToken();
                        _this.props.auth_ini();
                        _this.props.history.push(router.LOGIN);
                        break;
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getBoxesAI = (surveyId, surveyImageId, listImages) => {
        const _this = this;
        // axios({
        //     method: 'post',
        //     url: GET_COCA_BOUNDINGBOX,
        //     data: {
        //         survey_id: surveyId,
        //         survey_image_id: surveyImageId
        //     }
        // })
        axiosWrapper.axiosWithHeaders('post', GET_COCA_BOUNDINGBOX, {
            survey_id: surveyId,
            survey_image_id: surveyImageId
        })
            .then(function (response) {
                if (response.status === enums.RESPONSE_TOKEN_INVALID) {
                    removeToken();
                    _this.props.auth_ini();
                    _this.props.history.push(router.LOGIN);
                }
                if (response.status === enums.RESPONSE_OK) {
                    let result = response.data.data;
                    if (result !== undefined && result !== null && Object.keys(result).length > 0) {
                        let data = result.result;
                        if (data !== undefined && data !== null && data.length > 0) {
                            if (listImages !== undefined && listImages !== null && listImages.length > 0) {

                                const listproducts = _this.state.listproduct;

                                data.forEach(item => {
                                    listproducts.forEach(ktem => {
                                        if (item.product_id === ktem.id) {
                                            item["product_name"] = ktem.name;
                                        }
                                    })
                                });

                                // listImages.map((image)=>{
                                //     if(image.id === surveyImageId){
                                //         image.list_product = data;
                                //     }
                                // });

                                listImages.forEach(image => {
                                    if (image.id === surveyImageId) {
                                        image.list_product = data;
                                    }
                                })

                                let listInfo = _this.state.listInfo;
                                if (listInfo !== undefined && listInfo !== null && listInfo.length > 0) {
                                    listInfo.forEach(survey => {
                                        if (survey.id === surveyId) {
                                            survey.listImages = listImages;
                                        }
                                    });
                                }
                                _this.setState({
                                    listInfo: listInfo
                                })
                            }
                        }
                    }
                }
            });
    }

    getDetailSurvey = (storeId) => {
        const _this = this;
        // axios({
        //     method: 'get',
        //     url: DETAIL_SURVEY_ADMIN,
        //     headers: headerToken(),
        //     params: {
        //         StoreId: storeId,
        //         // EventId: eventId
        //     }
        // })
        axiosWrapper.axiosWithHeaders('get', DETAIL_SURVEY_ADMIN, {
            StoreId: storeId,
            // EventId: eventId
        })
            .then(function (response) {
                const { data } = response;
                switch (data.status) {
                    case enums.RESPONSE_OK:
                        const { listSurvey, detail } = data;
                        let objState = {}
                        if (detail !== undefined && detail !== null && Object.keys(detail).length > 0) {
                            objState.detailstore = detail;
                            localStorage.setItem(actionTypes.STORAGE_EVENTID, detail.eventId);
                        }
                        if (listSurvey !== undefined && listSurvey !== null && listSurvey.length > 0) {
                            objState.listInfo = listSurvey;
                            objState.storeId = storeId;
                            objState.channelId = listSurvey[0].channelId;
                            objState.channelPackageId = listSurvey[0].channelPackageId;
                            objState.surveyId = listSurvey[0].id;

                            //Xu ly AI
                            listSurvey.forEach(item => {
                                let surveyId = item.id;
                                let { listImages } = item;
                                if (listImages.length > 0) {
                                    listImages.forEach(image => {
                                        let surveyImageId = image.id;
                                        _this.getBoxesAI(surveyId, surveyImageId, listImages);
                                    })
                                }
                            })

                            _this.setState(objState);
                        }
                        break;
                    default:
                        Swal.fire("Lỗi", data.message, "error");
                        removeToken();
                        _this.props.auth_ini();
                        _this.props.history.push(router.LOGIN);
                        break;
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    renderApproveStatus(ID) {
        switch (ID) {
            case 4:
                return (
                    <div style={{ marginTop: -2 }}>
                        <img src={require('../assets/img/cancel.svg')} style={{ height: 30, objectFit: 'contain', paddingRight: 15 }} alt="Không đạt" />
                        <span style={{ fontWeight: 'bolder', color: '#BF1E2E' }}>Không đạt</span>
                    </div>
                )
            case 2:
                return (
                    <div style={{ marginTop: -2 }}>
                        <img src={require('../assets/img/pending.svg')} style={{ height: 30, objectFit: 'contain', paddingRight: 15 }} alt="Đang chờ" />
                        <span style={{ fontWeight: 'bolder', color: '#BF1E2E' }}>Đang chờ</span>
                    </div>
                )
            case 3:
                return (
                    <div style={{ marginTop: -2 }}>
                        <img src={require('../assets/img/approve.svg')} style={{ height: 30, objectFit: 'contain', paddingRight: 15 }} alt="Đạt" />
                        <span style={{ fontWeight: 'bolder', color: '#BF1E2E' }}>Đạt</span>
                    </div>
                )
            default:
                return '';
            // break;
        }
    }

    renderVolumnStatus(ID) {
        switch (ID) {
            case 2:
                return (
                    <div style={{ paddingTop: 5 }}>
                        <span style={{ fontWeight: 'bolder', color: '#BF1E2E' }}>Không đạt</span>
                    </div>
                )
            case 1:
                return (
                    <div style={{ paddingTop: 5 }}>
                        <span style={{ fontWeight: 'bolder', color: '#BF1E2E' }}>Đạt</span>
                    </div>
                )
            default:
                return (
                    <div style={{ paddingTop: 5 }}>
                        <span style={{ fontWeight: 'bolder', color: '#BF1E2E' }}>Chưa xử lý</span>
                    </div>
                )
        }
    }

    renderSurveyStatus(ID) {
        switch (ID) {
            case 4:
                return (
                    <div style={{ paddingTop: 5 }}>
                        <span style={{ fontWeight: 'bolder', color: '#BF1E2E' }}>Chờ duyệt</span>
                    </div>
                )
            case 3:
                return (
                    <div style={{ paddingTop: 5 }}>
                        <span style={{ fontWeight: 'bolder', color: '#BF1E2E' }}>Làm lại</span>
                    </div>
                )
            case 2:
                return (
                    <div style={{ paddingTop: 5 }}>
                        <span style={{ fontWeight: 'bolder', color: '#BF1E2E' }}>Không đạt</span>
                    </div>
                )
            case 1:
                return (
                    <div style={{ paddingTop: 5 }}>
                        <span style={{ fontWeight: 'bolder', color: '#BF1E2E' }}>Đạt</span>
                    </div>
                )
            default:
                return (
                    <div style={{ paddingTop: 5 }}>
                        <span style={{ fontWeight: 'bolder', color: '#BF1E2E' }}>Chưa xử lý</span>
                    </div>
                )
        }
    }

    redoSurveyAdmin = () => {
        const reason = this.state.reason;
        if (reason === undefined || reason === null || reason === "") {
            this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập lý do !' });
            return;
        }
        const objUpdate = {}
        objUpdate.SurveyId = this.state.surveyId;
        objUpdate.Note = this.state.reason;
        objUpdate.Status = 3;
        const _this = this;
        // axios({
        //     method: 'post',
        //     url: REDO_SURVEY_ADMIN,
        //     headers: headerToken(),
        //     data: objUpdate
        // })
        axiosWrapper.axiosWithHeaders('post', REDO_SURVEY_ADMIN, objUpdate)
            .then(function (response) {
                if (response.status === 200) {
                    _this.getDetailSurvey(_this.state.storeId)
                    _this.setState({
                        visible: false,
                        titleDialog: "",
                        contentDialog: "",
                        steps: 0,
                        surveyId: null,
                        reason: ""
                    })
                    Swal.fire("Thông báo", "Cập nhật dữ liệu thành công !", "success")
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    removeSurveyAdmin = () => {
        const reason = this.state.reason;
        if (reason === undefined || reason === null || reason === "") {
            this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập lý do !' });
            return;
        }
        const objUpdate = {}
        objUpdate.SurveyId = this.state.surveyId;
        objUpdate.Note = this.state.reason;

        const _this = this;
        // axios({
        //     method: 'post',
        //     url: REMOVE_SURVEY_ADMIN,
        //     headers: headerToken(),
        //     data: objUpdate
        // })
        axiosWrapper.axiosWithHeaders('post', REMOVE_SURVEY_ADMIN, objUpdate)
            .then(function (response) {
                if (response.status === 200) {
                    _this.getDetailSurvey(_this.state.storeId)
                    _this.setState({
                        visible: false,
                        titleDialog: "",
                        contentDialog: "",
                        steps: 0,
                        surveyId: null,
                        reason: ""
                    });
                    Swal.fire("Thông báo", "Cập nhật dữ liệu thành công !", "success")
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }


    storeRemoveAllSurvey() {
        const reason = this.state.reason;
        if (reason === undefined || reason === null || reason === "") {
            this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập lý do !' });
            return;
        }
        const objUpdate = {}
        objUpdate.StoreId = this.state.storeId;
        objUpdate.Note = this.state.reason;
        const _this = this;
        // axios({
        //     method: 'post',
        //     url: STORE_REMOVE_ALL_SURVEY,
        //     headers: headerToken(),
        //     data: objUpdate
        // })
        axiosWrapper.axiosWithHeaders('post', STORE_REMOVE_ALL_SURVEY, objUpdate)
            .then(function (response) {
                if (response.status === 200) {
                    _this.getDetailSurvey(_this.state.storeId)
                    _this.setState({
                        visible: false,
                        titleDialog: "",
                        contentDialog: "",
                        steps: 0,
                        reason: ""
                    })
                    Swal.fire("Thông báo", "Cập nhật dữ liệu thành công !", "success");
                    _this.props.history.push('/event/listinfo/' + _this.state.eventId)
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    renderButtonImageResult() {
        let { account } = this.props;

    }

    openDialogChangeChannel(idx) {
        const dataImage = this.state.listInfo;
        console.log(dataImage)
        this.setState({
            visibleDialog: true,
            surveyId: dataImage[idx].id,
            channelId: dataImage[idx].channelId,
            channelPackageId: dataImage[idx].channelPackageId
        })
    }

    renderButtonAction(infoId, idx) {
        let { account } = this.props;
        if (account !== undefined && account !== null && Object.keys(account).length > 0) {
            let { userInfo, level } = account;
            if (userInfo !== undefined && userInfo !== null && Object.keys(userInfo).length > 0) {
                // let { level } = userInfo;
                if (level == 6) {
                    return (
                        <div className="form-undo">
                            <Button
                                // label="Làm lại" 
                                tooltip="Thay đổi kênh/suất"
                                icon="pi pi-pencil"
                                className="p-button-raised p-button-primary"
                                style={{ marginRight: 10 }}
                                onClick={(index) => this.openDialogChangeChannel(idx)}
                            />
                            <Button
                                // label="Làm lại" 
                                tooltip="Làm lại"
                                icon="pi pi-undo"
                                className="p-button-raised p-button-warning"
                                style={{ marginRight: 10 }}
                                onClick={() => this.setState({
                                    visible: true,
                                    titleDialog: "XÁC NHẬN CHO PHÉP LÀM LẠI",
                                    contentDialog: "Bạn có muốn cho phép suất này được làm lại hay không ?",
                                    steps: 2,
                                    surveyId: infoId
                                })}
                            />
                            <Button
                                // label="Xóa suất" 
                                tooltip="Xóa suất"
                                icon="pi pi-trash"
                                className="p-button-raised p-button-danger"
                                onClick={() => this.setState({
                                    visible: true,
                                    titleDialog: "XÁC NHẬN XÓA SUẤT",
                                    contentDialog: "Bạn có muốn xóa suất này hay không ?",
                                    steps: 3,
                                    surveyId: infoId
                                })}
                            />
                        </div>
                    )
                }
            }
            return null;
        }
        return null;
    }

    renderGroupImage() {
        const dataImage = this.state.listInfo;
        // console.log(dataImage)
        const infoUser = this.props.account.userInfo;
        const level = this.props.account.level;
        if (dataImage !== undefined
            && dataImage !== null
            && dataImage.length > 0
            && infoUser !== undefined
            && infoUser !== null
            && Object.keys(infoUser).length > 0
        ) {
            let html = [];
            dataImage.map((info, i) => {
                return html.push(
                    <Fieldset legend={"SUẤT " + (i + 1) + ' - ' + info.nameChannel + ' - ' + info.namePackage} style={{ position: "relative", marginTop: 30 }} key={i}>
                        {
                            this.renderButtonAction(info.id, i)
                        }
                        <div className="p-grid" style={{ borderBottom: '1px #ccc solid', marginBottom: 5 }}>
                            <div className="p-xl-8 p-lg-8 p-md-12 p-col-12" style={{ paddingLeft: 10 }}>
                                <div className="p-grid">
                                    <div className="p-col-fixed" style={{ textAlign: "right", width: "150px", paddingBottom: 0 }}>
                                        <label>Thời gian gửi:</label>
                                    </div>
                                    <div className="p-col" style={{ paddingBottom: 0, paddingLeft: 13 }}>
                                        <label style={{ fontWeight: "bold" }}>
                                            {
                                                moment(new Date(info.datePost)).format("DD/MM/YYYY HH:mm:ss")
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid" style={{ borderBottom: '1px #ccc solid', paddingTop: 5 }}>
                            <div className="p-xl-8 p-lg-8 p-md-12 p-col-12" style={{ paddingLeft: 10 }}>
                                <div className="p-grid">
                                    <div className="p-col-fixed" style={{ textAlign: "right", width: "150px", paddingBottom: 0 }}>
                                        <label style={{ paddingTop: 5 }}>Kết quả Volumne: </label>
                                    </div>
                                    <div className="p-col" style={{ marginBottom: 0, paddingLeft: 13 }}>
                                        {
                                            this.renderVolumnStatus(info.volume)
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="p-xl-4 p-lg-8 p-md-12 p-col-12 p-no-padding" style={{ textAlign: "right", padding: 0 }}>
                                {
                                    level == 6 && (
                                        <div style={{ paddingTop: 5 }}>
                                            <Button
                                                tooltip="Duyệt"
                                                className="p-button-raised p-button-success"
                                                icon="pi pi-key"
                                                // style={{marginRight: 5}}
                                                onClick={(e, surveyId) => this.acceptSurveyVolume(e, info.id)}
                                            />
                                        </div>
                                    )
                                }

                            </div>

                        </div>

                        <div className="p-grid" style={{ borderBottom: '1px #ccc solid', paddingTop: 15, paddingBottom: 4 }}>
                            <div className="p-xl-8 p-lg-8 p-md-12 p-col-12" style={{ paddingLeft: 10, paddingTop: 0, paddingBottom: 0 }}>
                                <div className="p-grid">
                                    <div className="p-col-fixed" style={{ textAlign: "right", width: "150px", marginBottom: 0 }}>
                                        <label style={{ paddingTop: 5 }}>Kết quả Hình ảnh: </label>
                                    </div>
                                    <div className="p-col" style={{ marginBottom: 0, paddingLeft: 13 }}>
                                        {
                                            this.renderSurveyStatus(info.status)
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="p-xl-4 p-lg-4 p-md-12 p-col-12 p-no-padding" style={{ textAlign: "right" }}>
                                {
                                    (level == 6 || level == 10) && (
                                        <Button
                                            label="Đạt"
                                            className="p-button-raised p-button-success"
                                            icon="pi pi-key"
                                            style={{ marginRight: 5 }}
                                            // onClick={(e, surveyId, status)=>this.acceptSurveyStatus(e, info.id, 1)}
                                            onClick={(e) => this.setState({
                                                visible: true,
                                                titleDialog: "XÁC NHẬN CHẤM  ĐẠT",
                                                contentDialog: "Bạn có muốn chấm  đạt kết quả này hay không ?",
                                                steps: 5,
                                                surveyId: info.id
                                            })}
                                        />
                                    )
                                }
                                {
                                    (level == 6 || level == 10) && (
                                        <Button
                                            label="Không đạt"
                                            className="p-button-raised p-button-info"
                                            icon="pi pi-lock"
                                            onClick={(e) => this.setState({
                                                visible: true,
                                                titleDialog: "XÁC NHẬN CHẤM KHÔNG ĐẠT",
                                                contentDialog: "Bạn có muốn chấm không đạt kết quả này hay không ?",
                                                steps: 4,
                                                surveyId: info.id
                                            })}
                                        />
                                    )
                                }

                            </div>
                        </div>
                        {
                            (info.note !== undefined && info.note !== null && info.note !== "") && (
                                <div className="p-grid" style={{ paddingBottom: 10, paddingTop: 10 }}>
                                    <div className="p-col-fixed" style={{ textAlign: "right", width: "153px" }}>
                                        <label style={{ paddingTop: 5 }}>Lý do:  </label>
                                    </div>
                                    <div className="p-col">
                                        <InputTextarea value={info.note} style={{ width: "100%", color: "red", border: "unset", resize: "none" }} rows={4} readOnly autoResize={false} />
                                    </div>
                                </div>
                            )
                        }
                        <div className="p-grid" style={{ marginTop: 20 }}>
                            {
                                this.renderImage(info.listImages)
                            }
                        </div>
                    </Fieldset>
                )
            }, this)
            return html;
        }
        return null;
    }

    renderImage(dataImage, rowIdx) {
        // console.log(dataImage);
        if (dataImage.length > 0) {
            dataImage.forEach((item) => {
                item["title"] = item["nameImage"];
                item["description"] = item["descriptionImage"];
                item["src"] = item.hostUrl + item.path;
            });
            return (
                <Lightbox images={
                    dataImage
                }
                    renderImageFunc={(idx, image, toggleLightbox) => {
                        return (
                            <div
                                className="p-col-3 text-center"
                                style={{ position: "relative" }}
                                key={image.id}
                            >
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className='d-flex align-items-center justify-content-center' style={{ border: '1px solid #ccc', height: 60 }}>
                                            <div className='title-image line-clamp' style={{ fontSize: 12 }}><a title={image.title}>{image.title}</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12" style={{ paddingTop: 0, marginTop: -1 }}>
                                        <img
                                            alt={image.title}
                                            key={idx}
                                            src={image.src}
                                            style={{ height: 175, width: '100%', objectFit: 'contain', border: '1px solid #ccc' }}
                                            onClick={toggleLightbox.bind(null, idx)}
                                            onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }}
                                        />
                                    </div>
                                </div>

                                <a href={`https://www.google.com/maps/search/?api=1&query=${image.latitude},${image.longitude}`} target="_blank" rel="noopener">{parseFloat(image.latitude).toFixed(6) + ',' + parseFloat(image.longitude).toFixed(6)}</a>
                            </div>
                        )
                    }}
                />
            )
        }
        return null;
    }

    updateSurveyStatus = (e, surveyId, status) => {
        const _this = this;

        if (status === 2 && (this.state.reason === undefined || this.state.reason === null || this.state.reason === "")) {
            this.messages.show({ severity: 'error', summary: 'Lỗi', detail: 'Vui lòng nhập lý do !' });
            return;
        }
        const objUpdate = {}
        objUpdate.SurveyId = surveyId;
        objUpdate.Note = "";

        //  if(status===2){
        objUpdate.Note = this.state.reason;
        // }
        objUpdate.Status = status;

        // axios({
        //     method: 'post',
        //     url: ACCEPT_SURVEY_STATUS,
        //     headers: headerToken(),
        //     data: objUpdate
        // })
        axiosWrapper.axiosWithHeaders('post', ACCEPT_SURVEY_STATUS, objUpdate)
            .then(function (response) {
                if (response.status === 200) {
                    _this.getDetailSurvey(_this.state.storeId)
                    _this.setState({
                        visible: false,
                        titleDialog: "",
                        contentDialog: "",
                        steps: 0,
                        reason: ""
                    })
                    Swal.fire("Thông báo", "Cập nhật dữ liệu thành công !", "success")
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    acceptSurveyStatus = (e, surveyId, status) => {
        Swal.fire({
            title: 'Cảnh báo',
            text: "Bạn có muốn chấm đạt cho suất này không ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý!',
            cancelButtonText: 'Trở về'
        }).then((result) => {
            if (result.value) {
                this.updateSurveyStatus(e, surveyId, status);
            }
        })

    }

    acceptSurveyVolume = (e, surveyId) => {
        Swal.fire({
            title: 'Cảnh báo',
            text: "Bạn có muốn duyệt kết quả volume cho suất này không ?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Đồng ý!',
            cancelButtonText: 'Trở về'
        }).then((result) => {
            if (result.value) {
                const objUpdate = {}
                objUpdate.SurveyId = surveyId;
                objUpdate.Note = "";
                objUpdate.Status = 1;
                const _this = this;
                // axios({
                //     method: 'post',
                //     url: ACCEPT_SURVEY_VOLUME,
                //     headers: headerToken(),
                //     data: objUpdate
                // })
                axiosWrapper.axiosWithHeaders('post', ACCEPT_SURVEY_VOLUME, objUpdate)
                    .then(function (response) {
                        if (response.status === 200) {
                            _this.getDetailSurvey(_this.state.storeId)
                            _this.setState({
                                visible: false,
                                titleDialog: "",
                                contentDialog: "",
                                steps: 0,
                                reason: ""
                            })
                            Swal.fire("Thông báo", "Cập nhật dữ liệu thành công !", "success")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            }
        })
    }

    backToList = (e) => {
        this.props.history.push("/event/listinfo/" + this.state.eventId);
    }

    deleteAllChannel = () => {
        this.setState({
            visible: true,
            titleDialog: "XÁC NHẬN XÓA TOÀN BỘ SUẤT",
            contentDialog: "Bạn có muốn bỏ duyệt toàn bộ hay không ?",
            steps: 1
        })
    }

    render() {
        const state = this.state;
        const infoUser = this.props.account.userInfo;
        const footer = (
            <div>
                {(state.steps === 4) && (
                    <Button label="Có" icon="pi pi-check" className="p-button-raised" onClick={(e, surveyId, status) => this.updateSurveyStatus(e, this.state.surveyId, 2)} />
                )}
                {(state.steps === 3) && (
                    <Button label="Có" icon="pi pi-check" className="p-button-raised" onClick={() => this.removeSurveyAdmin()} />
                )}
                {(state.steps === 2) && (
                    <Button label="Có" icon="pi pi-check" className="p-button-raised" onClick={() => this.redoSurveyAdmin()} />
                )}
                {(state.steps === 1) && (
                    <Button label="Có" icon="pi pi-check" className="p-button-raised" onClick={() => this.storeRemoveAllSurvey()} />
                )}
                {(state.steps === 5) && (
                    <Button label="Có" icon="pi pi-check" className="p-button-raised" onClick={(e) => this.acceptSurveyStatus(e, this.state.surveyId, 1)} />
                )}
                <Button label="Không" icon="pi pi-times" className="p-button-raised p-button-secondary" onClick={() => this.setState({ visible: false, titleDialog: "", contentDialog: "", steps: 0, reason: "" })} />
            </div>
        );

        const datacontent = (this.state.isLoading) ?
            (
                <div className="p-col-12">
                    <LoadingView />
                </div>
            ) : (
                <div className="p-grid" style={{ marginTop: 10 }}>
                    <Dialog header={state.titleDialog} visible={this.state.visible} style={{ width: 450 }} modal={true} onHide={() => this.setState({ visible: false })} footer={footer}>
                        <Messages ref={(el) => this.messages = el}></Messages>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <label style={{ color: "red", textTransform: "uppercase" }}>{state.contentDialog}</label>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12" style={{ paddingBottom: 0 }}>
                                <label>Lý do (nếu chọn có): </label>
                            </div>
                            <div className="p-col-12" style={{ paddingTop: 0 }}>
                                <InputTextarea rows={5} cols={30} value={this.state.reason} onChange={(e) => this.setState({ reason: e.target.value })} style={{ width: "100%" }} placeholder="Vui lòng nhập lý do!" />
                            </div>
                        </div>
                    </Dialog>

                    <div className="p-xl-6 p-lg-6 p-md-12 p-col-12">
                        <Card style={{ width: "100%" }}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <Button label="QUAY VỀ DANH SÁCH" icon="pi pi-chevron-left" className="p-button-raised p-button-secondary" onClick={(e) => this.backToList(e)} />
                                </div>
                            </div>
                            {
                                this.renderGroupImage()
                            }
                        </Card>
                    </div>
                    <div className="p-xl-6 p-lg-6 p-md-12 p-col-12">
                        <StoreInfo data={state["detailstore"]} deleteAllChannel={() => this.deleteAllChannel()} showRemove={true} />
                    </div>
                </div>
            )


        if (state["detailstore"] !== undefined
            && state["detailstore"] !== null
            && Object.keys(state["detailstore"]).length > 0
            && infoUser !== undefined
            && infoUser !== null
            && Object.keys(infoUser).length > 0
        ) {
            return (
                <>
                    <div style={{ fontSize: 24, marginTop: 20, color: `#de1f27`, fontWeight: 900 }}>{localStorage.getItem(actionTypes.STORAGE_EVENTNAME)}</div>

                    <div className="p-grid">
                        <div className="p-col-12">
                            {datacontent}
                        </div>
                        <ChangeChannel
                            visible={this.state.visibleDialog}
                            channelId={this.state.channelId}
                            channelPackageId={this.state.channelPackageId}
                            surveyId={this.state.surveyId}
                            onChangeVisible={value => this.setState({
                                visibleDialog: value
                            })}
                            reRender={() => this.reRender()}
                        />
                    </div>
                </>
            )
        }
        return null;
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        events: state.events,
        account: state.account
    }
}

const mapDispatchToProps = dispatch => {
    return {
        auth_ini: () => dispatch(actions.auth_ini()),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailInfoInt));